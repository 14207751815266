import { Skeleton, SkeletonProps } from "@mui/material";
import { ReactNode } from "react";

interface SkeletonWrapperProps {
  /** Used to roughly determine the width of the loading skeleton. Defaults to `15` chars. */
  approxChars?: number;
  children: ReactNode;
  /** Defaults to `1.0em`. */
  height?: number | string;
  /** Defaults to `true` when `children` is `undefined`. */
  loading?: boolean;
  /** Defaults to `rectangular`. */
  variant?: SkeletonProps["variant"];
}

function SkeletonWrapper({ approxChars = 15, children, height, loading = children === undefined, variant = "rectangular" }: SkeletonWrapperProps) {
  return (
    <>
      {!loading && children}
      {!!loading && <Skeleton style={{ display: "inline-block", height }} variant={variant} width={`${approxChars * 0.5}em`} />}
    </>
  );
}

export { SkeletonWrapper };
