import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetAssociatedAdvisersResponse, GetTransactionTypesResponse, GetTransactionTypesTransType, ProblemDetails } from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { addLoading } from "./helpers";

interface advisersState {
  adviserBranches?: AdviserBranch[];
  adviserGroups?: AdviserGroup[];
  advisers?: Adviser[];
  autoSearch: boolean;
  isLoading: boolean;
  errors?: ProblemDetails;
  transactionTypes?: GetTransactionTypesTransType[];
}

interface Adviser {
  branchKeys: string[];
  groupKeys: string[];
  name: string;
  number: string;
}

interface AdviserBranch {
  key: string;
  groupKeys: string[];
  name: string;
}
interface AdviserGroup {
  key: string;
  name: string;
}

const initialState: advisersState = {
  autoSearch: false,
  isLoading: false,
};

export const associatedAdvisersAsync = createAsyncThunk<GetAssociatedAdvisersResponse, void, { state: RootState }>(
  "advisers/associatedAdvisers",
  (_, { getState, rejectWithValue, signal }) => getApis(getState()).adviserClient.associatedAdvisers(signal).catch(rejectWithValue)
);

export const transactionTypesAsync = createAsyncThunk<GetTransactionTypesResponse, void, { state: RootState }>(
  "advisers/transactionTypes",
  (_, { getState, rejectWithValue, signal }) => getApis(getState()).adviserClient.transactionTypes(signal).catch(rejectWithValue)
);

export const advisersSlice = createSlice({
  name: "advisers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addLoading(builder, associatedAdvisersAsync, (state, response) => {
      state.adviserGroups = Array.from(new Set(response.results.map((adviser) => adviser.adviserGroupKey).filter((groupKey) => !!groupKey)))
        .map((groupKey) => ({
          key: groupKey,
          name: response.results.find((adviser) => adviser.adviserGroupKey === groupKey)?.adviserGroup || "",
        }))
        .sort((a, b) => a.name?.localeCompare(b.name));

      state.adviserBranches = Array.from(new Set(response.results.map((adviser) => adviser.adviserBranchKey).filter((branchKey) => !!branchKey)))
        .map((branchKey) => {
          const advisers = response.results.filter((adviser) => adviser.adviserBranchKey === branchKey);

          return {
            groupKeys: Array.from(new Set(advisers.map((adviser) => adviser.adviserGroupKey).filter((groupKey) => !!groupKey))),
            key: branchKey,
            name: advisers[0]?.adviserBranch || "",
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      state.advisers = Array.from(new Set(response.results.map((adviser) => adviser.adviserCode).filter((adviserCode) => !!adviserCode)))
        .map((adviserCode) => {
          const advisers = response.results.filter((adviser) => adviser.adviserCode === adviserCode);

          return {
            branchKeys: Array.from(new Set(advisers.map((adviser) => adviser.adviserBranchKey).filter((branchKey) => !!branchKey))),
            groupKeys: Array.from(new Set(advisers.map((adviser) => adviser.adviserGroupKey).filter((groupKey) => !!groupKey))),
            name: advisers[0].name || "",
            number: adviserCode,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    });

    addLoading(builder, transactionTypesAsync, (state, response) => {
      state.transactionTypes = response.transactionTypes;
    });
  },
});

export type { Adviser, AdviserBranch };
export default advisersSlice.reducer;
