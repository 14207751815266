import { Box, Grid } from "@mui/material";
import { useMemo } from "react";
import { PieTaxableIncome } from "../../api";
import { useAppSelector } from "../../store";
import { AccountPieTaxByFundFootnote } from "../../store/reports";
import { formatPercent } from "../../utils";
import { Panel, Typography } from "../common";
import Table from "../table";

export default function AccountPieTaxByFund() {
  const { isLoadingAccountPieTaxByFund, accountPieTaxByFund, accountPieTaxByFundFootnotes } = useAppSelector((as) => as.reports);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isLoadingAccountPieTaxByFund && !accountPieTaxByFund ? null : (
        <Table
          loading={isLoadingAccountPieTaxByFund}
          loadingBlankRows={3}
          pageSizeHidden
          rows={accountPieTaxByFund?.pieTaxableIncomes}
          columns={[
            {
              heading: "Fund",
              field: "fund",
              display: "emphasize",
              footer: () => "Total",
              width: 26,
            },
            {
              heading: "Net income",
              field: "netIncome",
              type: "money",
              footer: "sum",
              width: 9,
            },
            {
              heading: "PIE tax rate",
              field: (row: PieTaxableIncome) => (
                <FormatWithFootnote
                  accountPieTaxByFundFootnotes={accountPieTaxByFundFootnotes}
                  fieldValue={row.pieTaxRate}
                  footnoteField="PieTaxRate"
                  formatValue={formatPercent}
                />
              ),
              align: "center",
              width: 9,
            },
            {
              heading: "Used FTC",
              headingHover: "Used foreign tax credits",
              field: "usedFTC",
              type: "money",
              footer: "sum",
              width: 6,
            },
            {
              heading: "Unused FTC",
              headingHover: "Unused foreign tax credits",
              field: "unusedFTC",
              type: "money",
              footer: "sum",
              width: 8,
            },
            {
              heading: "IC",
              headingHover: "Imputation credits",
              field: "ic",
              type: "money",
              footer: "sum",
              width: 6,
            },
            {
              heading: "RWT",
              headingHover: "Resident withholding tax",
              field: "rwt",
              type: "money",
              footer: "sum",
              width: 6,
            },
            {
              heading: "DWP",
              headingHover: "Dividend withholding payment",
              field: "dwp",
              type: "money",
              footer: "sum",
              width: 6,
            },
            {
              heading: "Expenses",
              field: "expenses",
              type: "money",
              footer: "sum",
              width: 6,
            },
            {
              heading: "Tax payable",
              field: "taxPayable",
              type: "money",
              footer: "sum",
              width: 9,
            },
            {
              heading: "ZR",
              headingHover: "Zero rated",
              field: (row: PieTaxableIncome) => (
                <FormatWithFootnote accountPieTaxByFundFootnotes={accountPieTaxByFundFootnotes} fieldValue={row.zeroRated} footnoteField="ZR" />
              ),
              align: "center",
              width: 6,
            },
            {
              heading: "EI",
              headingHover: "Exiting investor",
              field: (row: PieTaxableIncome) => (
                <FormatWithFootnote accountPieTaxByFundFootnotes={accountPieTaxByFundFootnotes} fieldValue={row.exitingInv} footnoteField="EI" />
              ),
              align: "center",
              width: 3,
            },
            {
              heading: "Paid",
              headingHover: "Tax/rebate paid",
              field: "taxPaid",
              type: "money",
              footer: "sum",
              width: 9,
            },
          ]}
        />
      )}
      <Panel noBorderTop>
        <Grid container>
          <Grid item xs={12} sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 0.75 }}>
            <Typography variant="section-heading">Additional Information</Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1.5 }}>
            <Typography variant="footnote">
              <Grid container spacing={1}>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">1</Typography>
                  PIE tax information for periods other than full financial years ended 31 March is indicative only and subject to change.
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">2</Typography>
                  PIE income is treated as being received in the investor&apos;s income year that includes the end of the PIE&apos;s income year (all NZ
                  Funds&apos; PIEs use a 31 March balance date). This means that, for investors with non-standard balance dates, the year in which the income is
                  received may be different from the year in which PIE income is attributed. Refer to the Inland Revenue&apos;s Portfolio Investment Entity
                  Guide (IR860) for more information.
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">3</Typography>
                  The Tax Report includes all transactions and amounts relating to a tax year (1 April to 31 March). This includes payments/refunds for the
                  March quarter which are paid/received in April. The Financial Summary lists transactions in chronological order. <br />
                  This creates a timing difference in the reporting of March tax payments/receipts between these reports.
                </Grid>
                {accountPieTaxByFundFootnotes?.map((footnote: AccountPieTaxByFundFootnote, index: number) => (
                  <Grid item xs={12} fontSize={11} lineHeight={1.2} key={index}>
                    <Typography variant="superscript">{index + 4}</Typography>
                    {footnote.footnote}
                  </Grid>
                ))}
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Panel>
    </Box>
  );
}

function FormatWithFootnote({
  accountPieTaxByFundFootnotes,
  fieldValue,
  footnoteField,
  formatValue,
}: {
  accountPieTaxByFundFootnotes: AccountPieTaxByFundFootnote[] | undefined;
  fieldValue: string | undefined;
  footnoteField: string | undefined;
  formatValue?: (value: string) => string;
}) {
  const footnoteIndex = useMemo(
    () => accountPieTaxByFundFootnotes?.findIndex((obj) => obj.field === footnoteField) ?? 0,
    [accountPieTaxByFundFootnotes, footnoteField]
  );

  return fieldValue == "*" || fieldValue == undefined ? (
    <>
      *<Typography variant="superscript">{footnoteIndex + 4}</Typography>
    </>
  ) : (
    <>{formatValue ? formatValue(fieldValue) : fieldValue}</>
  );
}
