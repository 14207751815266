import { ThemeVariant } from "../theme";
import { accountTheme, clientTheme, primaryTheme } from "../theme/variants";

export function getThemeByName(name: ThemeVariant) {
  switch (name) {
    case "account":
      return accountTheme;
    case "client":
      return clientTheme;
    default:
      return primaryTheme;
  }
}

export function getThemeByRoute(pathname: string): ThemeVariant | undefined {
  if (pathname.startsWith("/account")) return "account";
  if (pathname.startsWith("/client") || pathname === "/") return "client";
  return undefined;
}
