import { CircularProgress, IconButton as MuiIconButton, SxProps, Theme } from "@mui/material";
import { MouseEvent, ReactNode, useMemo } from "react";

interface IconButtonProps {
  children: ReactNode;
  loading?: boolean;
  onClick?: (event: MouseEvent) => void;
  size?: "small" | "medium" | "large";
  sx?: SxProps<Theme>;
}

const circularProgress = <CircularProgress size="1em" sx={{ color: "common.brandMid" }} />;

function IconButton({ children, loading, onClick, size, sx = [] }: IconButtonProps) {
  const _sx = useMemo(() => (Array.isArray(sx) ? sx : [sx]), [sx]);

  return (
    <MuiIconButton disabled={loading} onClick={onClick} size={size} sx={_sx}>
      {loading ? circularProgress : children}
    </MuiIconButton>
  );
}

export { IconButton };
