import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FileType } from "../../api";
import { UploadFileData } from "../../store/adviceTools";
import { Button } from "../common";
import { Form, FormButton } from "../form";
import { ManageFile } from "./ManageFile";

export const EditDocument = (props: {
  admin: boolean;
  fileType: FileType;
  initialValues: UploadFileData;
  loading: boolean;
  subTextLabel: string;
  onClose: () => void;
  onSubmit: (values: UploadFileData) => void;
}) => {
  const validate = (values: UploadFileData) => {
    const errors: Partial<Record<keyof typeof values, string>> = {};

    if (!values.fileName) {
      errors.fileName = "Name is a required field";
    }

    if (!values.url && !values.file) {
      errors.file = "A file or URL is required";
    }

    if (!values.keywords) {
      errors.keywords = "Keywords are required";
    }

    if (!values.categorySelected) {
      errors.categorySelected = "Category is required";
    }

    if (props.fileType == FileType.Document) {
      if (!values.products || values.products.length === 0) {
        errors.products = "A product is required";
      }
    }

    return errors;
  };

  return (
    <Form initialValues={props.initialValues} validate={validate} onSubmit={props.onSubmit}>
      <Dialog open={!!props.initialValues} onClose={props.onClose} fullWidth maxWidth={"md"}>
        <DialogTitle>Edit {props.subTextLabel}</DialogTitle>
        <DialogContent>
          <ManageFile fileType={props.fileType} isAdmin={props.admin} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" autoFocus onClick={props.onClose}>
            Cancel
          </Button>
          <FormButton type="submit" loading={props.loading}>
            Update
          </FormButton>
        </DialogActions>
      </Dialog>
    </Form>
  );
};
