import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { securitiesAsync } from "../../store/securities";
import { SecuritiesDto } from "../../types";
import Table from "../table";

function AllSecurities() {
  const dispatch = useAppDispatch();
  const { key } = useParams<{ key: string }>();
  const { isLoadingSecurities, securities } = useAppSelector((as) => as.securities);
  const { values } = useFormikContext<SecuritiesDto>();

  return (
    <Table
      loading={isLoadingSecurities}
      minHeight={368}
      paging={{ ...securities }}
      rows={securities?.results}
      handlePageChange={(page) => {
        dispatch(
          securitiesAsync({
            accountNumber: key,
            currentPage: page,
            pageSize: 55,
            searchName: values.searchName,
          })
        );
      }}
      columns={[
        {
          heading: "Security",
          field: "name",
          display: "emphasize",
          width: 45,
        },
        {
          heading: "6 months",
          field: "sixMonthPerformance",
          display: "emphasize",
          width: 7,
          align: "right",
          type: "percent",
          typeOption: { decimalPlaces: 1 },
        },
        {
          heading: "YTD",
          field: "ytdPerformance",
          display: "emphasize",
          width: 5,
          align: "right",
          type: "percent",
          typeOption: { decimalPlaces: 1 },
        },
        {
          heading: "",
          field: () => "",
          width: 23,
          align: "center",
        },
        {
          heading: "% of total",
          field: "weight",
          display: "emphasize",
          width: 10,
          align: "right",
          type: "percent",
          typeOption: { decimalPlaces: 1 },
        },
        {
          heading: "Value",
          field: "amount",
          display: "emphasize",
          width: 15,
          type: "money",
        },
      ]}
    />
  );
}

export { AllSecurities };
