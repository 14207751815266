import { Grid } from "@mui/material";
import { useField } from "formik";
import { useCallback, useMemo, useState } from "react";
import { formatValue, parseQuarter } from "../../utils";
import { BaseAutocomplete } from "./";
import { ErrorMessage } from "./ErrorMessage";

interface QuarterPickerProps {
  name: string;
}

const getYears = () => {
  const minYear = 2007;
  const maxYear = new Date().getFullYear();

  return Array(maxYear - minYear + 1)
    .fill(undefined)
    .map((_, idx) => maxYear - idx);
};

const getQuarters = (year: number) => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();

  return [
    { value: 1, label: "Q1" },
    ...(year < currentYear || currentMonth > 4 ? [{ value: 2, label: "Q2" }] : []),
    ...(year < currentYear || currentMonth > 7 ? [{ value: 3, label: "Q3" }] : []),
    ...(year < currentYear || currentMonth > 10 ? [{ value: 4, label: "Q4" }] : []),
  ];
};

function QuarterPicker({ name }: QuarterPickerProps) {
  const [{ value }, , { setValue }] = useField<string | undefined>(name);
  const fieldValue = useMemo(() => parseQuarter(value), [value]);
  const [year, setYear] = useState<number | undefined>(fieldValue?.year);
  const [quarter, setQuarter] = useState<number | undefined>(fieldValue?.quarter);

  const setField = useCallback(
    (year?: number, quarter?: number) => {
      setValue(year && quarter ? `${year}-Q${quarter}` : undefined);
    },
    [setValue]
  );

  return (
    <>
      <Grid width="9.08em">
        <BaseAutocomplete
          disableClearable
          options={getYears().map((value) => ({ label: `${value}/${value + 1}`, value }))}
          value={fieldValue?.year || year}
          onChange={(value) => {
            setYear(value as number);
            setField(value as number, fieldValue?.quarter || quarter);
          }}
        />
      </Grid>
      <Grid width="5em">
        {(!!fieldValue?.year || !!year) && (
          <BaseAutocomplete
            disableClearable
            options={fieldValue?.year ? getQuarters(fieldValue?.year) : year ? getQuarters(year) : []}
            value={fieldValue?.quarter || quarter}
            onChange={(value) => {
              setQuarter(value as number);
              setField(fieldValue?.year || year, value as number);
            }}
          />
        )}
      </Grid>
      <Grid>{formatValue(fieldValue, { type: "quarter" })}</Grid>
      <ErrorMessage name={name} />
    </>
  );
}

export { QuarterPicker };
