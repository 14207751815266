import { TableSortLabel } from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import { SortIcon } from "../common";

interface SortLabelProps {
  active: boolean;
  children: ReactNode;
  direction?: "asc" | "desc";
  disabled?: boolean;
}

export function SortLabel({ active, children, direction, disabled }: SortLabelProps) {
  const [hover, setHover] = useState(false);
  const _direction = useMemo(() => {
    if (active) return hover ? (direction === "asc" ? "desc" : "asc") : direction;
    else return hover ? "asc" : undefined;
  }, [active, direction, hover]);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  return (
    <TableSortLabel
      active={active}
      direction={direction}
      disabled={disabled}
      IconComponent={() => <SortIcon direction={_direction} />}
      onClick={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </TableSortLabel>
  );
}
