import { Grid } from "@mui/material";
import { Typography } from "../../common/Typography";

function NoteToAdviser() {
  return (
    <Grid item container xs={12} paddingBottom={2}>
      <Grid item xs={2} alignSelf="top" paddingTop="8px">
        Note to Adviser
      </Grid>
      <Grid item container xs={9} alignItems="center" gap={1} alignSelf="top">
        <Typography variant="superscript">
          <p>You acknowledge that:</p>
          <p>
            a) by making this template Client Agreement available to you, New Zealand Funds Management Limited (NZ Funds) is not providing you with legal
            services;
          </p>
          <p>b) this template Client Agreement is not intended to replace professional guidance and may require modification to suit the circumstances.</p>
          <p>
            We recommend that each party (both the Adviser and the Client) seeks independent legal advice prior to entering into any agreement based on this
            template Client Agreement.
          </p>
          <p>
            This template Client Agreement is only suitable for the provision of financial advice on the NZ Funds Active Series. It does not address
            circumstances where the Adviser intends to provide financial advice services to the Client in relation to any other investments.
          </p>
          <p>
            NZ Funds and its directors, employees and officers are not liable or responsible to you or any other person for any loss or damage suffered or
            incurred in connection with the provision, use or modification of this template Client Agreement. This exclusion applies regardless of whether the
            liability or responsibility arises in contract, tort (including negligence), equity, breach of statutory duty, or otherwise.
          </p>
        </Typography>
      </Grid>
    </Grid>
  );
}

export { NoteToAdviser };
