import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { Autocomplete } from "../../form";
import { FeeAgreementField } from "../FeeAgreementField";
import { FeeAgreementList } from "../FeeAgreementList";

interface MmgFeeStructureProps {
  feeEntered: () => void;
}

function getDefaultFormValues() {
  return {
    feeType: undefined,
    flatAmount: undefined,
    tier1: undefined,
    tier2: undefined,
    tier3: undefined,
    tier4: undefined,
  };
}

function MmgFeeStructure({ feeEntered }: MmgFeeStructureProps) {
  const { setFieldValue, values } = useFormikContext<{ mmg?: { feeType?: "flat" | "tier" } }>();

  useEffect(() => {
    // clear the other fields if we change the adviser's agreement type.
    setFieldValue("pw", undefined);
    setFieldValue("gis", undefined);
    setFieldValue("mmg", getDefaultFormValues());
  }, [setFieldValue]);

  return (
    <Grid item container xs={12} md={6}>
      <FeeAgreementField
        label="Choose fee type"
        field={
          <>
            <Autocomplete
              name="mmg.feeType"
              options={[
                { value: "flat", label: "Flat" },
                { value: "tier", label: "Tiered" },
              ]}
              onChange={() => feeEntered()}
              onChangeClear={["mmg.flatAmount", "mmg.tier1", "mmg.tier2", "mmg.tier3", "mmg.tier4"]}
            />
            {values.mmg?.feeType === "flat" && (
              <FeeAgreementList value="flat" name="Flat rate" fees={[{ field: "mmg.flatAmount", name: "Amount pa", type: "amount" }]} />
            )}
            {values.mmg?.feeType === "tier" && (
              <FeeAgreementList
                value="tier"
                name="Tiered rate"
                field="mmg.tier1"
                heading={{ label: "Tier", field: "Fee" }}
                tiered
                fees={[
                  {
                    field: `mmg.tier4`,
                    name: `Tier 4`,
                    type: "rate",
                    thresholdTo: 50000,
                  },
                  {
                    field: `mmg.tier1`,
                    name: `Tier 1`,
                    type: "rate",
                    thresholdTo: 500000,
                  },
                  {
                    field: `mmg.tier2`,
                    name: `Tier 2`,
                    type: "rate",
                    thresholdTo: 1000000,
                  },
                  {
                    field: `mmg.tier3`,
                    name: `Tier 3`,
                    type: "rate",
                  },
                ]}
              />
            )}
          </>
        }
      />
    </Grid>
  );
}

export { MmgFeeStructure };
