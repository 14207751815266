import React from "react";
import { Typography } from "../common";
import { HelpTooltip } from "./HelpTooltip";

interface FormLabelProps {
  label: React.ReactNode;
  tooltip?: string;
}

const FormLabel = (props: FormLabelProps) => {
  const { label, tooltip } = props;
  return label ? (
    <>
      <Typography variant="label">
        {label}
        <HelpTooltip title={tooltip} />
      </Typography>
    </>
  ) : null;
};

export { FormLabel };
