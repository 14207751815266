import AccountIcon from "@mui/icons-material/Assessment";
import ClientIcon from "@mui/icons-material/Person";
import { SvgIcon, SxProps, Theme, useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { ThemeVariant } from "../../theme";
import { ThemeContext } from "../../theme/context";

interface ThemeIconProps {
  /** Defaults to `medium`. */
  size?: "medium" | "large";
  sx?: SxProps<Theme>;
}

const ICON_MAP: Record<ThemeVariant, typeof SvgIcon | undefined> = {
  account: AccountIcon,
  client: ClientIcon,
  primary: undefined,
};

export function ThemeIcon({ size = "medium", sx = [] }: ThemeIconProps) {
  const currentTheme = useContext(ThemeContext);
  const { palette } = useTheme();
  const Icon = useMemo(() => ICON_MAP[currentTheme], [currentTheme]);
  const style = useMemo<SxProps>(
    () => [
      {
        ...(currentTheme === "account" && {
          transform: "scaleX(-1)",
        }),
        ...(size === "large" && {
          marginTop: 0.5,
          height: 34,
          width: 34,
        }),
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ],
    [currentTheme, size, sx]
  );

  if (!Icon) return null;

  return <Icon htmlColor={palette.primary.main} sx={style} />;
}
