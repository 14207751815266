import { styled } from "@mui/material";

const Icon = styled("div", { shouldForwardProp: (name) => name !== "isOpen" })<{ isOpen: boolean }>(({ theme, isOpen }) => ({
  width: 18,
  height: 18,
  padding: 0,
  margin: 3,
  "& > span, &::before, &::after": {
    content: "''",
    display: "block",
    height: "2px",
    margin: "3px 0 0 0",
    background: theme.palette.common.white,
    transition: "opacity 200ms, transform 200ms",
  },
  ...(isOpen
    ? {
        "& > span": {
          opacity: 0,
        },
        "&::before": {
          transform: "translateY(5px) rotate(135deg)",
        },
        "&::after": {
          transform: "translateY(-5px) rotate(-135deg)",
        },
      }
    : undefined),
}));

interface MenuProps {
  isOpen?: boolean;
}

const MenuIcon = (props: MenuProps) => {
  const { isOpen = false } = props;

  return (
    <Icon isOpen={isOpen}>
      <span />
    </Icon>
  );
};

export { MenuIcon };
