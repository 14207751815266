import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { GUTTER_SIZE } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { associatedAdvisersAsync } from "../../store/advisers";
import Theme, { ThemeVariant } from "../../theme";
import { Typography } from "../common";
import { BaseDualSwitch } from "../form/BaseDualSwitch";
import { Accounts } from "./Accounts";
import { Clients } from "./Clients";

export default function Search() {
  const { advisers } = useAppSelector((as) => as.advisers);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useMemo<ThemeVariant>(() => (history.location.pathname === "/accounts" ? "account" : "client"), [history.location.pathname]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  return (
    <Theme variant={theme}>
      <Grid container sx={{ backgroundColor: "background.default", flexGrow: 0, pt: 4, px: GUTTER_SIZE }}>
        <Grid item xs={6}>
          <Switch>
            <Route exact path="/accounts">
              <Typography variant="page-heading">Account information</Typography>
            </Route>
            <Route>
              <Typography variant="page-heading">Client information</Typography>
            </Route>
          </Switch>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right" }}>
          <BaseDualSwitch
            left="Client information"
            leftValue="/"
            onChange={(value) => history.push(value)}
            right="Account information"
            rightValue="/accounts"
            value={history.location.pathname}
          />
        </Grid>
      </Grid>
      <Switch>
        <Route exact path="/accounts">
          <Accounts />
        </Route>
        <Route>
          <Clients />
        </Route>
      </Switch>
    </Theme>
  );
}
