import { Switch } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useField } from "formik";
import { ReactElement } from "react";
import { ErrorMessage } from "./ErrorMessage";

function Checkbox({
  name,
  children,
  checkboxPlacement = "start",
  onChange,
}: {
  name: string;
  onChange?: (checked: boolean) => void;
  children: string | number | ReactElement;
  checkboxPlacement?: "start" | "end";
}) {
  const [field, , helper] = useField<boolean | undefined>(name);

  return (
    <>
      <FormControlLabel
        labelPlacement={checkboxPlacement == "end" ? "start" : "end"}
        label={children}
        control={
          <Switch
            color="primary"
            checked={field.value || false}
            onChange={(_, checked) => {
              onChange?.(checked);
              helper.setValue(checked);
            }}
          />
        }
      />
      <ErrorMessage name={field.name} />
    </>
  );
}

export { Checkbox };
