import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Route, Switch, useLocation, useParams } from "react-router-dom";
import { EnumOfAccountPermissionAndString } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountClear, accountDetailsAsync } from "../../store/accounts";
import Theme from "../../theme";
import { AccountReportDto } from "../../types";
import { productKeyToName } from "../../utils";
import { PageHeading, Tab, Tabs, TwoDetails } from "../common";
import Reports from "../reports";
import Agreement from "./agreement";
import Analytics from "./Analytics";
import Detail from "./Detail";
import Documents from "./Documents";
import RecurringTransactions from "./RecurringTransactions";
import RiskRating from "./risk-rating";
import Transactions from "./Transactions";

export default function Account() {
  const { key } = useParams<{ key: string }>();
  const { account, isLoadingDetails } = useAppSelector((as) => as.accounts);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const showFeesAndAgreement = useMemo(
    () => !!account?.product && ["Active Series", "Superannuation", "UK Pension Transfer", "Wealth Builder", "Income Generator"].includes(account.product),
    [account?.product]
  );
  const subPage = useMemo(() => (location.pathname.endsWith("/fee-structure/new") ? "New fee structure" : undefined), [location.pathname]);
  const accountReportDetails = useMemo<AccountReportDto>(
    () => ({
      accountNumber: account?.accountKey ?? "",
      permissions: [EnumOfAccountPermissionAndString.View],
      service: account?.product ?? "",
    }),
    [account]
  );

  useEffect(() => {
    const promise = dispatch(accountDetailsAsync(key));

    return () => {
      promise.abort();
      dispatch(accountClear());
    };
  }, [dispatch, key]);

  return (
    <Theme variant="account">
      <PageHeading
        breadcrumb={
          subPage && !account?.accountKey
            ? undefined
            : subPage
              ? {
                  default: [
                    { label: "Account Information", pathname: "/accounts", theme: "account" },
                    {
                      label: account?.name && `${account.name} (${account.product})`,
                      pathname: `/account/${account?.accountKey}`,
                      state: { breadcrumbs: [{ label: "Account Information", pathname: "/accounts", theme: "account" }] },
                    },
                  ],
                  current: { label: subPage, theme: "account" },
                }
              : {
                  default: [{ label: "Account Information", pathname: "/accounts", theme: "account" }],
                  current: { label: account?.name ? `${account.name} (${account.product})` : undefined, theme: "account" },
                }
        }
        heading={account?.name}
        subheading={<TwoDetails detail1={productKeyToName(account?.product)} detail2={account?.accountKey} />}
        subsubheading={account?.advisers.map(({ adviserName, adviserBranchName }) => (
          <TwoDetails detail1={adviserName} detail2={adviserBranchName} key={adviserName} />
        ))}
        tabs={
          subPage ? undefined : (
            <Tabs preserveState>
              <Tab>Account details</Tab>
              <Tab
                subTabs={[
                  { path: "Transactions", children: "Transactions" },
                  { path: "RecurringTransactions", children: "Recurring transactions" },
                ]}
              >
                Transactions
              </Tab>
              <Tab path="reports">Reports</Tab>
              <Tab path="documents">Documents</Tab>
              <Tab
                subTabs={[
                  ...(showFeesAndAgreement ? [{ path: "fee-structure", children: "Fees & agreement" }] : []),
                  { path: "risk-rating", children: "Risk rating & CDD" },
                ]}
              >
                Compliance
              </Tab>
              <Tab
                subTabs={[
                  { path: "strategies", children: "Strategies" },
                  { path: "asset-classes", children: "Asset Classes" },
                  { path: "managers", children: "Managers" },
                  { path: "regions", children: "Regions" },
                  { path: "securities", children: "Securities" },
                ]}
              >
                Analytics
              </Tab>
            </Tabs>
          )
        }
      />
      <Grid item xs={12}>
        <Switch>
          <Route exact path="/account/:key/documents">
            <Documents />
          </Route>
          <Route exact path="/account/:key/transactions">
            <Transactions />
          </Route>
          <Route exact path="/account/:key/recurringtransactions">
            <RecurringTransactions />
          </Route>
          <Route exact path="/account/:key/reports">
            <Reports accountReportDto={accountReportDetails} loading={isLoadingDetails} />
          </Route>
          <Route exact path="/account/:key/fee-structure/:action?">
            <Agreement />
          </Route>
          <Route exact path="/account/:key/risk-rating/:action?">
            <RiskRating />
          </Route>
          <Route exact path="/account/:key/strategies">
            <Analytics />
          </Route>
          <Route exact path="/account/:key/asset-classes">
            <Analytics />
          </Route>
          <Route exact path="/account/:key/managers">
            <Analytics />
          </Route>
          <Route exact path="/account/:key/regions">
            <Analytics />
          </Route>
          <Route exact path="/account/:key/securities">
            <Analytics />
          </Route>
          <Route>
            <Detail />
          </Route>
        </Switch>
      </Grid>
    </Theme>
  );
}

export { TwoDetails };
