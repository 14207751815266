import { Container, CssBaseline } from "@mui/material";
import { ReactNode } from "react";
import { APP_BAR_HEIGHT } from "../../constants";
import Theme from "../../theme";
import Header from "./Header";

export default function Layout({ children }: { children: ReactNode }) {
  return (
    <Theme>
      <CssBaseline />
      <Header />
      <Container
        component="main"
        maxWidth="xl"
        sx={({ typography }) => ({
          backgroundColor: "background.paper",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          pt: typography.pxToRem(APP_BAR_HEIGHT),
        })}
      >
        {children}
      </Container>
    </Theme>
  );
}
