import { PERCENTAGE_1DP } from "../../constants";

interface FormatDecimal {
  type: "decimal" | "money";
  typeOption?: { decimalPlaces?: number };
}

interface FormatPercent {
  type: "percent";
  typeOption?: { inputStyle?: "decimal"; decimalPlaces?: number };
}

const formatNumber = (value: unknown, options?: { decimalPlaces?: number }) =>
  Number(value).toLocaleString(
    undefined,
    options?.decimalPlaces !== undefined ? { minimumFractionDigits: options?.decimalPlaces, maximumFractionDigits: options?.decimalPlaces } : undefined
  );

const formatMoney = (value: unknown, options?: { decimalPlaces?: number }) => `$${formatNumber(value, { decimalPlaces: 2, ...options })}`;

const formatPercent = (value: unknown, options?: FormatPercent) => {
  return `${formatNumber(options?.typeOption?.inputStyle === "decimal" && value ? Number(value) * 100 : value, {
    decimalPlaces: options?.typeOption?.decimalPlaces !== undefined ? options?.typeOption?.decimalPlaces : 2,
  })}%`;
};

function formatWeight(percentage: number | undefined) {
  if (percentage === undefined) return "— %";

  if (percentage > 0 && percentage < 0.0005) return "<0.1%";

  if (percentage < 0 && percentage > -0.0005) return "-0.0%";

  return PERCENTAGE_1DP.format(percentage);
}

export type { FormatDecimal, FormatPercent };
export { formatNumber, formatMoney, formatPercent, formatWeight };
