import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { FileType } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { getKeyFilesAsync } from "../../store/adviceTools";
import { KeyFileDisplay } from "../advice-tools/KeyFileDisplay";

function KeyFiles({ fileType }: { fileType: FileType }) {
  const dispatch = useAppDispatch();
  const { keyFileResult } = useAppSelector((as) => as.adviceTools);
  const keyFilesRequest: Parameters<typeof getKeyFilesAsync>[0] = useMemo(() => ({ fileType }), [fileType]);

  useEffect(() => {
    dispatch(getKeyFilesAsync(keyFilesRequest));
  }, [dispatch, keyFilesRequest]);

  return (
    <Grid container spacing={2}>
      {keyFileResult?.files?.map((file) => (
        <Grid key={file.fileId} item sm={12} md={6} lg={4}>
          <KeyFileDisplay {...file} />
        </Grid>
      ))}
    </Grid>
  );
}

export { KeyFiles };
