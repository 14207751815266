import { Box, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import PositionPerformanceImg from "../../images/analytics/AllSecurities.png";
import AmountInvestedImg from "../../images/analytics/assetclass/AssetClass_1.png";
import PositionAmountInvestmentImg from "../../images/analytics/assetclass/AssetClass_2.png";
import PositionPercentInvestmentImg from "../../images/analytics/assetclass/AssetClass_3.png";
import ClientAmountImg from "../../images/analytics/strategies/Strategies_1.png";
import ClientInvestmentPerformanceImg from "../../images/analytics/strategies/Strategies_2.png";
import ClientInvestmentPositionImg from "../../images/analytics/strategies/Strategies_3.png";
import PositionImpactImg from "../../images/analytics/strategies/Strategies_4.png";
import { PositionGroupFilter } from "../../types";
import { Stepper } from "../common";

enum DisclaimerType {
  SecurityGroup = "SecurityGroup",
  Security = "Security",
  Strategy = "Strategy",
}

const ANALYTICS_DISCLAIMER = [
  {
    description:
      "This is the performance of the individual positions within the Strategies the client is currently invested in. If the position is held in multiple Strategies, this performance is added together to represent its overall impact. This does not represent the contribution of this position to the client's investment performance because the client's investment allocations may have differed over different time periods.",
    image: PositionPerformanceImg,
    type: DisclaimerType.Security,
  },
  {
    description:
      "This is the amount the client has invested within this asset class, manager, or region. This is also shown as a percentage of the client's investment.",
    image: AmountInvestedImg,
    type: DisclaimerType.SecurityGroup,
  },
  {
    description:
      "This is the amount invested on behalf of the client into a particular position. This could be shares, bonds, currencies, commodities or a more diverse investment such as a futures or options contract.",
    image: PositionAmountInvestmentImg,
    type: DisclaimerType.SecurityGroup,
  },
  {
    description:
      "This is the position level performance calculated on a Strategy basis. If the position is held in multiple Strategies, this performance is added together to represent its overall impact.",
    image: PositionPercentInvestmentImg,
    type: DisclaimerType.SecurityGroup,
  },
  {
    description: "This is the amount the client has invested in this Strategy. This is also shown as a percentage of the client's investment.",
    image: ClientAmountImg,
    type: DisclaimerType.Strategy,
  },
  {
    description:
      "This is the performance of the client's investment in that Strategy over the period of time shown. It takes into account contributions and withdrawals the client has made and the client's personal fees, expenses and tax.",
    image: ClientInvestmentPerformanceImg,
    type: DisclaimerType.Strategy,
  },
  {
    description:
      "This is the amount invested on behalf of the client into a particular position. This could be shares, bonds, currencies, commodities or a more diverse investment such as a futures or options contract. ",
    image: ClientInvestmentPositionImg,
    type: DisclaimerType.Strategy,
  },
  {
    description:
      "This is the impact of a position on the return of the Strategy that the client has invested in. This does not represent the contribution of this position to the client's investment performance because the client's investment allocations may have differed over different time periods. The return shown in the example here means that Company 1's positions collectively contributed 1.1% to the total return of the Growth Strategy over the period of time shown.",
    image: PositionImpactImg,
    type: DisclaimerType.Strategy,
  },
];

export function AnalyticsDetailsPopup({
  positionGroupFilter,
  open,
  onCloseModal,
}: {
  positionGroupFilter: PositionGroupFilter;
  open?: boolean;
  onCloseModal: () => void;
}) {
  const [progress, setProgress] = useState(0);
  const disclaimerType = useMemo(() => {
    switch (positionGroupFilter) {
      case PositionGroupFilter.Strategies:
        return DisclaimerType.Strategy;
      case PositionGroupFilter.Securities:
        return DisclaimerType.Security;
      case PositionGroupFilter.AssetClass:
      case PositionGroupFilter.Manager:
      case PositionGroupFilter.Region:
        return DisclaimerType.SecurityGroup;
    }
  }, [positionGroupFilter]);
  const content = useMemo(
    () =>
      positionGroupFilter == PositionGroupFilter.Region
        ? [ANALYTICS_DISCLAIMER.find(({ type }) => type === disclaimerType)!]
        : ANALYTICS_DISCLAIMER.filter(({ type }) => type === disclaimerType),
    [disclaimerType, positionGroupFilter]
  );
  const currentContent = useMemo(() => content[progress], [content, progress]);
  const totalItems = useMemo(() => content.length, [content.length]);

  const handleClose = useCallback(() => {
    setProgress(0);
    onCloseModal();
  }, [onCloseModal]);

  return (
    <Dialog onClose={handleClose} open={!!open} PaperProps={{ sx: { minWidth: 450, display: "flex", flexDirection: "column" } }}>
      <DialogTitle>Understanding the numbers</DialogTitle>
      <DialogContent>
        <Box component="img" src={currentContent.image} sx={{ borderRadius: 1, height: "100%", width: "100%" }} />
      </DialogContent>
      <DialogContent sx={{ mb: 0.5, pt: 0.5 }}>
        <DialogContentText>{currentContent.description}</DialogContentText>
      </DialogContent>
      {totalItems > 1 && (
        <DialogContent>
          <Stepper
            progress={progress}
            totalSteps={totalItems}
            stepBack={() => {
              if (progress === 0) return;
              setProgress((progress) => progress - 1);
            }}
            stepForward={() => {
              if (progress === totalItems - 1) return;
              setProgress((progress) => progress + 1);
            }}
          />
        </DialogContent>
      )}
    </Dialog>
  );
}
