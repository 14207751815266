import { FundModel, GetGisFeePresetsResponse } from "../../api";
import { BaseAutocomplete } from "../form";
import { FeeAgreementField } from "./FeeAgreementField";

interface GisFeeSelectPresetProps {
  presets: GetGisFeePresetsResponse;
  feeEntered: () => void;
  funds: FundModel[] | undefined;
}

function GisFeeSelectPreset({ presets, feeEntered, funds }: GisFeeSelectPresetProps) {
  return (
    <FeeAgreementField
      label="Use saved template"
      field={(values: { gis: { gisFeeAdviserPresetId?: number } }, formik) => (
        <BaseAutocomplete
          value={values.gis?.gisFeeAdviserPresetId || ""}
          options={presets.records?.map((preset) => ({ value: preset.gisFeeAdviserPresetId || "", label: preset.gisFeeAdviserPresetDescription || "" }))}
          onChange={(value) => {
            const preset = value && presets.records?.find((preset) => preset.gisFeeAdviserPresetId === (value as number));

            if (!value || !preset) {
              formik.resetForm();
              return;
            }

            formik.setFieldValue("gis.gisFeeAdviserPresetId", preset.gisFeeAdviserPresetId);

            const fees = [];

            if (preset.details?.some((detail) => !detail.tierOption)) {
              fees.push("flat");
              formik.setFieldValue("gis.details", preset.details);
            } else {
              formik.setFieldValue("gis.details", {});
            }

            if (preset.tierRates) {
              fees.push("tier");
              formik.setFieldValue(
                "gis.tierRates",
                preset.tierRates.split(";").map((r) => Number(r))
              );
            } else {
              formik.setFieldValue("gis.tierRates", undefined);
            }

            if (preset.regularSavingFeeRate) {
              fees.push("upfront");
              formik.setFieldValue("gis.regularSavingFeeRate", preset.regularSavingFeeRate);
            } else {
              formik.setFieldValue("gis.regularSavingFeeRate", undefined);
            }

            if (preset.otherFeeDescription) {
              fees.push("other");
              formik.setFieldValue("gis.otherFeeAmount", undefined);
              formik.setFieldValue("gis.otherFeeDescription", preset.otherFeeDescription);
            } else {
              formik.setFieldValue("gis.otherFeeAmount", undefined);
              formik.setFieldValue("gis.otherFeeDescription", "");
            }

            formik.setFieldValue("gis.deductPerFundOption", preset.deductPerFundOption);

            if (!preset.deductPerFundOption) {
              const deductFromFundId = String(preset.deductFromFundID);
              formik.setFieldValue("gis.deductFromFundId", funds?.some((fund) => fund.fundRegistryKey === deductFromFundId) ? deductFromFundId : undefined);
            } else {
              formik.setFieldValue("gis.deductFromFundId", undefined);
            }

            formik.setFieldValue("gis.gstRate", preset.gstRate);
            formik.setFieldValue("gis.fees", fees);
            formik.setTouched({}, false);

            if (fees.length > 0) feeEntered();
          }}
        />
      )}
    />
  );
}

export { GisFeeSelectPreset };
