import { FormControlLabel, Grid, GridSize, GridWrap, Radio, Skeleton, Switch, SxProps, Theme } from "@mui/material";
import { useField } from "formik";
import { ReactElement, useCallback } from "react";
import { ErrorMessage } from "./ErrorMessage";

interface CheckboxListOption<Value> {
  label: string;
  value: Value;
}

interface GridOptions {
  lg?: boolean | GridSize;
  md?: boolean | GridSize;
  sm?: boolean | GridSize;
  xl?: boolean | GridSize;
  xs?: boolean | GridSize;
  sx?: SxProps<Theme>;
  wrap?: GridWrap;
  zeroMinWidth?: boolean;
}

interface CheckboxListProps<Value> {
  /** Defaults to `start`. */
  checkboxPlacement?: "start" | "end";
  itemGrid?: GridOptions;
  name: string;
  options?: CheckboxListOption<Value>[];
  radio?: boolean;
}

function FormControl<Value>({
  checkboxPlacement,
  children,
  itemGrid,
  label,
}: Pick<CheckboxListProps<Value>, "checkboxPlacement" | "itemGrid"> & { children: ReactElement; label: string }) {
  return (
    <Grid item {...itemGrid}>
      <FormControlLabel
        control={children}
        label={label}
        labelPlacement={checkboxPlacement === "end" ? "start" : "end"}
        style={checkboxPlacement !== "end" ? undefined : { justifyContent: "space-between", paddingRight: "21px", width: "100%" }}
      />
    </Grid>
  );
}

function BaseCheckboxList<Value>({ checkboxPlacement, itemGrid, name, options = [] }: Omit<CheckboxListProps<Value>, "radio">) {
  const [field, , helper] = useField<Value[] | undefined>(name);

  const handleChange = useCallback(
    (value: Value, checked: boolean) => {
      const currentValues = field.value || [];
      helper.setValue(checked ? currentValues.concat(value) : currentValues.filter((currentValue) => value !== currentValue));
      helper.setTouched(true);
    },
    [field.value, helper]
  );

  return (
    <>
      {options.map(({ label, value }) => (
        <FormControl checkboxPlacement={checkboxPlacement} itemGrid={itemGrid} key={label} label={label}>
          <Switch color="primary" checked={!!field.value?.includes(value)} onChange={(e) => handleChange(value, e.target.checked)} />
        </FormControl>
      ))}
    </>
  );
}

function BaseRadioList<Value>({ checkboxPlacement, itemGrid, name, options = [] }: Omit<CheckboxListProps<Value>, "radio">) {
  const [field, , helper] = useField<Value | undefined>(name);

  const handleChange = useCallback(
    (value: Value) => {
      helper.setValue(value);
      helper.setTouched(true);
    },
    [helper]
  );

  return (
    <>
      {options.map(({ label, value }) => (
        <FormControl checkboxPlacement={checkboxPlacement} itemGrid={itemGrid} key={label} label={label}>
          <Radio color="primary" checked={field.value === value} onChange={() => handleChange(value)} />
        </FormControl>
      ))}
    </>
  );
}

export function CheckboxList<Value>(props: CheckboxListProps<Value>) {
  const { itemGrid, name, options, radio } = props;

  return (
    <>
      <Grid item container xs={12}>
        {!options && (
          <Grid item {...itemGrid}>
            <Skeleton />
          </Grid>
        )}
        {!!options && !radio && <BaseCheckboxList {...props} />}
        {!!options && !!radio && <BaseRadioList {...props} />}
      </Grid>
      <ErrorMessage name={name} />
    </>
  );
}
