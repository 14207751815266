import { Grid } from "@mui/material";
import { Route, Switch } from "react-router-dom";
import Theme from "../../theme";
import { PageHeading } from "../common";
import { NewFeeStructure } from "./NewFeeStructure";

export default function FeeStructure() {
  return (
    <Theme variant="account">
      <PageHeading heading="New Fee Structure" />
      <Grid item xs={12}>
        <Switch>
          <Route exact path="/fee-structure/new" component={NewFeeStructure} />
        </Switch>
      </Grid>
    </Theme>
  );
}
