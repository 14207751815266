import { Button as MuiButton, Grid, IconButton, Typography, useTheme } from "@mui/material";
import { ChangeEvent, useCallback, useRef } from "react";
import { AddIcon, ClearIcon } from "../common";

interface FileInfo {
  fileName: string;
  data: File;
}

interface BaseFileUploadProps {
  onChange: (value: FileInfo | undefined) => void;
  value?: FileInfo;
}

function BaseFileUpload({ value, onChange }: BaseFileUploadProps) {
  const theme = useTheme();
  const inputTheme = theme.components?.MuiOutlinedInput?.styleOverrides;
  const inputStyles = {
    ...(inputTheme?.root as object),
    ...(inputTheme?.input as object),
    paddingTop: 5,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 5,
    borderRadius: 3,
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 33,
    display: "flex",
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const { palette } = useTheme();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;

      if (!files?.length) return;

      const file = files[0];
      onChange({ fileName: file.name, data: file });
    },
    [onChange]
  );

  return (
    <Grid item container xs={12} alignItems="center" gap={1} flexWrap="nowrap">
      <Grid item flexGrow={1}>
        <div onClick={() => inputRef.current?.click()} style={inputStyles}>
          {value ? (
            <>
              <Typography variant="body1" style={{ color: palette.common.black }}>
                {value?.fileName}
              </Typography>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  if (inputRef.current) inputRef.current.value = "";
                  onChange(undefined);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </>
          ) : (
            <Typography variant="body1" style={{ color: "#959595", paddingTop: 3, paddingBottom: 3 }}>
              Select file...
            </Typography>
          )}
        </div>
      </Grid>
      <Grid item>
        <label>
          <input type="file" onChange={handleChange} style={{ display: "none" }} ref={inputRef} />
          <MuiButton variant="contained" component="span" fullWidth endIcon={<AddIcon />}>
            Browse
          </MuiButton>
        </label>
      </Grid>
    </Grid>
  );
}

export type { BaseFileUploadProps, FileInfo };
export { BaseFileUpload };
