import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  Chip,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import { HTMLAttributes, Ref, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { className } from "../../utils";
import { ErrorMessage } from "./";

type AutocompleteMultipleOption = { label: string; value: string } | string;

const filterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option: AutocompleteMultipleOption) => (typeof option === "string" ? option : `${option.label} ${option.value}`),
});

interface AutocompleteMultipleProps<FormValues> {
  autoSelectIfOneOption?: boolean;
  disableClearable?: boolean;
  disableTagsLimit?: boolean;
  freeSolo?: boolean;
  invert?: boolean;
  label?: string;
  name: string;
  onChange?: (value: string[] | null | undefined) => void;
  onChangeClear?: string[];
  onClose?: (closeReason: AutocompleteCloseReason) => void;
  onFocus?: () => void;
  onInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
  onOpen?: () => void;
  options: Array<AutocompleteMultipleOption> | ((formValues: FormValues) => Array<AutocompleteMultipleOption> | undefined) | undefined;
  placeholder?: string;
  shrinkLabel?: boolean;
  stackedTags?: boolean;
  valueIsTag?: boolean;
}

export function AutocompleteMultiple<FormValues>({
  autoSelectIfOneOption,
  disableTagsLimit,
  freeSolo,
  invert,
  label,
  name,
  onChange,
  onChangeClear,
  onClose,
  onFocus,
  onInputChange,
  options = [],
  placeholder,
  shrinkLabel,
  stackedTags,
  valueIsTag,
  ...props
}: AutocompleteMultipleProps<FormValues>) {
  const { getFieldHelpers, values } = useFormikContext<FormValues>();
  const [{ value }, , { setTouched, setValue }] = useField<string[] | null | undefined>(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const _limitTags = useMemo(() => (disableTagsLimit ? -1 : 1), [disableTagsLimit]);
  const _className = useMemo(
    () => [...(invert ? ["invert"] : []), ...(stackedTags ? ["stacked-tags"] : []), ...(!label ? ["no-label"] : [])],
    [invert, label, stackedTags]
  );
  const _options = useMemo(() => (Array.isArray(options) ? options : options(values) || []), [options, values]);
  const autoSelect = useMemo(() => autoSelectIfOneOption && _options.length === 1, [autoSelectIfOneOption, _options.length]);
  const [prevValue, setPrevValue] = useState(value);
  const [autoSelected, setAutoSelected] = useState(false);
  const valuesDifferent = useMemo(() => {
    const current = [...(value || [])].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)).join("");
    const prev = [...(prevValue || [])].sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)).join("");
    return current !== prev;
  }, [prevValue, value]);

  const updateValue = useCallback(
    async (option: (string | AutocompleteMultipleOption)[]) => {
      await setValue(option.map((o) => (typeof o === "string" ? o : o.value)));
      if (onChangeClear) onChangeClear.map(async (fieldName) => await getFieldHelpers(fieldName).setValue(undefined));
      await setTouched(true);
    },
    [getFieldHelpers, onChangeClear, setTouched, setValue]
  );

  const handleBlur = useCallback(() => {
    if (valuesDifferent) {
      onChange?.(value);
      setPrevValue(value);
    }
  }, [onChange, valuesDifferent, value]);

  const handleChange = useCallback(
    async (_, option: (string | AutocompleteMultipleOption)[], reason: AutocompleteChangeReason | undefined) => {
      await updateValue(option);
      if (reason === "clear") inputRef.current?.blur();
    },
    [updateValue]
  );

  const handleChipDelete = useCallback(
    async (valueToRemove: string) => {
      await updateValue(value?.filter((v) => v !== valueToRemove) || []);
      handleBlur();
    },
    [handleBlur, updateValue, value]
  );

  const handleClose = useCallback((_: SyntheticEvent, reason: AutocompleteCloseReason) => onClose?.(reason), [onClose]);

  const handleInputChange = useCallback(
    (_: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => onInputChange?.(value, reason),
    [onInputChange]
  );

  useEffect(() => {
    if (autoSelect && !autoSelected && _options[0]) {
      setAutoSelected(true);
      updateValue([_options[0]]);
    }
  }, [_options, autoSelect, autoSelected, updateValue]);

  return (
    <MuiAutocomplete
      {...props}
      autoHighlight
      disableCloseOnSelect
      filterOptions={filterOptions}
      freeSolo={freeSolo}
      getOptionKey={(option) => (typeof option === "string" ? option : option.value)}
      limitTags={_limitTags}
      multiple
      options={freeSolo ? (_options as string[]) : (_options as AutocompleteMultipleOption[])}
      onBlur={handleBlur}
      onChange={handleChange}
      onClose={handleClose}
      onInputChange={handleInputChange}
      onFocus={onFocus}
      renderInput={(params) => renderInput(params, name, label, placeholder, shrinkLabel, _className, inputRef)}
      renderTags={(value, getTagProps) => renderTags(value, getTagProps, disableTagsLimit, freeSolo, valueIsTag, handleChipDelete)}
      slotProps={{ popper: { keepMounted: true } }}
      value={_options.filter((option) => value?.find((v) => v === (typeof option === "string" ? option : option.value)))}
      renderOption={renderOption}
    />
  );
}

function renderOption(
  params: HTMLAttributes<HTMLLIElement> & { key: unknown },
  option: AutocompleteMultipleOption,
  { selected }: AutocompleteRenderOptionState
) {
  return (
    <li {...params} key={typeof option === "string" ? option : option.value} style={{ paddingBottom: 0, paddingTop: 0 }}>
      {<Checkbox style={{ marginRight: 3 }} checked={selected} />}
      {typeof option === "string" ? option : option.label}
    </li>
  );
}

function renderInput(
  params: AutocompleteRenderInputParams,
  name: string,
  label: string | undefined,
  placeholder: string | undefined,
  shrinkLabel: boolean | undefined,
  _className: string[],
  inputRef: Ref<HTMLInputElement>
) {
  return (
    <>
      <TextField
        {...params}
        InputLabelProps={{ ...params.InputLabelProps, shrink: shrinkLabel }}
        inputRef={inputRef}
        InputProps={{ ...params.InputProps, className: className(params.InputProps.className, ..._className), placeholder }}
        label={label}
        sx={label ? { "& .MuiInputBase-root": { alignItems: "flex-end" } } : undefined}
      />
      {<ErrorMessage name={name} />}
    </>
  );
}

function renderTags(
  value: AutocompleteMultipleOption[],
  getTagProps: AutocompleteRenderGetTagProps,
  disableTagsLimit?: boolean,
  freeSolo?: boolean,
  valueIsTag?: boolean,
  onDelete?: (valueToRemove: string) => void
) {
  if (freeSolo || disableTagsLimit)
    return value.map((option, index) => (
      <Chip {...getTagProps({ index })} label={typeof option === "string" ? option : valueIsTag ? option.value : option.label} sx={{ pb: 0.3 }} />
    ));

  const count = value.length;
  const option = value[0];

  return (
    <Chip
      onDelete={
        count > 1
          ? undefined
          : onDelete
            ? () => onDelete(typeof option === "string" ? option : valueIsTag ? option.value : option.label)
            : getTagProps({ index: 0 }).onDelete
      }
      sx={{ fontWeight: 500, pb: 0.3 }}
      label={
        <>
          {typeof option === "string" ? option : valueIsTag ? option.value : option.label}
          {count > 1 && (
            <Box component="span" sx={{ color: "primary.dark", fontWeight: 500, pl: 1 }}>
              +{count - 1}
            </Box>
          )}
        </>
      }
    />
  );
}
