import { Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AccountDocument, AccountDocumentType } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountDocumentsAsync, accountTaxStatementAsync } from "../../store/accounts";
import { parseDate } from "../../utils";
import { Button, DownloadIcon } from "../common";
import { Form } from "../form";
import Table from "../table";

const documentTypeToText: Record<AccountDocumentType, string> = {
  QuarterlyPieTax: "Quarterly Pie Tax",
  TaxStatement: "Tax Statement",
};

function DocumentDownload({
  downloading,
  error,
  onClick,
  row,
}: {
  downloading: boolean;
  error: boolean;
  onClick: (row: AccountDocument) => void;
  row: AccountDocument;
}) {
  const handleClick = useCallback(() => onClick(row), [onClick, row]);

  return row.type === AccountDocumentType.TaxStatement ? (
    <Button variant="text" onClick={handleClick} loading={downloading}>
      {!downloading && error ? (
        <Tooltip title="Error retrieving tax statement, contact NZ Funds for more details.">
          <span style={{ color: "red" }}>Error</span>
        </Tooltip>
      ) : (
        <DownloadIcon />
      )}
    </Button>
  ) : null;
}

export default function Documents() {
  const { key } = useParams<{ key: string }>();
  const dispatch = useAppDispatch();
  const { documents, isLoadingDocuments } = useAppSelector((as) => as.accounts);
  const [downloadingRow, setDownloadingRow] = useState<AccountDocument>();
  const [error, setError] = useState(false);

  const onDocumentClick = useCallback(
    (row: AccountDocument) => {
      setDownloadingRow(row);
      dispatch(accountTaxStatementAsync({ accountKey: key, year: parseDate(row.date)?.getFullYear() })).then((result) => {
        setDownloadingRow(undefined);
        setError(result.meta.requestStatus === "rejected");
      });
    },
    [dispatch, key]
  );

  useEffect(() => {
    dispatch(accountDocumentsAsync({ accountKey: key }));
  }, [dispatch, key]);

  return (
    <Form initialValues={{}}>
      <Table
        loading={isLoadingDocuments}
        minHeight={300}
        pageSizeHidden
        rows={documents}
        columns={[
          {
            heading: "Document name",
            field: "name",
            display: "emphasize",
            width: 30,
          },
          {
            heading: "Document type",
            field: (row) => row.type && documentTypeToText[row.type],
            width: 20,
          },
          {
            heading: "Date",
            field: "date",
            type: "date",
            width: 20,
          },
          {
            heading: "File name",
            field: "fileName",
            width: 25,
          },
          {
            field: (row) => <DocumentDownload downloading={downloadingRow === row} error={error} onClick={onDocumentClick} row={row} />,
            width: 5,
          },
        ]}
      />
    </Form>
  );
}
