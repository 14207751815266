import { styled, useMediaQuery, useTheme } from "@mui/material";
import * as d3 from "d3";
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { NUMBER_0DP, NUMBER_SHORT } from "../../constants";
import { formatWeight } from "../../utils";

interface PerformanceBarValues {
  value: number;
  gain: number;
  year: number;
  yearLabel: string;
  inPast?: boolean;
  inFuture?: boolean;
}

// Shorten values to three numbers for narrower bars
function getFormattedValue(value: number, parentWidth: number) {
  if (parentWidth < 70) {
    return value < 1000 ? NUMBER_0DP.format(value) : NUMBER_SHORT.format(value);
  } else if (parentWidth < 190) {
    return "$" + (value < 1000 ? NUMBER_0DP.format(value) : NUMBER_SHORT.format(value));
  }

  return "$" + (value < 1000 ? NUMBER_0DP.format(value) : NUMBER_SHORT.format(value));
}

const Graph = styled("div")(({ theme: { palette, typography } }) => ({
  height: typography.pxToRem(330),
  width: "100%",
  "& .axis": {
    color: palette.text.secondary,
    fontFamily: typography.fontFamily,
    fontWeight: 500,
    fontSize: typography.pxToRem(11),
    lineHeight: 1,
    letterSpacing: typography.pxToRem(1),
  },
  "& .grid": {
    line: {
      stroke: palette.divider,
      shapeRendering: "crispEdges",
      strokeOpacity: 0.5,
    },
    path: { strokeWidth: 0 },
  },
  "& .gain": {
    fontFamily: typography.fontFamily,
    lineHeight: 1,
  },
  "& .value": {
    fontFamily: typography.fontFamily,
    lineHeight: 1,
  },
  "& .y-label": {
    fill: palette.text.secondary,
    fontFamily: typography.fontFamily,
    fontWeight: 500,
    fontSize: typography.pxToRem(11),
    lineHeight: 1,
    letterSpacing: typography.pxToRem(1),
  },
}));

function PerformanceGraph({ data }: { data: PerformanceBarValues[] }) {
  const svgRef = useRef<SVGSVGElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [hasAnimated, setHasAnimated] = useState(false);
  const { palette, typography } = useTheme();
  const { breakpoints } = useTheme();
  const isSmallScreen = useMediaQuery(breakpoints.down("md"));

  // The minium amount of space required for the bar data. If the bar height is
  // less than this, then the data should pop out the top of the bar rather than
  // be positioned inside it (see designs in Figma)
  const minDataHeight = isSmallScreen ? 40 : 60;

  const updateDimensions = useCallback(() => {
    const node = svgRef.current;
    if (node) {
      const { width, height } = node.getBoundingClientRect();
      setDimensions({ width, height });
    }
  }, []);

  useLayoutEffect(() => {
    updateDimensions();
  }, [updateDimensions]);

  useEffect(() => {
    const handleWindowResize = () => {
      if (dimensions.width === 0 || dimensions.height === 0) updateDimensions();
    };
    window.addEventListener("resize", handleWindowResize);

    return () => window.removeEventListener("resize", handleWindowResize);
  }, [updateDimensions, dimensions]);

  const notEnoughSpaceForData = useCallback(
    (top: number, innerHeight: number) => {
      const barHeight = innerHeight - top;
      return barHeight < minDataHeight;
    },
    [minDataHeight]
  );

  useEffect(() => {
    // Only run the layout if we have an actual width and height to use,
    // otherwise may god have mercy on your soul...
    if (dimensions.width > 0 && dimensions.height > 0) {
      if (svgRef.current) {
        const svg = d3.select(svgRef.current);
        const margin = { top: 10, right: 50, bottom: 30, left: 0 };
        const { width, height } = dimensions;
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        if (data.length < 5) {
          // If we don't have enough bars to fill the whole graph, we need to
          // pad out the missing columns with 'empty' future bar states
          const currentNumberOfBars = data.length;
          const extraBarsRequired = 5 - currentNumberOfBars;

          for (let i = 0; i < extraBarsRequired; i++) {
            data.push({
              value: 0,
              gain: 0,
              year: data[currentNumberOfBars - 1].year + (i + 1),
              // We need to create the year label for the additional bars as strings.
              // this means item.year is required as item.yearlabel could have addtional text
              yearLabel: (data[currentNumberOfBars - 1].year + (i + 1)).toString(),
              inFuture: true,
            });
          }
        }

        // Set up the X and Y scales
        const xScale = d3
          .scaleBand()
          .domain(data.map((d) => d.yearLabel))
          .range([0, innerWidth])
          .paddingOuter(0)
          .paddingInner(0.05);

        const yScale = d3
          .scaleLinear()
          .domain([0, d3.max(data, (d) => d.value) || 0])
          .range([innerHeight, 0]);

        // Draw the bars
        const bars = svg
          .select(".bars")
          .attr("transform", `translate(${margin.left}, ${margin.top})`)
          .attr("width", innerWidth)
          .attr("height", innerHeight)
          .selectAll("rect")
          .data(data)
          .join("rect")
          .attr("rx", typography.pxToRem(4))
          .attr("ry", typography.pxToRem(4))
          .attr("fill", (d) => (d.inFuture ? "#DFDFDF" : palette.primary.main))
          .attr("x", (d) => xScale(d.yearLabel) || 0)
          .attr("width", xScale.bandwidth());

        // Add totals
        const totals = svg
          .select(".totals")
          .selectAll("text")
          .data(data)
          .join("text")
          .classed("total", true)
          .text((d) => (d.inFuture ? "" : getFormattedValue(d.value, xScale.bandwidth())))
          .attr("text-anchor", "start")
          .attr("font-size", isSmallScreen ? typography.pxToRem(12) : typography.pxToRem(20))
          .attr("fill", (d) => (notEnoughSpaceForData(yScale(d.value), innerHeight) ? palette.primary.main : palette.common.white))
          .attr("opacity", 0)
          .attr("y", (d) => {
            if (notEnoughSpaceForData(yScale(d.value), innerHeight)) {
              return d.value === 0 ? yScale(d.value) - (isSmallScreen ? 23 : 38) : yScale(d.value) - (isSmallScreen ? 11 : 26);
            } else {
              return yScale(d.value) + (isSmallScreen ? 28 : 40);
            }
          })
          .attr("x", (d) => {
            let leftOffset = isSmallScreen ? 7 : 10;
            if (notEnoughSpaceForData(yScale(d.value), innerHeight)) {
              leftOffset = isSmallScreen ? 5 : 8;
            }
            const left = (xScale(d.yearLabel) || 0) + leftOffset;
            return left;
          });

        // Add gain labels
        const gains = svg
          .select(".gains")
          .selectAll("text")
          .data(data)
          .join("text")
          .classed("gain", true)
          .text((d) => (d.inFuture ? "" : formatWeight(d.gain / 100)))
          .attr("text-anchor", "start")
          .attr("font-size", isSmallScreen ? typography.pxToRem(10) : typography.pxToRem(16))
          .attr("line-height", 1)
          .attr("fill", (d) => (notEnoughSpaceForData(yScale(d.value), innerHeight) ? palette.primary.main : palette.common.white))
          .attr("opacity", 0)
          .attr("y", (d) => {
            if (notEnoughSpaceForData(yScale(d.value), innerHeight)) {
              return d.value === 0 ? yScale(d.value) - (isSmallScreen ? 10 : 16) : yScale(d.value) - (isSmallScreen ? -2 : 4);
            } else {
              return yScale(d.value) + (isSmallScreen ? 42 : 62);
            }
          })
          .attr("x", (d) => {
            let leftOffset = isSmallScreen ? 7 : 10;
            if (notEnoughSpaceForData(yScale(d.value), innerHeight)) {
              leftOffset = isSmallScreen ? 5 : 8;
            }
            const left = (xScale(d.yearLabel) || 0) + leftOffset;
            return left;
          });

        svg
          .select(".y-label")
          .attr("y", innerHeight + margin.top + 9)
          .attr("x", width)
          .attr("text-anchor", "end")
          .attr("dominant-baseline", "hanging");

        // Only animate the bars on first load, not after resizing
        if (hasAnimated) {
          bars
            .attr("y", (d) => (d.inFuture || d.value === 0 ? innerHeight - 12 : yScale(d.value)))
            .attr("height", (d) => (d.inFuture || d.value === 0 ? 12 : innerHeight - yScale(d.value)));
          totals.attr("opacity", 1);
          gains.attr("opacity", 1);
        } else {
          bars
            .attr("y", () => yScale(0))
            .transition()
            .ease(d3.easeCubicInOut)
            .duration(800)
            .attr("y", (d) => (d.inFuture || d.value === 0 ? innerHeight - 12 : yScale(d.value)))
            .attr("height", (d) => (d.inFuture || d.value === 0 ? 12 : innerHeight - yScale(d.value)));

          totals.transition().ease(d3.easeCubicInOut).duration(300).delay(800).attr("opacity", 1);
          gains.transition().ease(d3.easeCubicInOut).duration(300).delay(900).attr("opacity", 1);
          setHasAnimated(true);
        }

        const gridAxis = d3
          .axisLeft(yScale)
          .tickSizeOuter(0)
          .tickPadding(0)
          .tickSize(-innerWidth)
          .ticks(isSmallScreen ? 5 : 8)
          .tickFormat(() => "");

        svg
          .select(".grid")
          .attr("transform", `translate(0, ${margin.top})`)
          // @ts-expect-error: need to fix Cactuslab crap in future
          .call(gridAxis);

        const xAxis = d3.axisBottom(xScale).tickSizeOuter(0).tickPadding(10).tickSizeInner(0);
        svg
          .select(".x-axis")
          .attr("transform", `translate(${margin.left}, ${height - margin.bottom})`)
          // @ts-expect-error: need to fix Cactuslab crap in future
          .call(xAxis)
          .call((g) => g.select(".domain").remove());

        const yAxis = d3
          .axisLeft(yScale)
          .tickSizeOuter(0)
          .tickPadding(0)
          .tickSizeInner(0)
          .ticks(isSmallScreen ? 5 : 8)
          .tickFormat((d) => NUMBER_SHORT.format(d as number));

        svg
          .select(".y-axis")
          .attr("transform", `translate(${width}, ${margin.top})`)
          // @ts-expect-error: need to fix Cactuslab crap in future
          .call(yAxis)
          .call((g) => g.select(".domain").remove());
      }
    }

    const resizeHandler = () => {
      const { width, height } = svgRef.current?.getBoundingClientRect() || { width: 0, height: 0 };
      setDimensions({ width, height });
    };

    window.addEventListener("resize", resizeHandler);
    return () => window.removeEventListener("resize", resizeHandler);
  }, [data, dimensions, hasAnimated, isSmallScreen, notEnoughSpaceForData, palette, typography]);

  return (
    <Graph>
      <svg ref={svgRef} style={{ width: "100%", height: "100%" }}>
        <g className="axis x-axis" />
        <g className="axis y-axis" />
        <g className="grid" />
        <g className="bars" />
        <g className="totals" />
        <g className="gains" />
        <text className="y-label" textAnchor="right" fontWeight="bold" fontSize="12">
          NZD
        </text>
      </svg>
    </Graph>
  );
}

export default memo(PerformanceGraph);
