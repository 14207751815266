import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AMLReportSummaryResponse,
  CalculateRiskRatingCommand,
  CalculateRiskRatingResponse,
  CddStatusFilter,
  CreateRiskRatingCommand,
  GetAccountsCddStatusResponse,
  GetAccountsCddStatusSort,
  GetRiskRatingQuestionsResponse,
  NatureAndPurposeGroup,
  ProblemDetails,
  RiskAssessmentByAccountTypeHeader,
  RiskAssessmentByAccountTypeResponse,
  RiskAssessmentByCountryHeader,
  RiskAssessmentByCountryResponse,
  RiskFactorGroup,
  RiskLevel,
} from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { addExporting, addLoading, fileThunk } from "./helpers";

interface accountsState {
  isLoading: boolean;
  isExporting: boolean;
  riskAssessmentByCountry?: RiskAssessmentByCountryHeader[];
  riskAssessmentByType?: RiskAssessmentByAccountTypeHeader[];
  accountsCddStatus?: GetAccountsCddStatusResponse;
  riskFactorGroups?: RiskFactorGroup[];
  adviserRiskLevels?: RiskLevel[];
  natureAndPurposeGroups?: NatureAndPurposeGroup[];
  riskRatingLevel?: string;
  amlReportSummary?: AMLReportSummaryResponse;
  amlReportSummaryKS?: [];
  errors?: ProblemDetails;
}

const initialState: accountsState = {
  isLoading: false,
  isExporting: false,
};

export const riskRatingQuestionsAsync = createAsyncThunk<GetRiskRatingQuestionsResponse, void, { state: RootState }>(
  "compliance/riskRatingQuestions",
  (_payload, { getState, rejectWithValue, signal }) => getApis(getState()).complianceClient.riskRatingQuestions(signal).catch(rejectWithValue)
);

export const riskAssessmentByCountryAsync = createAsyncThunk<RiskAssessmentByCountryResponse, { adviserBranchKeys?: string[] }, { state: RootState }>(
  "compliance/riskAssessmentByCountry",
  (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState()).complianceClient.riskAssessmentByCountry(payload.adviserBranchKeys, signal).catch(rejectWithValue)
);

export const exportRiskAssessmentByCountryAsync = fileThunk<{ adviserBranchKeys?: string[] }>(
  "compliance/exportRiskAssessmentByCountry",
  (payload, { getState, signal }) => getApis(getState()).complianceClient.exportRiskAssessmentByCountry(payload.adviserBranchKeys, signal),
  "Risk Assessment by country.csv"
);

export const riskAssessmentByAccountTypeAsync = createAsyncThunk<RiskAssessmentByAccountTypeResponse, { adviserBranchKeys?: string[] }, { state: RootState }>(
  "compliance/riskAssessmentByType",
  (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState()).complianceClient.riskAssessmentByAccountType(payload.adviserBranchKeys, signal).catch(rejectWithValue)
);
export const amlReportSummaryAsync = createAsyncThunk<AMLReportSummaryResponse, void, { state: RootState }>(
  "compliance/GetAMLAnnualReportSummary",
  (_payload, { getState, rejectWithValue, signal }) => getApis(getState()).complianceClient.getAMLAnnualReportSummary({}, signal).catch(rejectWithValue)
);

export const calculateRiskRatingAsync = createAsyncThunk<CalculateRiskRatingResponse, CalculateRiskRatingCommand, { state: RootState }>(
  "compliance/calculateRiskRating",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).complianceClient.calculateRiskRating(payload, signal).catch(rejectWithValue)
);

export const createRiskRatingAsync = fileThunk<CreateRiskRatingCommand>(
  "compliance/createRiskRating",
  (payload, { getState, signal }) => getApis(getState()).complianceClient.createRiskRating(payload, signal),
  "Risk Rating.pdf"
);

export const exportRiskAssessmentByAccountTypeAsync = fileThunk<{ adviserBranchKeys?: string[] }>(
  "compliance/exportRiskAssessmentByAccountType",
  (payload, { getState, signal }) => getApis(getState()).complianceClient.exportRiskAssessmentByAccountType(payload.adviserBranchKeys, signal),
  "Risk Assessment by account type.csv"
);

export const accountsCddStatusAsync = createAsyncThunk<
  GetAccountsCddStatusResponse,
  {
    adviserNumber?: string;
    accountKey?: string;
    cddStatus?: CddStatusFilter;
    currentPage?: number;
    pageSize?: number;
    sortOrder?: GetAccountsCddStatusSort;
  },
  { state: RootState }
>("reports/accountsCddStatus", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .complianceClient.accountsCddStatus(
      payload.adviserNumber,
      payload.accountKey,
      payload.cddStatus,
      payload.currentPage,
      payload.pageSize,
      payload.sortOrder,
      signal
    )
    .catch(rejectWithValue)
);

export const exportAccountsCddStatusAsync = fileThunk<{
  adviserNumber?: string;
  accountKey?: string;
  cddStatus?: CddStatusFilter;
  sortOrder?: GetAccountsCddStatusSort;
}>(
  "reports/exportAccountsCddStatus",
  (payload, { getState, signal }) =>
    getApis(getState()).complianceClient.exportAccountsCddStatus(payload.adviserNumber, payload.accountKey, payload.cddStatus, payload.sortOrder, signal),
  "CddStatus.csv"
);

export const exportAMLAnnualReportProductsAndServicesAsync = fileThunk<{
  service: string;
}>(
  "compliance/exportAMLAnnualReportProductsAndServices",
  (payload, { getState, signal }) => getApis(getState()).complianceClient.exportAMLAnnualReportProductsAndServices({ service: payload.service }, signal),
  "AML annual report by product.csv"
);

export const exportAMLAnnualReportCustomersAndMembersAsync = fileThunk<{
  service: string;
  isendofyear: boolean;
}>(
  "compliance/exportAMLAnnualReportCustomersAndMembers",
  (payload, { getState, signal }) =>
    getApis(getState()).complianceClient.exportAMLAnnualReportCustomersAndMembers({ service: payload.service, isEndOfYear: payload.isendofyear }, signal),
  "AML annual report by customer.csv"
);

const complianceSlice = createSlice({
  name: "compliance",
  initialState,
  reducers: {
    riskAssessmentByCountryClear(state) {
      state.riskAssessmentByCountry = undefined;
    },
    riskAssessmentByAccountTypeClear(state) {
      state.riskAssessmentByType = undefined;
    },
    accountCddStatusClear(state) {
      state.accountsCddStatus = undefined;
    },
    getAMLReportSummeryClear(state) {
      state.amlReportSummary = undefined;
    },
  },
  extraReducers: (builder) => {
    addLoading(builder, riskAssessmentByCountryAsync, (state, payload) => (state.riskAssessmentByCountry = payload.riskAssessments));
    addExporting(builder, exportRiskAssessmentByCountryAsync);

    addLoading(builder, riskAssessmentByAccountTypeAsync, (state, payload) => (state.riskAssessmentByType = payload.riskAssessments));
    addExporting(builder, exportRiskAssessmentByAccountTypeAsync);

    addLoading(builder, riskRatingQuestionsAsync, (state, payload) => {
      state.natureAndPurposeGroups = payload.natureAndPurposeGroups;
      state.riskFactorGroups = payload.riskFactorGroups;
      state.adviserRiskLevels = payload.adviserRiskLevels;
    });

    addLoading(builder, accountsCddStatusAsync, (state, payload) => (state.accountsCddStatus = payload));
    addLoading(builder, calculateRiskRatingAsync, (state, payload) => (state.riskRatingLevel = payload.riskRatingLevel));
    addExporting(builder, createRiskRatingAsync);

    addExporting(builder, exportAccountsCddStatusAsync);

    addLoading(builder, amlReportSummaryAsync, (state, payload) => {
      state.amlReportSummary = payload;
    });
    addExporting(builder, exportAMLAnnualReportCustomersAndMembersAsync);
    addExporting(builder, exportAMLAnnualReportProductsAndServicesAsync);
  },
});

export const { riskAssessmentByCountryClear, riskAssessmentByAccountTypeClear, accountCddStatusClear, getAMLReportSummeryClear } = complianceSlice.actions;
export default complianceSlice.reducer;
