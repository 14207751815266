import { Box, Grid } from "@mui/material";
import { useCallback } from "react";
import { SearchResult } from "../../api";
import { useAppDispatch } from "../../store";
import { getFile } from "../../store/adviceTools";
import { Button, DownloadIcon, LinkIcon, Typography } from "../common";

function InternalKeyFileDisplay({ fileName, isLink }: Pick<SearchResult, "fileName"> & { isLink?: boolean }) {
  return (
    <Grid container item xs={12} sx={{ alignItems: "center", justifyContent: "space-between", px: 2, py: 1, textAlign: "left" }}>
      <Grid item xs>
        <Typography variant="label">
          <Box component="span" sx={{ color: "common.brandLow" }}>
            {fileName}
          </Box>
        </Typography>
      </Grid>
      <Grid item xs="auto" sx={{ color: "primary.main", pt: 1 }}>
        {isLink ? <LinkIcon /> : <DownloadIcon />}
      </Grid>
    </Grid>
  );
}

function KeyFileDisplay({ fileId, fileName, url }: SearchResult) {
  const dispatch = useAppDispatch();

  const downloadKeyDoc = useCallback(() => {
    url ? window.open(url, "_blank") : dispatch(getFile({ fileId }));
  }, [dispatch, fileId, url]);

  return (
    <Button onClick={downloadKeyDoc} fullWidth sx={{ backgroundColor: "common.brandHigh" }} variant="text">
      <InternalKeyFileDisplay fileName={fileName} />
    </Button>
  );
}

export { KeyFileDisplay };
