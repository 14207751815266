import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { SearchTransactionsByAccountKeySort } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountExportTransactionsAsync, accountTransactionsAsync } from "../../store/accounts";
import { endOfDay, formatDateJSON, formatUnitsPrice } from "../../utils";
import { Panel } from "../common";
import { DateField, Form, FormButton, SearchTextField } from "../form";
import Table from "../table";

type FormValues = Parameters<typeof accountTransactionsAsync>[0];

export default function Transactions() {
  const { key } = useParams<{ key: string }>();
  const dispatch = useAppDispatch();
  const abortsRef = useRef<((reason?: string) => void)[]>([]);
  const { isExporting, isLoadingTransactions, transactions } = useAppSelector((as) => as.accounts);
  const initialValues = useMemo<FormValues>(
    () => ({
      accountKey: key,
      currentPage: 0,
      from: "",
      pageSize: 55,
      sortOrder: SearchTransactionsByAccountKeySort.EffectiveDate_Desc,
      term: "",
      to: "",
    }),
    [key]
  );

  const abortRequests = useCallback(() => {
    abortsRef.current.forEach((abort) => abort());
    abortsRef.current = [];
  }, []);

  const doSearch = useCallback(
    (values: FormValues) => {
      abortRequests();
      const promise = dispatch(accountTransactionsAsync({ ...values, to: formatDateJSON(endOfDay(values.to)) }));
      abortsRef.current.push(promise.abort);
    },
    [abortRequests, dispatch]
  );

  const exportTable = useCallback(
    (values: FormValues) => {
      dispatch(accountExportTransactionsAsync({ ...values, to: formatDateJSON(endOfDay(values.to)) }));
    },
    [dispatch]
  );

  const exportAll = useCallback(() => {
    exportTable(initialValues);
  }, [exportTable, initialValues]);

  useEffect(() => {
    doSearch(initialValues);
  }, [doSearch, initialValues]);

  useEffect(() => {
    return () => abortRequests();
  }, [abortRequests]);

  return (
    <Form defaultValues={initialValues} initialValues={initialValues} onSubmit={doSearch} submitOnReset>
      {({ submitForm }) => (
        <>
          <Panel>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item container xs={12} alignItems="center" justifyContent="flex-end">
                <FormButton type="reset" variant="text">
                  Reset
                </FormButton>
              </Grid>
              <Grid item container xs="auto" gap={2} alignItems="flex-end">
                <Grid item>
                  <FormButton loading={isExporting} onClick={exportAll}>
                    Export all
                  </FormButton>
                </Grid>
                <Grid item>
                  <FormButton loading={isExporting} variant="outlined" onClick={exportTable}>
                    Export filtered
                  </FormButton>
                </Grid>
              </Grid>
              <Grid item container xs alignItems="center" justifyContent="flex-end" gap={2}>
                <Grid item xs="auto">
                  <DateField label="From" name="from" onChange={submitForm} shrinkLabel />
                </Grid>
                <Grid item xs="auto">
                  <DateField label="To" name="to" onChange={submitForm} shrinkLabel />
                </Grid>
                <Grid item sx={{ width: 250 }}>
                  <SearchTextField name="term" />
                </Grid>
              </Grid>
            </Grid>
          </Panel>
          <Grid container sx={{ backgroundColor: "common.white" }}>
            <Table
              loading={isLoadingTransactions}
              minHeight={343}
              paging={{ ...transactions }}
              rows={transactions?.results}
              columns={[
                {
                  align: "left",
                  display: "emphasize",
                  field: "date",
                  heading: "Date",
                  sort: SearchTransactionsByAccountKeySort.EffectiveDate,
                  type: "date",
                  width: 15,
                },
                {
                  field: "transactionType",
                  heading: "Transaction type",
                  width: 50,
                },
                {
                  align: "right",
                  field: (row) => (row.transactions?.every(({ state }) => state === "Closed") ? "Closed" : "Open"),
                  heading: "Status",
                  width: 15,
                },
                {
                  display: "emphasize",
                  field: (row) => row.transactions?.reduce((total, trans) => total + (trans.amount || 0), 0),
                  heading: "Value",
                  type: "money",
                  width: 20,
                },
              ]}
              rowExpand={(row, backgroundColor) => (
                <Table
                  child
                  rowBackgroundColor={backgroundColor}
                  rows={row.transactions}
                  columns={[
                    { heading: "Date", field: () => <span style={{ visibility: "hidden" }}>00/00/0000</span>, width: 15 },
                    { heading: "Fund", field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.fundName}</>, width: 30, display: "emphasize" },
                    {
                      align: "right",
                      field: (row) => formatUnitsPrice(row.units, row.price),
                      heading: "Units & Price",
                      width: 35,
                    },
                    { display: "emphasize", field: "amount", heading: "Value", type: "money", width: 20 },
                  ]}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Form>
  );
}
