import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, useTheme } from "@mui/material";
import { push } from "connected-react-router";
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { AccountantAccountDto, SearchAccountantAccountSortBy } from "../../api";
import { GUTTER_SIZE } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { removeAccessAsync, searchAsync } from "../../store/accountantAccounts";
import Theme from "../../theme";
import { Breadcrumb, Button, DeleteIcon, IconButton, ThemeIcon, Typography } from "../common";
import { SearchPageFilters } from "../common/SearchPageFilters";
import { Form } from "../form";
import Table from "../table";

type FormValues = Parameters<typeof searchAsync>[0];

const DEFAULT_VALUES = { currentPage: 0, pageSize: 35, sortOrder: SearchAccountantAccountSortBy.AccountName, term: "" };

export default function Search() {
  const { palette } = useTheme();
  const dispatch = useAppDispatch();
  const history = useHistory<(FormValues & { breadcrumbs?: Breadcrumb[] }) | undefined>();
  const { accounts, errors, paging, removingAccess, searchRequestId } = useAppSelector((as) => as.accountantAccounts);
  const abortsRef = useRef<((reason?: string) => void)[]>([]);
  const [filters, setFilters] = useState<FormValues>({ ...DEFAULT_VALUES, ...history.location.state });

  const abortRequests = useCallback(() => abortsRef.current.forEach((abort) => abort()), []);

  const [removeAccessAccountKey, setRemoveAccessAccountKey] = useState<string>();
  const [confirmAuthorityAccount, setConfirmAuthorityAccount] = useState<AccountantAccountDto>();
  const loading = useMemo(() => !!removingAccess || !!searchRequestId, [removingAccess, searchRequestId]);

  const showRemoveAccessDialog = useCallback((accountKey: string | undefined) => setRemoveAccessAccountKey(accountKey), []);

  const showConfirmAuthorityDialog = useCallback((account: AccountantAccountDto | undefined) => setConfirmAuthorityAccount(account), []);

  const closeConfirmAuthorityDialog = useCallback(async () => {
    setConfirmAuthorityAccount(undefined);
  }, []);

  const fetchData = useCallback(
    (values: FormValues) => {
      abortRequests();
      const promise = dispatch(searchAsync(values));
      abortsRef.current = [promise.abort];
      history.replace(history.location.pathname, values);
    },
    [abortRequests, dispatch, history]
  );

  const closeRemoveAccessDialog = useCallback(
    async (proceed: boolean) => {
      setRemoveAccessAccountKey(undefined);

      if (proceed && removeAccessAccountKey) {
        const { meta } = await dispatch(removeAccessAsync(removeAccessAccountKey));
        meta.requestStatus === "fulfilled" && fetchData(filters);
      }
    },
    [dispatch, fetchData, filters, removeAccessAccountKey]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      setFilters({ ...values, currentPage: values.currentPage !== filters?.currentPage ? values.currentPage : 0 });
    },
    [filters]
  );

  useEffect(() => {
    filters && fetchData(filters);
  }, [filters, fetchData]);

  useEffect(() => {
    return () => abortRequests();
  }, [abortRequests]);

  return (
    <Theme variant="account">
      {!!errors && (
        <Grid container sx={{ backgroundColor: "#FF5454", color: "common.white", px: GUTTER_SIZE, py: 0.5 }}>
          <Grid item>
            Whoops... something went wrong. Please try again or{" "}
            <Link to="/contact-us" style={{ color: palette.primary.main }}>
              contact us
            </Link>{" "}
            if you need assistance.
          </Grid>
        </Grid>
      )}
      <Grid container sx={{ backgroundColor: "common.brandHigh", px: GUTTER_SIZE, py: 5 }}>
        <Grid item>
          <Typography variant="page-heading">Account Information</Typography>
        </Grid>
      </Grid>
      <Form disableReinitialize defaultValues={DEFAULT_VALUES} initialValues={filters} onSubmit={handleSubmit}>
        {({ submitForm }) => (
          <>
            <Grid container sx={{ justifyContent: "flex-end" }}>
              <Grid item xs={12} lg={4} xl={3}>
                <SearchPageFilters submitForm={submitForm} />
              </Grid>
            </Grid>
            <Box sx={{ backgroundColor: "common.white" }}>
              <Table
                loading={loading}
                minHeight={278}
                onRowClick={showConfirmAuthorityDialog}
                paging={paging}
                rows={accounts}
                columns={[
                  {
                    display: "emphasize",
                    field: "name",
                    heading: "Account name",
                    sort: SearchAccountantAccountSortBy.AccountName,
                    startAdornment: <ThemeIcon />,
                    width: 35,
                  },
                  {
                    display: "chip",
                    field: "productDisplayName",
                    heading: "Product",
                    sort: SearchAccountantAccountSortBy.Product,
                    width: 11,
                    variant: "outlined",
                  },
                  {
                    align: "left",
                    field: "accountKey",
                    heading: "Account number",
                    sort: SearchAccountantAccountSortBy.AccountKey,
                    width: 15,
                  },
                  {
                    align: "right",
                    field: "enrolled",
                    heading: "Enrolment date",
                    sort: SearchAccountantAccountSortBy.EnrolmentDate,
                    type: "date",
                    width: 15,
                  },
                  {
                    heading: "Authority expiry",
                    field: "authorityExpiry",
                    type: "date",
                    sort: SearchAccountantAccountSortBy.AuthorityExpiry,
                    width: 15,
                  },
                  {
                    align: "right",
                    field: (row) => <DeleteButton accountKey={row.accountKey} onClick={showRemoveAccessDialog} />,
                    heading: "Remove access",
                    width: 9,
                  },
                ]}
              />
            </Box>
          </>
        )}
      </Form>
      <RemoveAccessDialog onClose={closeRemoveAccessDialog} open={!!removeAccessAccountKey} />
      <ConfirmAuthorityDialog onClose={closeConfirmAuthorityDialog} open={!!confirmAuthorityAccount} accountKey={confirmAuthorityAccount?.accountKey} />
    </Theme>
  );
}

function DeleteButton({ accountKey, onClick }: { accountKey: string | undefined; onClick: (accountKey: string | undefined) => void }) {
  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      event.preventDefault();
      onClick(accountKey);
    },
    [accountKey, onClick]
  );

  return (
    <Box sx={{ px: 1, my: -0.75 }}>
      <IconButton onClick={handleClick} size="small">
        <DeleteIcon sx={{ color: "primary.main" }} titleAccess="Remove access" />
      </IconButton>
    </Box>
  );
}

function RemoveAccessDialog({ onClose, open }: { onClose: (proceed: boolean) => void; open: boolean }) {
  return (
    <Dialog onClose={() => onClose(false)} open={open}>
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>You will no longer be able to access this account.</DialogContentText>
        <DialogContentText>Do you want to proceed?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} variant="text">
          Cancel
        </Button>
        &emsp;
        <Button onClick={() => onClose(true)}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

function ConfirmAuthorityDialog({ accountKey, onClose, open }: { accountKey: string | undefined; onClose: () => void; open: boolean }) {
  const dispatch = useAppDispatch();

  const okButtonOnClick = useCallback(() => {
    dispatch(push(`/accountant/account/${accountKey}`));
  }, [dispatch, accountKey]);

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>I confirm my authority to access this information remains valid.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        &emsp;
        <Button onClick={okButtonOnClick}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
