import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AccountsSort, SearchAccountDto } from "../../api";
import { ACCOUNT_STATE_OPTIONS } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountExportAsync, accountSearchAsync } from "../../store/accounts";
import { filterAdvisers, generateLink, getProducts, userHasRole } from "../../utils";
import { Breadcrumb, ThemeIcon } from "../common";
import { SearchPageFilters } from "../common/SearchPageFilters";
import { Autocomplete, AutocompleteMultiple, Form } from "../form";
import Table from "../table";
import { ColumnDefinition } from "../table/ColumnDefinition";

type FormValues = Parameters<typeof accountSearchAsync>[0];

const DEFAULT_VALUES: FormValues = {
  adviserBranchKey: undefined,
  adviserNumber: undefined,
  currentPage: 0,
  pageSize: 35,
  services: [],
  sortOrder: AccountsSort.AccountName,
  states: ["Active"],
  term: "",
};

const COLUMNS: ColumnDefinition<SearchAccountDto, string>[] = [
  {
    field: () => <ThemeIcon />,
    width: 2,
  },
  {
    display: "emphasize",
    field: "name",
    heading: "Account name",
    sort: AccountsSort.AccountName,
    width: 22,
  },
  {
    heading: "Adviser",
    field: "advisers",
    width: 20,
  },
  {
    display: "chip",
    field: "product",
    heading: "Product",
    sort: AccountsSort.Service,
    width: 12,
    variant: "outlined",
  },
  {
    field: "state",
    heading: "Status",
    sort: AccountsSort.State,
    width: 6,
  },
  {
    align: "right",
    field: "accountKey",
    heading: "Account number",
    sort: AccountsSort.AccountKey,
    width: 14,
  },
  {
    align: "right",
    field: "enrolled",
    heading: "Enrolled",
    sort: AccountsSort.EnrolmentDate,
    type: "date",
    width: 12,
  },
  {
    display: "emphasize",
    field: "balance",
    heading: "Balance",
    sort: AccountsSort.Balance,
    type: "money",
    width: 12,
  },
];

function Accounts() {
  const dispatch = useAppDispatch();
  const history = useHistory<(FormValues & { breadcrumbs?: Breadcrumb[] }) | undefined>();
  const { autoSearch } = useAppSelector((as) => as.advisers);
  const abortsRef = useRef<((reason?: string) => void)[]>([]);
  const [filters, setFilters] = useState<FormValues>();
  const initialValues = useMemo<FormValues>(() => ({ ...DEFAULT_VALUES, ...history.location.state }), [history.location.state]);

  const abortRequests = useCallback(() => abortsRef.current.forEach((abort) => abort()), []);

  const handleSubmit = useCallback(
    (values: FormValues) => setFilters({ ...values, currentPage: values.currentPage !== filters?.currentPage ? values.currentPage : 0 }),
    [filters]
  );

  const fetchData = useCallback(
    (values: FormValues) => {
      abortRequests();
      const promise = dispatch(accountSearchAsync(values));
      abortsRef.current = [promise.abort];
      history.replace(history.location.pathname, values);
    },
    [abortRequests, dispatch, history]
  );

  useEffect(() => {
    if (autoSearch) fetchData(initialValues);
  }, [autoSearch, fetchData, initialValues]);

  useEffect(() => {
    filters && fetchData(filters);
  }, [filters, fetchData]);

  useEffect(() => {
    return () => abortRequests();
  }, [abortRequests]);

  return (
    <Form disableReinitialize defaultValues={DEFAULT_VALUES} initialValues={initialValues} onSubmit={handleSubmit}>
      <SearchForm />
    </Form>
  );
}

function SearchForm() {
  const { setValues, submitForm } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();
  const history = useHistory<(FormValues & { breadcrumbs?: Breadcrumb[] }) | undefined>();
  const { isLoading: isSearching, isExporting, searchResult } = useAppSelector((as) => as.accounts);
  const { adviserBranches, advisers } = useAppSelector((as) => as.advisers);
  const { user } = useAppSelector((as) => as.oidc);
  const adviserBranchOptions = useMemo(() => adviserBranches?.map(({ key, name }) => ({ label: name, value: key })), [adviserBranches]);
  const products = useMemo(() => getProducts(user), [user]);
  const canExport = useMemo(() => (userHasRole(user, "Deny Accounts Export") ? undefined : true), [user]);
  const isLoading = useMemo(() => !!isSearching, [isSearching]);

  const exportAll = useCallback(() => dispatch(accountExportAsync(DEFAULT_VALUES)), [dispatch]);

  const exportFiltered = useCallback((values: FormValues) => dispatch(accountExportAsync(values)), [dispatch]);

  const filterAdviserOptions = useCallback(
    (values: FormValues & { adviserBranchKey?: string; adviserGroupKey?: string }) =>
      filterAdvisers(advisers, values)?.map(({ name, number }) => ({
        label: `${name} (${number})`,
        value: number,
      })),
    [advisers]
  );

  const handleReset = useCallback(() => {
    setValues(DEFAULT_VALUES);
    history.replace(history.location.pathname, DEFAULT_VALUES);
  }, [history, setValues]);

  const createLink = useCallback(({ accountKey }: SearchAccountDto) => generateLink("Account Information", `/account/${accountKey}`, history), [history]);

  return (
    <Grid container>
      <SearchPageFilters
        exportAll={canExport && exportAll}
        exportFiltered={canExport && exportFiltered}
        exporting={isExporting}
        loading={isLoading}
        onReset={handleReset}
        resetLabel="Reset"
        searchTermLabel="Search by account name, number..."
        submitForm={submitForm}
      >
        <Grid item xs>
          <Autocomplete
            autoSelectIfOneOption
            label="Branch"
            name="adviserBranchKey"
            onChangeClear={["adviserNumber"]}
            options={adviserBranchOptions}
            shrinkLabel
          />
        </Grid>
        <Grid item xs>
          <Autocomplete autoSelectIfOneOption label="Adviser" name="adviserNumber" options={filterAdviserOptions} shrinkLabel />
        </Grid>
        <Grid item xs>
          <AutocompleteMultiple label="Product" name="services" options={products} shrinkLabel valueIsTag />
        </Grid>
        <Grid item xs>
          <AutocompleteMultiple label="Accounts status" name="states" options={ACCOUNT_STATE_OPTIONS} shrinkLabel />
        </Grid>
      </SearchPageFilters>
      <Table loading={isLoading} minHeight={315} paging={searchResult} rows={searchResult?.results} to={createLink} columns={COLUMNS} />
    </Grid>
  );
}

export { Accounts };
