import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { IconButton } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";

interface StepperProps {
  progress: number;
  totalSteps: number;
  stepBack: () => void;
  stepForward: () => void;
  variant?: "progress" | "text" | "dots" | undefined;
}

function Stepper({ progress, totalSteps, variant, stepBack, stepForward }: StepperProps) {
  return (
    <MobileStepper
      activeStep={progress}
      position="static"
      steps={totalSteps}
      variant={variant}
      backButton={
        <IconButton onClick={stepBack} disabled={progress === 0}>
          <KeyboardArrowLeftIcon />
        </IconButton>
      }
      nextButton={
        <IconButton onClick={stepForward} disabled={progress === totalSteps - 1}>
          <KeyboardArrowRightIcon />
        </IconButton>
      }
      sx={{ ".MuiMobileStepper-dot": { width: 12, height: 12, mx: 0.5, my: 0 } }}
    />
  );
}

export { Stepper };
