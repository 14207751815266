import { Grid } from "@mui/material";
import { FormikContextType, useFormikContext } from "formik";
import { ReactNode } from "react";

function FeeAgreementField<Values>(props: {
  label?: ReactNode;
  field: ReactNode | ((values: Values, formik: FormikContextType<Values>) => ReactNode);
  paddingTop?: string | number;
}) {
  const { label, field, paddingTop } = props;
  const formik = useFormikContext<Values>();

  return (
    <Grid item container xs={12} paddingBottom={2}>
      <Grid item xs={4} alignSelf="top" paddingTop={typeof field !== "string" && typeof label === "string" && paddingTop === undefined ? "8px" : paddingTop}>
        {label}
      </Grid>
      <Grid item container xs={8} alignItems="center" gap={1} alignSelf="top">
        {typeof field === "function" ? field(formik.values, formik) : field}
      </Grid>
    </Grid>
  );
}

export { FeeAgreementField };
