import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Report, ReportDynamicFilter, ReportDynamicFilters, ReportField, ReportList } from "../../../components/reports";
import { useAppDispatch, useAppSelector } from "../../../store";
import { associatedAdvisersAsync } from "../../../store/advisers";
import { exportFundsUnderManagementAsync, fundsUnderManagementAsync } from "../../../store/reports";
import { filterAdvisers, filterBranches, getProducts } from "../../../utils";
import { Autocomplete, DateField } from "../../form";
import { FundsUnderManagement } from "./FundsUnderManagement";

function Assets() {
  const { advisers, adviserBranches, adviserGroups } = useAppSelector((as) => as.advisers);
  const { user } = useAppSelector((as) => as.oidc);
  const dispatch = useAppDispatch();
  const products = useMemo(() => getProducts(user), [user]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  return (
    <Grid container sx={{ height: "100%" }}>
      <ReportList>
        <Report
          name="Funds Under Management Report"
          description="View total funds under management and accounts at a certain date, to the closest pricing date."
          initialValues={{}}
          filters={
            <ReportField label="Choose date" dualColumn>
              <DateField name="asAt" maxDate={new Date()} />
            </ReportField>
          }
          advancedFilters={
            <ReportDynamicFilters>
              <ReportDynamicFilter label="Product" field="service">
                <Autocomplete name="service" allOption="All" options={products} />
              </ReportDynamicFilter>
              {(adviserGroups?.length ?? 0) > 1 && (
                <ReportDynamicFilter label="Company" field="adviserGroupKey">
                  <Autocomplete
                    autoSelectIfOneOption
                    name="adviserGroupKey"
                    allOption="All"
                    options={adviserGroups?.map((adviserGroup) => ({ value: adviserGroup.key, label: adviserGroup.name })) || []}
                    onChangeClear={["adviserNumber", "adviserBranchKey"]}
                  />
                </ReportDynamicFilter>
              )}
              {(adviserBranches?.length ?? 0) > 1 && (
                <ReportDynamicFilter label="Branch" field="adviserBranchKey">
                  <Autocomplete
                    autoSelectIfOneOption
                    name="adviserBranchKey"
                    allOption="All"
                    options={(values: { adviserGroupKey?: string }) =>
                      filterBranches(adviserBranches, values)?.map((adviserBranch) => ({ value: adviserBranch.key, label: adviserBranch.name })) || []
                    }
                    onChangeClear={["adviserNumber"]}
                  />
                </ReportDynamicFilter>
              )}
              <ReportDynamicFilter label="Adviser" field="adviserNumber">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserNumber"
                  allOption="All"
                  options={(values: { adviserGroupKey?: string; adviserBranchKey?: string }) =>
                    filterAdvisers(advisers, values)?.map((adviser) => ({ value: adviser.number, label: `${adviser.name} (${adviser.number})` })) || []
                  }
                />
              </ReportDynamicFilter>
            </ReportDynamicFilters>
          }
          view={(values) => dispatch(fundsUnderManagementAsync(values))}
          export={(values) => dispatch(exportFundsUnderManagementAsync(values))}
        >
          <FundsUnderManagement />
        </Report>
      </ReportList>
    </Grid>
  );
}

export { Assets };
