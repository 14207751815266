import { styled, Theme } from "@mui/material";
import { CSSProperties } from "react";
import { Link as RouterLink } from "react-router-dom";

type LinkVariant = "breadcrumb" | "switch";

function linkStyles({ palette, shape }: Theme) {
  return {
    breadcrumb: {
      backgroundColor: palette.common.white,
      borderRadius: shape.borderRadius,
      color: palette.primary.dark,
      padding: "6px 12px",
      transition: "background-color 225ms, color 225ms",
      "&:hover": {
        backgroundColor: palette.primary.main,
        color: palette.common.white,
      },
    },
    switch: {
      color: palette.primary.main,
      textDecoration: "underline",
    },
  } as Record<LinkVariant, CSSProperties>;
}

const Link = styled(RouterLink, { shouldForwardProp: (name) => name !== "variant" })<{ variant?: LinkVariant }>(({ theme, variant }) => ({
  ...(variant && {
    "&, &:active, &:hover, &:visited": linkStyles(theme)[variant],
  }),
}));

export { Link };
