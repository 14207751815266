import DownloadIcon from "@mui/icons-material/Download";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { exportAMLAnnualReportCustomersAndMembersAsync, exportAMLAnnualReportProductsAndServicesAsync } from "../../../store/compliance";
import { IconButton } from "../../common";
import Table from "../../table";

function showDataOrIcon(data: string | undefined, download: (value: string) => void) {
  if (data?.startsWith("Icon"))
    return (
      <IconButton onClick={() => download("onClick " + data)}>
        <DownloadIcon />
      </IconButton>
    );
  else return data;
}

function AMLAnnualReport() {
  const { isLoading, amlReportSummary } = useAppSelector((as) => as.compliance);
  const dispatch = useAppDispatch();

  const download = useCallback(
    (value: string) => {
      const service = value.indexOf("KS") >= 0 ? "KS" : "";

      if (value.indexOf("Product") >= 0) dispatch(exportAMLAnnualReportProductsAndServicesAsync({ service }));
      else if (value.indexOf("EndOfYear") >= 0) dispatch(exportAMLAnnualReportCustomersAndMembersAsync({ service, isendofyear: true }));
      else dispatch(exportAMLAnnualReportCustomersAndMembersAsync({ service, isendofyear: false }));
    },
    [dispatch]
  );

  return !isLoading && !amlReportSummary ? null : (
    <Table
      loading={isLoading}
      loadingBlankRows={15}
      minHeight={312}
      rows={amlReportSummary?.amlReportSummary}
      rowExpanded="always"
      columns={[
        {
          heading: "Question",
          field: () => "",
          width: 5,
        },
        {
          heading: "Section",
          field: () => "",
          width: 55,
        },
        {
          heading: "",
          field: () => "",
          width: 15,
        },
        {
          heading: "ASP/MSS/WB/IG/CMS",
          field: () => "",
          align: "center",
          width: 15,
        },
        {
          heading: "KS",
          align: "center",
          field: () => "",
          width: 15,
        },
      ]}
      rowExpand={(data, backgroundColor) => (
        <Table
          child
          rowBackgroundColor={backgroundColor}
          rows={data.rows}
          columns={[
            {
              heading: "",
              field: "questionNumber",
              width: 5,
            },
            {
              heading: "",
              field: "question1",
              width: 55,
            },
            {
              heading: "",
              field: "question2",
              width: 15,
            },
            {
              heading: "",
              align: "center",
              field: (item) => showDataOrIcon(item.data, download),
              width: 15,
            },
            {
              heading: "",
              align: "center",
              field: (item) => showDataOrIcon(item.ksData, download),
              width: 15,
            },
          ]}
        />
      )}
    />
  );
}

export { AMLAnnualReport };
