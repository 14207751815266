import { ClickAwayListener, Grid, InputAdornment, Tooltip } from "@mui/material";
import { FormikContextType, setNestedObjectValues, validateYupSchema, yupToFormErrors } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { CreateFeePresetCommand, FundModel } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { accountCreateFeePresetAsync } from "../../../store/accounts";
import { portfolioSort } from "../../../utils";
import { IconButton, SaveIcon } from "../../common";
import { Autocomplete, CheckboxList, NumberField, TextField } from "../../form";
import { ErrorMessage } from "../../form/ErrorMessage";
import { FeeAgreementField } from "../FeeAgreementField";
import { gisFeeStructureValidation } from "../validation/gisFeeStructureValidation";

interface GisFeeOtherProps {
  funds?: FundModel[];
}

// Omit Core Inflation Portfolio, Property Inflation Portfolio, Equity Inflation Portfolio
const OMITTED_FUND_KEYS = ["IPP", "DHG", "DGSP"];

const createFeePresetValidation: yup.Schema<CreateFeePresetCommand> = yup.object({
  adviserNumber: yup.string().required("Adviser is required"),
  gis: gisFeeStructureValidation,
  presetName: yup.string().required("Preset name is required"),
});

function GisFeeOther({ funds }: GisFeeOtherProps) {
  const [saveTemplateResult, setSaveTemplateResult] = useState<string>();
  const { isSavingFeePreset } = useAppSelector((as) => as.accounts);
  const dispatch = useAppDispatch();

  return (
    <>
      <FeeAgreementField
        label="Choose fee deduction"
        paddingTop={0}
        field={(values: { gis: { deductPerFundOption?: boolean } }) => (
          <>
            <CheckboxList
              name="gis.deductPerFundOption"
              radio
              itemGrid={{ xs: 12 }}
              checkboxPlacement="end"
              options={[
                { value: true, label: "Deduct total fees separately and proportionally from each portfolio" },
                { value: false, label: "Deduct total fees from the following portfolio" },
              ]}
            />
            {values.gis != null && values.gis.deductPerFundOption === false && (
              <Autocomplete
                name="gis.deductFromFundId"
                placeholder="Choose portfolio"
                options={portfolioSort(
                  funds
                    ?.filter((fund) => !OMITTED_FUND_KEYS.includes(fund.fundKey || ""))
                    .map((fund) => ({ value: fund.fundRegistryKey || "", label: fund.fundName ?? "" })),
                  "label"
                )}
              />
            )}
          </>
        )}
      />
      <FeeAgreementField
        label="GST rate"
        field={
          <>
            <Grid item xs={12}>
              <Grid width="110px">
                <NumberField
                  decimalScale={2}
                  hideErrorMessage
                  InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  name="gis.gstRate"
                />
              </Grid>
            </Grid>
            <ErrorMessage name="gis.gstRate" />
          </>
        }
      />
      <FeeAgreementField label="Notes" field={<TextField name="gis.gisFeeAccountNote" multiline />} />
      <FeeAgreementField
        label="Save as template"
        field={(_, formik: FormikContextType<CreateFeePresetCommand>) => (
          <TextField
            name="presetName"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    loading={isSavingFeePreset}
                    onClick={async () => {
                      const presetName = formik.values.presetName;
                      formik.setSubmitting(true);

                      try {
                        await validateYupSchema(formik.values, createFeePresetValidation, false, formik);
                      } catch (error) {
                        formik.setTouched(
                          {
                            presetName: true,
                            adviserNumber: true,
                            gis: setNestedObjectValues(formik.values.gis, true),
                          },
                          false
                        );
                        formik.setErrors(yupToFormErrors(error));
                        formik.setSubmitting(false);
                        return;
                      }

                      const result = await dispatch(accountCreateFeePresetAsync(formik.values));

                      if (result.meta.requestStatus === "rejected") {
                        setSaveTemplateResult("Error saving");
                      } else {
                        setSaveTemplateResult(`Successfully saved template as ${presetName}`);
                        formik.getFieldHelpers("presetName")?.setValue("");
                      }

                      formik.setSubmitting(false);
                    }}
                  >
                    <ClickAwayListener onClickAway={() => setSaveTemplateResult(undefined)}>
                      <Tooltip
                        title={saveTemplateResult ?? ""}
                        open={!!setSaveTemplateResult}
                        disableFocusListener
                        disableHoverListener
                        disableInteractive
                        disableTouchListener
                      >
                        <SaveIcon />
                      </Tooltip>
                    </ClickAwayListener>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </>
  );
}

export { GisFeeOther };
