import { Grid, InputAdornment } from "@mui/material";
import { useFormikContext } from "formik";
import { ReactNode, useCallback, useMemo } from "react";
import { formatValue } from "../../utils";
import { Typography } from "../common/Typography";
import { BaseTextField } from "../form/BaseTextField";
import { ErrorMessage } from "../form/ErrorMessage";
import { FeeAgreementSubField } from "./FeeAgreementSubField";
import { Fee, FeeAgreementType, TierFee } from "./types";

interface FeeAgreementListBaseProps extends FeeAgreementType {
  field?: string;
  allOption?: string;
  description?: ReactNode;
  heading?: {
    label: ReactNode;
    field: ReactNode;
  };
  additionalFields?: ReactNode;
}

interface FeeAgreementListTierProps<Values> {
  tiered: true;
  fees: TierFee[] | ((values: Values) => TierFee[]);
}

interface FeeAgreementListDefaultProps<Values> {
  tiered?: false;
  fees: Fee[] | ((values: Values) => Fee[]);
}

const formatTier = (value?: number) => formatValue(value, { type: "money", typeOption: { decimalPlaces: 0 } });

type FeeAgreementListProps<Values> = (FeeAgreementListDefaultProps<Values> | FeeAgreementListTierProps<Values>) & FeeAgreementListBaseProps;

function mapFees(fees: { tiered: true; fees: TierFee[] } | { tiered?: false; fees: Fee[] }): Fee[] {
  return fees.tiered
    ? fees.fees.map((fee, index) => ({
        ...fee,
        name: `${formatTier(index === 0 ? 0 : (fees.fees[index - 1]?.thresholdTo ?? 0) + 1)}${formatTier((fee as TierFee).thresholdTo) ? " - " + formatTier((fee as TierFee).thresholdTo) : "+"}`,
      }))
    : fees.fees;
}

function FeeAgreementList<Values extends Record<string, unknown>>({
  additionalFields,
  allOption,
  description,
  fees: propFees,
  field,
  heading,
  tiered,
}: FeeAgreementListProps<Values>) {
  const { getFieldProps, setFieldValue, values } = useFormikContext<Values>();
  const fees = useMemo(() => mapFees({ tiered, fees: typeof propFees === "function" ? propFees(values) : propFees }), [propFees, tiered, values]);
  const allSame = useMemo(() => {
    const first = getFieldProps(fees[0]?.field);
    const firstValue = first.value;
    const notSame = fees.some((fee) => firstValue !== getFieldProps(fee.field).value);
    return notSame ? undefined : firstValue;
  }, [fees, getFieldProps]);

  const setAll = useCallback((value: number | undefined) => fees.forEach((fee) => setFieldValue(fee.field, value)), [fees, setFieldValue]);

  return (
    <>
      {!!description && (
        <Grid item xs={12} paddingLeft={2}>
          {description}
        </Grid>
      )}
      {!!heading && (
        <Grid item container xs={12}>
          <Grid item xs={6} paddingLeft={2}>
            <Typography variant="label">{heading.label}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="label">{heading.field}</Typography>
          </Grid>
        </Grid>
      )}
      {!!allOption && (
        <FeeAgreementSubField
          label={allOption}
          field={
            <BaseTextField
              decimalScale={2}
              InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
              max={5}
              onChange={setAll}
              number
              value={allSame}
            />
          }
        />
      )}
      {fees.map((fee) => (
        <FeeAgreementSubField key={fee.field} label={fee.name} fee={fee} hideErrorMessage={!!field} />
      ))}
      {additionalFields}
      {!!field && <ErrorMessage name={field} />}
    </>
  );
}

export { FeeAgreementList };
