import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { GetFundHistoryLine } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { fundHistoryClear } from "../../../store/funds";
import { portfolioSort, productKeyToName } from "../../../utils";
import Table from "../../table";

function getFunds(lines?: GetFundHistoryLine[]) {
  if (!lines) return undefined;

  const fundNames = Array.from(new Set(lines.map((line) => `${line.services?.[0]}|${line.fundName}`)));

  return fundNames.sort().map((fund) => {
    const name = fund.split("|")[1];
    const line = lines.find((line) => line.fundName === name);
    return { services: line?.services, fundName: line?.fundName };
  });
}

function UnitPricesTable() {
  const dispatch = useAppDispatch();
  const { isLoading, fundHistory } = useAppSelector((as) => as.funds);
  const funds = useMemo(() => portfolioSort(getFunds(fundHistory?.results), "fundName"), [fundHistory?.results]);

  const getFundLines = useCallback((fundName?: string) => fundHistory?.results?.filter((line) => line.fundName === fundName), [fundHistory?.results]);

  useEffect(() => {
    return () => {
      dispatch(fundHistoryClear());
    };
  }, [dispatch]);

  return !funds && !isLoading ? null : (
    <Grid container sx={{ backgroundColor: "common.white" }}>
      <Table
        loading={isLoading}
        loadingBlankRows={15}
        minHeight={447}
        rows={funds}
        columns={[
          {
            heading: "Product",
            field: (row) => row.services?.map(productKeyToName),
            display: "chip",
            variant: "outlined",
            width: 25,
          },
          {
            heading: "Fund",
            field: "fundName",
            width: 35,
          },
          {
            heading: "Pricing date",
            field: () => "",
            align: "center",
            width: 20,
          },
          {
            heading: "Price",
            field: () => "",
            align: "right",
            width: 20,
          },
        ]}
        rowExpanded={funds?.length === 1 ? "always" : undefined}
        rowExpand={(row, backgroundColor) => (
          <Table
            child
            rowBackgroundColor={backgroundColor}
            rows={getFundLines(row.fundName)}
            columns={[
              {
                heading: "Product",
                field: () => "",
                width: 25,
              },
              {
                heading: "Fund",
                field: () => "",
                width: 36,
              },
              {
                heading: "Pricing date",
                field: "fundHistoryDate",
                type: "date",
                width: 20,
              },
              {
                heading: "Price",
                field: "fundHistoryPrice",
                type: "money",
                typeOption: { decimalPlaces: 4 },
                width: 20,
              },
            ]}
          />
        )}
      />
    </Grid>
  );
}

export { UnitPricesTable };
