import { array, number, object, Schema, string } from "yup";
import { PrivateWealthFeeStructure } from "../../../api";

const pwFeeStructureValidation: Schema<PrivateWealthFeeStructure> = object({
  fees: array(string().required()).min(1, "At least one fee type is required").required(),
  services: array()
    .when("fees", ([fees], schema) => (fees?.includes("annualRate") ? schema.required().min(1, "At least one service is required") : schema))
    .required(),
  initialAdviceAmount: number().when("fees", ([fees], schema) => (fees?.includes("initial") ? schema.required("Amount required") : schema)),
  annualAmount: number().when("fees", ([fees], schema) => (fees?.includes("annualFixed") ? schema.required("Amount required") : schema)),
  annualAmountDescription: string().when("fees", ([fees], schema) => (fees?.includes("annualFixed") ? schema.required("Description required") : schema)),
  annualRate: number().when("fees", ([fees], schema) => (fees?.includes("annualRate") ? schema.required("Rate required") : schema)),
  annualAmountEstimate: number().when("fees", ([fees], schema) => (fees?.includes("annualRate") ? schema.required("Amount required") : schema)),
});

export { pwFeeStructureValidation };
