import { Grid, InputAdornment } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { NumberField, TextField } from "../form";
import { Fee, FeeType } from "./types";

interface FeeAgreementSubFieldProps {
  fee?: Fee;
  field?: ReactNode;
  hideErrorMessage?: boolean;
  label?: ReactNode;
}

function FeeAgreementSubField({ label, fee, field, hideErrorMessage }: FeeAgreementSubFieldProps) {
  const wider = useMemo(() => fee?.type === "note", [fee?.type]);

  return (
    <Grid item container xs={12} justifyContent={fee?.moreSpacing ? "space-between" : undefined}>
      <Grid item xs={wider ? 4 : 6} alignSelf="top" paddingTop={1} paddingLeft={2}>
        {label}
      </Grid>
      <Grid item container xs={wider ? 8 : 5} alignItems="center" gap={1} alignSelf="top" width={wider ? undefined : 0} minWidth={125}>
        {field}
        {!!fee && <Field field={fee.field} type={fee.type} disabled={fee.disabled} hideErrorMessage={hideErrorMessage} />}
      </Grid>
    </Grid>
  );
}

function Field({ field, type, disabled, hideErrorMessage }: { field: string; type: FeeType; disabled?: boolean; hideErrorMessage?: boolean }) {
  const multiline = useMemo(() => type === "note", [type]);

  switch (type) {
    case "amount":
      return <NumberField decimalScale={2} disabled={disabled} hideErrorMessage={hideErrorMessage} InputProps={{ startAdornment: "$" }} name={field} />;
    case "rate":
      return (
        <NumberField
          decimalScale={2}
          disabled={disabled}
          hideErrorMessage={hideErrorMessage}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
          max={5}
          name={field}
        />
      );
    case "text":
    case "note":
      return <TextField disabled={disabled} hideErrorMessage={hideErrorMessage} multiline={multiline} name={field} />;
  }
}

export { FeeAgreementSubField };
