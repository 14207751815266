import { Box } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { Link } from "./Link";

interface TwoDetailsProps {
  detail1?: string;
  detail2?: string;
  link?: string;
  startAdornment?: ReactNode;
}

export function TwoDetails({ detail1, detail2, link, startAdornment }: TwoDetailsProps) {
  const detail = useMemo(() => detail1?.trim() !== detail2?.trim() && detail2, [detail1, detail2]);

  const details = (
    <>
      {startAdornment} {detail1}{" "}
      <Box component="span" sx={{ color: "primary.main", fontWeight: 400 }}>
        {detail}
      </Box>
    </>
  );

  return link ? <Link to={link}>{details}</Link> : details;
}
