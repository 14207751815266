import { ContentCopy } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { MouseEvent, useCallback } from "react";
import { useAppSelector } from "../../store";
import { IconButton, Panel } from "../common";
import { Typography } from "../common/Typography";
import Table from "../table";

function copyValue(value: number) {
  navigator.clipboard.writeText((Math.round(value * 100) / 100).toFixed(2));
}

export default function AccountFinancialSummary() {
  const { isLoadingAccountFinancialSummary, accountFinancialSummary } = useAppSelector((as) => as.reports);

  return !isLoadingAccountFinancialSummary && !accountFinancialSummary ? null : (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Table
        loading={isLoadingAccountFinancialSummary}
        loadingBlankRows={3}
        minHeight={300}
        pageSizeHidden
        rows={accountFinancialSummary?.items}
        columns={[
          {
            heading: "Detail",
            field: "details",
            display: "emphasize",
            width: 26,
          },
          {
            heading: "Value",
            field: "value",
            type: "money",
            width: 9,
          },
          {
            heading: "",
            align: "center",
            field: (row) => <CopyButton onClick={copyValue} value={row.value} />,
            width: 9,
          },
        ]}
      />
      <Panel noBorderTop>
        <Grid container>
          <Grid item xs={12} sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 0.75 }}>
            <Typography variant="section-heading">Additional Information</Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1.5 }}>
            <Typography variant="footnote">
              <Grid container spacing={1}>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">1</Typography>
                  New Zealand Funds Management Limited is the manager and issuer of the Portfolios / Strategies. Details of the terms and conditions applying to
                  these holdings are set out in the Product Disclosure Statement(s).
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">2</Typography> This report does not constitute evidence of title to the units referred to above.
                  Accordingly, delivery of this document does not create any right or interest in the units. The Manager or any related company or person
                  accepts no liability to any person who accepts delivery of this document as security or for any other purpose except as required by law.
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">3</Typography>
                  The Tax Report includes all transactions and amounts relating to a tax year (1 April to 31 March). This includes payments/refunds for the
                  March quarter which are paid/received in April. The Financial Summary lists transactions in chronological order. <br />
                  This creates a timing difference in the reporting of March tax payments/receipts between these reports.
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Panel>
    </Box>
  );
}

function CopyButton({ value, onClick }: { value: number; onClick: (value: number) => void }) {
  const buttonOnClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onClick(value);
    },
    [value, onClick]
  );

  return (
    <IconButton onClick={buttonOnClick} size="small" sx={{ borderRadius: 1, px: 2, py: "1px" }}>
      <ContentCopy color="primary" titleAccess="Copy to clipboard" />
    </IconButton>
  );
}
