import { Grid } from "@mui/material";
import { useMemo } from "react";
import { FileType } from "../../api";
import { useAppSelector } from "../../store";
import { Panel, Typography } from "../common";
import { FormLabel } from "../common/FormLabel";
import { Autocomplete, Checkbox, CheckboxList, DateField, FileUpload, TextField } from "../form";

const ManageFileTypeToText: Record<FileType, string> = {
  Communication: "Communication",
  Document: "Document",
};

function ManageFile({ fileType }: { fileType: FileType; isAdmin: boolean }) {
  const { staticDataResults, isStaticDataLoading } = useAppSelector((as) => as.adviceTools);
  const title = useMemo(() => ManageFileTypeToText[fileType], [fileType]);
  const subTitle = useMemo(() => ManageFileTypeToText[fileType].toLowerCase(), [fileType]);
  const categoryNames = useMemo(
    () => (fileType == FileType.Communication ? staticDataResults?.communicationCategories : staticDataResults?.documentCategories),
    [fileType, staticDataResults]
  );

  return isStaticDataLoading ? null : (
    <Panel>
      <Grid container gap={1}>
        <FormLabel labelPrefix={title} label="name" field={<TextField name="fileName" />} />
        <FormLabel labelPrefix={title} label="url" field={<TextField name="url" />} />
        <FormLabel
          labelPrefix={title}
          label="upload"
          field={
            <Grid container>
              <FileUpload name="file" />
            </Grid>
          }
        />
        <Grid container>
          <Grid item xs={6}>
            <FormLabel label="Last updated" field={<DateField name="lastUpdated" />} />
          </Grid>
          <Grid item xs={6}>
            <FormLabel
              label="Priority"
              field={
                <Checkbox name="priority">
                  <>Key {subTitle} ?</>
                </Checkbox>
              }
            />
          </Grid>
        </Grid>
        <FormLabel
          label="Type"
          field={
            <Grid item xs={12}>
              <Autocomplete
                name="categorySelected"
                placeholder="Type"
                options={categoryNames?.map((category) => ({ value: category.categoryId || "", label: category.name || "" }))}
              />
            </Grid>
          }
        />
        <Grid item xs={12}>
          <Typography variant="label">
            Keywords <span style={{ color: "red" }}>(Use a comma to seperate tags)</span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField name="keywords" placeholder="Keywords" multiline />
        </Grid>
        {fileType == FileType.Document && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="label">Product</Typography>
            </Grid>
            <CheckboxList
              itemGrid={{ xs: 4 }}
              name="products"
              options={staticDataResults?.productNames?.map((product) => ({ value: product.code || "", label: product.name || "" }))}
            />
          </Grid>
        )}
        {fileType == FileType.Document && (
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="label">
                Roles <span style={{ color: "red" }}>(If you do not choose a role, this document will be publicly available to all PORT users)</span>
              </Typography>
            </Grid>
            <CheckboxList
              itemGrid={{ xs: 6 }}
              name="roles"
              options={staticDataResults?.roles?.map((role) => ({ value: role.roleType || "", label: role.value || "" }))}
            />
          </Grid>
        )}
      </Grid>
    </Panel>
  );
}

export { ManageFile };
