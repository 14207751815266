import { GetAccountDetailsResponse } from "../../../api";
import { CheckboxList, TextField } from "../../form";
import { FeeAgreementField } from "../FeeAgreementField";

interface PrivateWealthFeeOtherProps {
  account?: GetAccountDetailsResponse;
}

export function PrivateWealthFeeOther(props: PrivateWealthFeeOtherProps) {
  return (
    <>
      {!props.account && (
        <FeeAgreementField
          label="Service"
          field={
            <CheckboxList
              name="pw.services"
              options={[
                { value: "ASP", label: "Active Series" },
                { value: "MSS", label: "Managed Superannuation Service" },
              ]}
            />
          }
        />
      )}
      <FeeAgreementField label="Client phone" field={<TextField placeholder="Enter client phone to use in the agreement" name="clientPhone" />} />
      <FeeAgreementField label="Client mobile" field={<TextField placeholder="Enter client mobile to use in the agreement" name="clientMobile" />} />
      <FeeAgreementField label="Client email" field={<TextField placeholder="Enter client email to use in the agreement" name="clientEmail" />} />
    </>
  );
}
