import { Grid, useTheme } from "@mui/material";
import { useEffect } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { accountsCddStatusAsync } from "../../../store/compliance";
import { generateLink } from "../../../utils";
import { AccountCddStatus } from "../../business/compliance/AccountCddStatus";
import { AddIcon, Breadcrumb, Button, Link, Panel, Typography } from "../../common";

export default function RiskRatings() {
  const { key } = useParams<{ key: string }>();
  const { account: accountDetails } = useAppSelector((as) => as.accounts);
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  useEffect(() => {
    dispatch(accountsCddStatusAsync({ accountKey: key, pageSize: 500 }));
  }, [dispatch, key]);

  return (
    <>
      <Panel>
        <Grid item xs={12}>
          <Typography variant="page-subheading">
            <span style={{ color: palette.common.black }}>Risk rating & CDD</span>
          </Typography>
        </Grid>
        <Grid item xs={12} paddingTop={1}>
          <Link
            to={generateLink(`${accountDetails?.name} (${accountDetails?.product})`, generatePath("/account/:key/risk-rating/new", { key }), history)}
            target="_blank"
          >
            <Button icon={<AddIcon />}>Create new</Button>
          </Link>
        </Grid>
      </Panel>
      <AccountCddStatus accountKey={key} expanded={true} />
    </>
  );
}
