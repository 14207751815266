import { Grid } from "@mui/material";
import { format } from "date-fns";
import { enNZ } from "date-fns/locale";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { EnumOfAccountPermissionAndString } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountHoldingsReport, accountPerformanceReport, accountPortfolioReport } from "../../store/accounts";
import {
  accountFinancialSummaryAsync,
  accountPieTaxByFundAsync,
  accountPieTaxSummaryAsync,
  exportAccountFinancialSummaryAsync,
  exportAccountPieTaxByFundAsync,
  exportAccountPieTaxSummaryAsync,
  generateAccountFinancialSummaryReportAsync,
} from "../../store/reports";
import { AccountReportDto } from "../../types";
import { formatDateJSON, parseDate } from "../../utils";
import { CheckboxList, DateField, QuarterPicker } from "../form";
import AccountFinancialSummary from "./AccountFinancialSummary";
import AccountPieTaxByFund from "./AccountPieTaxByFund";
import AccountPieTaxSummary from "./AccountPieTaxSummary";
import Report from "./Report";
import ReportButton from "./ReportButton";
import ReportDynamicFilter from "./ReportDynamicFilter";
import ReportDynamicFilters from "./ReportDynamicFilters";
import ReportField from "./ReportField";
import ReportList from "./ReportList";

interface ReportComponentProps {
  accountReportDto: AccountReportDto;
  loading: boolean;
}

export { Report, ReportButton, ReportDynamicFilter, ReportDynamicFilters, ReportField, ReportList };

export default function Reports(props: ReportComponentProps) {
  const { key } = useParams<{ key: string }>();
  const { account } = useAppSelector((as) => as.accounts);
  const dispatch = useAppDispatch();
  const maxDate = useMemo(() => format(parseDate(account?.balanceDate) || new Date(), "yyyy-MM-dd", { locale: enNZ }), [account?.balanceDate]);
  const defaultInitialValues = useMemo(() => ({ accountKey: key }), [key]);

  const hasPermission = useCallback(
    (requiredPermissions: EnumOfAccountPermissionAndString[]) => {
      for (const r of requiredPermissions) {
        if (props.accountReportDto?.permissions.indexOf(r) >= 0) return true;
      }

      return false;
    },
    [props.accountReportDto?.permissions]
  );

  if (!account) return null;

  return (
    <ReportList>
      {hasPermission([EnumOfAccountPermissionAndString.View, EnumOfAccountPermissionAndString.FinancialSummary]) && (
        <Report
          name="Financial Summary"
          description="View financial information for this account."
          initialValues={{ ...defaultInitialValues, from: undefined, to: undefined } as Parameters<typeof accountFinancialSummaryAsync>[0]}
          filters={
            <ReportField label="Choose dates" fieldFullWidth>
              <DateRangePicker
                radioOptions={[
                  { value: FinancialYearPickerOptions.Current, label: "Current financial year" },
                  { value: FinancialYearPickerOptions.Last, label: "Last financial year" },
                ]}
              />
            </ReportField>
          }
          view={(values) => dispatch(accountFinancialSummaryAsync(values))}
          export={[
            {
              label: "CSV - comma separated values",
              onClick: (values) => dispatch(exportAccountFinancialSummaryAsync(values)),
            },
            {
              label: "PDF summary",
              onClick: (values) => dispatch(generateAccountFinancialSummaryReportAsync(values)),
            },
          ]}
          validate={(values) => {
            if (!values.from || !values.to) return "Both from and to dates are required.";
            if (values.from >= values.to) return "From date must be before to date.";
          }}
        >
          <AccountFinancialSummary />
        </Report>
      )}
      {hasPermission([EnumOfAccountPermissionAndString.View]) && (
        <Report
          name="Holdings Report"
          description="Download a report showing balance information for this Account at a particular date."
          initialValues={{ ...defaultInitialValues, asAt: undefined }}
          filters={
            <ReportField label="Choose date">
              <DateField name="upTo" maxDate={maxDate} />
            </ReportField>
          }
          generate={(values) => dispatch(accountHoldingsReport(values))}
        />
      )}
      {hasPermission([EnumOfAccountPermissionAndString.View]) && (
        <Report
          name="Performance Report"
          description="Download a report showing performance information for this Account between two dates."
          initialValues={{ ...defaultInitialValues } as Parameters<typeof accountPerformanceReport>[0]}
          filters={
            <ReportField label="Choose dates" fieldFullWidth>
              <DateRangePicker
                radioOptions={[
                  { value: FinancialYearPickerOptions.Current, label: "Current financial year" },
                  { value: FinancialYearPickerOptions.Last, label: "Last financial year" },
                ]}
              />
            </ReportField>
          }
          generate={(values) => dispatch(accountPerformanceReport(values))}
          validate={(values) => {
            if (!values.from || !values.to) return "Both from and to dates are required.";
            if (values.from >= values.to) return "From date must be before to date.";
          }}
        />
      )}

      {hasPermission([EnumOfAccountPermissionAndString.View, EnumOfAccountPermissionAndString.TaxSummary]) && (
        <Report
          name="PIE Tax Report by Fund"
          description="View quarterly PIE tax information for this account."
          initialValues={{ ...defaultInitialValues, quarterFrom: undefined, quarterTo: undefined } as Parameters<typeof accountPieTaxByFundAsync>[0]}
          filters={
            props.accountReportDto.service === "KS" ? (
              <ReportField>
                <span style={{ color: "red" }}>Not available for KiwiSaver</span>
              </ReportField>
            ) : (
              <>
                <QuarterRangePicker
                  radioOptions={[
                    { value: FinancialYearPickerOptions.Current, label: "Current financial year" },
                    { value: FinancialYearPickerOptions.Last, label: "Last financial year" },
                  ]}
                ></QuarterRangePicker>
              </>
            )
          }
          view={props.accountReportDto.service === "KS" ? undefined : (values) => dispatch(accountPieTaxByFundAsync(values))}
          export={props.accountReportDto.service === "KS" ? undefined : (values) => dispatch(exportAccountPieTaxByFundAsync(values))}
          validate={(values) =>
            !values.quarterFrom
              ? "From quarter is required."
              : !values.quarterTo
                ? "To quarter is required."
                : values.quarterFrom > values.quarterTo
                  ? "To quarter can't be before From quarter"
                  : undefined
          }
        >
          <AccountPieTaxByFund />
        </Report>
      )}
      {hasPermission([EnumOfAccountPermissionAndString.View, EnumOfAccountPermissionAndString.TaxSummary]) && (
        <Report
          name="PIE Tax Summary"
          description="View PIE tax information for this account."
          initialValues={{ ...defaultInitialValues, quarterFrom: undefined, quarterTo: undefined } as Parameters<typeof accountPieTaxSummaryAsync>[0]}
          filters={
            props.accountReportDto.service === "KS" ? (
              <ReportField>
                <span style={{ color: "red" }}>Not available for KiwiSaver</span>
              </ReportField>
            ) : (
              <>
                <QuarterRangePicker
                  radioOptions={[
                    { value: FinancialYearPickerOptions.Current, label: "Current financial year" },
                    { value: FinancialYearPickerOptions.Last, label: "Last financial year" },
                  ]}
                ></QuarterRangePicker>
              </>
            )
          }
          view={props.accountReportDto.service === "KS" ? undefined : (values) => dispatch(accountPieTaxSummaryAsync(values))}
          export={props.accountReportDto.service === "KS" ? undefined : (values) => dispatch(exportAccountPieTaxSummaryAsync(values))}
          validate={(values) =>
            !values.quarterFrom
              ? "From quarter is required."
              : !values.quarterTo
                ? "To quarter is required."
                : values.quarterFrom > values.quarterTo
                  ? "To quarter can't be before From quarter"
                  : undefined
          }
        >
          <AccountPieTaxSummary />
        </Report>
      )}
      {hasPermission([EnumOfAccountPermissionAndString.View]) && (
        <Report
          name="Portfolio Report"
          description={
            <>
              Download a Portfolio Report for all linked accounts, between two dates.
              <br />
              Please note this may take a few minutes to generate.
            </>
          }
          initialValues={{ ...defaultInitialValues } as Parameters<typeof accountPortfolioReport>[0]}
          filters={
            <ReportField label="Choose dates" fieldFullWidth>
              <DateRangePicker
                radioOptions={[
                  { value: FinancialYearPickerOptions.Current, label: "Current financial year" },
                  { value: FinancialYearPickerOptions.Last, label: "Last financial year" },
                ]}
              />
            </ReportField>
          }
          validate={(values) => {
            if (!values.from || !values.to) {
              return "Both from and to dates are required.";
            }

            if (values.from >= values.to) {
              return "From date must be before to date.";
            }
          }}
          generate={(values) => dispatch(accountPortfolioReport(values))}
        />
      )}
    </ReportList>
  );
}

interface CheckboxListOption<Value> {
  value: Value;
  label: string;
}

interface FinancialYearPickerProps {
  radioOptions: CheckboxListOption<FinancialYearPickerOptions>[];
}

enum FinancialYearPickerOptions {
  Current,
  Last,
}

interface DateRangePickerForm {
  yearType: FinancialYearPickerOptions;
  from: Date;
  to: Date;
}

function DateRangePicker({ radioOptions }: FinancialYearPickerProps) {
  const { setFieldValue, values } = useFormikContext<DateRangePickerForm>();
  const [currentlySelectedYearType, setCurrentlySelectedYearType] = useState<FinancialYearPickerOptions | undefined>(undefined);
  const yearType = useMemo(() => values.yearType, [values.yearType]);
  const maxDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (yearType != undefined && currentlySelectedYearType != yearType) {
      setFieldValue("from", formatDateJSON(getDate(yearType, true)));
      setFieldValue("to", formatDateJSON(getDate(yearType, false)));
      setCurrentlySelectedYearType(yearType);
    }
  }, [currentlySelectedYearType, setFieldValue, yearType]);

  function getDate(selectedYearType: FinancialYearPickerOptions, from: boolean): Date {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const financialYearStart = new Date(currentYear, 3, 1);

    if (currentDate < financialYearStart) {
      financialYearStart.setFullYear(currentYear - 1);
    }

    const financialYearEnd = new Date(financialYearStart);
    financialYearEnd.setFullYear(financialYearEnd.getFullYear() + 1);
    financialYearEnd.setDate(financialYearEnd.getDate() - 1);

    if (selectedYearType === FinancialYearPickerOptions.Last) {
      financialYearStart.setFullYear(financialYearStart.getFullYear() - 1);
      financialYearEnd.setFullYear(financialYearEnd.getFullYear() - 1);
    }

    return from ? financialYearStart : financialYearEnd;
  }

  return (
    <Grid container gap={2} sx={{ alignItems: "center" }}>
      <Grid item xs={12}>
        <CheckboxList name="yearType" options={radioOptions} radio />
      </Grid>
      <Grid item xs="auto" sx={{ pr: 3 }}>
        <DateField label="From" maxDate={maxDate} name="from" shrinkLabel />
      </Grid>
      <Grid item xs="auto">
        <DateField label="To" maxDate={maxDate} name="to" shrinkLabel />
      </Grid>
    </Grid>
  );
}

interface QuarterRangePickerForm {
  yearType: FinancialYearPickerOptions;
  quarterFrom: Date;
  quarterTo: Date;
}

function QuarterRangePicker(props: FinancialYearPickerProps) {
  const { setFieldValue, values } = useFormikContext<QuarterRangePickerForm>();
  const [currentlySelectedYearType, setCurrentlySelectedYearType] = useState<FinancialYearPickerOptions | undefined>(undefined);
  const yearType = useMemo(() => values.yearType, [values.yearType]);

  useEffect(() => {
    if (yearType != undefined && currentlySelectedYearType != yearType) {
      setFieldValue("quarterFrom", getQuarter(yearType, true));
      setFieldValue("quarterTo", getQuarter(yearType, false));
      setCurrentlySelectedYearType(yearType);
    }
  }, [currentlySelectedYearType, setFieldValue, yearType]);

  function getQuarter(selectedYearType: FinancialYearPickerOptions, from: boolean): string {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const financialYearStart = new Date(currentYear, 3, 1);

    if (currentDate < financialYearStart) {
      financialYearStart.setFullYear(currentYear - 1);
    }

    if (selectedYearType === FinancialYearPickerOptions.Last) {
      financialYearStart.setFullYear(financialYearStart.getFullYear() - 1);
    }

    return from ? financialYearStart.getFullYear() + "-Q1" : financialYearStart.getFullYear() + "-Q4";
  }

  return (
    <>
      <ReportField label="Choose dates" fieldFullWidth>
        <CheckboxList name="yearType" radio options={props.radioOptions} />
      </ReportField>
      <ReportField label="From quarter" fieldFullWidth>
        <QuarterPicker name="quarterFrom" />
      </ReportField>
      <ReportField label="To quarter" fieldFullWidth>
        <QuarterPicker name="quarterTo" />
      </ReportField>
    </>
  );
}
