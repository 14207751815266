import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { AccountantAccountDto, SearchAccountantAccountSortBy } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { clearAccounts, searchAsync } from "../../store/accountantAccounts";
import Theme from "../../theme";
import { AccountReportDto } from "../../types";
import Documents from "../account/Documents";
import RecurringTransactions from "../account/RecurringTransactions";
import Transactions from "../account/Transactions";
import { PageHeading, Tab, Tabs, TwoDetails } from "../common";
import Reports from "../reports";
import Detail from "./Detail";

interface AccountComponentProps {
  account?: AccountantAccountDto;
  loading: boolean;
}

export default function Account() {
  const { key } = useParams<{ key: string }>();
  const { accounts, searchRequestId } = useAppSelector((as) => as.accountantAccounts);
  const account = useMemo(() => accounts?.find(({ accountKey }) => accountKey === key), [key, accounts]);
  const loading = useMemo(() => !!searchRequestId, [searchRequestId]);
  const dispatch = useAppDispatch();
  const { replace } = useHistory();
  const accountReportDetails = useMemo<AccountReportDto>(
    () => ({
      accountNumber: account?.accountKey ?? "",
      permissions: account?.permissions?.map((accountPermission) => accountPermission.permission) ?? [],
      service: account?.product ?? "",
    }),
    [account]
  );

  useEffect(() => {
    (async () => {
      // If the user browses directly to the account's URL, we need to fetch it
      if (!account) {
        const result = await dispatch(searchAsync({ currentPage: 0, pageSize: 1, sortOrder: SearchAccountantAccountSortBy.AccountKey, term: key })).unwrap();

        // If no account returned, return back to search dashboard
        if (!result.accounts?.length) {
          dispatch(clearAccounts());
          replace("/accountant/search");
        }
      }
    })();
  }, [account, dispatch, key, replace]);

  return (
    <Theme variant="account">
      <PageHeading
        breadcrumb={{
          default: [{ label: "Account Information", pathname: "/accountant/accounts", theme: "account" }],
          current: { label: account?.name ? `${account?.name} (${account.productDisplayName})` : undefined, theme: "account" },
        }}
        heading={account?.name}
        subheading={<TwoDetails detail1={account?.productDisplayName} detail2={key} />}
        tabs={
          <Tabs preserveState>
            <Tab>Account details</Tab>
            <Tab
              subTabs={[
                { path: "Transactions", children: "Transactions" },
                { path: "RecurringTransactions", children: "Recurring transactions" },
              ]}
            >
              Transactions
            </Tab>
            <Tab path="reports">Reports</Tab>
            <Tab path="documents">Documents</Tab>
          </Tabs>
        }
      />
      <Grid item xs={12}>
        <Switch>
          <Route exact path="/accountant/account/:key/documents">
            <Documents />
          </Route>
          <Route exact path="/accountant/account/:key/transactions">
            <Transactions />
          </Route>
          <Route exact path="/accountant/account/:key/recurringtransactions">
            <RecurringTransactions />
          </Route>
          <Route exact path="/accountant/account/:key/reports">
            <Reports accountReportDto={accountReportDetails} loading={loading} />
          </Route>
          <Route>
            <Detail account={account} loading={loading} />
          </Route>
        </Switch>
      </Grid>
    </Theme>
  );
}

export type { AccountComponentProps };
export { TwoDetails };
