import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { useField } from "formik";
import { KeyboardEvent, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { GUTTER_SIZE } from "../../constants";
import { FormButton, TextField } from "../form";
import { Button, Typography } from "./";

interface SearchPageHeadingProps<FormValues> {
  alternate?: boolean;
  /** Typically for additional search filters. They will be wrapped in a `<Grid container />`. */
  children?: ReactNode;
  exportAll?: () => void;
  exportFiltered?: (values: FormValues) => void;
  exporting?: boolean;
  loading?: boolean;
  onReset?: () => void;
  resetLabel?: string;
  searchTermLabel?: string;
  submitForm: () => void;
}

function SearchPageFilters<FormValues>({
  children,
  exportAll,
  exportFiltered,
  exporting,
  loading,
  onReset,
  resetLabel,
  searchTermLabel,
  submitForm,
}: SearchPageHeadingProps<FormValues>) {
  const [{ value: term }, , { setValue: setTerm }] = useField<string>("term");
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const _resetLabel = useMemo(() => resetLabel || "Reset", [resetLabel]);
  const _searchTermLabel = useMemo(() => searchTermLabel || "Search", [searchTermLabel]);
  const showTermClearIcon = useMemo(() => !!term && (focus || hover), [term, focus, hover]);

  const clearTerm = useCallback(async () => {
    if (inputRef.current) inputRef.current.value = "";

    await setTerm("");
  }, [setTerm]);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const handleReset = useCallback(() => {
    if (inputRef.current) inputRef.current.value = "";
    onReset?.();
  }, [onReset]);

  const search = useCallback(() => {
    submitForm();
    setFocus(false);
  }, [submitForm]);

  const handleTextFieldEnterKey = useCallback(
    (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event.key === "Enter") search();
    },
    [search]
  );

  return (
    <>
      {(!!exportAll || !!exportFiltered) && (
        <Grid
          container
          item
          xs={12}
          sx={{
            alignItems: "center",
            backgroundColor: "common.brandHigh",
            borderBottomColor: "divider",
            borderBottomStyle: "solid",
            borderBottomWidth: 1,
            pb: 4,
            pt: 1.5,
            px: GUTTER_SIZE,
          }}
        >
          <Grid item container xs={4} gap={2}>
            {!!exportAll && (
              <FormButton disabled={loading} loading={exporting} onClick={exportAll}>
                Export all
              </FormButton>
            )}
            {!!exportFiltered && (
              <FormButton disabled={loading} loading={exporting} onClick={exportFiltered} variant="outlined">
                Export filtered
              </FormButton>
            )}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ px: GUTTER_SIZE, py: 3 }}>
        <Grid container sx={{ alignItems: "center", pb: 1 }}>
          <Grid item xs>
            {!!children && <Typography variant="section-heading">Filters</Typography>}
          </Grid>
          <Grid item xs="auto">
            <FormButton onClick={handleReset} sx={{ minWidth: 48, px: 0 }} variant="outlined">
              {_resetLabel}
            </FormButton>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ alignItems: "center" }}>
          {children}
          <Grid item xs>
            <TextField
              inputProps={{ onKeyUp: handleTextFieldEnterKey }}
              inputRef={inputRef}
              label={_searchTermLabel}
              name="term"
              onBlurCapture={() => setFocus(false)}
              onFocusCapture={handleFocus}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              uncontrolled
              shrinkLabel
              InputProps={{
                ...(showTermClearIcon && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={clearTerm}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }),
              }}
            />
          </Grid>
          <Grid item xs="auto">
            <Button onClick={submitForm}>
              Search
              <SearchIcon sx={{ ml: 1, mr: -0.5 }} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export { SearchPageFilters };
