import { Box, Grid } from "@mui/material";
import { GetAccountsCddStatusSort } from "../../../api";
import { GUTTER_SIZE } from "../../../constants";
import { useAppDispatch, useAppSelector } from "../../../store";
import { accountsCddStatusAsync } from "../../../store/compliance";
import { getFullName } from "../../../utils";
import { CheckIcon, ClearIcon } from "../../common";
import { Form } from "../../form";
import Table from "../../table";

type FormValues = Parameters<typeof accountsCddStatusAsync>[0];

function AmlIcon({ cddDate }: { cddDate?: string }) {
  return cddDate ? <CheckIcon color="success" /> : <ClearIcon color="error" />;
}

export function AccountCddStatus(values: FormValues & { expanded?: boolean }) {
  const dispatch = useAppDispatch();
  const { isLoading, accountsCddStatus } = useAppSelector((as) => as.compliance);

  return !isLoading && !accountsCddStatus ? null : (
    <Grid item xs={12} sx={{ backgroundColor: "common.white" }}>
      <Form initialValues={values} onSubmit={(values) => dispatch(accountsCddStatusAsync(values))} submitOnChange>
        <Table
          loading={isLoading}
          loadingBlankRows={15}
          minHeight={300}
          paging={accountsCddStatus || {}}
          rowExpanded={values.expanded ? "always" : undefined}
          rows={accountsCddStatus?.results}
          columns={[
            {
              heading: "AML'd",
              field: (d) =>
                d.clients?.some((c) => !c.cdddate) || d.legalEntities?.some((c) => !c.cdddate) ? <ClearIcon color="error" /> : <CheckIcon color="success" />,
              width: 6,
            },
            {
              heading: "Account/Client name",
              field: "name",
              sort: GetAccountsCddStatusSort.AccountName,
              width: 22,
            },
            {
              heading: "Account/Client number",
              sort: GetAccountsCddStatusSort.AccountKey,
              field: "accountKey",
              width: 15,
            },
            {
              heading: "CDD date",
              field: () => "",
              align: "right",
              type: "date",
              width: 15,
            },
            {
              heading: "Client type",
              field: () => "",
              width: 9,
            },
            {
              heading: "System rating",
              field: "systemRiskRating",
              width: 9,
            },
            {
              heading: "Adviser rating",
              field: "adviserRiskRating",
              width: 9,
            },
            {
              heading: "Override Adviser rating",
              field: "overrideAdviserRiskRating",
              width: 15,
            },
          ]}
          rowExpand={(data, backgroundColor) => (
            <>
              <Table
                child
                rowBackgroundColor={backgroundColor}
                rows={data.clients}
                columns={[
                  {
                    heading: "AML'd",
                    field: (d) => <AmlIcon cddDate={d.cdddate} />,
                    width: 6,
                  },
                  {
                    heading: "Client name",
                    field: (row) => getFullName(row),
                    width: 22,
                  },
                  {
                    heading: "Client number",
                    field: "clientKey",
                    width: 15,
                  },
                  {
                    heading: "CDD date",
                    field: "cdddate",
                    align: "right",
                    type: "date",
                    width: 15,
                  },
                  {
                    heading: "Client type",
                    field: "clientType",
                    width: 9,
                  },
                  {
                    field: () => "",
                    width: 9,
                  },
                  {
                    field: () => "",
                    width: 9,
                  },
                  {
                    field: () => "",
                    width: 15,
                  },
                ]}
              />
              <Table
                child
                rows={data.legalEntities}
                columns={[
                  {
                    heading: "AML'd",
                    field: (d) => <AmlIcon cddDate={d.cdddate} />,
                    width: 6,
                  },
                  {
                    heading: "Name",
                    field: "name",
                    width: 22,
                  },
                  {
                    heading: "Legal entity number",
                    field: "legalEntityKey",
                    width: 15,
                  },
                  {
                    heading: "CDD date",
                    field: "cdddate",
                    align: "right",
                    type: "date",
                    width: 15,
                  },
                  {
                    heading: "Legal entity type",
                    field: "legalEntityType",
                    width: 9,
                  },
                  {
                    field: () => "",
                    width: 9,
                  },
                  {
                    field: () => "",
                    width: 9,
                  },
                  {
                    field: () => "",
                    width: 15,
                  },
                ]}
              />
            </>
          )}
        />
      </Form>
      <Box sx={{ fontSize: 13, px: GUTTER_SIZE, py: 1.5 }}>Risk Rating&apos;s created today will appear in the above list on the next business day.</Box>
    </Grid>
  );
}
