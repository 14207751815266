import { Grid } from "@mui/material";
import { GUTTER_SIZE } from "../../constants";
import { Button, FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon, Typography } from "../common";
import ContactInfoPanel from "./ContactInfoPanel";

export default function ContactUs() {
  return (
    <>
      <Grid container sx={{ pt: 5, px: GUTTER_SIZE, backgroundColor: "common.brandHigh" }}>
        <Grid item xs={6}>
          <Typography variant="page-heading">Contact Us</Typography>
        </Grid>
        <Grid item container xs={6} gap={1} justifyContent="end">
          <Button onClick={() => window.open("https://twitter.com/nzfunds", "_blank")} variant="text">
            <TwitterIcon />
          </Button>
          <Button onClick={() => window.open("https://www.facebook.com/NZFunds/", "_blank")} variant="text">
            <FacebookIcon />
          </Button>
          <Button onClick={() => window.open("https://www.instagram.com/nzfunds/", "_blank")} variant="text">
            <InstagramIcon />
          </Button>
          <Button onClick={() => window.open("https://www.linkedin.com/company/nz-funds-management", "_blank")} variant="text">
            <LinkedInIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ backgroundColor: "common.brandHigh", pt: 5, px: GUTTER_SIZE, pb: 1.5, height: "100%" }}>
        <ContactInfoPanel
          sectionTitle="General Client Services enquiries"
          primaryEmail="clientservices@nzfunds.co.nz"
          primaryFootNote="Please do not send transaction or registry documents to this address"
          secondaryPhone="09 377 2277"
          secondaryPhoneAlt="0508 733 337"
        />
        <ContactInfoPanel sectionTitle="General Adviser Support enquiries" primaryEmail="advice.support@nzfunds.co.nz" />
        <ContactInfoPanel
          sectionTitle="Onboarding"
          primaryLabel="KiwiSaver, Wealth Builder, Income Generator*"
          primaryEmail="onboarding@nzfunds.co.nz"
          secondaryLabel="ASP, MSS*"
          secondaryEmail="registry@nzfunds.co.nz"
        />
        <ContactInfoPanel
          sectionTitle="Change in details"
          primaryLabel="KiwiSaver*"
          primaryEmail="nzfkiwi@linkmarketservices.com"
          secondaryLabel="Wealth Builder, Income Generator, ASP, MSS*"
          secondaryEmail="registry@nzfunds.co.nz"
        />
        <ContactInfoPanel
          sectionTitle="Withdrawals"
          primaryLabel="KiwiSaver*"
          primaryEmail="nzfkiwi@linkmarketservices.com"
          secondaryLabel="ASP, MSS*"
          secondaryEmail="withdrawals@nzfunds.co.nz"
        />
        <ContactInfoPanel
          sectionTitle="Transfers"
          primaryLabel="UK Pension, QROPS"
          primaryEmail="qrops@nzfunds.co.nz"
          secondaryLabel="Aussie Super"
          secondaryEmail="aussiesuper@nzfunds.co.nz"
        />
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ backgroundColor: "common.brandHigh", height: "100%", pb: 5, pt: 0, px: GUTTER_SIZE, verticalAlign: "Bottom" }}>
        <Typography variant="footnote">
          <b>*Please do not CC these emails to any other NZ Funds&apos; support addresses or individuals.</b>
          <br />
          When sending client documents via email, please observe the following:
          <br />
          1. One client per email
          <br />
          2. Please write the NZ Funds Client Number in the subject line of the email - please do not include any other description in the subject line. If the
          client is new, please write New Client - <b>[Client Name]</b>
          <br />
          3. Scanned documents attached to the email are to be broken up into the individual document types.
          <br />
          4. The email and attached documentation will also be sent through to Client Services via our scanning department, so you can include information about
          the transaction within the body of the email.
        </Typography>
      </Grid>
    </>
  );
}
