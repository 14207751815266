import { Grid } from "@mui/material";
import { useCallback, useEffect } from "react";
import { FeeHeaderTypeDto } from "../../../api";
import { Report, ReportDynamicFilter, ReportDynamicFilters, ReportField, ReportList } from "../../../components/reports";
import { useAppDispatch, useAppSelector } from "../../../store";
import { associatedAdvisersAsync } from "../../../store/advisers";
import {
  adviserFeesAsync,
  adviserFeesReportAsync,
  exportAdviserFeesAsync,
  exportFeeStructuresAsync,
  feeHeaderTypesAsync,
  feeStructuresAsync,
} from "../../../store/reports";
import { filterAdvisers, filterBranches, filterFeeHeaderTypes, formatValue } from "../../../utils";
import { Autocomplete, AutocompleteMultiple } from "../../form";
import { AdviserFees } from "./AdviserFees";
import { FeeStructures } from "./FeeStructures";

function Fees() {
  const { feeHeaderTypes } = useAppSelector((as) => as.reports);
  const { advisers, adviserBranches, adviserGroups } = useAppSelector((as) => as.advisers);
  const dispatch = useAppDispatch();

  const getSelectedFeeHeaderTypes = useCallback(
    (values: { adviserGroupKey?: string; adviserBranchKey?: string; adviserNumber?: string; feeHeaderTypes?: string[] }) => {
      return filterFeeHeaderTypes(
        values.feeHeaderTypes
          ?.map((feeHeaderTypeCode) => feeHeaderTypes?.find((feeHeaderType) => feeHeaderType.code === feeHeaderTypeCode))
          .filter((feeHeaderType) => !!feeHeaderType) as FeeHeaderTypeDto[] | undefined,
        { ...values, advisers }
      )?.map((feeHeaderType) => feeHeaderType.code ?? "");
    },
    [advisers, feeHeaderTypes]
  );

  const getValues = useCallback(
    (values: { paid?: boolean; adviserNumber?: string; feeHeaderTypes?: string[]; paymentDates?: string[] }) => {
      const batches = getBatches(feeHeaderTypes, getSelectedFeeHeaderTypes(values));

      return {
        ...values,
        adviserFeesBatchIds: batches
          ?.filter((batch) => !!batch.adviserFeesBatchId && !!values.paymentDates?.includes(batch.groupId))
          .map((batch) => batch.adviserFeesBatchId!),
      };
    },
    [feeHeaderTypes, getSelectedFeeHeaderTypes]
  );

  useEffect(() => {
    !feeHeaderTypes && dispatch(feeHeaderTypesAsync());
  }, [dispatch, feeHeaderTypes]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  const feesFilters = (
    <>
      <ReportField label="Choose adviser" dualColumn>
        <Autocomplete
          name="adviserNumber"
          allOption="All"
          autoSelectIfOneOption
          options={(values: { adviserGroupKey?: string; adviserBranchKey?: string }) =>
            filterAdvisers(advisers, values)?.map((adviser) => ({ value: adviser.number, label: `${adviser.name} (${adviser.number})` })) || []
          }
        />
      </ReportField>
      <ReportField label="Choose fee type" dualColumn>
        <AutocompleteMultiple
          autoSelectIfOneOption
          name="feeHeaderTypes"
          options={(values: { adviserGroupKey?: string; adviserBranchKey?: string; adviserNumber?: string }) =>
            filterFeeHeaderTypes(feeHeaderTypes, { ...values, advisers })
              ?.slice()
              .sort((a, b) => (a.description ?? "")?.localeCompare(b.description ?? ""))
              .map((feeHeaderType) => ({
                value: feeHeaderType.code ?? "",
                label: feeHeaderType.description ?? "",
              })) || []
          }
        />
      </ReportField>
      <ReportField label="Choose batch date" dualColumn>
        <Grid item xs={12} md={12}>
          <AutocompleteMultiple
            autoSelectIfOneOption
            name="paymentDates"
            options={(values: { adviserNumber?: string; feeHeaderTypes?: string[] }) =>
              groupPaymentDates(feeHeaderTypes, getSelectedFeeHeaderTypes(values)).map((batchGroup) => ({
                value: batchGroup.batchGroupId,
                label: `${formatValue(batchGroup.runDate, { type: "date", typeOption: { outputStyle: "shortYear" } })} (from ${formatValue(
                  batchGroup.fromDate,
                  {
                    type: "date",
                    typeOption: { outputStyle: "shortYear" },
                  }
                )} to ${formatValue(batchGroup.toDate, {
                  type: "date",
                  typeOption: { outputStyle: "shortYear" },
                })}) ${batchGroup.feeHeaderTypeCodes
                  .map((feeHeaderTypeCode) => {
                    switch (feeHeaderTypeCode) {
                      case "ADMN":
                        return "Admin";
                      case "MFEE":
                        return "GIS";
                      case "ONP2":
                        return "Part 2";
                    }

                    return feeHeaderTypeCode;
                  })
                  .join(", ")}`,
              }))
            }
          />
        </Grid>
      </ReportField>
    </>
  );

  const adviserCompanyBranchFilters = (
    <ReportDynamicFilters>
      <ReportDynamicFilter label="Adviser" field="adviserNumbers">
        <AutocompleteMultiple
          name="adviserNumbers"
          autoSelectIfOneOption
          options={advisers?.map((adviser) => ({ value: adviser.number, label: `${adviser.name} (${adviser.number})` })) || []}
        />
      </ReportDynamicFilter>
      {(adviserGroups?.length ?? 0) > 1 && (
        <ReportDynamicFilter label="Company" field="adviserGroupKey">
          <Autocomplete
            autoSelectIfOneOption
            name="adviserGroupKey"
            allOption="All"
            options={adviserGroups?.map((adviserGroup) => ({ value: adviserGroup.key, label: adviserGroup.name })) || []}
            onChangeClear={["adviserNumber", "adviserBranchKey"]}
          />
        </ReportDynamicFilter>
      )}
      {(adviserBranches?.length ?? 0) > 1 && (
        <ReportDynamicFilter label="Branch" field="adviserBranchKey">
          <Autocomplete
            autoSelectIfOneOption
            name="adviserBranchKey"
            allOption="All"
            options={(values: { adviserGroupKey?: string }) =>
              filterBranches(adviserBranches, values)?.map((adviserBranch) => ({ value: adviserBranch.key, label: adviserBranch.name })) || []
            }
            onChangeClear={["adviserNumber"]}
          />
        </ReportDynamicFilter>
      )}
    </ReportDynamicFilters>
  );

  const companyBranchFilters = (
    <ReportDynamicFilters>
      {(adviserGroups?.length ?? 0) > 1 && (
        <ReportDynamicFilter label="Company" field="adviserGroupKey">
          <Autocomplete
            autoSelectIfOneOption
            name="adviserGroupKey"
            allOption="All"
            options={adviserGroups?.map((adviserGroup) => ({ value: adviserGroup.key, label: adviserGroup.name })) || []}
            onChangeClear={["adviserNumber", "adviserBranchKey"]}
          />
        </ReportDynamicFilter>
      )}
      {(adviserBranches?.length ?? 0) > 1 && (
        <ReportDynamicFilter label="Branch" field="adviserBranchKey">
          <Autocomplete
            autoSelectIfOneOption
            name="adviserBranchKey"
            allOption="All"
            options={(values: { adviserGroupKey?: string }) =>
              filterBranches(adviserBranches, values)?.map((adviserBranch) => ({ value: adviserBranch.key, label: adviserBranch.name })) || []
            }
            onChangeClear={["adviserNumber"]}
          />
        </ReportDynamicFilter>
      )}
    </ReportDynamicFilters>
  );

  return (
    <ReportList>
      <Report
        name="Fees paid"
        description="View fees paid for a period. This will match the bank payments."
        initialValues={
          {
            adviserNumber: undefined,
            feeHeaderTypes: undefined,
            paid: true,
            paymentDates: undefined,
          } as Parameters<typeof adviserFeesAsync>[0]
        }
        filters={feesFilters}
        advancedFilters={companyBranchFilters}
        view={(values) => dispatch(adviserFeesAsync(getValues(values)))}
        export={[
          {
            label: "CSV - comma separated values",
            onClick: (values) => dispatch(exportAdviserFeesAsync(getValues(values))),
          },
          {
            label: "PDF summary",
            onClick: (values) => dispatch(adviserFeesReportAsync({ ...getValues(values), detailed: false })),
          },
          {
            action: "pdf-detailed",
            label: "PDF detailed (GIS only)",
            onClick: (values) => dispatch(adviserFeesReportAsync({ ...getValues({ ...values, feeHeaderTypes: ["MFEE"] }), detailed: true })),
            visible: (values) =>
              getValues({ ...values, feeHeaderTypes: ["MFEE"] })?.adviserFeesBatchIds?.some((batchId) =>
                feeHeaderTypes?.some(
                  (feeHeaderType) => feeHeaderType.code === "MFEE" && feeHeaderType.adviserFeesBatches?.some((batch) => batch.adviserFeesBatchId === batchId)
                )
              ),
          },
        ]}
        validate={(values, { action }) => {
          const valuesProcessed = getValues(values);
          if (!valuesProcessed.feeHeaderTypes || valuesProcessed.feeHeaderTypes.length === 0) return "Fee type is required.";
          if (!valuesProcessed.adviserFeesBatchIds || valuesProcessed.adviserFeesBatchIds.length === 0) return "Batch date is required.";
          if (action === "pdf-detailed" && advisers && advisers.length > 10 && !values.adviserNumber) return "Adviser is required for detailed export.";
        }}
      >
        <AdviserFees />
      </Report>
      <Report
        name="Fees not processed"
        description="View fee details for all accounts which did not process."
        initialValues={
          {
            paid: false,
            adviserNumber: undefined,
            feeHeaderTypes: undefined,
            paymentDates: undefined,
          } as Parameters<typeof adviserFeesAsync>[0]
        }
        filters={feesFilters}
        advancedFilters={companyBranchFilters}
        view={(values) => dispatch(adviserFeesAsync(getValues(values)))}
        export={(values) => dispatch(exportAdviserFeesAsync(getValues(values)))}
        validate={(values) => {
          const valuesProcessed = getValues(values);
          if (!valuesProcessed.feeHeaderTypes || valuesProcessed.feeHeaderTypes.length === 0) return "Fee type is required.";
          if (!valuesProcessed.adviserFeesBatchIds || valuesProcessed.adviserFeesBatchIds.length === 0) return "Batch date is required.";
        }}
      >
        <AdviserFees />
      </Report>
      <Report
        name="Fee structure per account"
        description="View fee structure details. Note that fee data is not available for some historical agreements. Please refer to actual agreement for details."
        initialValues={
          {
            adviserNumbers: undefined,
            adviserGroupKey: undefined,
            adviserBranchKey: undefined,
          } as Parameters<typeof feeStructuresAsync>[0]
        }
        advancedFilters={adviserCompanyBranchFilters}
        view={(values) => dispatch(feeStructuresAsync(values))}
        export={(values) => dispatch(exportFeeStructuresAsync(values))}
      >
        <FeeStructures />
      </Report>
    </ReportList>
  );
}

function getBatches(feeHeaderTypes: FeeHeaderTypeDto[] | undefined, feeTypes: string[] | undefined) {
  return feeHeaderTypes
    ?.filter((feeHeaderType) => feeHeaderType.code && feeTypes?.includes(feeHeaderType.code))
    ?.flatMap(
      (feeHeader) =>
        feeHeader.adviserFeesBatches?.map((batch) => ({
          groupId: `${formatValue(batch.runDate, { type: "date" })}|${batch.fromDate}|${batch.toDate}`,
          feeHeaderTypeCode: feeHeader.code,
          ...batch,
        })) || []
    );
}

function groupPaymentDates(feeHeaderTypes?: FeeHeaderTypeDto[], feeTypes?: string[]) {
  const batches = getBatches(feeHeaderTypes, feeTypes);
  const batchGroups = Array.from(new Set(batches?.map((batch) => batch.groupId)));

  return batchGroups.map((batchGroupId) => {
    const [runDate, fromDate, toDate] = batchGroupId.split("|");
    const batchesInThisGroup = batches?.filter((batches) => batches.groupId === batchGroupId);

    return {
      batchGroupId,
      runDate,
      fromDate,
      toDate,
      adviserFeesBatchIds: batchesInThisGroup?.map((batch) => batch.adviserFeesBatchId),
      feeHeaderTypeCodes: Array.from(new Set(batchesInThisGroup?.map((batch) => batch.feeHeaderTypeCode))).sort(),
    };
  });
}

export { Fees };
