import { ContentCopy } from "@mui/icons-material";
import { Box, Grid } from "@mui/material";
import { MouseEvent, ReactNode, useCallback } from "react";
import { AccountPieTaxSummaryItem } from "../../api";
import { useAppSelector } from "../../store";
import { IconButton, Panel, Typography } from "../common";
import Table from "../table";
import { ColumnDefinition } from "../table/ColumnDefinition";

function copyValue(value: number) {
  navigator.clipboard.writeText((Math.round(value * 100) / 100).toFixed(2));
}

const COLUMNS: ColumnDefinition<AccountPieTaxSummaryItem, string>[] = [
  {
    display: "emphasize",
    field: (row) => <FormatWithFootnote fieldValue={row.details} footnoteField={row.footnotes} />,
    heading: "Detail",
    width: 26,
  },
  {
    field: "value",
    heading: "Value",
    type: "money",
    width: 9,
  },
  {
    field: "taxReturn",
    heading: "Tax return",
    width: 9,
  },
  {
    align: "center",
    field: (row) => <CopyButton value={row.value} onClick={copyValue} />,
    width: 9,
  },
];

export default function AccountPieTaxSummary() {
  const { isLoadingAccountPieTaxSummary, accountPieTaxSummary, accountPieTaxSummaryFootnotes } = useAppSelector((as) => as.reports);

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!isLoadingAccountPieTaxSummary && !accountPieTaxSummary ? null : (
        <Table columns={COLUMNS} loading={isLoadingAccountPieTaxSummary} loadingBlankRows={3} pageSizeHidden rows={accountPieTaxSummary?.items} />
      )}
      <Panel noBorderTop>
        <Grid container>
          <Grid item xs={12} sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 0.75 }}>
            <Typography variant="section-heading">Additional Information</Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1.5 }}>
            <Typography variant="footnote">
              <Grid container spacing={1}>
                <FootNote>
                  <Typography variant="superscript">1</Typography>
                  PIE tax information for periods other than full financial years ended 31 March is indicative only and subject to change.
                </FootNote>
                <FootNote>
                  <Typography variant="superscript">2</Typography>
                  PIE income is treated as being received in the investor&apos;s income year that includes the end of the PIE&apos;s income year (all NZ
                  Funds&apos; PIEs use a 31 March balance date). This means that, for investors with non-standard balance dates, the year in which the income is
                  received may be different from the year in which PIE income is attributed. Refer to the Inland Revenue&apos;s Portfolio Investment Entity
                  Guide (IR860) for more information.
                </FootNote>
                <FootNote>
                  <Typography variant="superscript">3</Typography>The Tax Report includes all transactions and amounts relating to a tax year (1 April to 31
                  March). This includes payments/refunds for the March quarter which are paid/received in April. The Financial Summary lists transactions in
                  chronological order. <br />
                  This creates a timing difference in the reporting of March tax payments/receipts between these reports.
                </FootNote>
                {accountPieTaxSummaryFootnotes?.map(({ footnote }, index) => (
                  <FootNote key={footnote}>
                    <Typography variant="superscript">{index + 4}</Typography>
                    {footnote}
                  </FootNote>
                ))}
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Panel>
    </Box>
  );
}

function FootNote({ children }: { children: ReactNode }) {
  return (
    <Grid item xs={12} sx={({ typography }) => ({ fontSize: typography.pxToRem(11), lineHeight: 1.2 })}>
      {children}
    </Grid>
  );
}

function CopyButton({ onClick, value }: { onClick: (value: number) => void; value: number }) {
  const buttonOnClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      onClick(value);
    },
    [onClick, value]
  );

  return (
    <IconButton onClick={buttonOnClick} size="small" sx={{ borderRadius: 1, px: 2, py: "1px" }}>
      <ContentCopy color="primary" titleAccess="Copy to clipboard" />
    </IconButton>
  );
}

function FormatWithFootnote({ fieldValue, footnoteField }: { fieldValue: string; footnoteField: string[] }) {
  const footnotes = footnoteField.map((_, index) => (index + 4).toString()).join(", ");

  return (
    <>
      {fieldValue}
      {!!footnoteField.length && <Typography variant="superscript">{footnotes}</Typography>}
    </>
  );
}
