import { ReactNode } from "react";
import { CheckIcon, Chip, Chips, Typography } from "../../components/common";
import { FormatDisplayOptions } from "./";

const displayFormat = (value: ReactNode, options: FormatDisplayOptions) => {
  const { display } = options;

  switch (display) {
    case "chip":
      if (Array.isArray(value)) {
        return <Chips chips={value} variant={options.variant} />;
      } else {
        return <Chip variant={options.variant}>{value}</Chip>;
      }
    case "emphasize":
      return (
        <Typography variant="emphasize" startAdornment={options.startAdornment} endAdornment={options.endAdornment} alignment={options.align}>
          {value}
        </Typography>
      );
    case "check":
      return !value ? undefined : <CheckIcon />;
  }

  return value;
};

export { displayFormat };
