import { ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, useTheme } from "@mui/material";
import { useRef, useState } from "react";
import { ArrowDropDownIcon, Button, ButtonProps } from "../common";

interface ButtonMultiProps extends Omit<ButtonProps, "onClick"> {
  options: ButtonMultiOption[];
}

interface ButtonMultiOption {
  label: string;
  onClick?: () => void;
}

const ButtonMulti = (props: ButtonMultiProps) => {
  const { children, options, ...rest } = props;
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <div ref={anchorRef}>
        <Button {...rest} icon={<ArrowDropDownIcon />} onClick={() => setOpen((prevOpen) => !prevOpen)}>
          {children}
        </Button>
      </div>

      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: theme.zIndex.drawer }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {options.map((option) => (
                    <MenuItem
                      key={option.label}
                      onClick={() => {
                        option.onClick?.();
                        setTimeout(() => setOpen(false), 50);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export { ButtonMulti };
export type { ButtonMultiProps, ButtonMultiOption };
