import { ReactElement, ReactNode, useState } from "react";
import { Button, ExpandMoreIcon, Rotate } from "../common";

interface CollapseWrapperProps {
  children: (expandNode: ReactNode, isOpen: boolean) => ReactElement;
  initialOpen?: boolean;
  onToggleCollapse?: (isOpen: boolean) => void;
}

function CollapseWrapper({ children, initialOpen = false, onToggleCollapse }: CollapseWrapperProps) {
  const [open, setOpen] = useState(initialOpen);

  return children(
    <Button
      onClick={() => {
        setOpen(!open);
        onToggleCollapse && onToggleCollapse(!open);
      }}
      sx={{ height: 36, width: 36 }}
      variant="text"
    >
      <Rotate rotate={open}>
        <ExpandMoreIcon />
      </Rotate>
    </Button>,
    open
  );
}

export { CollapseWrapper };
