import { Grid } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../store";
import { downloadPortfolioReportBatchAsync } from "../../../store/reports";
import { Button } from "../../common";
import Table from "../../table";

function PortfolioReportBatches() {
  const dispatch = useAppDispatch();
  const { isLoading, portfolioReportBatches } = useAppSelector((as) => as.reports);

  return !isLoading && !portfolioReportBatches ? null : (
    <Grid item xs={12} sx={{ backgroundColor: "background.paper" }}>
      <Table
        loading={isLoading}
        loadingBlankRows={15}
        minHeight={312}
        rows={portfolioReportBatches?.results}
        columns={[
          {
            heading: "Contact name",
            field: (row) => (
              <Button
                variant="text"
                onClick={() => row.generatedFileName && dispatch(downloadPortfolioReportBatchAsync({ fileName: row.generatedFileName }))}
                sx={{ minHeight: 26, py: 0 }}
              >
                {row.contactsRange}
              </Button>
            ),
            align: "left",
            width: 40,
          },
          {
            heading: "Generation date",
            field: "generatedDate",
            type: "date",
            width: 20,
          },
          {
            heading: "Document name",
            field: "generatedFileName",
            width: 20,
          },
          {
            heading: "Adviser name",
            field: "adviserName",
            width: 20,
          },
        ]}
      />
    </Grid>
  );
}

export { PortfolioReportBatches };
