import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { loadUser, reducer as oidc } from "redux-oidc";
import { userManager } from "../api";
import accountantAccounts from "./accountantAccounts";
import accounts from "./accounts";
import adviceTools from "./adviceTools";
import advisers from "./advisers";
import clients from "./clients";
import compliance from "./compliance";
import funds from "./funds";
import notifications from "./notifications";
import reports from "./reports";
import securities from "./securities";

export const history = createBrowserHistory({ basename: "/" });

const store = configureStore({
  reducer: {
    oidc,
    accounts,
    accountantAccounts,
    advisers,
    clients,
    reports,
    compliance,
    adviceTools,
    notifications,
    funds,
    securities,
    // @ts-expect-error: Typing issue
    router: connectRouter(history),
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(routerMiddleware(history)),
});

loadUser(store, userManager);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
