import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { generateLink, productKeyToName } from "../../../utils";
import { Breadcrumb } from "../../common";
import Table from "../../table";

function FeeStructures() {
  const { isLoading, feeStructures } = useAppSelector((as) => as.reports);
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();

  return !isLoading && !feeStructures ? null : (
    <Table
      to={(row) => generateLink("Business Information", `/account/${row.accountKey}/fee-structure`, history)}
      loading={isLoading}
      loadingBlankRows={15}
      minHeight={394}
      rows={feeStructures}
      columns={[
        {
          heading: "Account name",
          field: "accountName",
          display: "emphasize",
          width: 30,
        },
        {
          heading: "Product",
          field: (row) => productKeyToName(row.service ?? ""),
          display: "chip",
          variant: "outlined",
          width: 15,
        },
        {
          heading: "Account number",
          field: "accountKey",
          align: "right",
          width: 15,
        },
        {
          heading: "Balance",
          field: "balance",
          type: "money",
          width: 10,
        },
        {
          heading: "Fee structure",
          field: "hasFeeStructure",
          display: "check",
          align: "center",
          width: 15,
        },
        {
          heading: "Updated",
          field: "createdDate",
          type: "date",
          width: 15,
        },
      ]}
    />
  );
}

export { FeeStructures };
