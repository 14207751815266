import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Route, Switch, useParams } from "react-router-dom";
import { GetClientDetailsResponse, SearchClientDto } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { detailsAsync, detailsClear, projectionsAsync } from "../../store/clients";
import Theme from "../../theme";
import { getFullName } from "../../utils";
import { PageHeading, Tab, Tabs } from "../common";
import Detail from "./Detail";
import Reports from "./Reports";

interface ClientComponentProps {
  details?: GetClientDetailsResponse;
  initialDetails?: SearchClientDto;
  isLoadingDetails: boolean;
}

export default function Client() {
  const { key } = useParams<{ key: string }>();
  const { clientDetails, isLoadingDetails, searchResult } = useAppSelector((as) => as.clients);
  const dispatch = useAppDispatch();
  const initialValues = useMemo(() => searchResult?.results.find((c) => c.nzFundsClientNumber === key), [key, searchResult?.results]);
  const details = useMemo(
    () => ({ ...initialValues, ...(clientDetails?.clientNumber === key ? { ...clientDetails } : undefined) }),
    [clientDetails, initialValues, key]
  );
  const fullName = useMemo(() => getFullName(details), [details]);

  useEffect(() => {
    dispatch(detailsAsync({ clientNumber: key, states: ["Active"] }));
    dispatch(projectionsAsync({ clientNumber: key }));

    return () => {
      dispatch(detailsClear());
    };
  }, [dispatch, key]);

  return (
    <Theme variant="client">
      <PageHeading
        breadcrumb={{
          current: { label: fullName ? `${fullName} (Client)` : undefined, theme: "client" },
          default: [{ label: "Client Information", pathname: "/", theme: "client" }],
        }}
        heading={fullName}
        tabs={
          <Tabs preserveState>
            <Tab>Client details</Tab>
            <Tab path="reports">Reports</Tab>
          </Tabs>
        }
      />
      <Grid item xs={12}>
        <Switch>
          <Route exact path="/client/:key/reports">
            {!!details && <Reports details={clientDetails} initialDetails={initialValues} isLoadingDetails={isLoadingDetails} />}
          </Route>
          <Route>{!!details && <Detail details={clientDetails} initialDetails={initialValues} isLoadingDetails={isLoadingDetails} />}</Route>
        </Switch>
      </Grid>
    </Theme>
  );
}

export type { ClientComponentProps };
