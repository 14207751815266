import { Autocomplete as MuiAutocomplete, AutocompleteCloseReason, AutocompleteInputChangeReason, createFilterOptions, TextField } from "@mui/material";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef } from "react";
import { className } from "../../utils";

type BaseAutocompleteOption = { label: string; value: string | number | null | undefined };

interface BaseAutocompleteProps {
  allOption?: string;
  autoSelectIfOneOption?: boolean;
  disableClearable?: boolean;
  invert?: boolean;
  label?: string;
  onBlur?: () => void;
  onChange?: (value: string | number | null | undefined) => void;
  onClose?: (closeReason: AutocompleteCloseReason) => void;
  onFocus?: () => void;
  onInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
  onOpen?: () => void;
  options?: Array<BaseAutocompleteOption> | undefined;
  placeholder?: string;
  shrinkLabel?: boolean;
  value?: string | number | null | undefined;
}

export function BaseAutocomplete({
  allOption,
  autoSelectIfOneOption,
  invert,
  label,
  onBlur,
  onChange,
  onClose,
  onFocus,
  onInputChange,
  options = [],
  placeholder,
  shrinkLabel,
  value,
  ...props
}: BaseAutocompleteProps) {
  const ref = useRef<HTMLDivElement>();
  const _className = useMemo(() => [...(invert ? ["invert"] : []), ...(!label ? ["no-label"] : [])], [invert, label]);
  const _options = useMemo(() => {
    return !allOption || options.length <= 1 ? options : [{ label: allOption, value: undefined } as BaseAutocompleteOption].concat(options);
  }, [allOption, options]);
  const _value = useMemo(() => _options.find((option) => value === option.value) || null, [_options, value]);

  const handleBlur = useCallback(() => {
    onBlur?.();
  }, [onBlur]);

  const handleChange = useCallback(
    (_: SyntheticEvent | undefined, option: BaseAutocompleteOption | null) => {
      onChange?.(option?.value);
    },
    [onChange]
  );

  const handleClose = useCallback((_: SyntheticEvent, reason: AutocompleteCloseReason) => onClose?.(reason), [onClose]);

  const handleInputChange = useCallback(
    (_: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => onInputChange?.(value, reason),
    [onInputChange]
  );

  useEffect(() => {
    autoSelectIfOneOption && _options.length === 1 && handleChange(undefined, _options[0]);
  }, [_options, autoSelectIfOneOption, handleChange]);

  return (
    <MuiAutocomplete
      {...props}
      autoHighlight
      blurOnSelect
      filterOptions={createFilterOptions({ matchFrom: "any", stringify: (option) => `${option.label} ${option.value || ""}` })}
      freeSolo={false}
      getOptionKey={({ label, value }) => value || label}
      multiple={false}
      options={_options}
      onBlur={handleBlur}
      onChange={handleChange}
      onClose={handleClose}
      onInputChange={handleInputChange}
      onFocus={onFocus}
      ref={ref}
      selectOnFocus
      slotProps={{ popper: { keepMounted: true } }}
      value={_value}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ ...params.InputLabelProps, shrink: shrinkLabel }}
          InputProps={{ ...params.InputProps, className: className(params.InputProps.className, ..._className), placeholder }}
          label={label}
          sx={label ? { "& .MuiInputBase-root": { alignItems: "flex-end" } } : undefined}
        />
      )}
      renderOption={(params, option) => (
        <li {...params} key={option.value || option.label}>
          {option.label}
        </li>
      )}
    />
  );
}
