import { Grid } from "@mui/material";
import { Children, cloneElement, isValidElement, JSXElementConstructor, ReactElement, ReactNode, useCallback, useMemo, useState } from "react";
import { BaseAutocomplete } from "../form";
import { ReportDynamicFilterFromParentProps, ReportDynamicFilterProps } from "./ReportDynamicFilter";
import ReportField from "./ReportField";

interface ReportDynamicFiltersProps {
  children: ReactNode;
}

const mapFilterToOption = ({ field, label }: ReportDynamicFilterProps) => ({ label, value: field });

export default function ReportDynamicFilters({ children }: ReportDynamicFiltersProps) {
  const [filters, setFilters] = useState<(string | undefined)[]>([]);
  const fields = useMemo(
    () =>
      Children.map(children, (child) => (isValidElement<ReportDynamicFilterProps>(child) ? child : undefined))?.filter((child) => !!child) as ReactElement<
        ReportDynamicFilterProps,
        string | JSXElementConstructor<unknown>
      >[],
    [children]
  );
  const fieldsProps = useMemo(() => fields.map((field) => field.props), [fields]);
  const options = useMemo(() => fieldsProps.filter((field) => !filters.includes(field.field)).map(mapFilterToOption), [fieldsProps, filters]);

  const handleChange = useCallback((value: string | number | null | undefined) => setFilters(filters.slice().concat(value as string | undefined)), [filters]);

  return (
    <>
      {filters.map((filterName, index) => {
        const filter = fields.find((field) => field.props.field === filterName);

        return (
          filter &&
          cloneElement(filter as ReactElement<ReportDynamicFilterProps & ReportDynamicFilterFromParentProps, string | JSXElementConstructor<unknown>>, {
            fields: fieldsProps,
            filters,
            setFilters,
            filterIndex: index,
          })
        );
      })}
      {!!options.length && (
        <Grid item xs={12} md={11}>
          <ReportField dualColumn label={<BaseAutocomplete placeholder="Choose filter" onChange={handleChange} options={options} value="" />} />
        </Grid>
      )}
    </>
  );
}
