import { FeeHeaderTypeDto, GetTransactionTypesTransType } from "../api";
import { Adviser, AdviserBranch } from "../store/advisers";

const filterAdvisers = (advisers: Adviser[] | undefined, options: { adviserBranchKey?: string; adviserGroupKey?: string }) => {
  if (options.adviserBranchKey) {
    const branchKey = options.adviserBranchKey;
    return advisers?.filter((adviser) => adviser.branchKeys.includes(branchKey));
  }

  if (options?.adviserGroupKey) {
    const groupKey = options.adviserGroupKey;
    return advisers?.filter((adviser) => adviser.groupKeys.includes(groupKey));
  }

  return advisers;
};

const filterBranches = (adviserBranches: AdviserBranch[] | undefined, options: { adviserGroupKey?: string }) => {
  if (options?.adviserGroupKey) {
    const groupKey = options.adviserGroupKey;
    return adviserBranches?.filter((adviserBranch) => adviserBranch.groupKeys.includes(groupKey));
  }

  return adviserBranches;
};

const filterTransactionSubTypes = (transactionTypes: GetTransactionTypesTransType[] | undefined, transactionType?: string) => {
  const result = transactionType
    ? transactionTypes?.find((transType) => transType.name === transactionType)?.subTypes?.slice()
    : transactionTypes?.flatMap((transType) => transType.subTypes ?? []);
  return result?.sort();
};

const filterFeeHeaderTypes = (
  feeHeaderTypes: FeeHeaderTypeDto[] | undefined,
  options: { advisers?: Adviser[]; adviserGroupKey?: string; adviserBranchKey?: string; adviserNumber?: string }
) => {
  if (options.adviserNumber) {
    const { adviserNumber } = options;
    return feeHeaderTypes?.filter((fh) => fh.adviserNumbers?.includes(adviserNumber));
  }

  if (options.adviserGroupKey || options.adviserBranchKey) {
    const advisers = filterAdvisers(options.advisers, options)?.map((adviser) => adviser.number);

    return feeHeaderTypes?.filter((fh) => fh.adviserNumbers?.some((adviserNumber) => advisers?.includes(adviserNumber)));
  }

  return feeHeaderTypes;
};

export { filterAdvisers, filterBranches, filterTransactionSubTypes, filterFeeHeaderTypes };
