import { debounce } from "@mui/material";
import { Form as FormikForm, Formik, FormikErrors, FormikProps, FormikValues } from "formik";
import { CSSProperties, ReactNode, useCallback, useMemo, useRef } from "react";
import { formatDateJSON } from "../../utils";

interface FormProps<Values extends FormikValues> {
  children: ((props: FormikProps<Values>) => ReactNode) | ReactNode;
  /** Provided values are merged into the `initialValues`, overwriting any values... */
  defaultValues?: Values;
  disableReinitialize?: boolean;
  initialValues: Values;
  submitOnBlur?: boolean;
  submitOnChange?: boolean;
  submitOnReset?: boolean;
  onSubmit?: (values: Values) => void | Promise<unknown>;
  style?: CSSProperties;
  validate?: (values: Values) => void | object | Promise<FormikErrors<Values>>;
  validationSchema?: unknown | (() => unknown);
}

function swapDatesWithStrings<Values>(values: Values) {
  if (typeof values !== "object") return values;

  const result = values as Record<string, unknown>;

  Object.keys(result).forEach((key) => {
    if (result[key] instanceof Date) result[key] = formatDateJSON(result[key] as Date | string | undefined);
  });

  return result as Values;
}

function Form<Values extends FormikValues>({
  children,
  defaultValues,
  disableReinitialize,
  initialValues,
  submitOnBlur,
  submitOnChange,
  submitOnReset,
  onSubmit,
  style,
  ...props
}: FormProps<Values>) {
  const submitFormRef = useRef<(() => Promise<void>) & (() => Promise<unknown>)>();
  const _initialValues = useMemo(() => swapDatesWithStrings({ ...defaultValues, ...initialValues }), [defaultValues, initialValues]);

  const handleBlur = useMemo(
    () =>
      submitOnBlur
        ? debounce(() => {
            submitFormRef.current?.();
          }, 999)
        : () => {},
    [submitOnBlur]
  );

  const handleChange = useMemo(
    () =>
      submitOnChange
        ? debounce(() => {
            submitFormRef.current?.();
          }, 999)
        : () => {},
    [submitOnChange]
  );

  const handleReset = useCallback(() => {
    !!submitOnReset && submitFormRef.current?.();
  }, [submitOnReset]);

  const handleSubmit = useCallback(
    async (values: FormikValues & Values) => {
      onSubmit && (await onSubmit(values));
    },
    [onSubmit]
  );

  return (
    <Formik
      {...props}
      enableReinitialize={!disableReinitialize}
      initialValues={_initialValues}
      onReset={handleReset}
      onSubmit={handleSubmit}
      validateOnChange={false}
    >
      {(formik) => {
        submitFormRef.current = formik.submitForm;

        return (
          <FormikForm onBlur={handleBlur} onChange={handleChange} style={{ flexGrow: 1, width: "100%", ...style }}>
            {typeof children === "function" ? children(formik) : children}
          </FormikForm>
        );
      }}
    </Formik>
  );
}

export { Form };
