import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ClientsSort, SearchClientDto } from "../../api";
import { ACCOUNT_STATE_OPTIONS } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { exportAsync, searchAsync } from "../../store/clients";
import { filterAdvisers, generateLink, getProducts, userHasRole } from "../../utils";
import { Breadcrumb, ThemeIcon } from "../common";
import { SearchPageFilters } from "../common/SearchPageFilters";
import { Autocomplete, AutocompleteMultiple, Form } from "../form";
import Table from "../table";
import { ColumnDefinition } from "../table/ColumnDefinition";

type FormValues = Parameters<typeof searchAsync>[0];

const COLUMNS = [
  {
    field: () => <ThemeIcon />,
    width: 2,
  },
  {
    display: "emphasize",
    field: "name",
    heading: "Client name",
    sort: ClientsSort.FirstName,
    width: 22,
  },
  {
    field: (row) => row.advisers,
    heading: "Adviser",
    width: 22,
  },
  {
    display: "chip",
    field: (row) => row.products,
    heading: "Product",
    variant: "outlined",
    width: 22,
  },
  {
    field: "mobileNo",
    heading: "Mobile",
    sort: ClientsSort.MobileNo,
    type: "phone",
    width: 9,
  },
  {
    field: "email",
    heading: "Email",
    sort: ClientsSort.Email,
    width: 22,
  },
] as ColumnDefinition<SearchClientDto, string>[];

const DEFAULT_VALUES: FormValues = {
  adviserBranchKey: "",
  adviserNumber: "",
  currentPage: 0,
  pageSize: 35,
  services: [],
  sortOrder: ClientsSort.FirstName,
  states: ["Active"],
  term: "",
};

function Clients() {
  const dispatch = useAppDispatch();
  const history = useHistory<(FormValues & { breadcrumbs?: Breadcrumb[] }) | undefined>();
  const { autoSearch } = useAppSelector((as) => as.advisers);
  const abortsRef = useRef<((reason?: string) => void)[]>([]);
  const [filters, setFilters] = useState<FormValues>();
  const initialValues = useMemo<FormValues>(() => ({ ...DEFAULT_VALUES, ...history.location.state }), [history.location.state]);

  const abortRequests = useCallback(() => abortsRef.current.forEach((abort) => abort()), []);

  const fetchData = useCallback(
    (values: FormValues) => {
      abortRequests();
      const promise = dispatch(searchAsync(values));
      abortsRef.current = [promise.abort];
      history.replace(history.location.pathname, values);
    },
    [abortRequests, dispatch, history]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => setFilters({ ...values, currentPage: values.currentPage !== filters?.currentPage ? values.currentPage : 0 }),
    [filters]
  );

  useEffect(() => {
    if (autoSearch) fetchData(initialValues);
  }, [autoSearch, initialValues, fetchData]);

  useEffect(() => {
    filters && fetchData(filters);
  }, [filters, fetchData]);

  useEffect(() => {
    return () => abortRequests();
  }, [abortRequests]);

  return (
    <Form disableReinitialize defaultValues={DEFAULT_VALUES} initialValues={initialValues} onSubmit={handleSubmit}>
      <SearchForm />
    </Form>
  );
}

function SearchForm() {
  const { setValues, submitForm } = useFormikContext<FormValues>();
  const dispatch = useAppDispatch();
  const history = useHistory<(FormValues & { breadcrumbs?: Breadcrumb[] }) | undefined>();
  const { isSearching, isExporting, searchResult } = useAppSelector((as) => as.clients);
  const { adviserBranches, advisers } = useAppSelector((as) => as.advisers);
  const { user } = useAppSelector((as) => as.oidc);
  const adviserBranchOptions = useMemo(() => adviserBranches?.map(({ key, name }) => ({ label: name, value: key })), [adviserBranches]);
  const products = useMemo(() => getProducts(user), [user]);
  const canExport = useMemo(() => (userHasRole(user, "Deny Clients Export") ? undefined : true), [user]);
  const isLoading = useMemo(() => !!isSearching, [isSearching]);

  const exportAll = useCallback(() => dispatch(exportAsync(DEFAULT_VALUES)), [dispatch]);

  const exportFiltered = useCallback((values: FormValues) => dispatch(exportAsync(values)), [dispatch]);

  const filterAdviserOptions = useCallback(
    (values: FormValues & { adviserBranchKey?: string; adviserGroupKey?: string }) =>
      filterAdvisers(advisers, values)?.map(({ name, number }) => ({
        label: `${name} (${number})`,
        value: number,
      })),
    [advisers]
  );

  const handleReset = useCallback(async () => {
    await setValues(DEFAULT_VALUES);
    history.replace(history.location.pathname, DEFAULT_VALUES);
  }, [history, setValues]);

  return (
    <Grid container>
      <SearchPageFilters
        exportAll={canExport && exportAll}
        exportFiltered={canExport && exportFiltered}
        exporting={isExporting}
        loading={isLoading}
        onReset={handleReset}
        resetLabel="Reset"
        searchTermLabel="Search by name, mobile, email..."
        submitForm={submitForm}
      >
        <Grid item xs>
          <Autocomplete
            autoSelectIfOneOption
            label="Branch"
            name="adviserBranchKey"
            onChangeClear={["adviserNumber"]}
            options={adviserBranchOptions}
            shrinkLabel
          />
        </Grid>
        <Grid item xs>
          <Autocomplete autoSelectIfOneOption label="Adviser" name="adviserNumber" options={filterAdviserOptions} shrinkLabel />
        </Grid>
        <Grid item xs>
          <AutocompleteMultiple label="Product" name="services" options={products} shrinkLabel valueIsTag />
        </Grid>
        <Grid item xs>
          <AutocompleteMultiple label="Accounts status" name="states" options={ACCOUNT_STATE_OPTIONS} shrinkLabel />
        </Grid>
      </SearchPageFilters>
      <Table
        loading={!!isSearching}
        minHeight={315}
        paging={searchResult}
        rows={searchResult?.results}
        to={(row) => generateLink("Client Information", `/client/${row.nzFundsClientNumber}`, history)}
        columns={COLUMNS}
      />
    </Grid>
  );
}

export { Clients };
