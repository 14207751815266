import { useFormikContext } from "formik";
import { useEffect } from "react";
import { GetAccountDetailsResponse } from "../../../api";
import { FeeAgreementList } from "../FeeAgreementList";
import { FeeAgreementSelector } from "../FeeAgreementSelector";

interface PrivateWealthFeeStructureProps {
  account?: GetAccountDetailsResponse;
  feeEntered: () => void;
}

function getDefaultFormValues(account?: GetAccountDetailsResponse) {
  return {
    fees: [],
    services: getServices(account),
    initialAdviceAmount: undefined,
    annualAmount: undefined,
    annualAmountDescription: "",
    annualRate: undefined,
    annualAmountEstimate: undefined,
  };
}

function getServices(account?: GetAccountDetailsResponse) {
  const services = [];
  if (account?.product) services.push(account.product);
  return services;
}

function PrivateWealthFeeStructure({ account, feeEntered }: PrivateWealthFeeStructureProps) {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    // clear the other fields if we change the adviser's agreement type.
    setFieldValue("gis", undefined);
    setFieldValue("mmg", undefined);
    setFieldValue("pw", getDefaultFormValues(account));
  }, [account, setFieldValue]);

  return (
    <FeeAgreementSelector
      field="pw.fees"
      onChange={(changeType, value, { setFieldValue }) => {
        feeEntered();

        if (changeType === "remove") {
          switch (value) {
            case "initial":
              setFieldValue("pw.initialAdviceAmount", undefined);
              break;
            case "annualFixed":
              setFieldValue("pw.annualAmount", undefined);
              setFieldValue("pw.annualAmountDescription", "");
              break;
            case "annualRate":
              setFieldValue("pw.annualRate", undefined);
              setFieldValue("pw.annualAmountEstimate", undefined);
              break;
            case "implementation":
              setFieldValue("pw.brokerage", undefined);
              setFieldValue("pw.brokerName", "");
              setFieldValue("pw.exited", "");
              setFieldValue("pw.exitAmount", undefined);
              setFieldValue("pw.investmentProvider", "");
              setFieldValue("pw.otherFees", "");
              break;
          }
        }
      }}
    >
      <FeeAgreementList
        value="annualFixed"
        name="Annual fixed (for fee system)"
        fees={[
          { field: "pw.annualAmount", name: "Fixed amount pa", type: "amount" },
          { field: "pw.annualAmountDescription", name: "Description", type: "text" },
        ]}
      />
      <FeeAgreementList
        value="annualRate"
        name="Annual variable (fee for system)"
        fees={[
          { field: "pw.annualRate", name: "Variable pa", type: "rate" },
          { field: "pw.annualAmountEstimate", name: "Estimated amount pa", type: "amount" },
        ]}
      />
      <FeeAgreementList
        value="initial"
        name="Initial advice"
        field="pw.initialAdviceAmount"
        fees={[{ field: "pw.initialAdviceAmount", name: "Amount", type: "amount" }]}
      />
      <FeeAgreementList
        value="implementation"
        name="Implementation"
        fees={[
          { field: "pw.brokerage", name: "Brokerage", type: "amount" },
          { field: "pw.brokerName", name: "Broker name", type: "text" },
          { field: "pw.exited", name: "Exited investment", type: "text" },
          { field: "pw.exitAmount", name: "Exit fee", type: "amount" },
          { field: "pw.investmentProvider", name: "Investment provider", type: "text" },
          { field: "pw.otherFees", name: "Other fees payable", type: "note" },
        ]}
      />
    </FeeAgreementSelector>
  );
}

export { PrivateWealthFeeStructure };
