import { useEffect, useMemo, useState } from "react";
import { CddStatusFilter } from "../../../api";
import { Report, ReportField, ReportList } from "../../../components/reports";
import { useAppDispatch, useAppSelector } from "../../../store";
import { associatedAdvisersAsync } from "../../../store/advisers";
import {
  accountsCddStatusAsync,
  amlReportSummaryAsync,
  exportAccountsCddStatusAsync,
  exportRiskAssessmentByAccountTypeAsync,
  exportRiskAssessmentByCountryAsync,
  riskAssessmentByAccountTypeAsync,
  riskAssessmentByCountryAsync,
} from "../../../store/compliance";
import { Autocomplete, AutocompleteMultiple } from "../../form";
import { AccountCddStatus } from "./AccountCddStatus";
import { AMLAnnualReport } from "./AMLAnnualReport";
import { RiskAssessmentByAccountType } from "./RiskAssessmentByAccountType";
import { RiskAssessmentByCountry } from "./RiskAssessmentByCountry";

function Compliance() {
  const { advisers, adviserBranches } = useAppSelector((as) => as.advisers);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<Parameters<typeof accountsCddStatusAsync>[0]>();
  const adviserOptions = useMemo(() => advisers?.map(({ name, number }) => ({ label: `${name} (${number})`, value: number })), [advisers]);
  const adviserBranchOptions = useMemo(() => adviserBranches?.map(({ key, name }) => ({ label: name, value: key })), [adviserBranches]);

  useEffect(() => {
    !adviserBranches && dispatch(associatedAdvisersAsync());
  }, [adviserBranches, dispatch]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  return (
    <ReportList>
      <Report
        name="AML annual report"
        description="View the most recent AML annual report data. Last updated August 2024 "
        initialValues={{}}
        view={() => dispatch(amlReportSummaryAsync())}
      >
        <AMLAnnualReport />
      </Report>
      <Report
        name="CDD status"
        description="View Client Due Diligence status for all clients and accounts."
        initialValues={{ pageSize: 15, currentPage: 0 } as Parameters<typeof accountsCddStatusAsync>[0]}
        filters={
          <>
            <ReportField label="Choose adviser">
              <Autocomplete name="adviserNumber" autoSelectIfOneOption options={adviserOptions} />
            </ReportField>
            <ReportField label="Choose CDD status">
              <Autocomplete
                name="cddStatus"
                options={[
                  { value: CddStatusFilter.Complete, label: "CDD complete accounts" },
                  { value: CddStatusFilter.Incomplete, label: "CDD incomplete accounts" },
                ]}
              />
            </ReportField>
          </>
        }
        view={(values) => {
          setValues(values);
          return dispatch(accountsCddStatusAsync(values));
        }}
        export={(values) => dispatch(exportAccountsCddStatusAsync(values))}
      >
        <AccountCddStatus {...values} />
      </Report>
      <Report
        name="Risk Assessment by account type"
        description="View a Risk Assessment by account type."
        initialValues={{}}
        filters={
          <ReportField label="Choose adviser branch/es">
            <AutocompleteMultiple autoSelectIfOneOption name="adviserBranchKeys" options={adviserBranchOptions} />
          </ReportField>
        }
        view={(values) => dispatch(riskAssessmentByAccountTypeAsync(values))}
        export={(values) => dispatch(exportRiskAssessmentByAccountTypeAsync(values))}
      >
        <RiskAssessmentByAccountType />
      </Report>
      <Report
        name="Risk Assessment by country"
        description="View Risk Assessment summary by country of client."
        initialValues={{}}
        filters={
          <ReportField label="Choose adviser branch/es">
            <AutocompleteMultiple autoSelectIfOneOption name="adviserBranchKeys" options={adviserBranchOptions} />
          </ReportField>
        }
        view={(values) => dispatch(riskAssessmentByCountryAsync(values))}
        export={(values) => dispatch(exportRiskAssessmentByCountryAsync(values))}
      >
        <RiskAssessmentByCountry />
      </Report>
      <Report name="Risk Assessment by product" description="View a Risk Assessment by product." initialValues={{}}></Report>
    </ReportList>
  );
}

export { Compliance };
