import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AccountFinancialSummaryResponse,
  AccountPieTaxByFundResponse,
  AccountPieTaxSummaryResponse,
  AdviserFeesFeeSummary,
  AdviserFeesResponse,
  FeeHeaderTypeDto,
  FeeStructureDto,
  GetFeeHeaderResponse,
  GetFeeStructuresResponse,
  GetManagementReportResponse,
  GetPeriodicReportsResponse,
  ProblemDetails,
  RecurringTransactionsResponse,
  RecurringTransactionTypeFilter,
  SearchTransactionsResponse,
  SearchTransactionsSort,
} from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { addDownloading, addExporting, addLoading, fileThunk } from "./helpers";

interface reportsState {
  accountFinancialSummary?: AccountFinancialSummaryResponse;
  accountPieTaxSummary?: AccountPieTaxSummaryResponse;
  accountPieTaxSummaryFootnotes?: AccountPieTaxSummaryFootnote[];
  isLoading: boolean;
  isDownloading: boolean;
  isExporting: boolean;
  feeHeaderTypes?: FeeHeaderTypeDto[];
  adviserFees?: AdviserFeesFeeSummary[];
  fundsUnderManagement?: GetManagementReportResponse;
  portfolioReportBatches?: GetPeriodicReportsResponse;
  feeStructures?: FeeStructureDto[];
  transactions?: SearchTransactionsResponse;
  recurringTransactions?: RecurringTransactionsResponse;
  isLoadingAccountFinancialSummary: boolean;
  isLoadingAccountPieTaxSummary: boolean;
  isLoadingAccountPieTaxByFund: boolean;
  accountPieTaxByFund?: AccountPieTaxByFundResponse;
  accountPieTaxByFundFootnotes?: AccountPieTaxByFundFootnote[];
  errors?: ProblemDetails;
}

const initialState: reportsState = {
  isLoading: false,
  isDownloading: false,
  isExporting: false,
  isLoadingAccountFinancialSummary: false,
  isLoadingAccountPieTaxSummary: false,
  isLoadingAccountPieTaxByFund: false,
  accountPieTaxByFundFootnotes: [],
  accountPieTaxSummaryFootnotes: [],
};

export interface AccountPieTaxByFundFootnote {
  field?: string | undefined;
  footnote?: string | undefined;
}

export interface AccountPieTaxSummaryFootnote {
  footnote?: string | undefined;
}

export const accountPieTaxByFundAsync = createAsyncThunk<
  AccountPieTaxByFundResponse,
  { accountKey?: string; quarterFrom?: string; quarterTo?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/accountPieTaxByFund", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.accountPieTaxByFund(payload.accountKey, payload.quarterFrom, payload.quarterTo, signal).catch(rejectWithValue)
);

export const exportAccountPieTaxByFundAsync = fileThunk<{ accountKey?: string; quarterFrom?: string; quarterTo?: string }>(
  "accounts/exportAccountPieTaxByFund",
  (payload, { getState, signal }) =>
    getApis(getState()).accountsClient.exportAccountPieTaxByFund(payload.accountKey, payload.quarterFrom, payload.quarterTo, signal),
  "Account Pie Tax By Fund.csv"
);

export const accountPieTaxSummaryAsync = createAsyncThunk<
  AccountPieTaxSummaryResponse,
  { accountKey?: string; quarterFrom?: string; quarterTo?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/accountPieTaxSummary", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.accountPieTaxSummary(payload.accountKey, payload.quarterFrom, payload.quarterTo, signal).catch(rejectWithValue)
);

export const exportAccountPieTaxSummaryAsync = fileThunk<{ accountKey?: string; quarterFrom?: string; quarterTo?: string }>(
  "accounts/exportAccountPieTaxSummary",
  (payload, { getState, signal }) =>
    getApis(getState()).accountsClient.exportAccountPieTaxSummary(payload.accountKey, payload.quarterFrom, payload.quarterTo, signal),
  "Pie Tax Summary.csv"
);

export const accountFinancialSummaryAsync = createAsyncThunk<
  AccountFinancialSummaryResponse,
  { accountKey?: string; from?: string; to?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/accountFinancialSummary", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.accountFinancialSummary(payload.accountKey, payload.from, payload.to, signal).catch(rejectWithValue)
);

export const exportAccountFinancialSummaryAsync = fileThunk<{ accountKey?: string; from?: string; to?: string }>(
  "accounts/exportAccountFinancialSummary",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.exportAccountFinancialSummary(payload.accountKey, payload.from, payload.to, signal),
  "Account Financial Summary.csv"
);

export const generateAccountFinancialSummaryReportAsync = fileThunk<{ accountKey?: string; from?: string; to?: string }>(
  "accounts/accountFinancialSummaryReport",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.accountFinancialSummaryReport(payload.accountKey, payload.from, payload.to, signal),
  "Account Financial Summary.pdf"
);

export const fundsUnderManagementAsync = createAsyncThunk<
  GetManagementReportResponse,
  { service?: string; asAt?: string; adviserNumber?: string; adviserBranchKey?: string; adviserGroupKey?: string },
  { state: RootState }
>("reports/fundsUnderManagement", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .reportsClient.fundsUnderManagementReport(payload.asAt, payload.service, payload.adviserNumber, payload.adviserBranchKey, payload.adviserGroupKey, signal)
    .catch(rejectWithValue)
);

export const exportFundsUnderManagementAsync = fileThunk<{
  service?: string;
  asAt?: string;
  adviserNumber?: string;
  adviserBranchKey?: string;
  adviserGroupKey?: string;
}>(
  "reports/exportFundsUnderManagement",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.exportFundsUnderManagementReport(
      payload.asAt,
      payload.service,
      payload.adviserNumber,
      payload.adviserBranchKey,
      payload.adviserGroupKey,
      signal
    ),
  "Funds Under Management.csv"
);

export const portfolioReportBatchesAsync = createAsyncThunk<
  GetPeriodicReportsResponse,
  { adviserNumber?: string; adviserBranchKey?: string; adviserGroupKey?: string },
  { state: RootState }
>("reports/portfolioReportBatches", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .reportsClient.portfolioReportBatches(payload.adviserNumber, payload.adviserBranchKey, payload.adviserGroupKey, signal)
    .catch(rejectWithValue)
);

export const exportPortfolioReportBatchesAsync = fileThunk<{ adviserNumber?: string; adviserBranchKey?: string; adviserGroupKey?: string }>(
  "reports/exportPortfolioReportBatches",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.exportPortfolioReportBatches(payload.adviserNumber, payload.adviserBranchKey, payload.adviserGroupKey, signal),
  "Report Batches.csv"
);

export const downloadPortfolioReportBatchAsync = fileThunk<{ fileName: string }>(
  "reports/downloadPortfolioReportBatch",
  (payload, { getState, signal }) => getApis(getState()).reportsClient.downloadPortfolioReportBatch(payload.fileName, signal),
  "Report Batch.zip"
);

export const downloadPortfolioReportBatchesAsync = fileThunk<{ adviserNumber?: string; adviserBranchKey?: string; adviserGroupKey?: string }>(
  "reports/downloadPortfolioReportBatches",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.downloadPortfolioReportBatches(payload.adviserNumber, payload.adviserBranchKey, payload.adviserGroupKey, signal),
  "Report Batches.zip"
);

export const transactionsAsync = createAsyncThunk<
  SearchTransactionsResponse,
  {
    openTransactions?: boolean;
    services?: string[];
    transactionType?: string;
    transactionSubTypes?: string[];
    adviserNumber?: string;
    adviserBranchKey?: string;
    adviserGroupKey?: string;
    from?: string;
    to?: string;
    currentPage?: number;
    pageSize?: number;
    sortOrder?: SearchTransactionsSort;
  },
  { state: RootState }
>("reports/transactions", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .reportsClient.searchTransactions(
      payload.openTransactions,
      payload.services,
      payload.transactionType,
      payload.transactionSubTypes,
      payload.adviserNumber,
      payload.adviserBranchKey,
      payload.adviserGroupKey,
      payload.from,
      payload.to,
      payload.currentPage,
      payload.pageSize,
      payload.sortOrder,
      signal
    )
    .catch(rejectWithValue)
);

export const exportTransactionsAsync = fileThunk<{
  openTransactions?: boolean;
  services?: string[];
  transactionType?: string;
  transactionSubTypes?: string[];
  adviserNumber?: string;
  adviserBranchKey?: string;
  adviserGroupKey?: string;
  from?: string;
  to?: string;
  currentPage?: number;
  pageSize?: number;
  sortOrder?: SearchTransactionsSort;
}>(
  "reports/exportTransactions",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.exportTransactions(
      payload.openTransactions,
      payload.services,
      payload.transactionType,
      payload.transactionSubTypes,
      payload.adviserNumber,
      payload.adviserBranchKey,
      payload.adviserGroupKey,
      payload.from,
      payload.to,
      payload.sortOrder,
      signal
    ),
  (payload) => (payload.openTransactions ? "Open transactions.csv" : "Transactions.csv")
);

export const recurringTransactionsAsync = createAsyncThunk<
  RecurringTransactionsResponse,
  {
    recurringTransactionType?: RecurringTransactionTypeFilter;
    currentPage?: number;
    pageSize?: number;
  },
  { state: RootState }
>("reports/recurringTransactions", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .reportsClient.searchRecurringTransactions(null, payload.recurringTransactionType, payload.currentPage, payload.pageSize, signal)
    .catch(rejectWithValue)
);

export const exportRecurringTransactionsAsync = fileThunk<{
  recurringTransactionType?: RecurringTransactionTypeFilter;
  currentPage?: number;
  pageSize?: number;
}>(
  "reports/exportRecurringTransactions",
  (payload, { getState, signal }) => getApis(getState()).reportsClient.exportRecurringTransactions(payload.recurringTransactionType, signal),
  "Recurring Transactions.csv"
);

export const feeHeaderTypesAsync = createAsyncThunk<GetFeeHeaderResponse, void, { state: RootState }>(
  "reports/feeHeaderTypes",
  (_, { getState, rejectWithValue, signal }) => getApis(getState()).reportsClient.feeHeaderTypes(signal).catch(rejectWithValue)
);

export const adviserFeesAsync = createAsyncThunk<
  AdviserFeesResponse,
  { paid?: boolean; adviserGroupKey?: string; adviserBranchKey?: string; adviserNumber?: string; adviserFeesBatchIds?: number[] },
  { state: RootState }
>("reports/adviserFees", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .reportsClient.adviserFees(payload.paid, payload.adviserGroupKey, payload.adviserBranchKey, payload.adviserNumber, payload.adviserFeesBatchIds, signal)
    .catch(rejectWithValue)
);

export const exportAdviserFeesAsync = fileThunk<{
  paid?: boolean;
  adviserGroupKey?: string;
  adviserBranchKey?: string;
  adviserNumber?: string;
  adviserFeesBatchIds?: number[];
}>(
  "reports/exportAdviserFees",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.exportAdviserFees(
      payload.paid,
      payload.adviserGroupKey,
      payload.adviserBranchKey,
      payload.adviserNumber,
      payload.adviserFeesBatchIds,
      signal
    ),
  (payload) => (payload.paid ? "Fees paid.csv" : "Fees not processed.csv")
);

export const adviserFeesReportAsync = fileThunk<{
  detailed?: boolean;
  paid?: boolean;
  adviserGroupKey?: string;
  adviserBranchKey?: string;
  adviserNumber?: string;
  adviserFeesBatchIds?: number[];
}>(
  "reports/adviserFeesReport",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.adviserFeesReport(
      payload.detailed,
      payload.paid,
      payload.adviserGroupKey,
      payload.adviserBranchKey,
      payload.adviserNumber,
      payload.adviserFeesBatchIds,
      signal
    ),
  (payload) => `Adviser fees ${payload.detailed ? "detailed" : "summary"}${payload.paid ? "" : " not processed"}.pdf`
);

export const feeStructuresAsync = createAsyncThunk<
  GetFeeStructuresResponse,
  { adviserNumbers?: string[]; adviserBranchKey?: string; adviserGroupKey?: string },
  { state: RootState }
>("reports/feeStructures", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).reportsClient.feeStructures(payload.adviserNumbers, payload.adviserBranchKey, payload.adviserGroupKey, signal).catch(rejectWithValue)
);

export const exportFeeStructuresAsync = fileThunk<{ adviserNumbers?: string[]; adviserBranchKey?: string; adviserGroupKey?: string }>(
  "reports/exportFeeStructures",
  (payload, { getState, signal }) =>
    getApis(getState()).reportsClient.exportFeeStructures(payload.adviserNumbers, payload.adviserBranchKey, payload.adviserGroupKey, signal),
  "Fee structures.csv"
);

export const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    fundsUnderManagementClear(state) {
      state.fundsUnderManagement = undefined;
    },
    portfolioReportBatchesClear(state) {
      state.portfolioReportBatches = undefined;
    },
    transactionsClear(state) {
      state.transactions = undefined;
    },
    recurringTransactionsClear(state) {
      state.recurringTransactions = undefined;
    },
    adviserFeesClear(state) {
      state.adviserFees = undefined;
    },
    feeStructuresClear(state) {
      state.feeStructures = undefined;
    },
    accountPieTaxByFundClear(state) {
      state.accountPieTaxByFund = undefined;
      state.accountPieTaxByFundFootnotes = [];
    },
    accountPieTaxSummaryClear(state) {
      state.accountPieTaxSummary = undefined;
      state.accountPieTaxSummaryFootnotes = [];
    },
    accountFinancialSummaryClear(state) {
      state.accountFinancialSummary = undefined;
    },
  },
  extraReducers: (builder) => {
    addLoading(builder, fundsUnderManagementAsync, (state, payload) => (state.fundsUnderManagement = payload));
    addExporting(builder, exportFundsUnderManagementAsync);
    addLoading(builder, portfolioReportBatchesAsync, (state, payload) => {
      state.portfolioReportBatches = payload;
    });
    addDownloading(builder, downloadPortfolioReportBatchesAsync);
    addDownloading(builder, downloadPortfolioReportBatchAsync);
    addExporting(builder, exportPortfolioReportBatchesAsync);
    addLoading(builder, transactionsAsync, (state, payload) => (state.transactions = payload));
    addLoading(builder, recurringTransactionsAsync, (state, payload) => (state.recurringTransactions = payload));
    addExporting(builder, exportTransactionsAsync);
    addLoading(builder, feeHeaderTypesAsync, (state, payload) => (state.feeHeaderTypes = payload.feeHeaderTypes));
    addLoading(builder, adviserFeesAsync, (state, payload) => (state.adviserFees = payload.adviserFees));
    addExporting(builder, exportAdviserFeesAsync);
    addExporting(builder, adviserFeesReportAsync);
    addExporting(builder, exportRecurringTransactionsAsync);
    addLoading(builder, feeStructuresAsync, (state, payload) => (state.feeStructures = payload.feeStructures));
    addExporting(builder, exportFeeStructuresAsync);
    builder
      .addCase(accountPieTaxByFundAsync.pending, (state) => {
        state.isLoadingAccountPieTaxByFund = true;
        state.errors = undefined;
      })
      .addCase(accountPieTaxByFundAsync.fulfilled, (state, action) => {
        state.isLoadingAccountPieTaxByFund = false;
        state.accountPieTaxByFund = action.payload;
        if (action.payload.pieTaxableIncomes.filter((x) => x.pieTaxRate == "*").length > 0)
          state.accountPieTaxByFundFootnotes?.push({ field: "PieTaxRate", footnote: "PIE tax rate may have changed during the selected period." });

        if (action.payload.pieTaxableIncomes.filter((x) => x.zeroRated == "*").length > 0)
          state.accountPieTaxByFundFootnotes?.push({ field: "ZR", footnote: "Zero rated may have changed during the selected period." });

        if (action.payload.pieTaxableIncomes.filter((x) => x.exitingInv == "*").length > 0)
          state.accountPieTaxByFundFootnotes?.push({ field: "EI", footnote: "Exiting investor may have changed during the selected period." });
      })
      .addCase(accountPieTaxByFundAsync.rejected, (state, action) => {
        state.isLoadingAccountPieTaxByFund = false;
        state.errors = action.payload;
      })
      .addCase(exportAccountPieTaxByFundAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(exportAccountPieTaxByFundAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(exportAccountPieTaxByFundAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountPieTaxSummaryAsync.pending, (state) => {
        state.isLoadingAccountPieTaxSummary = true;
        state.errors = undefined;
      })
      .addCase(accountPieTaxSummaryAsync.fulfilled, (state, action) => {
        state.isLoadingAccountPieTaxSummary = false;
        state.accountPieTaxSummary = action.payload;
        if (action.payload.items.filter((x) => x.footnotes.filter((x) => x == "PieTaxRate").length > 0).length > 0)
          state.accountPieTaxSummaryFootnotes?.push({ footnote: "PIE tax rate may have changed during the selected period." });

        if (action.payload.items.filter((x) => x.footnotes.filter((x) => x == "ZeroRated").length > 0).length > 0)
          state.accountPieTaxSummaryFootnotes?.push({ footnote: "Zero rated may have changed during the selected period." });

        if (action.payload.items.filter((x) => x.footnotes.filter((x) => x == "ExitingInv").length > 0).length > 0)
          state.accountPieTaxSummaryFootnotes?.push({ footnote: "Exiting investor may have changed during the selected period." });
      })
      .addCase(accountPieTaxSummaryAsync.rejected, (state, action) => {
        state.isLoadingAccountPieTaxSummary = false;
        state.errors = action.payload;
      })
      .addCase(exportAccountPieTaxSummaryAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(exportAccountPieTaxSummaryAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(exportAccountPieTaxSummaryAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountFinancialSummaryAsync.pending, (state) => {
        state.isLoadingAccountFinancialSummary = true;
        state.errors = undefined;
      })
      .addCase(accountFinancialSummaryAsync.fulfilled, (state, action) => {
        state.isLoadingAccountFinancialSummary = false;
        state.accountFinancialSummary = action.payload;
      })
      .addCase(accountFinancialSummaryAsync.rejected, (state, action) => {
        state.isLoadingAccountFinancialSummary = false;
        state.errors = action.payload;
      })
      .addCase(exportAccountFinancialSummaryAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(exportAccountFinancialSummaryAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(exportAccountFinancialSummaryAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      });
  },
});

const {
  adviserFeesClear,
  feeStructuresClear,
  fundsUnderManagementClear,
  portfolioReportBatchesClear,
  transactionsClear,
  recurringTransactionsClear,
  accountPieTaxByFundClear,
  accountPieTaxSummaryClear,
  accountFinancialSummaryClear,
} = reportsSlice.actions;
export {
  adviserFeesClear,
  feeStructuresClear,
  fundsUnderManagementClear,
  portfolioReportBatchesClear,
  transactionsClear,
  recurringTransactionsClear,
  accountPieTaxByFundClear,
  accountPieTaxSummaryClear,
  accountFinancialSummaryClear,
};
export default reportsSlice.reducer;
