import { useFormikContext } from "formik";
import { ButtonMulti, ButtonMultiProps } from "../common";
import { ButtonMultiOption } from "../common/ButtonMulti";

interface FormButtonMultiProps<Values> extends Omit<ButtonMultiProps, "options"> {
  options: FormButtonMultiOption<Values>[];
}

interface FormButtonMultiOption<Values> extends Omit<ButtonMultiOption, "onClick"> {
  type?: "submit" | "reset";
  onClick?: (values: Values) => void;
  visible?: (values: Values) => boolean | undefined;
}

const FormButtonMulti = <Values,>(props: FormButtonMultiProps<Values>) => {
  const { options, ...rest } = props;
  const context = useFormikContext<Values>();

  const handleButtonClick = (option: FormButtonMultiOption<Values>) => {
    option.onClick && option.onClick(context.values);

    switch (option.type) {
      case "reset":
        context.resetForm();
        break;
      case "submit":
        context.submitForm();
        break;
    }
  };

  return (
    <ButtonMulti
      {...rest}
      options={options
        .filter((option) => !option.visible || option.visible(context.values))
        .map((option) => ({ ...option, onClick: () => handleButtonClick(option) }))}
    />
  );
};

export { FormButtonMulti };
export type { FormButtonMultiOption };
