import { AppBar, Button, Container, Grid, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { APP_BAR_HEIGHT, GUTTER_SIZE } from "../../constants";
import { useAppSelector } from "../../store";
import { MenuIcon, Typography } from "../common";

export default function Header() {
  const { user } = useAppSelector((state) => state.oidc);
  const history = useHistory();
  const [anchor, setAnchor] = useState<HTMLSpanElement>();
  const menuItems = useMemo(() => [{ label: "Log off", onClick: () => history.push("/authentication/signout") }], [history]);

  const handleOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    setAnchor(event.currentTarget);
  }, []);

  return (
    <AppBar elevation={0} sx={({ typography }) => ({ backgroundColor: "common.black", minHeight: typography.pxToRem(APP_BAR_HEIGHT) })}>
      <Container maxWidth="xl">
        <Grid container sx={{ alignItems: "center", color: "common.white", pl: GUTTER_SIZE, pr: GUTTER_SIZE }}>
          <Grid item xs={4}>
            {user?.profile.name?.toLocaleUpperCase()}
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <Typography variant="heading">
              <a href={import.meta.env.VITE_PORT_URL}>PORT</a>
            </Typography>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "right" }}>
            <Button color="inherit" onClick={handleOpen} sx={{ minWidth: 0, mr: -2.5, "&:hover": { backgroundColor: "common.black" } }} variant="text">
              <MenuIcon isOpen={!!anchor} />
            </Button>
            <Menu
              anchorEl={anchor}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              keepMounted
              onClose={() => setAnchor(undefined)}
              open={!!anchor}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              {menuItems.map(({ label, onClick }) => (
                <MenuItem key={label} onClick={onClick}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  );
}
