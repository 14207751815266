import { useField } from "formik";
import { FormLabel } from "./";
import { BaseFileUpload, BaseFileUploadProps, FileInfo } from "./BaseFileUpload";
import { ErrorMessage } from "./ErrorMessage";

interface FileUploadProps extends Omit<BaseFileUploadProps, "onChange"> {
  label?: string;
  name: string;
}

function FileUpload({ label, name, ...rest }: FileUploadProps) {
  const [{ value }, , { setValue }] = useField<FileInfo | undefined>(name);

  return (
    <>
      <FormLabel label={label} />
      <BaseFileUpload {...rest} value={value} onChange={setValue} />
      <ErrorMessage name={name} />
    </>
  );
}

export { FileUpload };
