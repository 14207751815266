import { array, boolean, object, Schema, string } from "yup";
import { CreateFeeStructureCommand } from "../../../api";
import { gisFeeStructureValidation } from "./gisFeeStructureValidation";
import { mmgFeeStructureValidation } from "./mmgFeeStructureValidation";
import { pwFeeStructureValidation } from "./pwFeeStructureValidation";

export const accountFeeStructureValidation: (newAccount: boolean) => Schema<CreateFeeStructureCommand> = (newAccount) =>
  object({
    ...(newAccount
      ? {
          accountName: string().required("Account name is required"),
          additionalClients: array(string().required())
            .required()
            .min(1, "At least one party must be entered on the agreement")
            .max(4, "At most four parties can be entered on the agreement"),
          partiesToAgreement: array(string().required()).required(),
        }
      : {
          additionalClients: array(string().required()).required(),
          partiesToAgreement: array(string().required()).required().min(1, "At least one party must be entered on the agreement"),
        }),
    address: string().required("Address is required"),
    adviserNumber: string().required("Adviser is required"),
    gis: gisFeeStructureValidation.default(undefined),
    mmg: mmgFeeStructureValidation.default(undefined),
    pw: pwFeeStructureValidation.default(undefined),
    preview: boolean().default(true),
  });
