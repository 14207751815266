import { Grid } from "@mui/material";
import { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { useAppSelector } from "../../store";
import { userHasEitherRole, userHasRole } from "../../utils";
import { PageHeading, Tab, Tabs } from "../common";
import { Assets } from "./assets";
import { Compliance } from "./compliance";
import { Fees } from "./fees";
import { Other } from "./other";
import { Transactions } from "./transactions";

interface TabItem {
  Component: () => JSX.Element;
  name: string;
  path?: string;
  visible?: () => boolean;
}

export default function Business() {
  const { user } = useAppSelector((as) => as.oidc);
  const tabs = useMemo<TabItem[]>(
    () => [
      { name: "Assets", Component: Assets },
      {
        name: "Fees",
        path: "fees",
        Component: Fees,
        visible: () => userHasRole(user, "Adviser Payments") && userHasEitherRole(user, ["ASP", "ASP Support", "MSS", "MSS Support"]),
      },
      { name: "Transactions", path: "transactions", Component: Transactions },
      { name: "Compliance", path: "compliance", Component: Compliance },
      { name: "Other", path: "other", Component: Other },
    ],
    [user]
  );

  return (
    <>
      <Grid container>
        <PageHeading
          heading="Business Information"
          tabs={
            <Tabs preserveState>
              {tabs
                .filter(({ visible }) => (visible ? visible() : true))
                .map(({ path, name }) => (
                  <Tab key={path || "default"} path={path}>
                    {name}
                  </Tab>
                ))}
            </Tabs>
          }
        />
      </Grid>
      <Switch>
        {tabs
          .filter(({ path, visible }) => (visible ? visible() : true && !!path))
          .map(({ path, Component }) => (
            <Route key={path} exact path={`/business/${path}`}>
              <Component />
            </Route>
          ))}
        {tabs
          .filter(({ path }) => !path)
          .map(({ path, Component }) => (
            <Route key={path || "default"} exact>
              <Component />
            </Route>
          ))}
      </Switch>
    </>
  );
}
