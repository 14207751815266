/**
 * Checks if an element is visible within a particular container. Generally used for scrolling events.
 *
 * @param {HTMLElement | undefined} element - The element to check visibility for.
 * @param {HTMLElement | undefined} container - The container element.
 * @returns True if the element is visible, false otherwise.
 */
export function isElementVisible(element: HTMLElement | undefined, container: HTMLElement | undefined) {
  if (!container || !element) return false;
  const elementTop = element.offsetTop;
  const elementBottom = elementTop + element.clientHeight;
  const containerTop = container.scrollTop;
  const containerBottom = containerTop + container.clientHeight;

  return (
    // The element is fully visible in the container
    (elementTop >= containerTop && elementBottom <= containerBottom) ||
    // Some part of the element is visible in the container
    (elementTop < containerTop && containerTop < elementBottom) ||
    (elementTop < containerBottom && containerBottom < elementBottom)
  );
}

export function className(...classNames: (string | undefined)[]) {
  return classNames.filter((className) => className).join(" ");
}
