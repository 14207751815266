import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { RecurringTransactionTypeFilter, SearchTransactionsSort } from "../../../api";
import { Report, ReportDynamicFilter, ReportDynamicFilters, ReportField, ReportList } from "../../../components/reports";
import { useAppDispatch, useAppSelector } from "../../../store";
import { associatedAdvisersAsync, transactionTypesAsync } from "../../../store/advisers";
import { exportRecurringTransactionsAsync, exportTransactionsAsync, recurringTransactionsAsync, transactionsAsync } from "../../../store/reports";
import { filterAdvisers, filterBranches, filterTransactionSubTypes, getProducts, parseDate } from "../../../utils";
import { Autocomplete, AutocompleteMultiple, DateField } from "../../form";
import { AllTransactions } from "./AllTransactions";
import { RecurringTransactions } from "./RecurringTransactions";

type TransactionsRequest = Parameters<typeof transactionsAsync>[0];

function Transactions() {
  const { advisers, adviserBranches, adviserGroups, transactionTypes } = useAppSelector((as) => as.advisers);
  const { user } = useAppSelector((as) => as.oidc);
  const dispatch = useAppDispatch();
  const [values, setValues] = useState<TransactionsRequest>();
  const products = useMemo(() => getProducts(user), [user]);
  const adviserGroupOptions = useMemo(() => adviserGroups?.map(({ key, name }) => ({ label: name, value: key })), [adviserGroups]);
  const transactionTypeOptions = useMemo(() => transactionTypes?.map(({ name }) => ({ value: name ?? "", label: name ?? "" })), [transactionTypes]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  useEffect(() => {
    !transactionTypes && dispatch(transactionTypesAsync());
  }, [dispatch, transactionTypes]);

  return (
    <ReportList>
      <Report
        name="All Transactions"
        description="View transactions across all accounts."
        initialValues={
          {
            services: undefined as [] | undefined,
            transactionType: undefined as string | undefined,
            transactionSubTypes: undefined as [] | undefined,
            openTransactions: false,
            recurringTransactionType: undefined as RecurringTransactionTypeFilter | undefined,
            pageSize: 55,
            from: undefined as string | undefined,
            to: parseDate(new Date()),
            sortOrder: SearchTransactionsSort.EffectiveDate_Desc,
          } as TransactionsRequest
        }
        filters={
          <>
            <ReportField label="Choose product/s" dualColumn>
              <AutocompleteMultiple name="services" options={products} />
            </ReportField>
            <ReportField label="Choose transaction type" dualColumn>
              <Autocomplete name="transactionType" options={transactionTypeOptions} onChangeClear={["transactionSubTypes"]} />
            </ReportField>
            <ReportField label="Choose transaction sub type" dualColumn>
              <AutocompleteMultiple
                name="transactionSubTypes"
                options={(values: { transactionType?: string }) =>
                  filterTransactionSubTypes(transactionTypes, values.transactionType)?.map((transSubType) => ({ value: transSubType, label: transSubType }))
                }
              />
            </ReportField>
            <ReportField label="Choose dates" dualColumn>
              <Grid item container alignItems="center" gap={1} style={{ width: "auto" }}>
                From: <DateField name="from" maxDate={new Date()} />
              </Grid>
              <Grid item container alignItems="center" gap={1} style={{ width: "auto" }}>
                to: <DateField name="to" maxDate={new Date()} />
              </Grid>
            </ReportField>
          </>
        }
        advancedFilters={
          <ReportDynamicFilters>
            {(adviserGroups?.length ?? 0) > 1 && (
              <ReportDynamicFilter label="Company" field="adviserGroupKey">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserGroupKey"
                  allOption="All"
                  options={adviserGroupOptions}
                  onChangeClear={["adviserNumber", "adviserBranchKey"]}
                />
              </ReportDynamicFilter>
            )}
            {(adviserBranches?.length ?? 0) > 1 && (
              <ReportDynamicFilter label="Branch" field="adviserBranchKey">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserBranchKey"
                  allOption="All"
                  options={(values: { adviserGroupKey?: string }) =>
                    filterBranches(adviserBranches, values)?.map((adviserBranch) => ({ value: adviserBranch.key, label: adviserBranch.name }))
                  }
                  onChangeClear={["adviserNumber"]}
                />
              </ReportDynamicFilter>
            )}
            <ReportDynamicFilter label="Adviser" field="adviserNumber">
              <Autocomplete
                autoSelectIfOneOption
                name="adviserNumber"
                allOption="All"
                options={(values: { adviserGroupKey?: string; adviserBranchKey?: string }) =>
                  filterAdvisers(advisers, values)?.map((adviser) => ({ value: adviser.number, label: `${adviser.name} (${adviser.number})` }))
                }
              />
            </ReportDynamicFilter>
          </ReportDynamicFilters>
        }
        view={(values) => {
          setValues(values);
          return dispatch(transactionsAsync(values));
        }}
        export={(values) => dispatch(exportTransactionsAsync(values))}
        validate={(values) => {
          if (!values.services?.length && !values.transactionType && !values.transactionSubTypes?.length) {
            return "Product or transaction type selection is required.";
          }

          if (!values.from || !values.to) {
            return "Both from and to dates are required.";
          }

          if (values.from > values.to) {
            return "From date must be before to date.";
          }
        }}
      >
        <AllTransactions {...values} />
      </Report>
      <Report
        name="Open Transactions"
        description="View open transactions for all products across all accounts, as at today."
        initialValues={{ openTransactions: true, pageSize: 55, sortOrder: SearchTransactionsSort.EffectiveDate }}
        view={(values) => {
          setValues(values);
          return dispatch(transactionsAsync(values));
        }}
        advancedFilters={
          <ReportDynamicFilters>
            {(adviserGroups?.length ?? 0) > 1 && (
              <ReportDynamicFilter label="Company" field="adviserGroupKey">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserGroupKey"
                  allOption="All"
                  options={adviserGroups?.map((adviserGroup) => ({ value: adviserGroup.key, label: adviserGroup.name }))}
                  onChangeClear={["adviserNumber", "adviserBranchKey"]}
                />
              </ReportDynamicFilter>
            )}
            {(adviserBranches?.length ?? 0) > 1 && (
              <ReportDynamicFilter label="Branch" field="adviserBranchKey">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserBranchKey"
                  allOption="All"
                  options={(values: { adviserGroupKey?: string }) =>
                    filterBranches(adviserBranches, values)?.map((adviserBranch) => ({ value: adviserBranch.key, label: adviserBranch.name }))
                  }
                  onChangeClear={["adviserNumber"]}
                />
              </ReportDynamicFilter>
            )}
            <ReportDynamicFilter label="Adviser" field="adviserNumber">
              <Autocomplete
                autoSelectIfOneOption
                name="adviserNumber"
                allOption="All"
                options={(values: { adviserGroupKey?: string; adviserBranchKey?: string }) =>
                  filterAdvisers(advisers, values)?.map((adviser) => ({ value: adviser.number, label: `${adviser.name} (${adviser.number})` }))
                }
              />
            </ReportDynamicFilter>
          </ReportDynamicFilters>
        }
        export={(values) => dispatch(exportTransactionsAsync(values))}
      >
        <AllTransactions {...values} />
      </Report>

      <Report
        name="Recurring Transactions Structure"
        description="View accounts with a recurring transaction configuration. Note some clients may have a savings configuration of $100 which is used as a percentage to calculate allocations. This may not be the amount received."
        initialValues={{
          currentPage: 0,
          pageSize: 55,
          recurringTransactionType: undefined,
        }}
        view={(values) => {
          setValues(values);
          return dispatch(recurringTransactionsAsync(values));
        }}
        export={(values) => dispatch(exportRecurringTransactionsAsync(values))}
        validate={(values) => {
          if (!values.recurringTransactionType) {
            return "Recurring type selection is required.";
          }
        }}
        filters={
          <ReportField label="Choose recurring type">
            <Autocomplete
              name="recurringTransactionType"
              options={[
                { value: RecurringTransactionTypeFilter.RecurringDeposit, label: "Deposits" },
                { value: RecurringTransactionTypeFilter.RegularWithdrawal, label: "Withdrawals" },
              ]}
            />
          </ReportField>
        }
      >
        <RecurringTransactions {...values} />
      </Report>
    </ReportList>
  );
}

export { Transactions };
