import { useField } from "formik";
import { useCallback } from "react";
import { BaseTextField, NumberTextFieldProps } from "./BaseTextField";
import { ErrorMessage } from "./ErrorMessage";

interface NumberFieldProps extends Omit<NumberTextFieldProps, "number"> {
  hideErrorMessage?: boolean;
  name: string;
}

function NumberField({ hideErrorMessage, name, onChange, ...rest }: NumberFieldProps) {
  const [{ value }, , { setTouched, setValue }] = useField<number | undefined>(name);

  const handleChange = useCallback(
    async (value: number | undefined) => {
      await setValue(value);
      await setTouched(true);
      onChange?.(value);
    },
    [onChange, setTouched, setValue]
  );

  return (
    <>
      <BaseTextField {...rest} number onChange={handleChange} uncontrolled value={value} />
      {!hideErrorMessage && <ErrorMessage name={name} />}
    </>
  );
}

export { NumberField };
