import { createUserManager } from "redux-oidc";

export const userManager = createUserManager({
  authority: import.meta.env.VITE_PORT_URL?.toString(),
  client_id: "nzfunds.port",
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}/authentication/callback`,
  response_type: "code",
  scope: "openid profile offline_access registry.api.public investment.api.public auth.api.public fee.api port.api",
  automaticSilentRenew: true,
  monitorSession: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
});
