import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { FileType, SearchDocumentSort, SearchResult, UpsertNotificationCommand } from "../../api";
import { GUTTER_SIZE, SPACING_MULTIPLIER } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { deleteFile, DeleteFileDetails, searchAsync, uploadFile, UploadFileData } from "../../store/adviceTools";
import { deleteNotification, getNotification, upsertNotification } from "../../store/notifications";
import { Accordion, AddIcon, Breadcrumb, Button, Panel, SearchPageFilters } from "../common";
import { Autocomplete, Form } from "../form";
import Table from "../table";
import { EditDocument } from "./EditDocument";
import { EditNotification } from "./EditNotification";
import { FileFunctions } from "./FileFunctions";
import { KeyFiles } from "./KeyFiles";

type FormValues = Parameters<typeof searchAsync>[0];

function DocumentsDisplay({ fileType, isAdmin }: { fileType: FileType; isAdmin: boolean }) {
  const dispatch = useAppDispatch();
  const { isLoading, isSubmitting, searchResult, staticDataResults } = useAppSelector((as) => as.adviceTools);
  const notifications = useAppSelector((as) => as.notifications);
  const history = useHistory<(FormValues & { breadcrumbs?: Breadcrumb[] }) | undefined>();
  const [edit, setEdit] = useState<UploadFileData>();
  const [autoSearch, setAutoSearch] = useState(true);
  const [deleteFileDetails, setDeleteFileDetails] = useState<DeleteFileDetails>();
  const [editNotification, setEditNotification] = useState<UpsertNotificationCommand>();
  const subTextLabel = useMemo(() => (fileType === FileType.Document ? "document" : "communication"), [fileType]);
  const accordionLabel = useMemo(() => (fileType === FileType.Document ? "Key documents" : "Key communications"), [fileType]);
  const defaultValues: FormValues = useMemo(
    () => ({
      categorySelected: "",
      categoryTagIds: [],
      currentPage: 0,
      fileType,
      pageSize: 35,
      priority: false,
      productSelected: "",
      productTagIds: [],
      sortOrder: fileType === FileType.Document ? SearchDocumentSort.Name : SearchDocumentSort.LastUpdated_Desc,
      term: "",
    }),
    [fileType]
  );
  const initialValues: FormValues = useMemo(() => ({ ...defaultValues, ...history.location.state }), [defaultValues, history.location.state]);
  const pageCategories = useMemo(
    () => (fileType == FileType.Document ? staticDataResults?.documentCategories : staticDataResults?.communicationCategories),
    [fileType, staticDataResults]
  );

  const doSearch = useCallback(
    (values: FormValues) => {
      dispatch(searchAsync(values));
      history.replace(history.location.pathname, values);
    },
    [dispatch, history]
  );

  const refresh = useCallback(() => {
    doSearch(initialValues);
  }, [doSearch, initialValues]);

  const deleteDoc = useCallback(async () => {
    await dispatch(deleteFile({ fileId: deleteFileDetails?.fileId }));
    setDeleteFileDetails(undefined);
    refresh();
  }, [deleteFileDetails?.fileId, dispatch, refresh]);

  const editSelected = useCallback(
    (values: SearchResult) => {
      setEdit({
        categorySelected: values.categorySelected,
        file: values.file?.fileName ? { fileName: values.file.fileName, data: null } : undefined,
        fileId: values.fileId,
        fileName: values.fileName,
        fileType,
        hasContent: values.hasContent,
        keywords: values.keywords?.join(", "),
        lastUpdated: values.lastUpdated,
        priority: values.priority,
        products: values.products,
        roles: values.roles,
        url: values.url,
      });
    },
    [fileType]
  );

  const doUpdateFile = useCallback(
    async (values: UploadFileData) => {
      await dispatch(uploadFile(values));
      setEdit(undefined);
      refresh();
    },
    [dispatch, refresh]
  );

  const handleOpenAddModal = useCallback(() => {
    setEdit({
      categorySelected: undefined,
      file: undefined,
      fileId: undefined,
      fileName: undefined,
      fileType,
      keywords: undefined,
      lastUpdated: undefined,
      priority: false,
      products: [],
      roles: [],
      url: undefined,
    });
  }, [fileType]);

  useEffect(() => {
    if (autoSearch) {
      setAutoSearch(false);
      refresh();
    }
  }, [autoSearch, refresh]);

  useEffect(() => {
    return () => setAutoSearch(true);
  }, []);

  return (
    <>
      {isAdmin && (
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", right: GUTTER_SIZE * SPACING_MULTIPLIER, top: -103 }}>
            <Grid container gap={1}>
              <Button icon={<AddIcon />} onClick={handleOpenAddModal}>
                Add new {subTextLabel}
              </Button>
              <Button
                loading={notifications.isLoading}
                icon={<AddIcon />}
                onClick={() => {
                  dispatch(getNotification())
                    .unwrap()
                    .then((notification) =>
                      setEditNotification({
                        message: notification?.message || "",
                        title: notification?.title || "",
                      })
                    );
                }}
              >
                System Notifications
              </Button>
            </Grid>
          </div>
        </div>
      )}
      <Panel>
        <Accordion heading={accordionLabel} defaultExpanded>
          <KeyFiles fileType={fileType} />
        </Accordion>
      </Panel>
      <Form disableReinitialize defaultValues={defaultValues} initialValues={initialValues} onSubmit={doSearch}>
        {({ resetForm, submitForm }) => (
          <>
            <SearchPageFilters onReset={resetForm} searchTermLabel="Search" submitForm={submitForm}>
              <Grid item xs={9} container spacing={3}>
                {fileType == FileType.Document && (
                  <Grid item xs={4}>
                    <Autocomplete
                      label="Product"
                      name="productSelected"
                      options={staticDataResults?.productNames?.map((product) => ({ value: product.productId || "", label: product.name || "" }))}
                      shrinkLabel
                    />
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Autocomplete
                    label="Document type"
                    name="categorySelected"
                    options={pageCategories?.map((category) => ({ value: category.categoryId || "", label: category.name || "" }))}
                    shrinkLabel
                  />
                </Grid>
              </Grid>
            </SearchPageFilters>
            <Grid container item xs={12} sx={{ backgroundColor: "common.white" }}>
              <Table
                loading={isLoading}
                minHeight={fileType == FileType.Communication ? 444 : 501}
                columns={
                  fileType == FileType.Communication
                    ? [
                        {
                          heading: "Communication name",
                          field: "fileName",
                          display: "emphasize",
                          sort: SearchDocumentSort.Name,
                          width: 50,
                        },
                        {
                          heading: "Document type",
                          field: "categoryNames",
                          width: 20,
                        },
                        {
                          heading: "Last updated",
                          field: "lastUpdated",
                          type: "date",
                          sort: SearchDocumentSort.LastUpdated,
                          width: 10,
                          align: "right",
                        },
                        {
                          field: (row) => (
                            <FileFunctions
                              url={row.url}
                              fileId={row.hasContent ? row.fileId : ""}
                              onEdit={isAdmin ? () => editSelected(row) : undefined}
                              onDelete={isAdmin ? () => setDeleteFileDetails({ fileId: row.fileId, fileName: row.fileName }) : undefined}
                            />
                          ),
                          width: 20,
                          align: "center",
                        },
                      ]
                    : [
                        {
                          heading: "Document name",
                          field: "fileName",
                          display: "emphasize",
                          sort: SearchDocumentSort.Name,
                          width: 40,
                        },
                        {
                          heading: "Product",
                          field: "products",
                          display: "chip",
                          variant: "outlined",
                          width: 20,
                        },
                        {
                          heading: "Document type",
                          field: (row) => row.categoryNames,
                          width: 15,
                        },
                        {
                          heading: "Last updated",
                          field: "lastUpdated",
                          type: "date",
                          sort: SearchDocumentSort.LastUpdated,
                          width: 10,
                          align: "right",
                        },
                        {
                          field: (row) => (
                            <FileFunctions
                              url={row.url}
                              fileId={row.hasContent ? row.fileId : ""}
                              onEdit={isAdmin ? () => editSelected(row) : undefined}
                              onDelete={isAdmin ? () => setDeleteFileDetails({ fileId: row.fileId, fileName: row.fileName }) : undefined}
                            />
                          ),
                          width: 20,
                          align: "right",
                        },
                      ]
                }
                rows={searchResult?.files?.map((f) => ({ ...f, ...f }))}
                rowExpand={(row, backgroundColor) =>
                  isAdmin ? (
                    <Table
                      child
                      rowBackgroundColor={backgroundColor}
                      columns={[
                        {
                          heading: "Roles",
                          field: (r) => r.fileRoles?.map((role) => role.text),
                          width: 30,
                          display: "chip",
                          variant: "outlined",
                          align: "left",
                        },
                        {
                          heading: "Keywords",
                          field: (r) => r.keywords,
                          width: 70,
                          align: "right",
                          display: "chip",
                          variant: "outlined",
                        },
                      ]}
                      rows={[row]}
                    />
                  ) : undefined
                }
                paging={{ ...searchResult }}
              />
            </Grid>
          </>
        )}
      </Form>
      {!!edit && (
        <EditDocument
          admin={isAdmin}
          fileType={fileType}
          initialValues={edit}
          loading={isSubmitting}
          subTextLabel={subTextLabel}
          onClose={() => setEdit(undefined)}
          onSubmit={doUpdateFile}
        />
      )}
      {!!deleteFileDetails && (
        <Dialog open={!!deleteFileDetails}>
          <DialogContent>
            <DialogContentText>Confirm delete of :</DialogContentText>
            <DialogContentText>
              <b>{deleteFileDetails.fileName}</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" autoFocus onClick={() => setDeleteFileDetails(undefined)}>
              No
            </Button>
            <Button onClick={deleteDoc}>Yes</Button>
          </DialogActions>
        </Dialog>
      )}
      {!!editNotification && (
        <EditNotification
          editNotification={editNotification}
          notifications={notifications}
          onCancel={() => setEditNotification(undefined)}
          onClear={() => dispatch(deleteNotification()).then(() => setEditNotification(undefined))}
          onClose={() => setEditNotification(undefined)}
          onSubmit={(notification) => dispatch(upsertNotification(notification)).then(() => setEditNotification(undefined))}
        />
      )}
    </>
  );
}

export { DocumentsDisplay };
