import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAccountPositionsResponse } from "../../api";
import { POSITION_GROUP_NAMES } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { securitiesAsync } from "../../store/securities";
import { PositionGroupFilter } from "../../types";
import { toPositionGroupType } from "../../utils";
import Table from "../table";

interface GroupedSecurities {
  positionGroupName: string;
  positions?: GetAccountPositionsResponse;
}

function SecurityGroups({ positionGroupFilter }: { positionGroupFilter: PositionGroupFilter }) {
  const dispatch = useAppDispatch();
  const { key } = useParams<{ key: string }>();
  const { securityGroups, isLoadingSecurityGroups, isLoadingSecurities } = useAppSelector((as) => as.securities);
  const [groupedSecurities, setGroupSecurities] = useState<GroupedSecurities[]>([]);

  const onToggleCollapse = useCallback(
    async (
      row: {
        weight: number;
        name?: string | undefined;
        date?: string | undefined;
        amount?: number | undefined;
      },
      isOpen?: boolean | undefined
    ) => {
      if (isOpen) {
        const securitiesByGroupType = await dispatch(
          securitiesAsync({
            accountNumber: key,
            pageSize: 30,
            currentPage: 0,
            positionGroupName: row.name,
            positionGroupType: toPositionGroupType(positionGroupFilter),
          })
        ).unwrap();

        if (!groupedSecurities.find((s) => s.positionGroupName === row.name)) {
          setGroupSecurities((previous) => [
            ...previous,
            {
              positionGroupName: row.name!,
              positions: securitiesByGroupType,
            },
          ]);
        }
      }
    },
    [dispatch, groupedSecurities, key, positionGroupFilter]
  );

  return (
    <Table
      key={positionGroupFilter}
      loading={isLoadingSecurityGroups || isLoadingSecurities}
      loadingBlankRows={3}
      minHeight={276}
      pageSizeHidden
      rows={securityGroups?.results?.map((security) => ({ ...security, weight: security.weight || 0 })).sort((a, b) => (a.weight > b.weight ? -1 : 1))}
      onToggleCollapse={onToggleCollapse}
      columns={
        positionGroupFilter !== PositionGroupFilter.Region
          ? [
              {
                heading: POSITION_GROUP_NAMES[positionGroupFilter],
                field: "name",
                display: "emphasize",
                width: 45,
              },
              {
                heading: "6 months",
                field: () => "",
                width: 7,
                align: "right",
              },
              {
                heading: "YTD",
                field: () => "",
                width: 5,
                align: "right",
              },
              {
                heading: "",
                field: () => "",
                width: 23,
                align: "center",
              },
              {
                heading: "% of total",
                field: "weight",
                display: "emphasize",
                width: 10,
                align: "right",
                type: "percent",
                typeOption: { decimalPlaces: 1 },
              },
              {
                heading: "Value",
                field: "amount",
                display: "emphasize",
                width: 15,
                type: "money",
              },
            ]
          : [
              {
                heading: POSITION_GROUP_NAMES[positionGroupFilter],
                field: "name",
                display: "emphasize",
                width: 57,
              },
              {
                heading: "",
                field: () => "",
                width: 23,
                align: "center",
              },
              {
                heading: "% of total",
                field: "weight",
                display: "emphasize",
                width: 10,
                align: "right",
                type: "percent",
                typeOption: { decimalPlaces: 1 },
              },
              {
                heading: "Value",
                field: "amount",
                display: "emphasize",
                width: 15,
                type: "money",
              },
            ]
      }
      rowExpand={(row, backgroundColor) => (
        <Table
          child
          loadingBlankRows={3}
          rowBackgroundColor={backgroundColor}
          handlePageChange={async (page) => {
            const securitiesByGroupType = await dispatch(
              securitiesAsync({
                accountNumber: key,
                pageSize: 30,
                currentPage: page,
                positionGroupName: row.name,
                positionGroupType: toPositionGroupType(positionGroupFilter),
              })
            ).unwrap();

            if (groupedSecurities.find((s) => s.positionGroupName === row.name)) {
              setGroupSecurities(
                groupedSecurities.map((security) => {
                  if (security.positionGroupName === row.name) {
                    return {
                      positionGroupName: row.name,
                      positions: securitiesByGroupType,
                    };
                  } else {
                    return { ...security };
                  }
                })
              );
            }
          }}
          rows={groupedSecurities.find((s) => s.positionGroupName === row?.name)?.positions?.results || []}
          paging={{ ...groupedSecurities.find((s) => s.positionGroupName === row.name!)?.positions }}
          columns={[
            {
              heading: "Name",
              field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.name}</>,
              display: "emphasize",
              width: 45,
            },
            {
              heading: "6 months",
              field: (row) => row.sixMonthPerformance || 0,
              display: "emphasize",
              width: 7,
              align: "right",
              type: "percent",
              typeOption: { decimalPlaces: 1 },
            },
            {
              heading: "YTD",
              field: "ytdPerformance",
              display: "emphasize",
              width: 5,
              align: "right",
              type: "percent",
              typeOption: { decimalPlaces: 1 },
            },
            {
              heading: "",
              field: () => "",
              width: 23,
              align: "center",
            },
            {
              heading: "% of total",
              field: "weight",
              display: "emphasize",
              width: 10,
              align: "right",
              type: "percent",
              typeOption: { decimalPlaces: 1 },
            },
            {
              heading: "Value",
              field: "amount",
              display: "emphasize",
              width: 15,
              type: "money",
            },
          ]}
        />
      )}
    />
  );
}

export { SecurityGroups };
