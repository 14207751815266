import { ThemeProvider } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { getThemeByName } from "../utils";
import { ThemeVariant } from "./";
import { ThemeContext } from "./context";

interface ThemeProps {
  children: ReactNode;
  /** Defaults to `primary`. */
  variant?: ThemeVariant;
}

export function Theme({ children, variant = "primary" }: ThemeProps) {
  const theme = useMemo(() => getThemeByName(variant), [variant]);

  return (
    <ThemeContext.Provider value={variant}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
