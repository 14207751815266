import { Grid, Skeleton } from "@mui/material";
import { ReactNode } from "react";
import { Alignment, FormatType } from "../../types";
import { formatValue } from "../../utils";
import { Accordion, HelpText, Typography } from "./";

type Visible = boolean | "gap";

interface DetailItemBase<PreloadedData, Data> {
  label: ReactNode;
  labelSuffix?: ReactNode;
  field: ((values: PreloadedData & Data) => ReactNode) | keyof PreloadedData | keyof Data;
  align?: Alignment;
  visible?: ((values: PreloadedData & Data) => Visible) | Visible;
  loading?: boolean;
  helpText?: ReactNode;
}

type DetailItem<PreloadedData, Data> = DetailItemBase<PreloadedData, Data> & FormatType;

interface Section<PreloadedData, Data> {
  heading?: ReactNode;
  visible?: ((values: PreloadedData & Data) => boolean) | boolean;
  fields: (DetailItem<PreloadedData, Data> | undefined)[];
}

interface DetailsProps<PreloadedData, Data> {
  heading: ReactNode;
  hideExpandToggle?: boolean;
  loading?: boolean;
  initialValues?: PreloadedData;
  values?: Data;
  sections: Section<PreloadedData, Data>[];
  defaultExpanded?: boolean;
}

function showLoading<Loading>(detailsLoading?: boolean | Loading, itemLoading?: boolean | keyof Loading) {
  if (itemLoading === false) return false;
  return itemLoading === true || detailsLoading === true ? true : false;
}

function Details<PreloadedData, Data>({
  loading,
  heading,
  hideExpandToggle,
  initialValues,
  values,
  sections,
  defaultExpanded,
}: DetailsProps<PreloadedData, Data>) {
  const data = { ...initialValues, ...values } as PreloadedData & Data;
  const sectionComponents = sections.map((section, sectionIndex) => {
    const { visible = true } = section;

    if (typeof visible === "function" ? !visible(data) : !visible) return undefined;

    return (
      <Grid
        item
        container
        xs={12}
        sx={{
          borderTopColor: "divider",
          borderTopStyle: "solid",
          borderTopWidth: sectionIndex === 0 ? 0 : 1,
          pb: sectionIndex === 0 || sectionIndex === sections.length - 1 ? undefined : 1.5,
          pt: 1.5,
        }}
        key={sectionIndex}
      >
        <Grid item xs={12}>
          {section.heading && <Typography variant="section-heading">{section.heading}</Typography>}
        </Grid>
        {section.fields.map((item, index) => {
          if (!item) return <Grid item xs={4} key={index} />;

          const visible = typeof item.visible === "function" ? item.visible(data) : item.visible;

          if (visible === false) return undefined;

          if (visible === "gap") return <Grid item xs={4} key={index} />;

          return (
            <Grid item xs={4} sx={{ py: 1 }} key={index}>
              <Typography variant="label">
                {item.label}
                {item.labelSuffix}
                {item.helpText && <HelpText text={item.helpText} />}
              </Typography>
              <br />
              <Typography variant="value">
                {showLoading(loading, item.loading) && (typeof item.field === "function" ? item.field(data) : data[item.field]) === undefined ? (
                  <Skeleton variant="rectangular" width="50%" style={{ marginTop: 4 }} />
                ) : (
                  formatValue(typeof item.field === "function" ? item.field(data) : data[item.field], item) || <>&nbsp;</>
                )}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    );
  });

  const alwaysShow = sectionComponents.shift();

  return (
    <Accordion heading={heading} hideExpandToggle={hideExpandToggle} alwaysShow={alwaysShow} defaultExpanded={defaultExpanded}>
      {sectionComponents}
    </Accordion>
  );
}

export { Details };
