import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Report, ReportField, ReportList } from "../../components/reports";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountPortfolioReport } from "../../store/accounts";
import { accountContactNumbersAsync, holdingsReport } from "../../store/clients";
import { formatDateJSON } from "../../utils";
import { Autocomplete, CheckboxList, DateField } from "../form";
import { ClientComponentProps } from "./";

interface FinancialYearPickerProps {
  radioOptions: CheckboxListOption<FinancialYearPickerOptions>[];
}

interface CheckboxListOption<Value> {
  value: Value;
  label: string;
}
enum FinancialYearPickerOptions {
  Current,
  Last,
}

interface DateRangePickerForm {
  yearType: FinancialYearPickerOptions;
  from: Date;
  to: Date;
}

function DateRangePicker({ radioOptions }: FinancialYearPickerProps) {
  const { setFieldValue, values } = useFormikContext<DateRangePickerForm>();
  const [currentlySelectedYearType, setCurrentlySelectedYearType] = useState<FinancialYearPickerOptions | undefined>(undefined);
  const yearType = useMemo(() => values.yearType, [values.yearType]);
  const maxDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (yearType != undefined && currentlySelectedYearType != yearType) {
      setFieldValue("from", formatDateJSON(getDate(yearType, true)));
      setFieldValue("to", formatDateJSON(getDate(yearType, false)));
      setCurrentlySelectedYearType(yearType);
    }
  }, [currentlySelectedYearType, setFieldValue, yearType]);

  function getDate(selectedYearType: FinancialYearPickerOptions, from: boolean): Date {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const financialYearStart = new Date(currentYear, 3, 1);
    if (currentDate < financialYearStart) {
      financialYearStart.setFullYear(currentYear - 1);
    }

    const financialYearEnd = new Date(financialYearStart);
    financialYearEnd.setFullYear(financialYearEnd.getFullYear() + 1);

    financialYearEnd.setDate(financialYearEnd.getDate() - 1);

    if (selectedYearType === FinancialYearPickerOptions.Last) {
      financialYearStart.setFullYear(financialYearStart.getFullYear() - 1);
      financialYearEnd.setFullYear(financialYearEnd.getFullYear() - 1);
    }

    return from ? financialYearStart : financialYearEnd;
  }

  return (
    <Grid container gap={2} sx={{ alignItems: "center" }}>
      <Grid item xs={12}>
        <CheckboxList name="yearType" options={radioOptions} radio />
      </Grid>
      <Grid item xs="auto" sx={{ pr: 3 }}>
        <DateField label="From" maxDate={maxDate} name="from" shrinkLabel />
      </Grid>
      <Grid item xs="auto">
        <DateField label="To" maxDate={maxDate} name="to" shrinkLabel />
      </Grid>
    </Grid>
  );
}

export default function Reports({ details }: ClientComponentProps) {
  const { key } = useParams<{ key: string }>();
  const { accountContactNumbers } = useAppSelector((as) => as.clients);
  const dispatch = useAppDispatch();
  const defaultInitialValues = useMemo(() => ({ accountKey: "", clientNumber: key }), [key]);
  const hasContactNumbers = useMemo(() => accountContactNumbers && Object.keys(accountContactNumbers).length > 0, [accountContactNumbers]);
  const maxDate = useMemo(() => new Date(), []);

  useEffect(() => {
    !accountContactNumbers && dispatch(accountContactNumbersAsync({ clientNumber: key }));
  }, [accountContactNumbers, dispatch, key]);

  return (
    <ReportList>
      <Report
        name="Client Holdings Report"
        description="Download a report showing balance information for this Client at a particular date."
        initialValues={{ ...defaultInitialValues, asAt: undefined }}
        filters={
          <ReportField label="Choose date">
            <DateField name="accountBalanceUpTo" maxDate={maxDate} />
          </ReportField>
        }
        generate={(values) => dispatch(holdingsReport(values))}
      />
      {!!hasContactNumbers && (
        <Report
          name="Portfolio Report"
          description={
            <>
              Download a Portfolio Report for all linked accounts, between two dates.
              <br />
              Portfolio Reports must be initiated from an account.
              <br />
              Please note this may take a few minutes to generate.
            </>
          }
          initialValues={{} as Parameters<typeof accountPortfolioReport>[0]}
          filters={
            <>
              <ReportField label="Account">
                <Autocomplete
                  name="accountKey"
                  options={details?.accounts
                    ?.filter((account) => account.accountKey && accountContactNumbers![account.accountKey])
                    .map((account) => ({ value: account.accountKey || "", label: `${account.name} (${account.product} - ${account.accountKey})` || "" }))}
                />
              </ReportField>
              <ReportField fieldFullWidth label="Choose dates">
                <DateRangePicker
                  radioOptions={[
                    { value: FinancialYearPickerOptions.Current, label: "Current financial year" },
                    { value: FinancialYearPickerOptions.Last, label: "Last financial year" },
                  ]}
                />
              </ReportField>
            </>
          }
          generate={(values) => dispatch(accountPortfolioReport(values))}
          validate={(values) => {
            if (!values.accountKey) return "Please choose an account.";
            if (!values.from || !values.to) return "Both from and to dates are required.";
            if (values.from >= values.to) return "From date must be before to date.";
          }}
        />
      )}
    </ReportList>
  );
}
