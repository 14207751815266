import { Popover, Typography } from "@mui/material";
import { MouseEvent, useState } from "react";

export interface IconPopoverProps {
  message: React.ReactNode;
  color?: string;
  icon: React.ReactNode;
}

const IconPopover = (props: IconPopoverProps) => {
  const { message, icon } = props;
  const [anchorElement, setAnchorElement] = useState<HTMLSpanElement | null>(null);
  const openPopup = Boolean(anchorElement);

  const handleOpenPopup = (event: MouseEvent<HTMLSpanElement>) => {
    setAnchorElement(event.currentTarget);
    event.preventDefault();
  };

  const handleClosePopup = () => {
    setAnchorElement(null);
  };

  return (
    <>
      <span onClick={handleOpenPopup}>{icon}</span>
      <Popover
        elevation={4}
        open={openPopup}
        anchorEl={anchorElement}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography>{message}</Typography>
      </Popover>
    </>
  );
};

export { IconPopover };
