import { Grid } from "@mui/material";
import { Typography } from "../common";

interface ContactInfoPanelProps {
  primaryEmail?: string;
  primaryFootNote?: string;
  primaryLabel?: string;
  secondaryEmail?: string;
  secondaryLabel?: string;
  secondaryPhone?: string;
  secondaryPhoneAlt?: string;
  sectionTitle?: string;
}

export default function ContactInfoPanel({
  primaryEmail,
  primaryFootNote,
  primaryLabel,
  secondaryEmail,
  secondaryLabel,
  secondaryPhone,
  secondaryPhoneAlt,
  sectionTitle,
}: ContactInfoPanelProps) {
  return (
    <>
      <Grid item xs={12}>
        <hr />
        <br />
      </Grid>
      <Grid item xs={4}>
        <Typography variant="page-subsubheading">{sectionTitle}</Typography>
      </Grid>
      <Grid item xs={4}>
        {!!primaryLabel && <Typography variant="label">{primaryLabel}</Typography>}
        <Typography variant="page-subheading">
          <a href={"mailto:" + primaryEmail} style={{ textDecoration: "underline" }}>
            {primaryEmail}
          </a>
        </Typography>
        {!!primaryFootNote && <Typography variant="label">{primaryFootNote}</Typography>}
      </Grid>
      <Grid item xs={4}>
        {!!secondaryLabel && <Typography variant="label">{secondaryLabel}</Typography>}
        {!!secondaryEmail && (
          <Typography variant="page-subheading">
            <a href={"mailto:" + secondaryEmail} style={{ textDecoration: "underline" }}>
              {secondaryEmail}
            </a>
          </Typography>
        )}
        {!!secondaryPhone && <Typography variant="page-subheading">{secondaryPhone}</Typography>}
        {!!secondaryPhoneAlt && <Typography variant="page-subheading">{secondaryPhoneAlt}</Typography>}
      </Grid>
      <Grid item xs={12}>
        <br />
      </Grid>
    </>
  );
}
