import { groupBy as lodashGroupBy } from "lodash";
import { parseDate } from "./date";

const groupBy = <Item, ExtraFields extends Record<string, unknown>>(items: Item[], groupKey: keyof Item, aggFields?: (items: Item[]) => ExtraFields) => {
  const grouping = lodashGroupBy(items, groupKey);
  return Object.keys(grouping).map((key) => ({ [groupKey]: key, items: grouping[key], ...(aggFields && aggFields(grouping[key])) }));
};

const maxDate = <T>(array: T[] | undefined, callbackfn: (value: T) => string | Date | undefined) => {
  return !array
    ? undefined
    : array
        .map(callbackfn)
        .map(parseDate)
        .reduce((date, newDate) => (!newDate ? date : !date ? newDate : newDate > date ? newDate : date), undefined);
};

export { groupBy, maxDate };
