import React from "react";
import { IconPopover } from "./IconPopover";

interface HelpTooltipProps {
  title: string | undefined;
  iconStyle?: React.CSSProperties;
}

const HelpTooltip = ({ title }: HelpTooltipProps) => {
  return !title ? null : <IconPopover icon={/*<HelpOutlineIcon style={iconStyle} />*/ undefined} message={title} />;
};

export { HelpTooltip };
