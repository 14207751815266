import { Grid } from "@mui/material";
import Theme from "../../theme";
import { getFullName } from "../../utils";
import { Chip, Details, Panel, ThemeIcon, Typography } from "../common";
import { AccountComponentProps } from "./Account";

export default function Detail({ account, loading }: AccountComponentProps) {
  return (
    <Panel>
      {!!account && (
        <Details
          heading="Key account details"
          hideExpandToggle
          initialValues={account}
          loading={loading}
          values={account}
          sections={[
            {
              fields: [
                {
                  label: "Client name/s",
                  field: (values) => (
                    <Theme variant="client">
                      <Typography variant="label">
                        <Grid container spacing={1} paddingTop={0.5}>
                          {values.clients.map(({ firstName, lastName, roles }) => (
                            <Grid item key={`${firstName}${lastName}`}>
                              <Chip startAdornment={<ThemeIcon />}>
                                {getFullName({ firstName, lastName })}{" "}
                                <span style={{ fontWeight: 400 }}>{values.clients.length === 1 ? undefined : roles.map((r) => r.displayName).join()}</span>
                              </Chip>
                            </Grid>
                          ))}
                        </Grid>
                      </Typography>
                    </Theme>
                  ),
                },
                { label: "Enrolment date", field: "enrolled", type: "date" },
                { label: "Account type", field: "accountType" },
                {
                  label: "Legal entities",
                  field: (values) => (
                    <Theme variant="client">
                      <Typography variant="label">
                        <Grid container spacing={1} paddingTop={0.5}>
                          {values.legalEntities.map(({ name, roles }) => (
                            <Grid item key={name}>
                              <Chip startAdornment={<ThemeIcon />}>
                                {name} <span style={{ fontWeight: 400 }}>{roles.map((r) => r.displayName).join()}</span>
                              </Chip>
                            </Grid>
                          ))}
                        </Grid>
                      </Typography>
                    </Theme>
                  ),
                },
                { label: "PIE tax rate", field: "pieTaxRate", type: "percent" },
                {
                  label: "Authority approved",
                  field: "authorityApproved",
                  type: "date",
                },
                {
                  label: "Authority expiry",
                  field: "authorityExpiry",
                  type: "date",
                },
              ],
            },
          ]}
        />
      )}
    </Panel>
  );
}
