import { styled, SxProps, Theme } from "@mui/material";
import { LocationDescriptor } from "history";
import { ReactNode } from "react";
import { Link } from "./Link";

interface ChipProps {
  children: ReactNode;
  endAdornment?: ReactNode;
  link?: LocationDescriptor<unknown>;
  small?: boolean;
  startAdornment?: ReactNode;
  sx?: SxProps<Theme>;
  variant?: "outlined" | "button";
}

const ChipInner = styled("span", { shouldForwardProp: (name) => name !== "button" && name !== "small" })<{ button?: boolean; small?: boolean }>(
  ({ button, small, theme }) => ({
    alignItems: "center",
    border: `1px solid ${button ? theme.palette.common.brandHigh : theme.palette.common.brandMid}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: button ? theme.palette.common.brandHigh : theme.palette.common.white,
    color: theme.palette.common.black,
    display: "inline-flex",
    fontSize: theme.typography.pxToRem(13),
    fontWeight: 500,
    justifyContent: "center",
    lineHeight: 1.1,
    minWidth: small ? 24 : 45,
    paddingBottom: 2,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 2,
    whiteSpace: "nowrap",
    ...(button && {
      "&:hover": {
        backgroundColor: button ? theme.palette.primary.main : theme.palette.common.brandMid,
        color: theme.palette.common.white,
        svg: {
          color: theme.palette.common.white,
          transition: "color 225ms",
        },
        transition: "background-color 225ms, color 225ms",
      },
    }),
  })
);

function Chip({ children, endAdornment, link, small, startAdornment, sx = [], variant = "button" }: ChipProps) {
  const chip = (
    <ChipInner button={variant === "button"} small={small} sx={[...(Array.isArray(sx) ? sx : [sx]), { gap: 1 }]}>
      {startAdornment}
      {children}
      {endAdornment}
    </ChipInner>
  );

  return link ? <Link to={link}>{chip}</Link> : chip;
}

export { Chip };
