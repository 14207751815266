import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { ClientDto, GetAccountDetailsResponse } from "../../api";
import Theme from "../../theme";
import { generateLink } from "../../utils";
import { Breadcrumb, Chip, ThemeIcon, Typography } from "../common";

function clientsGetSortByRoleType(client: ClientDto) {
  const clientName = client.name;
  return (client.roles.some((r) => r === "Primary") ? "AAA" : client.roles || "ZZZ") + "-" + clientName;
}

function clientsSortByRoleType(clients?: ClientDto[]) {
  return clients?.slice().sort((a, b) => clientsGetSortByRoleType(a).localeCompare(clientsGetSortByRoleType(b)));
}

export default function ClientsDetail({ pathSuffix, ...values }: GetAccountDetailsResponse & { pathSuffix?: string }) {
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();

  return (
    <Theme variant="client">
      <Typography variant="label">
        <Grid container spacing={1} sx={{ alignItems: "center", pt: 0.5 }}>
          {clientsSortByRoleType(values.clients)?.map((c, i) => (
            <Grid key={i} item>
              <Chip
                startAdornment={<ThemeIcon />}
                link={generateLink(`${values.name} (${values.product})`, `/client/${c.clientNumber}${pathSuffix || ""}`, history)}
              >
                {c.name} <span style={{ fontWeight: 400 }}>{values.clients?.length === 1 && c.roles.some((r) => r === "Primary") ? undefined : c.roles}</span>
              </Chip>
            </Grid>
          ))}
        </Grid>
      </Typography>
    </Theme>
  );
}
