import { FormatType } from "../../types";
import { formatValue } from "../../utils";
import { ColumnDefinition } from "./ColumnDefinition";

function getValue<Row>({ col, row }: { col: Pick<ColumnDefinition<Row, unknown>, "field" | "suffix"> & FormatType; row: Row }) {
  const value = typeof col.field === "function" ? col.field(row) : row[col.field];

  return col.suffix ? (
    <>
      {formatValue(value, col)}
      {col.suffix}
    </>
  ) : (
    formatValue(value, col)
  );
}

const getFooter = <Row, Sort>({ col, rows }: { col: ColumnDefinition<Row, Sort>; rows: Row[] }) => {
  if (!col.footer) {
    return undefined;
  }

  if (typeof col.footer === "function") {
    return col.footer(rows);
  }

  if (col.footer === "sum") {
    const sum = rows.reduce((prev, data) => {
      return prev + (typeof col.field === "function" ? (col.field(data) as unknown as number) : ((data[col.field] || 0) as unknown as number));
    }, 0);

    return formatValue(sum, col);
  }

  return undefined;
};

export { getValue, getFooter };
