import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { UpsertNotificationCommand } from "../../api";
import { NotificationsState } from "../../store/notifications";
import { Button, Typography } from "../common";
import { FormLabel } from "../common/FormLabel";
import { Form, FormButton, TextField } from "../form";

export const EditNotification = (props: {
  editNotification: UpsertNotificationCommand;
  notifications: NotificationsState;
  onCancel: () => void;
  onClear: () => void;
  onClose: () => void;
  onSubmit: (notification: UpsertNotificationCommand) => void;
}) => {
  const validate = (values: UpsertNotificationCommand) => {
    const errors: Partial<Record<keyof typeof values, string>> = {};

    if (!values.title) {
      errors.title = "Title is required";
    }

    if (!values.message) {
      errors.message = "Message is required";
    }

    return errors;
  };

  return (
    <Form defaultValues={{ ...props.editNotification }} initialValues={props.editNotification} onSubmit={props.onSubmit} validate={validate}>
      <Dialog open={!!props.editNotification} onClose={props.onClose} fullWidth maxWidth={"md"}>
        <DialogTitle>Add / edit notification</DialogTitle>
        <DialogContent>
          <Typography variant="page-subheading">Current notification</Typography>
          <br />
          <FormLabel label="Title" field={<TextField name="title" />} />
          <FormLabel label="Message" field={<TextField name="message" multiline />} />
          <span style={{ color: "red" }}>{props.notifications.errors}</span>
        </DialogContent>
        <DialogActions>
          <FormButton variant="outlined" loading={props.notifications.isSubmitting} onClick={props.onClear}>
            Clear
          </FormButton>
          <Button variant="outlined" autoFocus onClick={props.onCancel}>
            Cancel
          </Button>
          <FormButton type="submit" loading={props.notifications.isSubmitting}>
            Update
          </FormButton>
        </DialogActions>
      </Dialog>
    </Form>
  );
};
