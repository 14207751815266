import { Grid } from "@mui/material";
import { Route, Switch } from "react-router-dom";
import Theme from "../../theme";
import { PageHeading } from "../common";
import { NewRiskRating } from "./NewRiskRating";

export default function RiskRating() {
  return (
    <Theme variant="account">
      <PageHeading heading="New Risk Rating" />
      <Grid item xs={12}>
        <Switch>
          <Route exact path="/risk-rating/new">
            <NewRiskRating />
          </Route>
        </Switch>
      </Grid>
    </Theme>
  );
}
