import { Grid } from "@mui/material";
import { ReactNode } from "react";
import { Chip } from "./Chip";

interface ChipsProps {
  chips: ReactNode[];
  variant?: "outlined";
}

function Chips({ chips, variant }: ChipsProps) {
  return (
    <Grid container spacing={0.5}>
      {chips.map((chip, i) => (
        <Grid key={i} item>
          <Chip variant={variant}>{chip}</Chip>
        </Grid>
      ))}
    </Grid>
  );
}

export { Chips };
