interface FormatSpecial {
  type: "special";
  typeOption: "irdNumber";
}

const formatSpecial = (value: unknown, options: FormatSpecial) => {
  if (!value) return undefined;

  switch (options.typeOption) {
    case "irdNumber":
      return formatIrdNumber(value.toString());
    default:
      return value;
  }
};

const irdSplitCharacter = "-";
const irdSplitInterval = 3;

const formatIrdNumber = (value: string) => {
  return value
    .split("")
    .reverse()
    .map((c, i, a) => (i > 0 && i < a.length && i % irdSplitInterval == 0 ? c + irdSplitCharacter : c))
    .reverse()
    .join("");
};

export type { FormatSpecial };
export { formatSpecial };
