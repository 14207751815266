import { User } from "oidc-client";

function getProducts(user: User | undefined) {
  return userHasRole(user, "WIP")
    ? [
        { value: "ASP", label: "Active Series" },
        { value: "IG", label: "Income Generator" },
        { value: "KS", label: "KiwiSaver" },
        { value: "MSS", label: "Managed Superannuation Service" },
        { value: "QROPS", label: "UK Pension Transfer" },
        { value: "WB", label: "Wealth Builder" },
        { value: "WIP", label: "Wholesale Investment" },
      ]
    : [
        { value: "ASP", label: "Active Series" },
        { value: "IG", label: "Income Generator" },
        { value: "KS", label: "KiwiSaver" },
        { value: "MSS", label: "Managed Superannuation Service" },
        { value: "QROPS", label: "UK Pension Transfer" },
        { value: "WB", label: "Wealth Builder" },
      ];
}

function userHasEitherRole(user: User | undefined, roles: string[]) {
  return roles.some((role) => !!user?.profile.role?.includes(role));
}

function userHasRole(user: User | undefined, role: string) {
  return !!user?.profile.role?.includes(role);
}

export { getProducts, userHasEitherRole, userHasRole };
