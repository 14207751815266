import { Route, Switch } from "react-router-dom";
import Account from "./account";
import AccountantSearch from "./accountant";
import AccountantAccount from "./accountant/Account";
import AdviceTools from "./advice-tools";
import Business from "./business";
import Client from "./client";
import ContactUs from "./contact-us";
import FeeStructure from "./fee-structure";
import RiskRating from "./risk-rating";
import Search from "./search";

export default function AppRoutes() {
  return (
    <Switch>
      <Route exact path="/client/:key/:tab?" component={Client} />
      <Route path="/account/:key/:tab?" component={Account} />
      <Route exact path="/contact-us/:tab?" component={ContactUs} />
      <Route exact path="/advice-tools/:tab?" component={AdviceTools} />
      <Route exact path="/business/:tab?" component={Business} />
      <Route exact path="/fee-structure/:tab?" component={FeeStructure} />
      <Route exact path="/risk-rating/:tab?" component={RiskRating} />
      <Route exact path="/:tab?" component={Search} />
      <Route exact path="/accountant/:tab?" component={AccountantSearch} />
      <Route exact path="/accountant/account/:key/:tab?" component={AccountantAccount} />
    </Switch>
  );
}
