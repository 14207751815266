function Skeleton({ height, width }: { height?: string; width?: string }) {
  return (
    <span
      style={{
        marginTop: 8,
        width: width,
        display: "block",
        backgroundColor: "rgba(0, 0, 0, 0.11)",
        height: height ?? "1.2em",
        animation: "animation-c7515d 1.5s ease-in-out 0.5s infinite",
      }}
    />
  );
}

export { Skeleton };
