import { PositionGroupFilter } from "./types";

// Theme constants
export const APP_BAR_HEIGHT = 50;
export const GUTTER_SIZE = 8;
export const SPACING_MULTIPLIER: number = 6;

// Other constants and enums
export enum AccountStates {
  Active = "Active",
  Inactive = "Inactive",
  Pending = "Pending",
}

export const ACCOUNT_STATE_OPTIONS = [
  { value: AccountStates.Active, label: AccountStates.Active },
  { value: AccountStates.Inactive, label: AccountStates.Inactive },
  { value: AccountStates.Pending, label: AccountStates.Pending },
];

export const CURRENCY_0DP = new Intl.NumberFormat(undefined, {
  currency: "NZD",
  currencyDisplay: "narrowSymbol",
  currencySign: "standard",
  maximumFractionDigits: 0,
  style: "currency",
});

export const CURRENCY_ACCOUNTING = new Intl.NumberFormat(undefined, {
  currency: "NZD",
  currencyDisplay: "narrowSymbol",
  currencySign: "accounting",
  style: "currency",
});

export const NUMBER_0DP = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 0,
  notation: "compact",
});

export const NUMBER_SHORT = new Intl.NumberFormat(undefined, {
  maximumSignificantDigits: 3,
  notation: "compact",
});

export const PAGE_SIZE_OPTIONS = [
  { label: "15", value: 15 },
  { label: "35", value: 35 },
  { label: "55", value: 55 },
];

export const PERCENTAGE_1DP = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
  style: "percent",
});

export const POSITION_GROUP_NAMES: Record<PositionGroupFilter, string> = {
  [PositionGroupFilter.Strategies]: "Strategies",
  [PositionGroupFilter.AssetClass]: "Asset Class",
  [PositionGroupFilter.Manager]: "Manager",
  [PositionGroupFilter.Region]: "Region",
  [PositionGroupFilter.Securities]: "Securities",
};
