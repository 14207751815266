import { array, boolean, number, object, Schema, string } from "yup";
import { GisFeeStructure } from "../../../api";

const gisFeeStructureValidation: Schema<GisFeeStructure> = object({
  fees: array().required().of(string().required()).min(1, "At least one fee type is required"),
  otherFeeAmount: number().when("fees", ([fees], schema) => (fees?.includes("other") ? schema.required("Amount is required") : schema)),
  otherFeeDescription: string().when("fees", ([fees], schema) => (fees?.includes("other") ? schema.required("Description is required") : schema)),
  gstRate: number().required("GST rate is required").max(20, "GST rate can not be more than 20%"),
  deductPerFundOption: boolean().required("Fee deduction is required"),
  deductFromFundId: number().when("deductPerFundOption", ([deductPerFundOption], schema) =>
    deductPerFundOption ? schema : schema.required("A portfolio is required")
  ),
  details: object().when("fees", ([fees], schema) =>
    fees?.includes("flat") && fees?.includes("tier")
      ? schema.test((value: Record<string, number>, context) =>
          Object.keys(value)
            .map((key) => value[key])
            .filter((value) => value !== undefined).length > 0
            ? true
            : context.createError({ message: "At least one flat rate is required" })
        )
      : fees?.includes("flat")
        ? schema.test((value: Record<string, number>, context) =>
            Object.keys(value)
              .map((key) => value[key])
              .filter((value) => value !== undefined).length === 5
              ? true
              : context.createError({ message: "All flat rate fields required" })
          )
        : schema
  ),
  tierRates: array().when(["fees", "details", "linkedAccountKey"], ([fees, details, linkedAccountKey], schema) => {
    if (!fees?.includes("tier")) {
      return schema;
    }

    if (!linkedAccountKey)
      schema = schema
        .required("All tier rates are required")
        .compact((value) => value === undefined)
        .length(6, "All tier rates are required");

    if (fees?.includes("flat")) {
      schema = schema.test((_, context) =>
        Object.keys(details)
          .map((key) => details[key])
          .filter((value) => value !== undefined).length === 5
          ? context.createError({ message: "As all fund groups have a flat rate, you cannot select a Tiered rate fee" })
          : true
      );
    }

    return schema;
  }),
  regularSavingFeeRate: number().when("fees", ([fees], schema) => (fees?.includes("upfront") ? schema.required("Regular saving fee required") : schema)),
});

export { gisFeeStructureValidation };
