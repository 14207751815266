import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ClientsSort, GetClientDetailsResponse, GetClientProjectionResponse, ProblemDetails, SearchClientsResponse } from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { fileThunk } from "./helpers";

interface clientsState {
  accountContactNumbers?: Record<string, number>;
  clientDetails?: GetClientDetailsResponse;
  latestBalanceDate?: string;
  errors?: ProblemDetails;
  isExporting: boolean;
  isLoadingAccountContactNumbers: boolean;
  isLoadingDetails: boolean;
  isLoadingPerformance: boolean;
  isLoadingProjections: boolean;
  isSearching?: string;
  projections?: GetClientProjectionResponse;
  searchResult?: SearchClientsResponse;
}

const initialState: clientsState = {
  isExporting: false,
  isLoadingAccountContactNumbers: false,
  isLoadingDetails: false,
  isLoadingPerformance: false,
  isLoadingProjections: false,
  isSearching: undefined,
};

export const searchAsync = createAsyncThunk<
  SearchClientsResponse,
  {
    adviserBranchKey?: string;
    adviserNumber?: string;
    currentPage: number;
    pageSize: number;
    services?: string[];
    sortOrder: ClientsSort;
    states?: string[];
    term?: string;
  },
  { state: RootState; rejectValue: ProblemDetails }
>("clients/search", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .clientsClient.searchClients(
      payload.term,
      payload.services,
      payload.states,
      payload.adviserBranchKey,
      payload.adviserNumber,
      payload.currentPage,
      payload.pageSize,
      payload.sortOrder,
      signal
    )
    .catch(rejectWithValue)
);

export const detailsAsync = createAsyncThunk<
  GetClientDetailsResponse,
  { clientNumber: string; states?: string[]; accountBalanceUpTo?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("clients/details", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).clientsClient.details(payload.clientNumber, payload.accountBalanceUpTo, payload.states, signal).catch(rejectWithValue)
);

export const exportAsync = fileThunk<{
  term?: string;
  services?: string[];
  states?: string[];
  adviserBranchKey?: string;
  adviserNumber?: string;
  sortOrder: ClientsSort;
}>(
  "clients/export",
  (payload, { getState, signal }) =>
    getApis(getState()).clientsClient.exportClients(
      payload.term,
      payload.services,
      payload.states,
      payload.adviserBranchKey,
      payload.adviserNumber,
      payload.sortOrder,
      signal
    ),
  "Clients.csv"
);

export const holdingsReport = fileThunk<{ clientNumber?: string; accountBalanceUpTo?: string }>(
  "clients/holdingsReport",
  (payload, { getState, signal }) => getApis(getState()).clientsClient.holdingsReport(payload.clientNumber, payload.accountBalanceUpTo, signal),
  "Client Holdings Report.pdf"
);

export const accountContactNumbersAsync = createAsyncThunk<Record<string, number>, { clientNumber: string }, { state: RootState; rejectValue: ProblemDetails }>(
  "clients/accountContactNumbers",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).clientsClient.contactNumber(payload.clientNumber, true, signal).catch(rejectWithValue)
);

export const projectionsAsync = createAsyncThunk<GetClientProjectionResponse, { clientNumber: string }, { state: RootState; rejectValue: ProblemDetails }>(
  "clients/projections",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).clientsClient.projections(payload.clientNumber, signal).catch(rejectWithValue)
);

export const clientsSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    searchResultClear(state) {
      state.searchResult = undefined;
      state.isSearching = undefined;
    },
    detailsClear(state) {
      state.accountContactNumbers = undefined;
      state.clientDetails = undefined;
      state.latestBalanceDate = undefined;
      state.projections = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAsync.pending, (state, action) => {
        state.isSearching = action.meta.requestId;
        state.errors = undefined;
      })
      .addCase(searchAsync.fulfilled, (state, action) => {
        state.isSearching = undefined;
        state.searchResult = action.payload;
      })
      .addCase(searchAsync.rejected, (state, action) => {
        if (state.isSearching === action.meta.requestId) {
          state.isSearching = undefined;
          state.errors = action.payload;
        }
      })

      .addCase(detailsAsync.pending, (state) => {
        state.isLoadingDetails = true;
        state.errors = undefined;
      })
      .addCase(detailsAsync.fulfilled, (state, action) => {
        state.isLoadingDetails = false;
        state.clientDetails = action.payload;
        if (state.latestBalanceDate === undefined) state.latestBalanceDate = action.payload.balanceAsAt;
      })
      .addCase(detailsAsync.rejected, (state, action) => {
        state.isLoadingDetails = false;
        state.errors = action.payload;
      })

      .addCase(exportAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(exportAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(exportAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })

      .addCase(holdingsReport.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(holdingsReport.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(holdingsReport.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })

      .addCase(accountContactNumbersAsync.pending, (state) => {
        state.isLoadingAccountContactNumbers = true;
        state.errors = undefined;
      })
      .addCase(accountContactNumbersAsync.fulfilled, (state, action) => {
        state.isLoadingAccountContactNumbers = false;
        state.accountContactNumbers = action.payload || [];
      })
      .addCase(accountContactNumbersAsync.rejected, (state, action) => {
        state.isLoadingAccountContactNumbers = false;
        state.errors = action.payload;
      })

      .addCase(projectionsAsync.pending, (state) => {
        state.isLoadingProjections = true;
        state.errors = undefined;
      })
      .addCase(projectionsAsync.fulfilled, (state, action) => {
        state.isLoadingProjections = false;
        state.projections = action.payload;
      })
      .addCase(projectionsAsync.rejected, (state, action) => {
        state.isLoadingProjections = false;
        state.errors = action.payload;
      });
  },
});

export const { detailsClear, searchResultClear } = clientsSlice.actions;
export default clientsSlice.reducer;
