import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AccountantAccountDto, ProblemDetails, SearchAccountantAccountSortBy, SearchAccountantAccountsResponse } from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";

interface Paging {
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
}

interface accountsState {
  accounts?: AccountantAccountDto[];
  errors?: ProblemDetails;
  paging?: Paging;
  removingAccess: boolean;
  searchRequestId?: string;
}

const initialState: accountsState = {
  removingAccess: false,
};

export const removeAccessAsync = createAsyncThunk<boolean, string, { state: RootState; rejectValue: ProblemDetails }>(
  "accountantAccounts/removeAccess",
  (accountKey, { getState, rejectWithValue, signal }) => getApis(getState()).accountantAccountsClient.removeAccess(accountKey, signal).catch(rejectWithValue)
);

export const searchAsync = createAsyncThunk<
  SearchAccountantAccountsResponse,
  {
    currentPage: number;
    pageSize: number;
    sortOrder: SearchAccountantAccountSortBy;
    term?: string;
  },
  { state: RootState; rejectValue: ProblemDetails }
>("accountantAccounts/search", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .accountantAccountsClient.searchAccounts(payload.term, payload.currentPage, payload.pageSize, payload.sortOrder, signal)
    .catch(rejectWithValue)
);

export const accountantAccountsSlice = createSlice({
  name: "accountantAccounts",
  initialState,
  reducers: {
    clearAccounts: (state) => {
      state.accounts = undefined;
      state.errors = undefined;
      state.paging = undefined;
      state.searchRequestId = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(removeAccessAsync.pending, (state) => {
        state.errors = undefined;
        state.removingAccess = true;
      })
      .addCase(removeAccessAsync.fulfilled, (state) => {
        state.removingAccess = false;
      })
      .addCase(removeAccessAsync.rejected, (state, action) => {
        state.errors = action.payload;
        state.removingAccess = false;
      })
      .addCase(searchAsync.pending, (state, action) => {
        state.errors = undefined;
        state.searchRequestId = action.meta.requestId;
      })
      .addCase(searchAsync.fulfilled, (state, action) => {
        const { accounts, ...paging } = action.payload;
        state.accounts = accounts;
        state.paging = paging;
        state.searchRequestId = undefined;
      })
      .addCase(searchAsync.rejected, (state, action) => {
        if (state.searchRequestId === action.meta.requestId) {
          state.errors = action.payload;
          state.searchRequestId = undefined;
        }
      });
  },
});

export const { clearAccounts } = accountantAccountsSlice.actions;
export default accountantAccountsSlice.reducer;
