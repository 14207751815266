import { useField } from "formik";
import { useCallback } from "react";
import { BaseTextField, TextFieldProps as InternalTextFieldProps } from "./BaseTextField";
import { ErrorMessage } from "./ErrorMessage";

interface TextFieldProps extends InternalTextFieldProps {
  hideErrorMessage?: boolean;
  name: string;
  onBlur?: () => void;
}

function TextField({ hideErrorMessage, name, onBlur, onChange, ...rest }: TextFieldProps) {
  const [{ value }, , { setTouched, setValue }] = useField<string | undefined>(name);

  const handleBlur = useCallback(async () => {
    await setTouched(true);
    onBlur?.();
  }, [onBlur, setTouched]);

  const handleChange = useCallback(
    async (newValue: string | undefined) => {
      await setValue(newValue);
      onChange?.(newValue || "");
    },
    [onChange, setValue]
  );

  return (
    <>
      <BaseTextField {...rest} onBlur={handleBlur} onChange={handleChange} value={value} />
      {!hideErrorMessage && <ErrorMessage name={name} />}
    </>
  );
}

export { TextField };
