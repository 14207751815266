import { Box, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { Redirect, Route, RouteProps, useHistory, useLocation } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";
import { userManager } from "../../api";
import { useAppSelector } from "../../store";

export default function Authentication({ children, ...rest }: RouteProps) {
  const { isLoadingUser, user } = useAppSelector((state) => state.oidc);

  return (
    <>
      <Route path="/authentication/callback" component={Callback} />
      <Route path="/authentication/signin" component={SignIn} />
      <Route path="/authentication/signout" component={SignOut} />
      <Route
        {...rest}
        render={({ location }: { location: { pathname: string } }) =>
          isLoadingUser ? null : user?.expired === false || !location.pathname.indexOf("/authentication") ? (
            children
          ) : (
            <Redirect to={{ pathname: "/authentication/signin", state: { from: location.pathname } }} />
          )
        }
      />
    </>
  );
}

function SignIn() {
  const { state } = useLocation<{ from?: string }>();

  useEffect(() => {
    userManager.signinRedirect({ state: state.from });
  }, [state.from]);

  return null;
}

function Callback() {
  const history = useHistory();

  return (
    <CallbackComponent errorCallback={console.error} successCallback={(user) => history.replace(user.state)} userManager={userManager}>
      <Box sx={{ display: "flex", flexGrow: 1, height: "100%", justifyContent: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <CircularProgress size={100} color="primary" />
        </Box>
      </Box>
    </CallbackComponent>
  );
}

function SignOut() {
  useEffect(() => {
    userManager.signoutRedirect();
  }, []);

  return null;
}
