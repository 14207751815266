import { EnumOfAccountPermissionAndString } from "./api";

export type { Alignment } from "./utils/alignment";
export type { FormatType } from "./utils/format";

export enum PositionGroupFilter {
  Strategies = "Strategies",
  AssetClass = "AssetClass",
  Manager = "Manager",
  Region = "Region",
  Securities = "Securities",
}

export interface AccountReportDto {
  accountNumber: string;
  service: string;
  permissions: EnumOfAccountPermissionAndString[];
}

export interface Projections {
  tenYearBalance: string;
  twentyYearBalance: string;
  thirtyYearBalance: string;
  tenYearPerformance: string;
  twentyYearPerformance: string;
  thirtyYearPerformance: string;
}

export interface SecuritiesDto {
  searchName: "";
  positionGroupType?: PositionGroupFilter;
  positionGroupName?: string;
  currentPage: number;
  pageSize: number;
}

export interface UserSettings {
  accountSearchState?: string;
  pageSize?: number;
  sub: string;
}

interface FieldFormatTypePercent {
  type: "percent";
  typeOption?: { inputStyle?: "decimal"; decimalPlaces?: number };
}

interface FieldFormatTypeNoOptions {
  type?: "date" | "money";
}

type FieldFormatType = FieldFormatTypePercent | FieldFormatTypeNoOptions;

type FieldType = NonNullable<FieldFormatType["type"]>;

export type { FieldFormatType, FieldType };
