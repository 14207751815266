import FormHelperText from "@mui/material/FormHelperText";
import { ErrorMessage as FormikErrorMessage } from "formik";

interface ErrorMessageProps {
  name: string;
}

const ErrorMessage = (props: ErrorMessageProps) => {
  return <FormikErrorMessage name={props.name}>{(errorMessage) => <FormHelperText error>{errorMessage}</FormHelperText>}</FormikErrorMessage>;
};

export { ErrorMessage };
