import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetFundGroupsResponse, GetFundHistoryResponse, ProblemDetails } from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { addCase, addExporting, addLoading, fileThunk } from "./helpers";

interface accountsState {
  isLoading: boolean;
  isLoadingFundGroups: boolean;
  isExporting: boolean;
  fundGroups?: GetFundGroupsResponse;
  fundHistory?: GetFundHistoryResponse;
  errors?: ProblemDetails;
}

const initialState: accountsState = {
  isLoading: false,
  isLoadingFundGroups: false,
  isExporting: false,
};

export const fundGroupsAsync = createAsyncThunk<GetFundGroupsResponse, undefined, { state: RootState }>(
  "reports/fundGroups",
  (_, { getState, rejectWithValue, signal }) => getApis(getState()).fundsClient.fundGroups(signal).catch(rejectWithValue)
);

export const fundHistoryAsync = createAsyncThunk<
  GetFundHistoryResponse,
  { fundKey?: string; service?: string; from: string; to: string },
  { state: RootState }
>("reports/fundHistory", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).fundsClient.fundHistory(payload.fundKey, payload.service, payload.from, payload.to, signal).catch(rejectWithValue)
);

export const exportFundHistoryAsync = fileThunk<{ fundKey?: string; service?: string; from?: string; to?: string }>(
  "reports/exportFundHistory",
  (payload, { getState, signal }) => getApis(getState()).fundsClient.exportFundHistory(payload.fundKey, payload.service, payload.from, payload.to, signal),
  "Unit prices.csv"
);

export const fundsSlice = createSlice({
  name: "funds",
  initialState,
  reducers: {
    fundHistoryClear(state) {
      state.fundHistory = undefined;
    },
  },
  extraReducers: (builder) => {
    addCase(
      builder,
      fundGroupsAsync,
      (state, isLoading) => (state.isLoadingFundGroups = isLoading),
      (state, payload) => (state.fundGroups = payload)
    );
    addLoading(builder, fundHistoryAsync, (state, payload) => (state.fundHistory = payload));
    addExporting(builder, exportFundHistoryAsync);
  },
});

const { fundHistoryClear } = fundsSlice.actions;
export { fundHistoryClear };
export default fundsSlice.reducer;
