import { styled } from "@mui/material";

const Rotate = styled("div", { shouldForwardProp: (name) => name !== "rotate" })<{ rotate: boolean }>(({ rotate }) => ({
  display: "flex",
  transition: "opacity 200ms, transform 200ms",
  ...(rotate
    ? {
        transform: `rotate(-180deg)`,
      }
    : undefined),
}));

export { Rotate };
