import {
  Autocomplete as MuiAutocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
  createFilterOptions,
  TextField,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import { HTMLAttributes, Ref, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { className } from "../../utils";
import { ErrorMessage } from "./";

type AutocompleteOption = { label: string; value: string | number | null | undefined };

interface AutocompleteProps<FormValues> {
  allOption?: string;
  autoSelectIfOneOption?: boolean;
  disableClearable?: boolean;
  invert?: boolean;
  label?: string;
  name: string;
  onChange?: (value: string | number | null | undefined) => void;
  onChangeClear?: string[];
  onClose?: (closeReason: AutocompleteCloseReason) => void;
  onFocus?: () => void;
  onInputChange?: (value: string, reason: AutocompleteInputChangeReason) => void;
  onOpen?: () => void;
  options: Array<AutocompleteOption> | ((formValues: FormValues) => Array<AutocompleteOption> | undefined) | undefined;
  placeholder?: string;
  shrinkLabel?: boolean;
}

const filterOptions = createFilterOptions({ matchFrom: "any", stringify: (option: AutocompleteOption) => `${option.label} ${option.value || ""}` });

export function Autocomplete<FormValues>({
  allOption,
  autoSelectIfOneOption,
  disableClearable,
  invert,
  label,
  name,
  onChange,
  onChangeClear,
  onClose,
  onFocus,
  onInputChange,
  options = [],
  placeholder,
  shrinkLabel,
  ...props
}: AutocompleteProps<FormValues>) {
  const { getFieldHelpers, values } = useFormikContext<FormValues>();
  const [{ value }, , { setTouched, setValue }] = useField<string | number | null | undefined>(name);
  const [autoSelected, setAutoSelected] = useState(false);
  const _className = useMemo(() => [...(invert ? ["invert"] : []), ...(!label ? ["no-label"] : [])], [invert, label]);
  const _options = useMemo(() => {
    const opts = Array.isArray(options) ? options : options(values);
    return !opts ? [] : !allOption || opts.length <= 1 ? opts : [{ label: allOption, value: undefined } as AutocompleteOption].concat(opts);
  }, [allOption, options, values]);
  const _value = useMemo(() => _options.find((option) => option.value === value) || null, [_options, value]);
  const autoSelect = useMemo(() => autoSelectIfOneOption && _options.length === 1, [autoSelectIfOneOption, _options.length]);
  const _disableClearable = useMemo(() => disableClearable || autoSelect, [autoSelect, disableClearable]);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = useCallback(() => {
    setTouched(true);
  }, [setTouched]);

  const updateValue = useCallback(
    async (option: AutocompleteOption | null) => {
      await setValue(option?.value);
      await setTouched(true);
    },
    [setTouched, setValue]
  );

  const handleChange = useCallback(
    async (_: SyntheticEvent | undefined, option: AutocompleteOption | null, reason: AutocompleteChangeReason) => {
      await updateValue(option);
      if (onChangeClear) onChangeClear.map(async (fieldName) => await getFieldHelpers(fieldName).setValue(undefined));
      onChange?.(option?.value);
      if (reason === "clear") inputRef.current?.blur();
    },
    [getFieldHelpers, onChange, onChangeClear, updateValue]
  );

  const handleClose = useCallback((_: SyntheticEvent, reason: AutocompleteCloseReason) => onClose?.(reason), [onClose]);

  const handleInputChange = useCallback(
    (_: SyntheticEvent, value: string, reason: AutocompleteInputChangeReason) => onInputChange?.(value, reason),
    [onInputChange]
  );

  const getOptionKey = useCallback(({ label, value }: AutocompleteOption) => value || label, []);

  useEffect(() => {
    if (autoSelect && !autoSelected && _options[0]) {
      setAutoSelected(true);
      updateValue(_options[0]);
    }
  }, [_options, autoSelect, autoSelected, updateValue]);

  return (
    <MuiAutocomplete
      {...props}
      autoHighlight
      blurOnSelect
      disableClearable={_disableClearable}
      filterOptions={filterOptions}
      freeSolo={false}
      getOptionKey={getOptionKey}
      multiple={false}
      options={_options}
      onBlur={handleBlur}
      onChange={handleChange}
      onClose={handleClose}
      onFocus={onFocus}
      onInputChange={handleInputChange}
      selectOnFocus
      slotProps={{ popper: { keepMounted: true } }}
      value={_value}
      renderOption={renderOption}
      renderInput={(params) => renderInput(params, name, label, placeholder, shrinkLabel, _className, inputRef)}
    />
  );
}

function renderInput(
  params: AutocompleteRenderInputParams,
  name: string,
  label: string | undefined,
  placeholder: string | undefined,
  shrinkLabel: boolean | undefined,
  _className: string[],
  inputRef: Ref<HTMLInputElement>
) {
  return (
    <>
      <TextField
        {...params}
        InputLabelProps={{ ...params.InputLabelProps, shrink: shrinkLabel }}
        inputRef={inputRef}
        InputProps={{ ...params.InputProps, className: className(params.InputProps.className, ..._className), placeholder }}
        label={label}
        sx={label ? { "& .MuiInputBase-root": { alignItems: "flex-end" } } : undefined}
      />
      <ErrorMessage name={name} />
    </>
  );
}

function renderOption(params: HTMLAttributes<HTMLLIElement> & { key: unknown }, option: AutocompleteOption) {
  return (
    <li {...params} key={option.value || option.label}>
      {option.label}
    </li>
  );
}
