import { Quarter, quarterToDates } from "../quarter";
import { formatDate } from "./formatDate";

const formatQuarter = (value?: Quarter) => {
  if (!value) {
    return undefined;
  }

  const dates = quarterToDates(value);

  return dates ? `${formatDate(dates.startDate)} - ${formatDate(dates.endDate)}` : undefined;
};

export { formatQuarter };
