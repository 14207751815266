import { parsePhoneNumber } from "libphonenumber-js";

interface FormatPhone {
  type: "phone";
}

const formatPhone = (value: unknown) => {
  if (!value) return undefined;

  const parsedNumber = parsePhoneNumber(value.toString(), "NZ");
  return parsedNumber.format(parsedNumber.country === "NZ" ? "NATIONAL" : "INTERNATIONAL");
};

export type { FormatPhone };
export { formatPhone };
