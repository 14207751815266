import { Fragment } from "react";

export function AddressDetail(values?: { street1?: string; street2?: string; suburb?: string; city?: string; postcode?: string }) {
  return (
    <>
      {[values?.street1, values?.street2, values?.suburb, [values?.city, values?.postcode].filter(Boolean).join(" ")].map((value) =>
        (typeof value === "string" ? value.trim() : value !== undefined) ? (
          <Fragment key={value}>
            {value}
            <br />
          </Fragment>
        ) : undefined
      )}
    </>
  );
}
