import { FilledTextFieldProps, TextField as MuiTextField } from "@mui/material";
import { ChangeEvent, useCallback, useMemo } from "react";
import { NumberFormatValues, NumericFormat } from "react-number-format";

interface BaseNumberTextFieldProps {
  decimalScale?: number;
  max?: number;
  min?: number;
  maxRows?: never;
  minRows?: never;
  multiline?: never;
  number: true;
  onChange?: (value: number | undefined) => void;
  value?: number;
}

interface BaseTextFieldTextProps {
  decimalScale?: never;
  max?: never;
  min?: never;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  number?: false | never;
  onChange?: (value: string) => void;
  value?: string;
}

type NumberAndTextConditionalProps = BaseTextFieldTextProps | BaseNumberTextFieldProps;

type BaseTextFieldCommonProps = Omit<
  FilledTextFieldProps,
  "defaultValue" | "maxRows" | "minRows" | "multiline" | "name" | "onChange" | "type" | "value" | "variant"
> & {
  shrinkLabel?: boolean;
  uncontrolled?: boolean;
};

type BaseTextFieldProps = BaseTextFieldCommonProps & NumberAndTextConditionalProps;
type NumberTextFieldProps = BaseTextFieldCommonProps & BaseNumberTextFieldProps;
type TextFieldProps = BaseTextFieldCommonProps & BaseTextFieldTextProps;

function clamp(value?: number, min?: number, max?: number) {
  if (value === undefined) return undefined;

  if (min !== undefined) value = Math.max(value, min);
  if (max !== undefined) value = Math.min(value, max);

  return value;
}

function BaseTextField({ InputProps, invert, ...props }: BaseTextFieldProps) {
  const _InputProps = useMemo(
    () => ({ ...InputProps, className: [InputProps?.className, invert ? "invert" : "", props.label ? "" : "no-label"].filter((c) => !!c).join(" ") }),
    [InputProps, invert, props.label]
  );

  if (props.number) return <NumberTextField {...props} InputProps={_InputProps} />;
  else return <TextField {...props} InputProps={_InputProps} />;
}

function NumberTextField({
  max,
  min,
  onChange,
  ref,
  shrinkLabel,
  uncontrolled,
  value,
  ...props
}: Omit<NumberTextFieldProps, "invert" | "multiline" | "number">) {
  const valueProps = useMemo(() => (uncontrolled ? { defaultValue: value } : { value: value || "" }), [uncontrolled, value]);

  const handleValueChange = useCallback(
    ({ floatValue }: NumberFormatValues) => {
      onChange && floatValue !== value && onChange(clamp(floatValue, min, max));
    },
    [onChange, max, min, value]
  );

  return (
    <NumericFormat
      {...props}
      {...valueProps}
      customInput={MuiTextField}
      fixedDecimalScale
      getInputRef={ref}
      InputLabelProps={{ shrink: shrinkLabel }}
      InputProps={{ ...props.InputProps, type: "text" }}
      inputProps={{ ...props.inputProps, style: { textAlign: "right" } }}
      onValueChange={handleValueChange}
      thousandSeparator
    />
  );
}

function TextField({ label, onChange, shrinkLabel, uncontrolled, value, ...props }: Omit<TextFieldProps, "invert">) {
  const valueProps = useMemo(() => (uncontrolled ? { defaultValue: value } : { value }), [uncontrolled, value]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onChange?.(event.target.value || "");
    },
    [onChange]
  );

  return <MuiTextField {...props} {...valueProps} InputLabelProps={{ shrink: shrinkLabel }} label={label} onChange={handleChange} />;
}

export { BaseTextField };
export type { BaseTextFieldProps, NumberTextFieldProps, TextFieldProps };
