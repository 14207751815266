import { Grid } from "@mui/material";
import { addYears, differenceInMonths, format, isThisYear, startOfYear } from "date-fns";
import { enNZ } from "date-fns/locale";
import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TimeSpan } from "../../api";
import { CURRENCY_ACCOUNTING } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountBalancesAsync, accountPerformanceAsync } from "../../store/accounts";
import { formatValue, formatWeight, getRebalanceUrl, parseDate, userHasEitherRole } from "../../utils";
import { AddressDetail, Button, Details, Panel, Skeleton, SkeletonWrapper, Typography } from "../common";
import ClientsDetail from "./ClientsDetail";
import Holdings from "./Holdings";
import LegalEntitiesDetail from "./LegalEntitiesDetail";
import PerformanceGraph from "./PerformanceGraph";

function eligibleToWithdraw(dateOfBirth: string | undefined, enrolmentDate: string | undefined) {
  if (!dateOfBirth || !enrolmentDate) return undefined;

  const ageBased = addYears(new Date(dateOfBirth), 65);
  const enrolmentBased = addYears(new Date(enrolmentDate), 5);

  return enrolmentBased > ageBased ? enrolmentBased : ageBased;
}

function getDateRange(from: Date | undefined, to: Date) {
  const startDate = from || to;
  const currentDate = new Date(startDate);
  const dates: Date[] = [];
  const today = new Date();

  while (currentDate.getFullYear() <= to.getFullYear()) {
    const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31);

    if (lastDayOfYear.getTime() > today.getTime()) {
      if (to.toDateString() === today.toDateString()) {
        dates.push(today);
      }
      break;
    } else {
      dates.push(lastDayOfYear);
    }

    currentDate.setFullYear(currentDate.getFullYear() + 1);
  }

  return dates;
}

export default function Detail() {
  const { key } = useParams<{ key: string }>();
  const { user } = useAppSelector((as) => as.oidc);
  const { accountBalance, account, isLoadingDetails, isLoadingBalances, isLoadingPerformance, performances } = useAppSelector((as) => as.accounts);
  const dispatch = useAppDispatch();
  const aspEnrolledBeforeApril2012 = useMemo(
    () => account?.product === "Active Series" && !!account?.enrolled && new Date(account.enrolled) < new Date(2012, 3, 1),
    [account]
  );
  const primaryClient = useMemo(() => account?.clients.find((c) => c.roles.some((r) => r === "Primary")), [account?.clients]);
  const taxPayerClient = useMemo(
    () => account?.clients.find((c) => c.roles.some((r) => r === "TaxPayer")) ?? account?.clients.find((c) => c.irdNumber == account.irdNumber),
    [account]
  );
  const performanceGraph = useMemo(
    () =>
      performances
        ?.filter((p) => p.dateRange.timeSpan === TimeSpan.YearToDate)
        .map(({ balance, cumulativePerformance, dateRange }) => {
          const to = dateRange?.to ? new Date(dateRange?.to) : new Date();

          return {
            gain: cumulativePerformance || 0,
            value: balance || 0,
            year: to.getFullYear(),
            yearLabel: to.getFullYear() + (isThisYear(to) ? " YTD" : ""),
          };
        }),
    [performances]
  );
  const performanceData = useMemo(() => {
    if (!account) return undefined;

    const fiveYearsAgo = addYears(startOfYear(new Date()), -4);
    const startDate = account.enrolled && new Date(account.enrolled) >= fiveYearsAgo ? new Date(account.enrolled) : fiveYearsAgo;
    const monthsActive = differenceInMonths(new Date(), startDate);
    const result = performances?.flatMap(({ cumulativePerformance, annualisedPerformance, dateRange }) => {
      switch (dateRange.timeSpan) {
        case TimeSpan.SinceInception:
          return [
            {
              gain: (annualisedPerformance ?? 0) as number | undefined,
              isVisibleInDw: !!monthsActive && monthsActive >= 12 && monthsActive <= 36,
              timeSpan: TimeSpan.SinceInception,
              label: "Since inception (Annualised)",
            },
            {
              gain: (cumulativePerformance ?? 0) as number | undefined,
              isVisibleInDw: !!monthsActive && monthsActive >= 36,
              timeSpan: TimeSpan.SinceInception,
              label: "Since inception (Cumulative)",
            },
          ];
        case TimeSpan.SixMonthsAgo:
          return {
            gain: (cumulativePerformance ?? 0) as number | undefined,
            isVisibleInDw: false,
            timeSpan: dateRange.timeSpan,
            label: "6 months",
          };
        case TimeSpan.OneYearAgo:
          return { gain: (cumulativePerformance ?? 0) as number | undefined, isVisibleInDw: false, timeSpan: dateRange.timeSpan, label: "1 year" };
        case TimeSpan.YearToDate:
          return dateRange.to && isThisYear(new Date(dateRange.to))
            ? {
                gain: (cumulativePerformance ?? 0) as number | undefined,
                isVisibleInDw: !!monthsActive && monthsActive <= 12,
                timeSpan: dateRange.timeSpan,
                label: "YTD",
              }
            : [];
        default:
          return [];
      }
    });

    monthsActive <= 6 &&
      result.push({
        timeSpan: TimeSpan.SixMonthsAgo,
        isVisibleInDw: false,
        gain: undefined,
        label: "6 months",
      });
    monthsActive <= 12 &&
      result.push({
        timeSpan: TimeSpan.OneYearAgo,
        isVisibleInDw: false,
        gain: undefined,
        label: "1 year",
      });

    return result;
  }, [account, performances]);

  useEffect(() => {
    if (account?.accountKey != key) return;

    const fiveYearsAgo = addYears(startOfYear(new Date()), -4);
    const startDate = account.enrolled && new Date(account.enrolled) >= fiveYearsAgo ? new Date(account.enrolled) : fiveYearsAgo;
    const performanceDateRange = getDateRange(startDate, new Date());
    const today = new Date();
    const formatted = format(today, "yyyy-MM-dd", { locale: enNZ });
    const dateRanges = [
      ...performanceDateRange.map((date) => ({
        timeSpan: TimeSpan.YearToDate,
        to: format(date, "yyyy-MM-dd", { locale: enNZ }),
      })),
      { timeSpan: TimeSpan.SinceInception, to: formatted },
    ];

    differenceInMonths(today, startDate) >= 6 && dateRanges.push({ timeSpan: TimeSpan.SixMonthsAgo, to: formatted });
    differenceInMonths(today, startDate) >= 12 && dateRanges.push({ timeSpan: TimeSpan.OneYearAgo, to: formatted });

    const promise = dispatch(accountPerformanceAsync({ accountNumber: account.accountKey, dateRanges }));

    return () => promise.abort();
  }, [account, key, dispatch]);

  useEffect(() => {
    const promise = dispatch(accountBalancesAsync({ accountNumber: key }));
    return () => promise?.abort();
  }, [key, dispatch]);

  return (
    <>
      <Panel>
        {!!account && (
          <Details
            heading="Key account details"
            initialValues={account}
            loading={isLoadingDetails}
            sections={[
              {
                fields: [
                  { label: "Client name/s", field: (values) => <ClientsDetail {...values} /> },
                  { label: "Email", field: "email" },
                  { label: "Mobile", field: () => primaryClient?.mobileNumber, type: "phone" },
                  {
                    label: "Legal entities",
                    field: (values) => <LegalEntitiesDetail {...values} />,
                    visible: (values) => !!values.legalEntities?.length,
                  },
                ],
              },
              {
                fields: [
                  { label: "Enrolment date", field: "enrolled", type: "date" },
                  { label: "Exit date", field: "exited", type: "date" },
                  { label: "Account state", field: (values) => (values.state === "Active" ? "Open" : values.state) },
                  { label: "Account type", field: "accountType" },
                  {
                    label: "Categorisation",
                    field: (values) => values.qropsCategorisation ?? "Unspecified",
                    visible: (d) => ["UK Pension Transfer"].includes(d.product || ""),
                  },
                  {
                    label: "Address",
                    visible: (values) => values.state === "Active",
                    field: (values) => <AddressDetail {...values} />,
                  },
                  {
                    label: "Phone",
                    visible: (values) => values.state === "Active",
                    field: () => primaryClient?.mobileNumber,
                    type: "phone",
                  },
                  {
                    label: "Investment choice",
                    field: "investmentOption",
                    visible: (d) => ["KiwiSaver", "Wealth Builder", "Managed Superannuation Service"].includes(d.product || ""),
                  },
                  {
                    label: "Distribution option",
                    field: "distributionOption",
                    visible: (d) => d.product === "Income Generator",
                  },
                  {
                    label: "",
                    field: () => "",
                    visible: (d) =>
                      ["KiwiSaver", "Wealth Builder", "Managed Superannuation Service", "Income Generator"].includes(d.product || "") ? false : "gap",
                  },
                  {
                    label: "Date of birth",
                    field: (values) => values.clients.find((c) => c.roles.some((r) => r === "Primary"))?.dateOfBirth,
                    type: "date",
                  },
                  {
                    label: "Age",
                    field: (values) => values.clients.find((c) => c.roles.some((r) => r === "Primary"))?.dateOfBirth,
                    type: "date",
                    typeOption: { outputStyle: "duration" },
                  },
                ],
              },
              {
                fields: [
                  {
                    label: "IRD number",
                    visible: (values) => values.state === "Active",
                    field: (v) => v.irdNumber ?? taxPayerClient?.irdNumber ?? "",
                    type: "special",
                    typeOption: "irdNumber",
                  },
                  {
                    label: "PIE tax rate",
                    visible: (values) => values.state === "Active",
                    field: (v) => v.pieTaxRate ?? taxPayerClient?.pieTaxRate,
                    type: "percent",
                  },
                  {
                    label: "PIE tax payer",
                    visible: (values) => values.state === "Active",
                    field: () => taxPayerClient?.name,
                  },
                  {
                    label: "Bank account name",
                    visible: (values) => values.state === "Active" && values.product !== "KiwiSaver",
                    field: "bankAccountName",
                  },
                  {
                    label: "Bank account number",
                    visible: (values) => values.state === "Active" && values.product !== "KiwiSaver",
                    field: "bankAccountNumber",
                  },
                  {
                    label: "",
                    field: () => "",
                    visible: (values) => (values.state === "Active" && values.product !== "KiwiSaver" ? "gap" : false),
                  },
                  { label: "First transaction", field: "firstTransaction", type: "date" },
                  {
                    label: "DIMS activation date",
                    field: "dimsActivated",
                    type: "date",
                    visible: (d) => ["Active Series", "UK Pension Transfer"].includes(d.product) && !!d.dimsActivated,
                  },
                  { label: "Enrolment date", field: "enrolled", type: "date" },
                  {
                    label: "Enrolment duration",
                    field: "enrolled",
                    type: "date",
                    typeOption: { outputStyle: "duration" },
                  },
                ],
              },
              {
                heading: "KiwiSaver details",
                visible: (values) => values.product === "KiwiSaver",
                fields: [
                  { label: "KiwiSaver Enrolment date", field: "ksEnrolled", type: "date" },
                  {
                    label: "KiwiSaver Enrolment duration",
                    field: "ksEnrolled",
                    type: "date",
                    typeOption: { outputStyle: "duration" },
                  },
                  {
                    label: "Eligible to withdraw",
                    field: () => eligibleToWithdraw(primaryClient?.dateOfBirth, account?.ksEnrolled),
                    type: "date",
                  },
                  {
                    label: "Govt contribution shortfall",
                    helpText: (
                      <>
                        Amount required during the current contribution year (1 July to 30 June), to receive the full Govt contribution you are entitled to.
                        <br />
                        <br />
                        Takes in to account direct debits due between now and the end of the contribution year. This figure is indicative only. Where a member
                        has turned 18 or 65, or transferred during this contribution year, please contact Link Market Services{" "}
                        <a href="mailto:NZFKiwi@linkmarketservices.com" style={{ textDecoration: "underline" }}>
                          NZFKiwi@linkmarketservices.com
                        </a>{" "}
                        for full breakdown.
                      </>
                    ),
                    field: "ksContributionShortfall",
                    type: "money",
                  },
                ],
              },
            ]}
          />
        )}
      </Panel>
      <Holdings
        accountNumber={key}
        group={account?.product === "Active Series"}
        headerLeft={
          account?.state === "Active" &&
          account?.accountKey &&
          account?.product === "Active Series" &&
          userHasEitherRole(user, ["ASP", "ASP Support"]) && (
            <a
              href={getRebalanceUrl(account.accountKey, parseDate(account.clients.find((c) => c.roles.some((r) => r === "Primary"))?.dateOfBirth))}
              target="_blank"
              rel="noreferrer"
            >
              <Button>Rebalance</Button>
            </a>
          )
        }
      />
      <Panel noBorderTop>
        <Grid container sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 1 }}>
          <Grid item xs={6}>
            <Typography variant="section-heading">
              Performance<Typography variant="superscript">2</Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginTop={2}>
          <Grid item xs={10} pr={5}>
            {!isLoadingBalances && !!performanceGraph.length ? <PerformanceGraph data={performanceGraph} /> : <Skeleton height="322px" width="100%" />}
          </Grid>
          <Grid item xs={2}>
            <Grid container pl={3} pt={2}>
              {performanceData ? (
                performanceData.map(({ label, timeSpan, isVisibleInDw, gain }) => (
                  <Grid item md={12} lg={timeSpan === TimeSpan.SinceInception || timeSpan === TimeSpan.YearToDate ? 12 : 6} key={label} pb={2.5}>
                    <Typography variant="label">
                      {label}
                      {isVisibleInDw && <Typography variant="superscript">3</Typography>}
                    </Typography>
                    <Typography variant={timeSpan === TimeSpan.SinceInception || timeSpan === TimeSpan.YearToDate ? "page-heading" : "value"}>
                      {formatWeight(gain !== undefined ? gain / 100 : gain)}
                    </Typography>
                  </Grid>
                ))
              ) : (
                <>
                  <Grid item md={12} lg={12} pb={1}>
                    <Typography variant="label">
                      <Skeleton />
                    </Typography>
                    <Typography variant="page-heading">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item md={12} lg={12} pb={1}>
                    <Typography variant="label">
                      <Skeleton />
                    </Typography>
                    <Typography variant="page-heading">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item md={12} lg={12} pb={1}>
                    <Typography variant="label">
                      <Skeleton />
                    </Typography>
                    <Typography variant="page-heading">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item md={12} lg={6} pb={1} pr={1}>
                    <Typography variant="label">
                      <Skeleton />
                    </Typography>
                    <Typography variant="value">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                  <Grid item md={12} lg={6} pb={1} pl={1}>
                    <Typography variant="label">
                      <Skeleton />
                    </Typography>
                    <Typography variant="value">
                      <Skeleton width="50%" />
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1 }}>
          <Grid item xs={12} sx={{ pt: 5, pb: 1 }}>
            <Typography variant="section-heading">
              Since inception<Typography variant="superscript">4</Typography>
            </Typography>
          </Grid>
        </Grid>
        <Grid container marginTop={2} marginBottom={4}>
          <Grid item xs={4}>
            <Grid container rowSpacing={2} justifyContent="space-between">
              {aspEnrolledBeforeApril2012 && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="label">Opening balance at 01 April 2012</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="value">
                      {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.openingBalance || 0)}`}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Typography variant="label">Net {account?.product === "KiwiSaver" ? "contributions" : "invested"}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography variant="value">{isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.netInvested || 0)}`}</Typography>
              </Grid>
              {account?.product === "KiwiSaver" && (
                <>
                  <Grid item xs={6} pl={2}>
                    <Typography variant="label">Employee contributions</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="label">
                      {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.ksEmployee || 0)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pl={2}>
                    <Typography variant="label">Employer contributions</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="label">
                      {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.ksEmployer || 0)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pl={2}>
                    <Typography variant="label">Government contributions</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="label">
                      {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.ksGovernment || 0)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} pl={2}>
                    <Typography variant="label">Administration fees</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="label">
                      {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.administrationFee || 0)}`}
                    </Typography>
                  </Grid>
                  {!!accountBalance?.adviserFee && (
                    <>
                      <Grid item xs={6} pl={2}>
                        <Typography variant="label">Advisory fees</Typography>
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Typography variant="label">
                          {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.adviserFee || 0)}`}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Grid item xs={6}>
                <Typography variant="label">PIE tax (payments)/rebates</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography variant="value">{isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.pieTax || 0)}`}</Typography>
              </Grid>
              {!!accountBalance?.managementFeeRebate && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="label">Management fee rebates</Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <Typography variant="value">
                      {isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance.managementFeeRebate || 0)}`}
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <Typography variant="label">Portfolio gain/(loss)</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography variant="value">{isLoadingBalances ? <Skeleton /> : `${CURRENCY_ACCOUNTING.format(accountBalance?.gainLoss || 0)}`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 1 }}>
            <Typography variant="section-heading">Footnotes</Typography>
          </Grid>
          <Grid item xs={12} sx={{ pt: 1.5 }}>
            <Typography variant="footnote">
              <Grid container spacing={1}>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">1</Typography>Weekend and public holidays will use the latest unit price available.
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">2</Typography>The performance shown here relates to this investment. All returns are calculated using the
                  “Internal Rate of Return” method which takes into account the timing and value of every transaction. All returns are shown after portfolio
                  fees and expenses but before investor tax and any advisory fees. Returns are for each reporting period. The reporting period covered by the
                  first and last reporting period may be less than 12 months. Performance figures are not projections or forecasts. Past returns are no
                  guarantee of future performance.
                  <ul style={{ marginBottom: 0, marginTop: 6 }}>
                    <li>
                      Year to date returns are for the current calendar year up to the latest pricing date,{" "}
                      <SkeletonWrapper approxChars={13} loading={isLoadingPerformance}>
                        {formatValue(account?.balanceDate ?? new Date(), { type: "date", typeOption: { outputStyle: "long" } })}
                      </SkeletonWrapper>
                      .
                    </li>
                    <li>
                      6 month and 1 year returns are rolling returns for the relevant time period to the latest pricing date,{" "}
                      <SkeletonWrapper approxChars={13} loading={isLoadingPerformance}>
                        {formatValue(account?.balanceDate ?? new Date(), { type: "date", typeOption: { outputStyle: "long" } })}
                      </SkeletonWrapper>
                      .
                    </li>
                    <li>
                      Annualised return is the average amount earned by their investment each year since the date they first invested. It assumes the annual
                      rate is compounded each year.
                    </li>
                    <li>
                      Cumulative return is the total return from their investment since the date they first invested. It is the total gain or loss on the
                      investment throughout this time.
                    </li>
                    <li>For Active Series accounts that were opened before 01&nbsp;April&nbsp;2012, the return included is from 01&nbsp;April&nbsp;2012.</li>
                  </ul>
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">3</Typography>This is visible to the client in Digital Wallet.
                </Grid>
                <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                  <Typography variant="superscript">4</Typography>Since inception:
                  <ul style={{ marginBottom: 0, marginTop: 6 }}>
                    <li>
                      All contributions and withdrawals from their account
                      {account?.product === "KiwiSaver" ? " (we break these down by contribution type)" : ", including adviser fees"}.
                    </li>
                    <li>All PIE tax payments and rebates.</li>
                    {!!accountBalance?.managementFeeRebate && <li>Total Management Fee Rebates received.</li>}
                    <li>How much they have made on their portfolio.</li>
                    {account?.product !== "KiwiSaver" && (
                      <li>
                        For Active Series accounts that were opened before 01&nbsp;April&nbsp;2012, the transactions included are from 01&nbsp;April&nbsp;2012.
                      </li>
                    )}
                  </ul>
                </Grid>
              </Grid>
            </Typography>
          </Grid>
        </Grid>
      </Panel>
    </>
  );
}
