import { Box, CircularProgress as MuiCircularProgress, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";

interface CircularProgressProps {
  color?: string;
  small?: boolean;
  sx?: SxProps<Theme>;
}

export function CircularProgress({ color, small, sx = [] }: CircularProgressProps) {
  const size = useMemo(() => (small ? 24 : 48), [small]);

  return (
    <Box className="spinner" sx={[{ display: "flex", justifyContent: "center", position: "relative" }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box className="spinner-indicator" sx={{ height: size, position: "relative", width: size }}>
        <MuiCircularProgress
          color="secondary"
          size={size}
          sx={{ animationDuration: "600ms", color, left: 0, position: "absolute", top: 0 }}
          thickness={small ? 5.7 : 4.5}
          variant="indeterminate"
        />
      </Box>
    </Box>
  );
}
