import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Grid, useTheme as useMuiTheme } from "@mui/material";
import { format } from "date-fns";
import { enNZ } from "date-fns/locale";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { TimeSpan } from "../../api";
import { useAppDispatch } from "../../store";
import { accountDetailsAsync, accountHoldingsAtAsync } from "../../store/accounts";
import { securitiesAsync, securityGroupsAsync } from "../../store/securities";
import { PositionGroupFilter, SecuritiesDto } from "../../types";
import { toPositionGroupType } from "../../utils";
import { Button, Panel, SearchIcon, Typography } from "../common";
import { Form, FormButton, TextField } from "../form";
import { AllSecurities } from "./AllSecurities";
import { AnalyticsDetailsPopup } from "./AnalyticsDetailsPopup";
import { SecurityGroups } from "./SecurityGroups";
import Strategies from "./Strategies";

const PAGE_SIZE = 55;
const TIME_SPANS = [TimeSpan.SixMonthsAgo, TimeSpan.OneYearAgo, TimeSpan.YearToDate];
const DEFAULT_VALUES: SecuritiesDto = {
  currentPage: 0,
  pageSize: PAGE_SIZE,
  positionGroupType: PositionGroupFilter.Strategies,
  searchName: "",
};

function Filters({ positionGroupFilter }: { positionGroupFilter: PositionGroupFilter }) {
  const [openDetails, setOpenDetails] = useState(false);
  const { palette } = useMuiTheme();
  const headerName = useMemo(() => {
    switch (positionGroupFilter) {
      case PositionGroupFilter.Strategies:
        return "Strategies";
      case PositionGroupFilter.AssetClass:
        return "Asset Classes";
      case PositionGroupFilter.Manager:
        return "Managers";
      case PositionGroupFilter.Region:
        return "Regions";
      default:
        return "Securities";
    }
  }, [positionGroupFilter]);

  return (
    <Panel smallPadding>
      <Grid container>
        <Grid item container xs={12} justifyContent="end">
          {positionGroupFilter === PositionGroupFilter.Securities ? (
            <FormButton type="reset" variant="text">
              Clear filters
            </FormButton>
          ) : (
            <>&nbsp;</>
          )}
        </Grid>
        <Grid
          item
          xs={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={[
            {
              ...(positionGroupFilter !== PositionGroupFilter.Securities && {
                paddingTop: 1,
                paddingBottom: 1,
              }),
            },
          ]}
        >
          <Typography variant="page-subheading">
            <span style={{ color: palette.common.black }}>{headerName}</span>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          &nbsp;
        </Grid>
        <Grid item xs={2} textAlign="right">
          {positionGroupFilter === PositionGroupFilter.Securities ? (
            <TextField InputProps={{ endAdornment: <SearchIcon /> }} name="searchName" placeholder="Search" />
          ) : (
            <>&nbsp;</>
          )}
        </Grid>
        <Grid item xs={12} pb={1}>
          <Button variant="text" onClick={() => setOpenDetails(true)} sx={{ ml: -2 }}>
            <InfoIcon fontSize="small" style={{ paddingRight: 5, color: palette.primary.main }} />
            Understanding the numbers in this table
          </Button>
        </Grid>
      </Grid>
      <AnalyticsDetailsPopup positionGroupFilter={positionGroupFilter} open={openDetails} onCloseModal={() => setOpenDetails(false)} />
    </Panel>
  );
}

export default function Analytics() {
  const dispatch = useAppDispatch();
  const { key: accountNumber } = useParams<{ key: string }>();
  const location = useLocation();
  const today = useMemo(() => format(new Date(), "yyyy-MM-dd", { locale: enNZ }), []);
  const dateRanges = useMemo(() => TIME_SPANS.map((timeSpan) => ({ timeSpan, to: today })), [today]);
  const positionGroupFilter = useMemo(() => {
    const pathname = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);

    switch (pathname) {
      case "strategies":
        return PositionGroupFilter.Strategies;
      case "asset-classes":
        return PositionGroupFilter.AssetClass;
      case "managers":
        return PositionGroupFilter.Manager;
      case "regions":
        return PositionGroupFilter.Region;
      default:
        return PositionGroupFilter.Securities;
    }
  }, [location.pathname]);

  useEffect(() => {
    const promises = [dispatch(accountDetailsAsync(accountNumber)), dispatch(accountHoldingsAtAsync({ accountNumber, dateRanges }))];
    return () => promises.forEach((promise) => promise.abort());
  }, [accountNumber, dateRanges, dispatch]);

  useEffect(() => {
    const promises = [
      ...(positionGroupFilter === PositionGroupFilter.Strategies ? [dispatch(accountHoldingsAtAsync({ accountNumber, dateRanges }))] : []),
      ...(positionGroupFilter === PositionGroupFilter.Securities ? [dispatch(securitiesAsync({ accountNumber, currentPage: 0, pageSize: PAGE_SIZE }))] : []),
      ...(positionGroupFilter !== PositionGroupFilter.Strategies && positionGroupFilter !== PositionGroupFilter.Securities
        ? [dispatch(securityGroupsAsync({ accountNumber, currentPage: 0, pageSize: PAGE_SIZE, positionGroupType: toPositionGroupType(positionGroupFilter)! }))]
        : []),
    ];

    return () => promises.forEach((promise) => promise.abort());
  }, [accountNumber, dateRanges, dispatch, positionGroupFilter]);

  return (
    <Form
      initialValues={DEFAULT_VALUES}
      submitOnChange
      submitOnReset
      onSubmit={({ currentPage, pageSize, searchName }) => {
        positionGroupFilter === PositionGroupFilter.Securities && dispatch(securitiesAsync({ accountNumber, currentPage, pageSize, searchName }));
      }}
    >
      <Filters positionGroupFilter={positionGroupFilter} />
      {![PositionGroupFilter.Securities, PositionGroupFilter.Strategies].includes(positionGroupFilter) && (
        <SecurityGroups positionGroupFilter={positionGroupFilter} />
      )}
      {positionGroupFilter === PositionGroupFilter.Strategies && <Strategies />}
      {positionGroupFilter === PositionGroupFilter.Securities && <AllSecurities />}
    </Form>
  );
}
