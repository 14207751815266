import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountAgreementPresetsAsync } from "../../store/accounts";
import { Panel } from "../common";
import { AccountFeeStructure } from "./AccountFeeStructure";

function NewFeeStructure({ accountKey }: { accountKey?: string }) {
  const { agreementPresets, account, isLoadingDetails } = useAppSelector((as) => as.accounts);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    let mounted = true;

    (async () => {
      await dispatch(accountAgreementPresetsAsync({ accountKey }));
      mounted && setLoading(false);
    })();

    return () => {
      mounted = false;
      setLoading(true);
    };
  }, [accountKey, dispatch]);

  return (
    <Panel>
      {(accountKey && (isLoadingDetails || !account)) || loading || !agreementPresets?.type ? (
        <>Loading...</>
      ) : (
        <AccountFeeStructure agreementType={agreementPresets.type} account={account} adviser={agreementPresets.adviser} presets={agreementPresets.gis} />
      )}
    </Panel>
  );
}

export { NewFeeStructure };
