import { Grid } from "@mui/material";
import { ReactNode } from "react";
import { Typography } from "./Typography";

function FormLabel(props: { labelPrefix?: string; label: ReactNode; field: ReactNode; xs?: "auto" | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 }) {
  const { labelPrefix, label, field, xs } = props;

  return (
    <Grid item container xs={xs || 12}>
      <Grid item xs={12}>
        <Typography variant="label">
          {labelPrefix} {label}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {field}
      </Grid>
    </Grid>
  );
}

export { FormLabel };
