import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { recurringTransactionsAsync } from "../../../store/reports";
import { generateLink } from "../../../utils";
import { Breadcrumb } from "../../common";
import { Form } from "../../form";
import Table from "../../table";

type RecurringTransactionsProps = Parameters<typeof recurringTransactionsAsync>[0];

function RecurringTransactions(values: RecurringTransactionsProps) {
  const dispatch = useAppDispatch();
  const { isLoading, recurringTransactions } = useAppSelector((as) => as.reports);
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();

  return !isLoading && !recurringTransactions ? null : (
    <Grid item xs={12} sx={{ backgroundColor: "common.white" }}>
      <Form initialValues={values} onSubmit={(values) => dispatch(recurringTransactionsAsync(values))} submitOnChange>
        <Table
          minHeight={490}
          to={(row) => generateLink("Business Information", `/account/${row.accountNumber}/RecurringTransactions`, history)}
          loading={isLoading}
          columns={[
            {
              heading: "Account name",
              field: "accountName",
              display: "emphasize",
              width: 22,
            },
            {
              heading: "Product",
              field: "product",
              display: "chip",
              variant: "outlined",
              width: 5,
            },
            {
              heading: "Account number",
              field: "accountNumber",
              align: "right",
              width: 10,
            },
            {
              heading: "Amount",
              field: (row) => row.holdingAllocations?.reduce((total, trans) => total + (trans.amount || 0), 0),
              type: "money",
              display: "emphasize",
              width: 6,
            },
            {
              heading: "Transaction type",
              field: "transactionTypeDisplay",
              width: 10,
            },
            {
              heading: "Frequency",
              field: "transactionFrequency",
              align: "right",
              width: 7,
            },
            {
              heading: "Balance",
              field: "balance",
              type: "money",
              width: 7,
            },
            {
              heading: "Next due date",
              field: "nextDueDate",
              type: "date",
              align: "right",
              display: "emphasize",
              width: 10,
            },
            {
              heading: "Payments left",
              field: "paymentsLeft",
              align: "right",
              width: 8,
            },
            {
              heading: "Payments run out",
              field: "paymentsRunOut",
              type: "date",
              align: "right",
              width: 15,
            },
          ]}
          rows={recurringTransactions?.results}
          paging={recurringTransactions || {}}
          rowExpand={(row, backgroundColor) => (
            <Table
              child
              rowBackgroundColor={backgroundColor}
              rows={row.holdingAllocations}
              columns={[
                {
                  heading: "Fund",
                  field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.fundName}</>,
                  width: 39,
                  display: "emphasize",
                },
                {
                  heading: "Amount",
                  field: "amount",
                  type: "money",
                  width: 6,
                  display: "emphasize",
                },
                {
                  heading: "Date",
                  field: () => <span style={{ visibility: "hidden" }}>00/00/0000</span>,
                  width: 35.29,
                },
                {
                  heading: "Payments left",
                  field: "paymentsLeft",
                  align: "right",
                  width: 8,
                },
                {
                  heading: "Payments run out",
                  field: "paymentsRunOut",
                  type: "date",
                  align: "right",
                  width: 15,
                },
              ]}
            />
          )}
        />
      </Form>
    </Grid>
  );
}

export { RecurringTransactions };
