import { TableCell, Tooltip } from "@mui/material";
import { LocationDescriptor } from "history";
import { ReactNode, useMemo } from "react";
import { getAlignment } from "../../utils";
import { Link } from "../common/Link";
import { ColumnDefinition } from "./ColumnDefinition";
import { getValue } from "./value";

interface ColumnProps<Row, Sort> {
  children?: ReactNode;
  col: ColumnDefinition<Row, Sort>;
  onRowClick?: (data: Row) => void;
  paddingBottom?: number;
  paddingTop?: number;
  row?: Row;
  setWidths?: boolean;
  to?: LocationDescriptor<unknown>;
}

function Column<Row, Sort extends string>({ children, col, onRowClick, paddingBottom, paddingTop, row, setWidths, to }: ColumnProps<Row, Sort>) {
  const value = useMemo(() => (row ? getValue({ col, row }) : children), [children, col, row]);
  const alignment = useMemo(() => getAlignment(col), [col]);

  return (
    <Tooltip
      placement={col.tooltipPlacement || "bottom"}
      PopperProps={{ modifiers: [{ name: "offset", options: { offset: [0, -20] } }] }}
      title={!col.tooltip ? "" : col.tooltip}
    >
      <TableCell
        align={to ? undefined : alignment}
        className="table-cell"
        onClick={() => (onRowClick && row ? onRowClick(row) : undefined)}
        style={to ? { padding: 0, height: 0 } : { paddingBottom, paddingTop }}
        width={setWidths ? `${col.width}%` : undefined}
      >
        {to ? (
          <Link
            to={to}
            style={{
              alignItems: "center",
              display: "flex",
              gap: 6,
              height: "100%",
              justifyContent: alignment,
              paddingBottom: paddingBottom !== undefined ? paddingBottom : 6,
              paddingLeft: 9,
              paddingRight: 9,
              paddingTop: paddingTop !== undefined ? paddingTop : 6,
            }}
          >
            {value}
          </Link>
        ) : (
          value
        )}
      </TableCell>
    </Tooltip>
  );
}

export { Column };
