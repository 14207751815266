import { Box, Grid } from "@mui/material";
import { Children, isValidElement, JSXElementConstructor, ReactElement, ReactNode, useState } from "react";
import { Panel } from "../common";
import { BaseAutocomplete, Form } from "../form";
import { ReportProps } from "./Report";
import ReportField from "./ReportField";

interface ReportsProps {
  children: ReactNode;
  downloading?: boolean;
  exporting?: boolean;
  generating?: boolean;
  viewing?: boolean;
}

export default function ReportList({ children }: ReportsProps) {
  const [report, setReport] = useState<number>();

  let selectedReport: ReactElement<ReportProps<unknown>, string | JSXElementConstructor<unknown>> | undefined;
  let selectedReportIndex: number | undefined = undefined;

  const reports: { index: number; name: string }[] = [];
  let first = true;

  Children.forEach(children, (child, index) => {
    if (!isValidElement<ReportProps<unknown>>(child)) return;

    if (report === undefined ? first : index === report) {
      first = false;
      selectedReport = child;
      selectedReportIndex = index;
    }

    reports.push({ index, name: child.props.name });
  });

  return (
    <Box sx={{ backgroundColor: "background.paper", display: "flex", flexDirection: "column" }}>
      <Panel joinPanelBelow>
        <ReportField label="Choose report">
          <BaseAutocomplete
            onChange={(value) => setReport(value as number)}
            options={reports.map(({ index, name }) => ({ value: index, label: name }))}
            value={selectedReportIndex}
          />
        </ReportField>
      </Panel>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {!!selectedReport && (
          <Form disableReinitialize initialValues={selectedReport?.props.initialValues || {}} key={report} style={{ height: "100%" }}>
            <Grid container>
              {selectedReport}
              {selectedReport?.props.children}
            </Grid>
          </Form>
        )}
      </Box>
    </Box>
  );
}
