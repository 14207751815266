const BLACK: {
  /** Lightest shade. */
  0: "#FFFFFF";
  /** NZ Funds brand white. */
  10: "#FCFCFC";
  20: "#F4F4F4";
  30: "#DFDFDF";
  40: "#C2C2C2";
  50: "#959595";
  60: "#797979";
  70: "#626262";
  80: "#4C4C4C";
  90: "#333333";
  /** Primary shade. */
  100: "#232323";
  110: "#171717";
  /** Darkest shade. */
  120: "#0D0D0D";
} = {
  0: "#FFFFFF",
  10: "#FCFCFC",
  20: "#F4F4F4",
  30: "#DFDFDF",
  40: "#C2C2C2",
  50: "#959595",
  60: "#797979",
  70: "#626262",
  80: "#4C4C4C",
  90: "#333333",
  100: "#232323",
  110: "#171717",
  120: "#0D0D0D",
};

const BLUE: {
  /** Lightest shade. */
  10: "#E5EEFE";
  20: "#CCDDFD";
  30: "#B2CBFB";
  40: "#99BAFA";
  50: "#80A9F9";
  60: "#6698F8";
  70: "#4C87F7";
  80: "#3375F5";
  90: "#1964F4";
  /** Primary shade. */
  100: "#0053F3";
  110: "#0047CE";
  /** Darkest shade. */
  120: "#00369D";
} = {
  10: "#E5EEFE",
  20: "#CCDDFD",
  30: "#B2CBFB",
  40: "#99BAFA",
  50: "#80A9F9",
  60: "#6698F8",
  70: "#4C87F7",
  80: "#3375F5",
  90: "#1964F4",
  100: "#0053F3",
  110: "#0047CE",
  120: "#00369D",
};

const GREEN: {
  /** Primary shade. */
  100: "#20EC72";
  /** Darkest shade. */
  120: "#34C759";
} = {
  100: "#20EC72",
  120: "#34C759",
};

const RED: {
  /** Lightest shade. */
  10: "#FFF4F4";
  /** Primary shade. */
  100: "#FF5454";
  /** Darkest shade. */
  120: "#FF3B30";
} = {
  10: "#FFF4F4",
  100: "#FF5454",
  120: "#FF3B30",
};

const ACCOUNT: {
  /** Lightest shade. */
  10: "#e2ebf2";
  /** Light shade. */
  50: "#94b7ce";
  /** Primary shade. */
  100: "#477B9D";
  /** Darkest shade. */
  120: "#3C6784";
} = {
  10: "#e2ebf2",
  50: "#94b7ce",
  100: "#477B9D",
  120: "#3C6784",
};

const CLIENT: {
  /** Lightest shade. */
  10: "#eee5ea";
  /** Light shade. */
  50: "#c2a0b3";
  /** Primary shade. */
  100: "#9c6684";
  /** Darkest shade. */
  120: "#84556f";
} = {
  10: "#eee5ea",
  50: "#c2a0b3",
  100: "#9c6684",
  120: "#84556f",
};

export { ACCOUNT, BLACK, BLUE, CLIENT, GREEN, RED };
