import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { SearchTransactionsSort } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { transactionsAsync } from "../../../store/reports";
import { generateLink } from "../../../utils";
import { Breadcrumb } from "../../common";
import { Form } from "../../form";
import Table from "../../table";

type AllTransactionsProps = Parameters<typeof transactionsAsync>[0];

function AllTransactions(values: AllTransactionsProps) {
  const dispatch = useAppDispatch();
  const { isLoading, transactions } = useAppSelector((as) => as.reports);
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();

  return !isLoading && !transactions ? null : (
    <Grid item xs={12} sx={{ backgroundColor: "background.paper" }}>
      <Form disableReinitialize initialValues={values} onSubmit={(values) => dispatch(transactionsAsync(values))} submitOnChange>
        <Table
          loading={isLoading}
          loadingBlankRows={15}
          minHeight={611}
          paging={{ ...values, ...(transactions || {}) }}
          rows={transactions?.results}
          to={(row) => generateLink("Business Information", `/account/${row.accountKey}/Transactions`, history)}
          columns={[
            {
              heading: "Date",
              field: "effectiveDate",
              type: "date",
              sort: SearchTransactionsSort.EffectiveDate,
              width: 10,
            },
            {
              heading: "Account name",
              field: "accountName",
              display: "emphasize",
              sort: SearchTransactionsSort.AccountName,
              width: 25,
            },
            {
              heading: "Product",
              field: "service",
              display: "chip",
              variant: "outlined",
              sort: SearchTransactionsSort.Service,
              width: 10,
            },
            {
              heading: "Account Number",
              field: "accountKey",
              align: "right",
              sort: SearchTransactionsSort.AccountKey,
              width: 15,
            },
            {
              heading: "Transaction type",
              field: "transactionType",
              sort: SearchTransactionsSort.TransType,
              width: 15,
            },
            {
              heading: "Transaction subtype",
              field: "transactionSubType",
              sort: SearchTransactionsSort.TransSubType,
              width: 15,
            },
            {
              heading: "Amount",
              field: "transAmount",
              type: "money",
              sort: SearchTransactionsSort.TransAmount,
              width: 10,
            },
          ]}
        />
      </Form>
    </Grid>
  );
}

export { AllTransactions };
