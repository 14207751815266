import { addDays, addMonths } from "date-fns";

type Quarter = { year: number; quarter: number };

const quarterRegex = /^(20[0-9][0-9])-Q([1-4])$/;

const parseQuarter = (value?: string): Quarter | undefined => {
  const result = value?.match(quarterRegex);
  return result ? { year: Number(result[1]), quarter: Number(result[2]) } : undefined;
};

const quarterToDates = (value: Quarter) => {
  const months = value.quarter * 3;
  const startDate = addMonths(new Date(value.year, 0, 1), months);
  const endDate = addDays(addMonths(startDate, 3), -1);
  return { startDate, endDate };
};

export type { Quarter };
export { parseQuarter, quarterToDates };
