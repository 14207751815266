import { Grid } from "@mui/material";

import { ManagementReportLine } from "../../../api";
import { GUTTER_SIZE } from "../../../constants";
import { useAppSelector } from "../../../store";
import { maxDate, productKeyToName } from "../../../utils";
import { Accordion } from "../../common";
import Table from "../../table";

function getTotals(funds: ManagementReportLine[], filters?: { service?: string; adviserBranch?: string; adviser?: string }) {
  const { service, adviserBranch, adviser } = { ...filters };

  let fundsFiltered = funds;

  if (service !== undefined) {
    fundsFiltered = fundsFiltered.filter((fund) => fund.service === service);
  }

  if (adviserBranch !== undefined) {
    fundsFiltered = fundsFiltered.filter((fund) => fund.adviserBranchName === adviserBranch);
  }

  if (adviser !== undefined) {
    fundsFiltered = fundsFiltered.filter((fund) => fund.adviserName === adviser);
  }

  return {
    pricingDate: maxDate(fundsFiltered, (value) => value.pricingDate),
    accounts: fundsFiltered.reduce((total, fund) => total + (fund.accounts || 0), 0),
    value: fundsFiltered.reduce((total, fund) => total + (fund.value || 0), 0),
  };
}

function getFundsUnderManagementByProduct(funds?: ManagementReportLine[], adviser?: string) {
  if (!funds) {
    return undefined;
  }

  const fundsFiltered = adviser ? funds.filter((fund) => fund.adviserName === adviser) : funds;

  const services = Array.from(new Set(fundsFiltered.map((fund) => fund.service)));

  return services.sort().map((service) => ({
    service,
    ...getTotals(fundsFiltered, { service }),
  }));
}

function getFundsUnderManagementByAdviser(funds?: ManagementReportLine[]) {
  if (!funds) {
    return undefined;
  }

  const adviserKeys = Array.from(new Set(funds.map((fund) => (fund.adviserBranchName ?? "") + "-|-" + (fund.adviserName ?? ""))));

  return adviserKeys.sort().map((adviserKey) => {
    const [adviserBranch, adviser] = adviserKey.split("-|-");

    return {
      adviserBranch,
      adviser,
      ...getTotals(funds, { adviserBranch, adviser }),
    };
  });
}

function FundsUnderManagement() {
  const { isLoading, fundsUnderManagement } = useAppSelector((as) => as.reports);

  return !isLoading && !fundsUnderManagement ? null : (
    <>
      <Grid item xs={12} sx={{ px: GUTTER_SIZE }}>
        <Accordion heading="By products" defaultExpanded>
          <Table
            loading={isLoading}
            loadingBlankRows={3}
            minHeight={290}
            rows={getFundsUnderManagementByProduct(fundsUnderManagement?.results)}
            columns={[
              {
                heading: "Product",
                field: (row) => productKeyToName(row.service),
                width: 30,
              },
              {
                align: "right",
                heading: "Pricing date",
                field: "pricingDate",
                type: "date",
                width: 20,
              },
              {
                heading: "Accounts",
                field: "accounts",
                type: "integer",
                footer: "sum",
                width: 20,
              },
              {
                heading: "Value",
                field: "value",
                type: "money",
                typeOption: { decimalPlaces: 0 },
                footer: "sum",
                width: 30,
              },
            ]}
          />
        </Accordion>
      </Grid>
      <Grid item xs={12} sx={{ px: GUTTER_SIZE, backgroundColor: "background.paper", py: 3 }}>
        <Accordion heading="By advisers" defaultExpanded>
          <Grid container>
            <Table
              loading={isLoading}
              loadingBlankRows={3}
              minHeight={622}
              rows={getFundsUnderManagementByAdviser(fundsUnderManagement?.results)}
              columns={[
                {
                  heading: "Branch",
                  field: "adviserBranch",
                  width: 40,
                },
                {
                  heading: "Adviser",
                  field: "adviser",
                  width: 30,
                },
                {
                  heading: "Accounts",
                  field: "accounts",
                  type: "integer",
                  footer: "sum",
                  width: 10,
                },
                {
                  heading: "Value",
                  field: "value",
                  type: "money",
                  typeOption: { decimalPlaces: 0 },
                  footer: "sum",
                  width: 20,
                },
              ]}
              rowExpand={(row, backgroundColor) => (
                <Table
                  child
                  rowBackgroundColor={backgroundColor}
                  rows={getFundsUnderManagementByProduct(fundsUnderManagement?.results, row.adviser)}
                  columns={[
                    {
                      heading: "Product",
                      field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{productKeyToName(row.service)}</>,
                      width: 60,
                    },
                    {
                      heading: "Accounts",
                      field: "accounts",
                      type: "integer",
                      width: 20,
                    },
                    {
                      heading: "Value",
                      field: "value",
                      type: "money",
                      typeOption: { decimalPlaces: 0 },
                      width: 20,
                    },
                  ]}
                />
              )}
            />
          </Grid>
        </Accordion>
      </Grid>
    </>
  );
}

export { FundsUnderManagement };
