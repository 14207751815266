import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, InputAdornment } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { FocusEvent, KeyboardEvent, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import { TextField } from "./TextField";

interface SearchTextFieldProps {
  label?: ReactNode;
  name: string;
}

export function SearchTextField({ label, name }: SearchTextFieldProps) {
  const { submitForm } = useFormikContext();
  const [{ value }, , { setValue }] = useField(name);
  const [prevValue, setPrevValue] = useState(value);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const _label = useMemo(() => label || "Search", [label]);

  const clearTerm = useCallback(async () => {
    if (inputRef.current) inputRef.current.value = "";
    await setValue("");
    submitForm();
    setPrevValue("");
  }, [setValue, submitForm]);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const search = useCallback(
    (value: string) => {
      if (value !== prevValue) {
        submitForm();
        setPrevValue(value);
        setFocus(false);
      }
    },
    [prevValue, submitForm]
  );

  const handleTextFieldBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      search(event.currentTarget.value);
    },
    [search]
  );

  const handleTextFieldEnterKey = useCallback(
    ({ currentTarget, key }: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => key === "Enter" && currentTarget.blur(),
    []
  );

  return (
    <TextField
      label={_label}
      name={name}
      onBlurCapture={() => setFocus(false)}
      onFocusCapture={handleFocus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      uncontrolled
      inputRef={inputRef}
      shrinkLabel
      InputProps={{
        onBlur: handleTextFieldBlur,
        onKeyDown: handleTextFieldEnterKey,
        endAdornment: (
          <InputAdornment position="end">
            {!!value && (focus || hover) && (
              <IconButton onClick={clearTerm}>
                <ClearIcon />
              </IconButton>
            )}
            <IconButton onClick={submitForm}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
