import { ButtonProps } from "../common";
import { FormButton } from "../form";

type OnExecute<Values> = (values: Values) => void | Promise<unknown>;
type ReportButtonOnClick<Values> = (action: string, values: Values, success: OnExecute<Values>) => void;

interface ReportButtonProps<Values> extends Omit<ButtonProps, "onClick"> {
  action: string;
  onClick: ReportButtonOnClick<Values>;
  onExecute: OnExecute<Values>;
}

export default function ReportButton<Values>({ action, onClick, onExecute, children, ...rest }: ReportButtonProps<Values>) {
  return (
    <FormButton {...rest} onClick={(values: Values) => onClick(action, values, onExecute)}>
      {children}
    </FormButton>
  );
}

export type { ReportButtonOnClick };
