import { Box, Grid } from "@mui/material";
import { useCallback, useMemo, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ClientAccountDto } from "../../api";
import { ACCOUNT_STATE_OPTIONS } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { detailsAsync } from "../../store/clients";
import Theme from "../../theme";
import { generateLink, getFullName } from "../../utils";
import { formatProjections } from "../../utils/portfolio";
import { AddressDetail, Breadcrumb, Details, Panel, Skeleton, ThemeIcon, Typography } from "../common";
import { AutocompleteMultiple, DateField, Form } from "../form";
import Table from "../table";
import { ColumnDefinition } from "../table/ColumnDefinition";
import { ClientComponentProps } from "./";

type FormValues = Parameters<typeof detailsAsync>[0];

const COLUMNS: ColumnDefinition<ClientAccountDto, string>[] = [
  {
    display: "emphasize",
    field: "name",
    footer: () => "Total holdings",
    heading: "Account name",
    startAdornment: <ThemeIcon />,
    width: 21,
  },
  {
    field: "advisers",
    heading: "Adviser",
    width: 14,
  },
  {
    display: "chip",
    field: "product",
    heading: "Product",
    variant: "outlined",
    width: 11,
  },
  {
    align: "right",
    field: "accountKey",
    heading: "Account number",
    width: 9,
  },
  {
    align: "right",
    field: "enrolmentDate",
    heading: "Enrolment date",
    width: 11,
    type: "date",
  },
  {
    field: "accountType",
    heading: "Type",
    width: 7,
  },
  {
    field: "state",
    heading: "Status",
    width: 6,
  },
  {
    align: "right",
    field: "balanceAsAt",
    heading: "Balance date",
    type: "date",
    width: 10,
  },
  {
    field: "balance",
    footer: "sum",
    heading: "Balance",
    type: "money",
    width: 11,
  },
];

const holdingsAsAtLabel = (
  <>
    Holdings as at<Typography variant="superscript">1</Typography>
  </>
);

export default function Detail({ details, initialDetails, isLoadingDetails }: ClientComponentProps) {
  const { key } = useParams<{ key: string }>();
  const { isLoadingProjections, projections: projectionResults, latestBalanceDate } = useAppSelector((as) => as.clients);
  const maxDate = useMemo(() => latestBalanceDate, [latestBalanceDate]);
  const initialValues = useMemo<FormValues>(() => ({ accountBalanceUpTo: maxDate, clientNumber: key, states: ["Active", "Pending"] }), [key, maxDate]);
  const dispatch = useAppDispatch();
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();
  const address = useMemo(() => details?.addresses.find((a) => a.type == "Postal") ?? details?.addresses[0] ?? {}, [details?.addresses]);
  const abortsRef = useRef<((reason?: string) => void)[]>([]);
  const projections = useMemo(() => formatProjections(projectionResults), [projectionResults]);

  const abortRequests = useCallback(() => abortsRef.current.forEach((abort) => abort()), []);

  const accountLink = useCallback(
    ({ accountKey }: ClientAccountDto) => generateLink(`${getFullName(details)} (Client)`, `/account/${accountKey}`, history),
    [details, history]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      abortRequests();
      const promise = dispatch(detailsAsync(values));
      abortsRef.current = [promise.abort];
    },
    [abortRequests, dispatch]
  );

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Panel>
            <Details
              defaultExpanded
              heading="Key client details"
              initialValues={initialDetails}
              loading={isLoadingDetails}
              values={details}
              sections={[
                {
                  fields: [
                    { label: "Email", field: "email" },
                    { label: "Mobile", field: "mobileNo", type: "phone" },
                  ],
                },
                {
                  fields: [
                    { label: "Address", field: () => <AddressDetail {...address} /> },
                    { label: "Date of birth", field: "dateOfBirth", type: "date" },
                    { label: "Age", field: "dateOfBirth", type: "date", typeOption: { outputStyle: "duration" } },
                    { label: "IRD number", field: "irdNumber", type: "special", typeOption: "irdNumber" },
                    { label: "PIE tax rate", field: "pieTaxRate", type: "percent" },
                  ],
                },
              ]}
            />
          </Panel>
          <Theme variant="account">
            <Panel alternate>
              <Grid container sx={{ alignItems: "center", gap: 3, justifyContent: "flex-end" }}>
                <Grid item sx={{ width: 210 }}>
                  <AutocompleteMultiple invert label="Accounts status" name="states" onChange={submitForm} options={ACCOUNT_STATE_OPTIONS} shrinkLabel />
                </Grid>
                <Grid item xs="auto">
                  <DateField invert label={holdingsAsAtLabel} maxDate={maxDate} name="accountBalanceUpTo" onChange={submitForm} shrinkLabel />
                </Grid>
              </Grid>
            </Panel>
            <Table columns={COLUMNS} loading={isLoadingDetails} loadingBlankRows={3} pageSizeHidden rows={details?.accounts} to={accountLink} />
          </Theme>
          <Panel noBorderTop>
            <Grid container>
              <Grid item xs={12} sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 1.5 }}>
                <Typography variant="section-heading">
                  Projections<Typography variant="superscript">2</Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ pt: 1.5, pb: 4 }}>
                <Grid container>
                  <Grid item xs={4} lg={3} pb={1} pr={1}>
                    <Typography variant="label">{isLoadingProjections ? <Skeleton width="6.25em" /> : "After 10 years"}</Typography>
                    <Typography variant="value">{isLoadingProjections ? <Skeleton width="4em" /> : projections.tenYearBalance}</Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} pb={1} pr={1}>
                    <Typography variant="label">{isLoadingProjections ? <Skeleton width="6.25em" /> : "After 20 years"}</Typography>
                    <Typography variant="value">{isLoadingProjections ? <Skeleton width="4.5em" /> : projections.twentyYearBalance}</Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} pb={1} pr={1}>
                    <Typography variant="label">{isLoadingProjections ? <Skeleton width="6.25em" /> : "After 30 years"}</Typography>
                    <Typography variant="value">{isLoadingProjections ? <Skeleton width="5em" /> : projections.thirtyYearBalance}</Typography>
                  </Grid>
                  <Grid item xs={false} lg={3} />
                  <Grid item xs={4} lg={3} pb={1} pr={1}>
                    <Typography variant="label">{isLoadingProjections ? <Skeleton width="5em" /> : "Cumulative"}</Typography>
                    <Typography variant="value">{isLoadingProjections ? <Skeleton width="3em" /> : projections.tenYearPerformance}</Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} pb={1} pr={1}>
                    <Typography variant="label">{isLoadingProjections ? <Skeleton width="5em" /> : "Cumulative"}</Typography>
                    <Typography variant="value">{isLoadingProjections ? <Skeleton width="3.5em" /> : projections.twentyYearPerformance}</Typography>
                  </Grid>
                  <Grid item xs={4} lg={3} pb={1} pr={1}>
                    <Typography variant="label">{isLoadingProjections ? <Skeleton width="5em" /> : "Cumulative"}</Typography>
                    <Typography variant="value">{isLoadingProjections ? <Skeleton width="3.5em" /> : projections.thirtyYearPerformance}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, pb: 1.5 }}>
                <Typography variant="section-heading">Footnotes</Typography>
              </Grid>
              <Grid item xs={12} sx={{ pt: 1.5 }}>
                <Typography variant="footnote">
                  <Grid container spacing={1}>
                    <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                      <Typography variant="superscript">1</Typography>Weekend and public holidays will use the latest unit price available.
                    </Grid>
                    <Grid item xs={12} fontSize={11} lineHeight={1.2}>
                      <Typography variant="superscript">2</Typography>The “Projections” represents your client’s “Total Balance*” forecasted at 10-year
                      intervals to a maximum of 30 years. The NZ Funds planning technology WealthPlan is used to calculate these forecasts. For the purposes of
                      Digital Wallet and PORT, WealthPlan assumes no investor contributions or redemptions are made. The calculations take into account the
                      Total Balance, PIE tax and management fees. If your client has received financial advice from one of our Private Wealth Advisers or
                      affiliated Advisers who use WealthPlan as part of providing financial advice, it is unlikely that the Projections will match that of the
                      WealthPlan equivalent. This is because Advisers can more uniquely customise WealthPlan for each client’s circumstances when providing
                      advice. Further details about WealthPlan are available on request by contacting{" "}
                      <a href="mailto:support@nzfunds.co.nz" style={{ textDecoration: "underline" }}>
                        support@nzfunds.co.nz
                      </a>
                      . Cumulative is the Projections increase or decrease expressed as a percentage.
                      <br />
                      *Total Balance includes all investments the Client is associated with. It includes: individual and joint investments; where the Client is
                      the Parent/Guardian of the account holder or any investment where the Client is the trustee of a Trust. It does not include investments
                      where the Client is the professional trustee or power of attorney.
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
          </Panel>
        </Box>
      )}
    </Form>
  );
}
