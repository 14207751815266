import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { generateLink, productKeyToName } from "../../../utils";
import { Breadcrumb } from "../../common";
import Table from "../../table";

function AdviserFees() {
  const { isLoading, feeHeaderTypes, adviserFees } = useAppSelector((as) => as.reports);
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();

  return !feeHeaderTypes || (!isLoading && !adviserFees) ? null : (
    <Table
      to={(row) => generateLink("Business Information", `/account/${row.accountKey}/fee-structure`, history)}
      loading={isLoading}
      loadingBlankRows={15}
      minHeight={451}
      rows={adviserFees}
      columns={[
        {
          heading: "Batch date",
          field: "runDate",
          type: "date",
          width: 8,
        },
        {
          heading: "From date",
          field: "fromDate",
          type: "date",
          width: 7,
        },
        {
          heading: "To date",
          field: "toDate",
          type: "date",
          width: 7,
        },
        {
          heading: "Account name",
          field: "accountName",
          display: "emphasize",
          width: 24,
        },
        {
          heading: "Product",
          field: (row) => productKeyToName(row.service ?? ""),
          display: "chip",
          variant: "outlined",
          width: 10,
        },
        {
          heading: "Account number",
          field: "accountKey",
          align: "right",
          width: 10,
        },
        {
          heading: "Average daily balance",
          field: "averageDailyBal",
          type: "money",
          width: 12,
        },
        {
          heading: "GST",
          field: "feeGST",
          type: "money",
          width: 6,
        },
        {
          heading: "Total fee",
          field: "feeTotal",
          type: "money",
          width: 6,
        },
      ]}
    />
  );
}

export { AdviserFees };
