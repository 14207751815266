import { setNestedObjectValues, useFormikContext } from "formik";
import { useCallback } from "react";
import { Button as BaseButton, ButtonProps } from "../common";

interface FormButtonProps<Values> extends Omit<ButtonProps, "onClick"> {
  onClick?: (values: Values) => void;
  /** Defaults to `button`. */
  type?: "button" | "reset" | "submit";
}

function FormButton<Values>({ type = "button", onClick, ...rest }: FormButtonProps<Values>) {
  const context = useFormikContext<Values>();

  const handleClick = useCallback(async () => {
    if (onClick) {
      context.setTouched(setNestedObjectValues(context.values, true), false);
      const errors = await context.validateForm();

      if (Object.keys(errors || {}).length > 0) return;

      onClick(context.values);
    }

    switch (type) {
      case "reset":
        context.resetForm();
        break;
      case "submit":
        context.submitForm();
        break;
    }
  }, [context, onClick, type]);

  return <BaseButton {...rest} onClick={handleClick} />;
}

export { FormButton };
