import { useEffect, useMemo } from "react";
import { Report, ReportButton, ReportDynamicFilter, ReportDynamicFilters, ReportList } from "../../../components/reports";
import { useAppDispatch, useAppSelector } from "../../../store";
import { associatedAdvisersAsync } from "../../../store/advisers";
import { downloadPortfolioReportBatchesAsync, exportPortfolioReportBatchesAsync, portfolioReportBatchesAsync } from "../../../store/reports";
import { filterAdvisers, filterBranches } from "../../../utils";
import { Autocomplete } from "../../form";
import { PortfolioReportBatches } from "./PortfolioReportBatches";

function Other() {
  const { isDownloading, isExporting } = useAppSelector((as) => as.reports);
  const { advisers, adviserBranches, adviserGroups } = useAppSelector((as) => as.advisers);
  const dispatch = useAppDispatch();
  const adviserGroupOptions = useMemo(() => adviserGroups?.map(({ key, name }) => ({ label: name, value: key })) || [], [adviserGroups]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  return (
    <ReportList>
      <Report
        name="Portfolio Reports"
        description="Download the most recent Portfolio Report batches. There are up to 10 clients per batch."
        initialValues={{}}
        advancedFilters={
          <ReportDynamicFilters>
            {(adviserGroups?.length ?? 0) > 1 && (
              <ReportDynamicFilter label="Company" field="adviserGroupKey">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserGroupKey"
                  allOption="All"
                  options={adviserGroupOptions}
                  onChangeClear={["adviserNumber", "adviserBranchKey"]}
                />
              </ReportDynamicFilter>
            )}
            {(adviserBranches?.length ?? 0) > 1 && (
              <ReportDynamicFilter label="Branch" field="adviserBranchKey">
                <Autocomplete
                  autoSelectIfOneOption
                  name="adviserBranchKey"
                  allOption="All"
                  options={(values: { adviserGroupKey?: string }) =>
                    filterBranches(adviserBranches, values)?.map((adviserBranch) => ({ value: adviserBranch.key, label: adviserBranch.name })) || []
                  }
                  onChangeClear={["adviserNumber"]}
                />
              </ReportDynamicFilter>
            )}
            <ReportDynamicFilter label="Adviser" field="adviserNumber">
              <Autocomplete
                autoSelectIfOneOption
                name="adviserNumber"
                allOption="All"
                options={(values: { adviserGroupKey?: string; adviserBranchKey?: string }) =>
                  filterAdvisers(advisers, values)?.map((adviser) => ({ value: adviser.number, label: `${adviser.name} (${adviser.number})` })) || []
                }
              />
            </ReportDynamicFilter>
          </ReportDynamicFilters>
        }
        view={(values) => dispatch(portfolioReportBatchesAsync(values))}
        buttons={(onClick) => (
          <>
            <ReportButton
              variant="outlined"
              action="download"
              loading={isDownloading}
              onClick={onClick}
              onExecute={(values) => dispatch(downloadPortfolioReportBatchesAsync(values))}
            >
              Download batches
            </ReportButton>
            <ReportButton
              variant="outlined"
              action="export"
              loading={isExporting}
              onClick={onClick}
              onExecute={(values) => dispatch(exportPortfolioReportBatchesAsync(values))}
            >
              Export list
            </ReportButton>
          </>
        )}
      >
        <PortfolioReportBatches />
      </Report>
    </ReportList>
  );
}

export { Other };
