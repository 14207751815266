import { createTheme, ThemeOptions } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { ACCOUNT, CLIENT } from "../colors";
import { primaryTheme, primaryThemeOptions } from "./primary";

interface CustomPalette {
  /** Lightest shade. */
  [10]: string;
  /** Light shade. */
  [50]: string;
  /** Primary shade. */
  [100]: string;
  /** Darkest shade. */
  [120]: string;
}

const accountTheme = createTheme(
  createThemeOptions({
    [10]: ACCOUNT[10],
    [50]: ACCOUNT[50],
    [100]: ACCOUNT[100],
    [120]: ACCOUNT[120],
  })
);
const clientTheme = createTheme(
  createThemeOptions({
    [10]: CLIENT[10],
    [50]: CLIENT[50],
    [100]: CLIENT[100],
    [120]: CLIENT[120],
  })
);

function createThemeOptions(customPalette: CustomPalette) {
  return deepmerge(primaryThemeOptions, {
    components: {
      MuiAutocomplete: {
        styleOverrides: {
          listbox: {
            scrollbarColor: `${customPalette[100]} ${primaryTheme.palette.background.default}`,
            "& .MuiAutocomplete-option": {
              "&.Mui-focused": {
                color: customPalette[100],
              },
              "&:hover": {
                color: customPalette[100],
              },
              "&[aria-selected='true']:hover": {
                color: customPalette[100],
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&:disabled": {
              backgroundColor: customPalette[50],
            },
          },
          outlined: {
            color: customPalette[100],
            "&:hover": {
              backgroundColor: customPalette[120],
            },
            "&:disabled": {
              backgroundColor: customPalette[50],
            },
          },
          text: {
            color: customPalette[100],
            "&:hover": {
              backgroundColor: customPalette[10],
            },
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          deleteIcon: {
            "&:hover": {
              color: customPalette[120],
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&.Mui-focused": {
              borderColor: customPalette[100],
            },
            "& .MuiIconButton-root": {
              color: customPalette[100],
              "&:hover": {
                backgroundColor: customPalette[10],
              },
            },
            "& .MuiCircularProgress-root": {
              color: customPalette[100],
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: customPalette[10],
            },
          },
        },
      },
      MuiPaginationItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              backgroundColor: customPalette[100],
              "&:hover": {
                backgroundColor: customPalette[120],
              },
            },
          },
          previousNext: {
            color: customPalette[100],
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "& .Mui-checked+.MuiSwitch-track": {
              backgroundColor: customPalette[50],
              opacity: 1,
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: customPalette[100],
          },
        },
      },
      MuiTableContainer: {
        styleOverrides: {
          root: {
            scrollbarColor: `${customPalette[100]} ${primaryTheme.palette.background.default}`,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            "&.MuiTableRow-hover:hover": {
              backgroundColor: customPalette[10],
            },
          },
          head: {
            backgroundColor: customPalette[100],
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& input::selection": {
              backgroundColor: customPalette[100],
            },
          },
        },
      },
      MuiTableSortLabel: {
        styleOverrides: {
          root: {
            height: "100%",
            width: "100%",
          },
        },
      },
    },
    palette: {
      primary: {
        light: customPalette[10],
        lighter: customPalette[50],
        main: customPalette[100],
        dark: customPalette[120],
      },
    },
  } as ThemeOptions);
}

export { accountTheme, clientTheme, primaryTheme };
