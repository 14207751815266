import { ReactNode } from "react";
import { Alignment } from "../alignment";
import { Quarter } from "../quarter";
import { displayFormat } from "./displayFormat";
import { formatDate, FormatDate } from "./formatDate";
import { FormatDecimal, formatMoney, formatNumber, formatPercent, FormatPercent } from "./formatNumber";
import { formatPhone, FormatPhone } from "./formatPhone";
import { formatQuarter } from "./formatQuarter";
import { formatSpecial, FormatSpecial } from "./formatSpecial";

interface FormatDisplayEmphasize {
  display?: "emphasize";
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  align?: Alignment;
}

interface FormatDisplayChips {
  display?: "chip";
  variant?: "outlined";
}

interface FormatDisplayNoOptions {
  display?: "check";
}

type FormatDisplayOptions = FormatDisplayEmphasize | FormatDisplayChips | FormatDisplayNoOptions;

interface FormatNoOptions {
  type?: "quarter" | "integer";
}

type FormatType = (FormatDecimal | FormatPercent | FormatDate | FormatNoOptions | FormatSpecial | FormatPhone) & FormatDisplayOptions;

type FieldType = NonNullable<FormatType["type"]>;

const formatValue = (value: unknown, options: FormatType) => {
  if (value === undefined) return undefined;

  let result;

  try {
    switch (options.type) {
      case "percent":
        result = formatPercent(value, options);
        break;
      case "integer":
        result = formatNumber(value);
        break;
      case "decimal":
        result = formatNumber(value, { decimalPlaces: 2, ...options.typeOption });
        break;
      case "date":
        result = formatDate(value as string | Date, options);
        break;
      case "quarter":
        result = formatQuarter(value as Quarter);
        break;
      case "phone":
        result = formatPhone(value);
        break;
      case "money":
        result = formatMoney(value, options.typeOption);
        break;
      case "special":
        result = formatSpecial(value, options);
        break;
      default:
        result = value;
        break;
    }
  } catch {
    console.warn(`Error formatting value as ${options.type}`, { value, options });
  }

  return displayFormat(result, options);
};

export type { FormatType, FieldType, FormatDisplayOptions };
export { formatValue };
