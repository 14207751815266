import { Grid } from "@mui/material";
import { ReactNode } from "react";
import { GUTTER_SIZE } from "../../constants";

interface PanelProps {
  children: ReactNode;
  noBorderTop?: boolean;
  smallPadding?: boolean;
  alternate?: boolean;
  joinPanelBelow?: boolean;
  joinPanelAbove?: boolean;
}

export function Panel({ children, noBorderTop, smallPadding, alternate, joinPanelBelow, joinPanelAbove }: PanelProps) {
  return (
    <Grid
      container
      sx={{
        backgroundColor: alternate ? "background.default" : "background.paper",
        borderTopColor: "divider",
        borderTopStyle: "solid",
        borderTopWidth: noBorderTop || joinPanelAbove ? 0 : 1,
        pb: smallPadding ? 1.5 : joinPanelBelow ? 0 : 3,
        pt: smallPadding ? 1.5 : joinPanelAbove ? 0 : 3,
        px: GUTTER_SIZE,
        width: "100%",
      }}
    >
      {children}
    </Grid>
  );
}
