const ProductKeyToNameValues: Record<string, string> = {
  KS: "KiwiSaver",
  IG: "Income Generator",
  WB: "Wealth Builder",
  ASP: "Active Series",
  APS: "Active Series",
  MPS: "Active Series",
  MSS: "Managed Superannuation Service",
  CMS: "Cash Management Service",
  WIP: "Wholesale Investment",
};

const productKeyToName = <T extends string | undefined>(product: T) => ((product && ProductKeyToNameValues[product as string]) || product) as T;

export { productKeyToName };
