import { Grid, IconButton } from "@mui/material";
import { useAppDispatch } from "../../store";
import { getFile } from "../../store/adviceTools";
import { DeleteIcon, DownloadIcon, EditIcon, LinkIcon } from "../common";

interface FileFunctionsProps {
  onEdit?: () => void;
  onDelete?: () => void;
  fileId?: string;
  url?: string;
}

const FileFunctions = ({ onEdit, onDelete, url, fileId }: FileFunctionsProps) => {
  const dispatch = useAppDispatch();

  return (
    <Grid container sx={{ alignItems: "center", gap: 1, justifyContent: "flex-end" }}>
      {!!onEdit && (
        <Grid item xs="auto">
          <IconButton onClick={onEdit} sx={{ my: -0.75 }}>
            <EditIcon sx={{ color: "common.brandLow" }} />
          </IconButton>
        </Grid>
      )}
      {!!onDelete && (
        <Grid item xs="auto">
          <IconButton onClick={onDelete} sx={{ my: -0.75 }}>
            <DeleteIcon sx={{ color: "common.brandLow" }} />
          </IconButton>
        </Grid>
      )}
      {!!url && (
        <Grid item xs="auto">
          <IconButton onClick={() => window.open(url, "_blank")} sx={{ my: -0.75 }}>
            <LinkIcon sx={{ color: "common.brandLow" }} />
          </IconButton>
        </Grid>
      )}
      {!!fileId && (
        <Grid item xs="auto">
          <IconButton onClick={() => dispatch(getFile({ fileId }))} sx={{ my: -0.75 }}>
            <DownloadIcon sx={{ color: "common.brandLow" }} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

export { FileFunctions };
