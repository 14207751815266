import { Box, Button as MuiButton, SxProps, Theme } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { CircularProgress } from "./";

interface ButtonProps {
  autoFocus?: boolean;
  children: ReactNode;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactNode;
  /** Defaults to `end`. */
  iconPosition?: "end" | "start";
  invert?: boolean;
  /** Shows a `<CircularProgress />` spinner. Also disables the button when `true`. */
  loading?: boolean;
  onClick?: () => void;
  sx?: SxProps<Theme>;
  /** Defaults to `contained`. */
  variant?: ButtonVariant;
}

type ButtonVariant = "contained" | "outlined" | "text";

function Button({ children, color, disabled, icon, iconPosition = "end", invert, loading, onClick, variant = "contained", sx = [], ...rest }: ButtonProps) {
  const style = useMemo(() => (Array.isArray(sx) ? sx : [sx]), [sx]);
  const className = useMemo(() => [...(invert ? ["invert"] : [])].join(" "), [invert]);

  return (
    <MuiButton
      {...rest}
      className={className}
      color={color ?? "primary"}
      disabled={disabled || loading}
      endIcon={iconPosition == "end" && icon ? icon : undefined}
      onClick={onClick}
      startIcon={iconPosition == "start" && icon ? icon : undefined}
      sx={style}
      variant={variant}
    >
      {!loading && children}
      {!!loading && (
        <>
          <Box component="span" style={{ opacity: 0 }}>
            {children}
          </Box>
          <CircularProgress color="common.white" small />
        </>
      )}
    </MuiButton>
  );
}

export { Button };
export type { ButtonProps };
