import { add, addYears, differenceInMonths as dateFnsDifferenceInMonths, endOfDay as endOfDayDateFns, parse, parseISO } from "date-fns";

export function parseDate(value?: string | Date | null) {
  if (!value) return undefined;
  if (value instanceof Date) return value;

  let valueString = value.toString();
  const dateParsed = parseISO(valueString);

  if (dateParsed instanceof Date && !isNaN(dateParsed.getTime())) return dateParsed;

  valueString = valueString.replace(/-/g, " ").replace(/\./g, " ").replace(/\//g, " ");
  const formatsShortYear = ["dd MM yy", "dd MMM yy", "dd MMMM yy", "dd MM", "dd MMM", "dd MMMM"];

  for (const i in formatsShortYear) {
    const format = formatsShortYear[i];
    const dateParseLoop = parse(valueString, format, new Date());

    if (dateParseLoop instanceof Date && !isNaN(dateParseLoop.getTime())) {
      return dateParseLoop.getFullYear() < 1999 ? addYears(dateParseLoop, 100) : dateParseLoop;
    }
  }

  const formats = ["dd MM yyyy", "dd MMM yyyy", "dd MMMM yyyy"];

  for (const i in formats) {
    const format = formats[i];
    const dateParseLoop = parse(valueString, format, new Date());

    if (dateParseLoop instanceof Date && !isNaN(dateParseLoop.getTime())) {
      return dateParseLoop;
    }
  }

  console.warn("Date parsing failed", value);
  return undefined;
}

export function endOfDay(value?: string | Date) {
  const date = parseDate(value);
  return date ? endOfDayDateFns(date) : undefined;
}

export function differenceInMonths(from?: Date | string, to?: Date | string) {
  const fromParsed = parseDate(from);
  const toParsed = parseDate(to);
  return !fromParsed || !toParsed ? undefined : dateFnsDifferenceInMonths(toParsed, fromParsed);
}

export function differenceInYears(from?: Date | string, to?: Date | string) {
  const months = differenceInMonths(from, to);
  return months === undefined ? undefined : months / 12;
}

export function eligibleToWithdraw(dateOfBirth: string | Date | undefined, enrolmentDate: string | Date | undefined) {
  const dateOfBirthParsed = parseDate(dateOfBirth);
  const enrolmentDateParsed = parseDate(enrolmentDate);

  if (!dateOfBirthParsed) return undefined;

  const ageBased = add(dateOfBirthParsed, { years: 65 });
  const enrolmentBased = enrolmentDateParsed && add(enrolmentDateParsed, { years: 5 });

  return enrolmentBased && enrolmentBased > ageBased ? enrolmentBased : ageBased;
}
