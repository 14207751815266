import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { UserState } from "redux-oidc";
import { AccountantAccountsClient, AccountsClient, AdviserClient, AdviseToolsClient, ClientsClient, ComplianceClient, FundsClient, NotificationsClient, ReportsClient, SecuritiesClient } from "../api";

export const getApis = createDraftSafeSelector(
  (state: { oidc: UserState }) => state.oidc.user,
  (user) => {
    const http = (url: RequestInfo, init?: RequestInit) => {
      const token = user?.access_token;

      if (token && init) {
        init.headers = {
          ...init.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return fetch(url, init);
    };

    return {
      adviserClient: new AdviserClient(import.meta.env.VITE_API_URL, { fetch: http }),
      clientsClient: new ClientsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      accountsClient: new AccountsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      accountantAccountsClient: new AccountantAccountsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      adviseToolsClient: new AdviseToolsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      notificationsClient: new NotificationsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      reportsClient: new ReportsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      fundsClient: new FundsClient(import.meta.env.VITE_API_URL, { fetch: http }),
      complianceClient: new ComplianceClient(import.meta.env.VITE_API_URL, { fetch: http }),
      securitiesClient: new SecuritiesClient(import.meta.env.VITE_API_URL, { fetch: http }),
    };
  }
);
