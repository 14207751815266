import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ConnectedRouter } from "connected-react-router";
import { connect, Provider } from "react-redux";
import { Switch } from "react-router-dom";
import { OidcProvider } from "redux-oidc";
import { userManager } from "./api";
import AppRoutes from "./components";
import Authentication from "./components/authentication";
import Layout from "./components/layout";
import store, { history } from "./store";

const ConnectedAppRoutes = connect()(AppRoutes);

export default function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <OidcProvider store={store} userManager={userManager}>
          <Switch>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Layout>
                <Authentication>
                  <ConnectedAppRoutes />
                </Authentication>
              </Layout>
            </LocalizationProvider>
          </Switch>
        </OidcProvider>
      </ConnectedRouter>
    </Provider>
  );
}
