import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Grid, Menu, MenuItem, Tab as MuiTab, Tabs as MuiTabs } from "@mui/material";
import { Children, isValidElement, MouseEvent, ReactNode, useCallback, useMemo, useState } from "react";
import { generatePath, useHistory, useRouteMatch } from "react-router-dom";

interface TabsProps {
  children: ReactNode;
  preserveState?: boolean;
}

interface TabProps {
  path?: string;
  subTabs?: TabProps[];
  children: ReactNode;
}

interface TabPageProps {
  children: ReactNode;
}

function useRouteParam(param: string) {
  const history = useHistory();
  const routeMatch = useRouteMatch<Record<string, string>>();

  return [
    routeMatch.params[param],
    (value: string | undefined, state: unknown) => {
      history.push({ pathname: generatePath(routeMatch.path, { ...routeMatch.params, [param]: value }), state });
    },
  ] as [string | undefined, (value: string, state: unknown) => void];
}

function Tab({ children, path, subTabs, ...rest }: TabProps) {
  const [anchor, setAnchor] = useState<HTMLElement>();
  const hasSubTabs = useMemo(() => !!subTabs?.length, [subTabs]);
  const value = useMemo(() => path || (hasSubTabs ? null : undefined), [hasSubTabs, path]);

  const openSubTabsMenu = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      hasSubTabs && setAnchor(event.currentTarget);
    },
    [hasSubTabs]
  );

  const handleClose = useCallback(() => {
    setAnchor(undefined);
  }, []);

  return (
    <>
      <MuiTab
        {...rest}
        onClick={openSubTabsMenu}
        value={value}
        label={
          <Box display="flex" alignItems="center">
            {children}
            {hasSubTabs && (
              <ExpandMoreIcon
                sx={{
                  color: "primary.dark",
                  height: 24,
                  transform: `rotate(${anchor ? -180 : 0}deg)`,
                  transition: "transform 225ms",
                  width: 24,
                }}
              />
            )}
          </Box>
        }
      />
      {hasSubTabs && (
        <Menu
          anchorEl={anchor}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          keepMounted
          onClose={handleClose}
          open={!!anchor}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          {subTabs!.map((subTab, index) => (
            <SubTab key={index} onClick={handleClose} path={subTab.path}>
              {subTab.children}
            </SubTab>
          ))}
        </Menu>
      )}
    </>
  );
}

function SubTab({ children, onClick, path }: { children: ReactNode; onClick: () => void; path?: string | null }) {
  const history = useHistory();
  const [activeTab, setActiveTab] = useRouteParam("tab");

  const handleClick = useCallback(() => {
    path && setActiveTab(path, history.location.state);
    onClick();
  }, [history.location.state, onClick, path, setActiveTab]);

  return (
    <MenuItem onClick={handleClick} sx={activeTab === path ? { backgroundColor: "background.default", color: "primary.main" } : undefined}>
      {children}
    </MenuItem>
  );
}

function Tabs({ children, preserveState }: TabsProps) {
  const [activeTab, setActiveTab] = useRouteParam("tab");
  const history = useHistory();

  const findActiveTab = useCallback(
    (tabs: TabProps[]): boolean => tabs.some((t) => t.path === activeTab || (t.subTabs && findActiveTab(t.subTabs))),
    [activeTab]
  );

  const activeTabIndex = useMemo(
    () =>
      Children.toArray(children).findIndex((child) => {
        return isValidElement<TabProps>(child) && (child.props.path == activeTab || (child.props.subTabs && findActiveTab(child.props.subTabs)));
      }),
    [activeTab, children, findActiveTab]
  );

  const currentTabValue = useMemo(() => (activeTabIndex !== -1 ? activeTabIndex : 0), [activeTabIndex]);

  const handleChange = useCallback(
    (_, value: unknown) => value !== null && setActiveTab(value as string, preserveState ? history.location.state : undefined),
    [history.location.state, preserveState, setActiveTab]
  );

  return (
    <MuiTabs value={currentTabValue} onChange={handleChange}>
      {children}
    </MuiTabs>
  );
}

function TabPage({ children }: TabPageProps) {
  return (
    <Grid container sx={{ backgroundColor: "background.paper", borderTopColor: "divider", borderTopStyle: "solid", borderTopWidth: 1, height: "100%", pb: 5 }}>
      {children}
    </Grid>
  );
}

export { Tab, TabPage, Tabs };
