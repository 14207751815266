import { useField } from "formik";
import { useCallback } from "react";
import { BaseDateField, BaseDateFieldProps } from "./BaseDateField";
import { ErrorMessage } from "./ErrorMessage";

interface DateFieldProps extends BaseDateFieldProps {
  name: string;
}

function DateField({ name, onChange, ...rest }: DateFieldProps) {
  const [{ value }, , { setValue }] = useField<string | undefined>(name);

  const handleOnChange = useCallback(
    async (value: string | undefined) => {
      await setValue(value);
      onChange?.(value);
    },
    [onChange, setValue]
  );

  return (
    <>
      <BaseDateField {...rest} onChange={handleOnChange} value={value} />
      <ErrorMessage name={name} />
    </>
  );
}

export { DateField };
