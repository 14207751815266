import { GetClientProjectionResponse, PositionGroupType } from "../api";
import { CURRENCY_0DP } from "../constants";
import { PositionGroupFilter, Projections } from "../types";
import { formatValue, formatWeight } from "./";

const PORTFOLIO_SORT_CONFIG: { key: string; value: number }[][] = [
  [{ key: "british", value: 100 }],
  [
    { key: "income series", value: 10 },
    { key: "defensive series", value: 20 },
    { key: "growth series", value: 30 },
    // APS categories before the restructure.
    { key: "cash", value: 10 },
    { key: "income", value: 20 },
    { key: "inflation", value: 30 },
    { key: "growth", value: 40 },
  ],
  [
    { key: "core cash", value: 1 },
    { key: "cash portfolio", value: 1 },
    { key: "new zealand & australian bonds", value: 2 },
    { key: "new zealand and australian bonds", value: 2 },
    { key: "global bonds", value: 3 },
    { key: "core inflation portfolio", value: 4 },
    { key: "property inflation portfolio", value: 5 },
    { key: "equity inflation portfolio", value: 6 },
    { key: "new zealand property & retirement villages", value: 7 },
    { key: "new zealand property and retirement villages", value: 7 },
    { key: "new zealand utilities", value: 8 },
    { key: "new zealand gentailer", value: 8 },
    { key: "new zealand infrastructure", value: 9 },
    { key: "global property", value: 10 },
    { key: "global utilities", value: 11 },
    { key: "global infrastructure", value: 12 },
    { key: "global macro", value: 13 },
    { key: "global shares", value: 14 },
    { key: "new zealand & australian shares", value: 15 },
    { key: "new zealand and australian shares", value: 15 },
    // APS categories before the restructure.
    { key: "core", value: 1 },
    { key: "global", value: 2 },
    { key: "property", value: 3 },
    { key: "equity", value: 4 },
    { key: "dividend", value: 5 },
  ],
];
/*
New Zealand & Australian Bonds
Global Bonds
Core Cash
Core Inflation Portfolio
Property Inflation Portfolio
Equity Inflation Portfolio
New Zealand Property & Retirement Villages Shares
New Zealand Gentailer Shares
New Zealand Infrastructure Shares
Global Property Shares
Global Utilities Shares
Global Infrastructure Shares
Global Macro
Global Shares
New Zealand & Australian Shares
 */
function calculateSequence(name: string) {
  const nameLowerCase = name.toLocaleLowerCase();
  return PORTFOLIO_SORT_CONFIG.reduce((total, values) => total + (values.find((v) => nameLowerCase.includes(v.key))?.value || 50), 0);
}

function portfolioSort<Portfolio>(items: Portfolio[] | undefined, key: keyof Portfolio) {
  if (!items) return items;

  return items
    .map((item) => ({ ...item, sequence: calculateSequence(item[key] !== undefined ? String(item[key]) : "") }))
    .sort((a, b) => a.sequence - b.sequence);
}

function formatUnitsPrice(units: number | undefined, price: number | undefined) {
  if (price === undefined) return undefined;

  return `${formatValue(units || 0, { type: "decimal" })} units @ ${formatValue(price, { type: "money", typeOption: { decimalPlaces: 4 } })}`;
}

function toPositionGroupType(groupType: PositionGroupFilter) {
  switch (groupType) {
    case PositionGroupFilter.AssetClass:
      return PositionGroupType.AssetClass;
    case PositionGroupFilter.Manager:
      return PositionGroupType.Manager;
    case PositionGroupFilter.Region:
      return PositionGroupType.Region;
    default:
      return undefined;
  }
}

function formatProjectionBalance(balance: number | undefined) {
  return balance === undefined || isNaN(balance) ? "N/A" : CURRENCY_0DP.format(balance);
}

function formatProjectionPerformance(performance: number | undefined) {
  return formatWeight(performance === undefined || isNaN(performance) ? undefined : performance / 100);
}

function formatProjections(projections?: GetClientProjectionResponse) {
  return {
    tenYearBalance: formatProjectionBalance(projections?.tenYearBalance),
    tenYearPerformance: formatProjectionPerformance(projections?.tenYearPerformance),
    twentyYearBalance: formatProjectionBalance(projections?.twentyYearBalance),
    twentyYearPerformance: formatProjectionPerformance(projections?.twentyYearPerformance),
    thirtyYearBalance: formatProjectionBalance(projections?.thirtyYearBalance),
    thirtyYearPerformance: formatProjectionPerformance(projections?.thirtyYearPerformance),
  } as Projections;
}

export { formatProjections, formatUnitsPrice, portfolioSort, toPositionGroupType };
