import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";
import { GetAccountPositionGroupsQuery, GetAccountPositionGroupsResponse, GetAccountPositionsQuery, GetAccountPositionsResponse, ProblemDetails } from "../api";
import { getApis } from "./apiSelector";
 
interface SecuritiesState {
    isLoadingSecurities: boolean;
    securities?: GetAccountPositionsResponse;
    isLoadingSecurityGroups: boolean;
    securityGroups?: GetAccountPositionGroupsResponse;
    errors?: ProblemDetails;
} 

const initialState: SecuritiesState = {
    isLoadingSecurities: false,
    isLoadingSecurityGroups: false
};

export const securitiesAsync = createAsyncThunk<
    GetAccountPositionsResponse,
    GetAccountPositionsQuery,
    { state: RootState; rejectValue: ProblemDetails }
>("securities/securities", (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState())
        .securitiesClient.securities(payload, signal)
        .catch(rejectWithValue)
);

export const securityGroupsAsync = createAsyncThunk<
    GetAccountPositionGroupsResponse,
    GetAccountPositionGroupsQuery,
    { state: RootState; rejectValue: ProblemDetails }
    >("securities/securityGroups", (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState())
        .securitiesClient.securityGroups(payload, signal)
        .catch(rejectWithValue)
);

export const securitiesSlice = createSlice({
    name: "advisers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(securitiesAsync.pending, (state) => {
                state.isLoadingSecurities = true;
                state.errors = undefined;
            })
            .addCase(securitiesAsync.fulfilled, (state, action) => {
                state.isLoadingSecurities = false;
                state.securities = action.payload;
            })
            .addCase(securitiesAsync.rejected, (state, action) => {
                state.isLoadingSecurities = false;
                state.errors = action.payload;
            })
            .addCase(securityGroupsAsync.pending, (state) => {
                state.isLoadingSecurityGroups = true;
                state.errors = undefined;
            })
            .addCase(securityGroupsAsync.fulfilled, (state, action) => {
                state.isLoadingSecurityGroups = false;
                state.securityGroups = action.payload;
            })
            .addCase(securityGroupsAsync.rejected, (state, action) => {
                state.isLoadingSecurityGroups = false;
                state.errors = action.payload;
            });
    },
});

export default securitiesSlice.reducer;
