import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  AccountDocument,
  AccountDocumentsResponse,
  AccountPerformanceDto,
  AccountsSort,
  Agreement,
  CreateFeePresetCommand,
  CreateFeeStructureCommand,
  GetAccountCashFlowResponse,
  GetAccountDetailsResponse,
  GetAccountHoldingsResponse,
  GetAccountPerformanceResponse,
  GetAgreementPresetsResponse,
  GetAgreementsResponse,
  GetPieTaxableIncomeResponse,
  HoldingDto,
  PaginatedTransactionGroupsModel,
  PerformanceDateRange,
  ProblemDetails,
  RecurringTransactionsResponse,
  SearchAccountDto,
  SearchAccountsResponse,
  SearchTransactionsByAccountKeySort,
} from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { addCase, addDownloading, fileThunk } from "./helpers";

interface accountsState {
  account?: GetAccountDetailsResponse;
  accounts?: SearchAccountDto[];
  agreementPresets?: GetAgreementPresetsResponse;
  agreements?: Agreement[];
  accountBalance?: GetAccountCashFlowResponse;
  documents?: AccountDocument[];
  errors?: ProblemDetails;
  holdingsAt?: HoldingDto[];
  isDownloading: boolean;
  isDownloadTaxStatement: boolean;
  isExporting: boolean;
  isLoading?: string;
  isLoadingAccount: boolean;
  isLoadingAccounts: boolean;
  isLoadingAgreementPresets: boolean;
  isLoadingAgreements: boolean;
  isLoadingBalances: boolean;
  isLoadingDetails: boolean;
  isLoadingDocuments: boolean;
  isLoadingHoldingsAt: boolean;
  isLoadingPerformance: boolean;
  isLoadingQuarterlyPieTax: boolean;
  isLoadingRecurringTransactions: boolean;
  isLoadingTransactions: boolean;
  isSavingFeePreset: boolean;
  performances: AccountPerformanceDto[];
  quarterlyPieTax?: GetPieTaxableIncomeResponse;
  recurringTransactions?: RecurringTransactionsResponse;
  searchResult?: SearchAccountsResponse;
  transactions?: PaginatedTransactionGroupsModel;
}

const initialState: accountsState = {
  holdingsAt: [],
  isDownloading: false,
  isDownloadTaxStatement: false,
  isExporting: false,
  isLoadingAccount: false,
  isLoadingAccounts: false,
  isLoadingAgreementPresets: false,
  isLoadingAgreements: false,
  isLoadingBalances: false,
  isLoadingDetails: false,
  isLoadingDocuments: false,
  isLoadingHoldingsAt: false,
  isLoadingPerformance: false,
  isLoadingRecurringTransactions: false,
  isLoadingTransactions: false,
  isLoadingQuarterlyPieTax: false,
  isSavingFeePreset: false,
  performances: [],
};

export const accountAgreementPresetsAsync = createAsyncThunk<
  GetAgreementPresetsResponse,
  { accountKey?: string; adviserNumber?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/agreementPresets", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.agreementPresets(payload.accountKey, payload.adviserNumber, signal).catch(rejectWithValue)
);

export const accountAgreementsAsync = createAsyncThunk<GetAgreementsResponse, { accountKey?: string }, { state: RootState; rejectValue: ProblemDetails }>(
  "accounts/agreements",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).accountsClient.agreements(payload.accountKey, signal).catch(rejectWithValue)
);

export const accountBalancesAsync = createAsyncThunk<GetAccountCashFlowResponse, { accountNumber: string }, { state: RootState; rejectValue: ProblemDetails }>(
  "accounts/AccountBalance",
  (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState()).accountsClient.accountCashFlow({ accountNumber: payload.accountNumber }, signal).catch(rejectWithValue)
);

export const accountCreateFeePresetAsync = createAsyncThunk<boolean, CreateFeePresetCommand, { state: RootState; rejectValue: ProblemDetails }>(
  "accounts/createFeePreset",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).accountsClient.createFeePreset(payload, signal).catch(rejectWithValue)
);

export const accountCreateFeeStructureAsync = fileThunk<CreateFeeStructureCommand>(
  "accounts/createFeeStructure",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.createFeeStructure(payload, signal),
  "Agreement.pdf"
);

export const accountDetailsAsync = createAsyncThunk<GetAccountDetailsResponse, string, { state: RootState; rejectValue: ProblemDetails }>(
  "accounts/details",
  (payload: string, { getState, rejectWithValue, signal }) => getApis(getState()).accountsClient.accountDetails(payload, signal).catch(rejectWithValue)
);

export const accountDocumentsAsync = createAsyncThunk<AccountDocumentsResponse, { accountKey?: string }, { state: RootState; rejectValue: ProblemDetails }>(
  "accounts/documents",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).accountsClient.documents(payload.accountKey, signal).catch(rejectWithValue)
);

export const accountExportAsync = fileThunk<{
  accounts?: SearchAccountDto[];
  adviserBranchKey?: string;
  adviserNumber?: string;
  services?: string[];
  sortOrder: AccountsSort;
  states?: string[];
  term?: string;
}>(
  "accounts/export",
  (payload, { getState, signal }) =>
    getApis(getState()).accountsClient.exportAccounts(
      payload.term,
      payload.services,
      payload.states,
      payload.adviserBranchKey,
      payload.adviserNumber,
      payload.sortOrder,
      signal
    ),
  "Accounts.csv"
);

export const accountExportQuarterlyPieTaxAsync = fileThunk<{ accountKey?: string; quarter?: string }>(
  "accounts/exportQuarterlyPieTax",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.exportQuarterlyPieTax(payload.accountKey, payload.quarter, signal),
  "Quarterly Pie.csv"
);

export const accountExportTransactionsAsync = fileThunk<{
  accountKey?: string;
  sortOrder: SearchTransactionsByAccountKeySort;
  term?: string;
  from?: string;
  to?: string;
}>(
  "accounts/exportTransactions",
  (payload, { getState, signal }) =>
    getApis(getState()).accountsClient.exportTransactions(payload.accountKey, payload.term, payload.from, payload.to, payload.sortOrder, signal),
  "transactions.csv"
);

export const accountHoldingsAtAsync = createAsyncThunk<
  GetAccountHoldingsResponse,
  { accountNumber: string; balanceAt?: string; dateRanges: PerformanceDateRange[] },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/HoldingsAt", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.holdingsAt(payload.accountNumber, payload.balanceAt, payload.dateRanges, signal).catch(rejectWithValue)
);

export const accountHoldingsReport = fileThunk<{ accountKey?: string; upTo?: string }>(
  "accounts/holdingsReport",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.holdingsReport(payload.accountKey, payload.upTo, signal),
  "Account Holdings Report.pdf"
);

export const accountPerformanceAsync = createAsyncThunk<
  GetAccountPerformanceResponse,
  { accountNumber: string; dateRanges: PerformanceDateRange[] },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/AccountPerformance", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.accountPerformance(payload.accountNumber, payload.dateRanges, signal).catch(rejectWithValue)
);

export const accountPerformanceReport = fileThunk<{ accountKey?: string; from?: string; to?: string }>(
  "accounts/performanceReport",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.performanceReport(payload.accountKey, payload.from, payload.to, signal),
  "Account Performance Report.pdf"
);

export const accountPortfolioReport = fileThunk<{ accountKey?: string; from?: string; to?: string }>(
  "accounts/portfolioReport",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.portfolioReport(payload.accountKey, payload.from, payload.to, signal),
  "Client Portfolio Report.pdf"
);

export const accountQuarterlyPieTaxAsync = createAsyncThunk<
  GetPieTaxableIncomeResponse,
  { accountKey?: string; quarter?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/quarterlyPieTax", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.quarterlyPieTax(payload.accountKey, payload.quarter, signal).catch(rejectWithValue)
);

export const accountRecurringTransactionsAsync = createAsyncThunk<
  RecurringTransactionsResponse,
  { accountKey?: string },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/recurringTransactions", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).accountsClient.recurringTransactions(payload.accountKey, signal).catch(rejectWithValue)
);

export const accountSearchAsync = createAsyncThunk<
  SearchAccountsResponse,
  {
    adviserBranchKey?: string;
    adviserNumber?: string;
    currentPage: number;
    pageSize: number;
    services?: string[];
    sortOrder: AccountsSort;
    states?: string[];
    term?: string;
  },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/search", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .accountsClient.searchAccounts(
      payload.term,
      payload.services,
      payload.states,
      payload.adviserBranchKey,
      payload.adviserNumber,
      payload.currentPage,
      payload.pageSize,
      payload.sortOrder,
      signal
    )
    .catch(rejectWithValue)
);

export const accountTaxStatementAsync = fileThunk<{ accountKey?: string; year?: number }>(
  "accounts/taxStatement",
  (payload, { getState, signal }) => getApis(getState()).accountsClient.taxStatement(payload.accountKey, payload.year, signal),
  (payload) => `Investor Tax Summary ${payload.accountKey} ${payload.year}.pdf`
);

export const accountTransactionsAsync = createAsyncThunk<
  PaginatedTransactionGroupsModel,
  { accountKey?: string; term?: string; from?: string; to?: string; currentPage: number; pageSize: number; sortOrder: SearchTransactionsByAccountKeySort },
  { state: RootState; rejectValue: ProblemDetails }
>("accounts/transactions", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .accountsClient.transactions(payload.accountKey, payload.term, payload.from, payload.to, payload.currentPage, payload.pageSize, payload.sortOrder, signal)
    .catch(rejectWithValue)
);

export const searchAccountAsync = createAsyncThunk<SearchAccountsResponse, string, { state: RootState; rejectValue: ProblemDetails }>(
  "accounts/searchAccount",
  (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState())
      .accountsClient.searchAccounts(payload, ["APS", "MSS"], ["Active"], null, null, 0, 10, AccountsSort.AccountKey, signal)
      .catch(rejectWithValue)
);

export const accountsSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    accountClear(state) {
      state.account = undefined;
      state.accountBalance = undefined;
      state.agreementPresets = undefined;
      state.agreements = undefined;
      state.documents = undefined;
      state.holdingsAt = undefined;
      state.performances = [];
      state.quarterlyPieTax = undefined;
      state.recurringTransactions = undefined;
      state.transactions = undefined;
    },
    accountQuarterlyPieTaxClear(state) {
      state.quarterlyPieTax = undefined;
    },
    accountSearchClear(state) {
      state.accounts = undefined;
      state.errors = undefined;
    },
    accountSearchResultClear(state) {
      state.isLoading = undefined;
      state.searchResult = undefined;
    },
  },
  extraReducers: (builder) => {
    addCase(
      builder,
      accountAgreementsAsync,
      (state, isLoading) => (state.isLoadingAgreements = isLoading),
      (state, payload) => (state.agreements = payload.agreements)
    );

    addCase(
      builder,
      accountAgreementPresetsAsync,
      (state, isLoading) => (state.isLoadingAgreementPresets = isLoading),
      (state, payload) => (state.agreementPresets = payload)
    );

    addDownloading(builder, accountCreateFeeStructureAsync);
    addCase(builder, accountCreateFeePresetAsync, (state, isLoading) => (state.isSavingFeePreset = isLoading));

    builder
      .addCase(accountBalancesAsync.fulfilled, (state, action) => {
        state.isLoadingBalances = false;
        state.accountBalance = action.payload;
      })
      .addCase(accountBalancesAsync.rejected, (state, action) => {
        state.isLoadingBalances = false;
        state.errors = action.payload;
      })
      .addCase(accountDetailsAsync.pending, (state) => {
        state.isLoadingAccount = true;
        state.errors = undefined;
      })
      .addCase(accountDetailsAsync.fulfilled, (state, action) => {
        state.isLoadingAccount = false;
        state.account = action.payload;
      })
      .addCase(accountDetailsAsync.rejected, (state, action) => {
        state.isLoadingAccount = false;
        state.errors = action.payload;
      })
      .addCase(accountDocumentsAsync.pending, (state) => {
        state.isLoadingDocuments = true;
        state.errors = undefined;
      })
      .addCase(accountDocumentsAsync.fulfilled, (state, action) => {
        state.isLoadingDocuments = false;
        state.documents = action.payload.documents;
      })
      .addCase(accountDocumentsAsync.rejected, (state, action) => {
        state.isLoadingDocuments = false;
        state.errors = action.payload;
      })
      .addCase(accountExportAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(accountExportAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(accountExportAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountExportQuarterlyPieTaxAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(accountExportQuarterlyPieTaxAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(accountExportQuarterlyPieTaxAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountExportTransactionsAsync.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(accountExportTransactionsAsync.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(accountExportTransactionsAsync.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountHoldingsAtAsync.pending, (state) => {
        state.isLoadingHoldingsAt = true;
        state.errors = undefined;
      })
      .addCase(accountHoldingsAtAsync.fulfilled, (state, action) => {
        state.isLoadingHoldingsAt = false;
        state.holdingsAt = action.payload.holdings;
      })
      .addCase(accountHoldingsAtAsync.rejected, (state, action) => {
        state.isLoadingHoldingsAt = false;
        state.errors = action.payload;
      })
      .addCase(accountHoldingsReport.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(accountHoldingsReport.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(accountHoldingsReport.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountBalancesAsync.pending, (state) => {
        state.isLoadingBalances = true;
        state.errors = undefined;
      })
      .addCase(accountPerformanceAsync.pending, (state) => {
        state.isLoadingPerformance = true;
        state.errors = undefined;
      })
      .addCase(accountPerformanceAsync.fulfilled, (state, action) => {
        state.isLoadingPerformance = false;
        state.performances = action.payload.performance;
      })
      .addCase(accountPerformanceAsync.rejected, (state, action) => {
        state.isLoadingPerformance = false;
        state.errors = action.payload;
      })
      .addCase(accountPerformanceReport.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(accountPerformanceReport.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(accountPerformanceReport.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountPortfolioReport.pending, (state) => {
        state.isExporting = true;
        state.errors = undefined;
      })
      .addCase(accountPortfolioReport.fulfilled, (state) => {
        state.isExporting = false;
      })
      .addCase(accountPortfolioReport.rejected, (state, action) => {
        state.isExporting = false;
        state.errors = action.payload;
      })
      .addCase(accountQuarterlyPieTaxAsync.pending, (state) => {
        state.isLoadingQuarterlyPieTax = true;
        state.errors = undefined;
      })
      .addCase(accountQuarterlyPieTaxAsync.fulfilled, (state, action) => {
        state.isLoadingQuarterlyPieTax = false;
        state.quarterlyPieTax = action.payload;
      })
      .addCase(accountQuarterlyPieTaxAsync.rejected, (state, action) => {
        state.isLoadingQuarterlyPieTax = false;
        state.errors = action.payload;
      })
      .addCase(accountRecurringTransactionsAsync.pending, (state) => {
        state.isLoadingRecurringTransactions = true;
        state.errors = undefined;
      })
      .addCase(accountRecurringTransactionsAsync.fulfilled, (state, action) => {
        state.isLoadingRecurringTransactions = false;
        state.recurringTransactions = action.payload;
      })
      .addCase(accountRecurringTransactionsAsync.rejected, (state, action) => {
        state.isLoadingRecurringTransactions = false;
        state.errors = action.payload;
      })
      .addCase(accountSearchAsync.pending, (state, action) => {
        state.isLoading = action.meta.requestId;
        state.errors = undefined;
      })
      .addCase(accountSearchAsync.fulfilled, (state, action) => {
        state.isLoading = undefined;
        state.searchResult = action.payload;
      })
      .addCase(accountSearchAsync.rejected, (state, action) => {
        if (state.isLoading === action.meta.requestId) {
          state.isLoading = undefined;
          state.errors = action.payload;
        }
      })
      .addCase(accountTaxStatementAsync.pending, (state) => {
        state.isDownloadTaxStatement = true;
        state.errors = undefined;
      })
      .addCase(accountTaxStatementAsync.fulfilled, (state) => {
        state.isDownloadTaxStatement = false;
      })
      .addCase(accountTaxStatementAsync.rejected, (state, action) => {
        state.isDownloadTaxStatement = false;
        state.errors = action.payload;
      })
      .addCase(accountTransactionsAsync.pending, (state) => {
        state.isLoadingTransactions = true;
        state.errors = undefined;
      })
      .addCase(accountTransactionsAsync.fulfilled, (state, action) => {
        state.isLoadingTransactions = false;
        state.transactions = action.payload;
      })
      .addCase(accountTransactionsAsync.rejected, (state, action) => {
        state.isLoadingTransactions = false;
        state.errors = action.payload;
      })
      .addCase(searchAccountAsync.pending, (state) => {
        state.isLoadingAccounts = true;
        state.errors = undefined;
      })
      .addCase(searchAccountAsync.fulfilled, (state, action) => {
        state.isLoadingAccounts = false;
        state.accounts = action.payload.results;
      })
      .addCase(searchAccountAsync.rejected, (state, action) => {
        state.isLoadingAccounts = false;
        state.errors = action.payload;
      });
  },
});

export const { accountClear, accountQuarterlyPieTaxClear, accountSearchClear, accountSearchResultClear } = accountsSlice.actions;
export default accountsSlice.reducer;
