import { Grid } from "@mui/material";
import { useFormikContext } from "formik";
import { ReactNode, useCallback, useMemo } from "react";
import { BaseAutocomplete } from "../form";
import ReportField from "./ReportField";

interface ReportDynamicFilterProps {
  label: string;
  field: string;
  children: ReactNode;
  onChangeClear?: string[];
}

interface ReportDynamicFilterFromParentProps {
  fields: ReportDynamicFilterProps[];
  filters: (string | undefined)[];
  setFilters: React.Dispatch<React.SetStateAction<(string | undefined)[]>>;
  filterIndex: number;
}

const mapFilterToOption = ({ field, label }: ReportDynamicFilterProps) => ({ label, value: field });

export default function ReportDynamicFilter(props: ReportDynamicFilterProps) {
  const { field, children, fields, filters, setFilters, filterIndex } = props as ReportDynamicFilterProps & ReportDynamicFilterFromParentProps;
  const { getFieldHelpers } = useFormikContext();
  const options = useMemo(() => fields.filter((f) => field === f.field || !filters.includes(f.field)).map(mapFilterToOption), [field, fields, filters]);

  const handleChange = useCallback(
    (value: string | number | null | undefined) => {
      const oldValue = filters[filterIndex];

      if (oldValue === value) return;

      if (oldValue) {
        const oldField = fields.find((f) => f.field === oldValue);

        if (oldField?.field && oldValue) getFieldHelpers(oldField.field).setValue(undefined);

        oldField?.onChangeClear?.forEach((fieldName) => {
          getFieldHelpers(fieldName).setValue(undefined);
        });
      }

      if (!value) {
        setFilters(filters.slice(0, filterIndex).concat(filters.slice(filterIndex + 1)));
      } else {
        setFilters(
          filters
            .slice(0, filterIndex)
            .concat(value as string)
            .concat(filters.slice(filterIndex + 1))
        );
      }
    },
    [fields, filterIndex, filters, getFieldHelpers, setFilters]
  );

  return (
    <ReportField
      dualColumn
      label={
        <Grid item xs={12} md={11}>
          <BaseAutocomplete onChange={handleChange} options={options} value={field} />
        </Grid>
      }
    >
      {children}
    </ReportField>
  );
}

export type { ReportDynamicFilterProps, ReportDynamicFilterFromParentProps };
