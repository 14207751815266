import { styled, Switch } from "@mui/material";
import { ReactNode } from "react";

interface BaseDualSwitchProps<LeftValue, RightValue> {
  left: ReactNode;
  leftValue: LeftValue;
  onChange: (value: LeftValue | RightValue) => void;
  right: ReactNode;
  rightValue: RightValue;
  value: LeftValue | RightValue;
}

export function BaseDualSwitch<LeftValue, RightValue>({ left, leftValue, onChange, right, rightValue, value }: BaseDualSwitchProps<LeftValue, RightValue>) {
  return (
    <>
      {left}
      <MuiSwitch checked={rightValue === value} color="primary" onChange={(_, checked) => onChange(checked ? rightValue : leftValue)} />
      {right}
    </>
  );
}

const MuiSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.primary.main,
  },
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 20 / 2,
  },
}));
