import { differenceInMonths, format as dateFnsFormat, formatDuration } from "date-fns";
import { parseDate } from "../date";

interface FormatDate {
  type: "date";
  typeOption?: { outputStyle?: "datetime" | "duration" | "long" | "short" | "shortYear" };
}

function formatMonths(months: number) {
  return formatDuration({ years: Math.floor(months / 12), months: months % 12 });
}

function formatDate(value: string | Date, options?: FormatDate) {
  const date = parseDate(value);

  if (!date) {
    return undefined;
  }

  switch (options?.typeOption?.outputStyle) {
    case "datetime":
      return dateFnsFormat(date, "dd MMM yyyy HH:mm");
    case "duration":
      return formatMonths(differenceInMonths(new Date(), date));
    case "long":
      return dateFnsFormat(date, "dd MMMM yyyy");
    case "short":
      return dateFnsFormat(date, "dd/MM/yyyy");
    case "shortYear":
      return dateFnsFormat(date, "dd MMM yy");
  }

  return dateFnsFormat(date, "dd MMM yyyy");
}

function formatDateJSON(value?: string | Date) {
  const date = parseDate(value);
  return date ? dateFnsFormat(date, "yyyy-MM-dd") : undefined;
}

export type { FormatDate };
export { formatDate, formatDateJSON };
