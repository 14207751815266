import { Grid } from "@mui/material";
import { FormikContextType, useField, useFormikContext } from "formik";
import { Children, cloneElement, isValidElement, ReactElement, useMemo } from "react";
import { BaseAutocomplete, ErrorMessage } from "../form";
import { FeeAgreementField } from "./FeeAgreementField";
import { FeeAgreementType } from "./types";

const mapFilterToOption = (field: FeeAgreementType) => ({ value: field.value, label: field.name });

interface FeeAgreementSelectorProps<Values> {
  field: string;
  children?: (JSX.Element | false)[];
  onChange?: (changeType: "add" | "remove", value: string, formik: FormikContextType<Values>) => void;
}

function FeeAgreementSelector<Values>({ children, field: fieldProp, onChange }: FeeAgreementSelectorProps<Values>) {
  const formik = useFormikContext<Values>();
  const [field, , helpers] = useField<string[] | undefined>(fieldProp);
  const filters = useMemo(() => field.value || [], [field.value]);
  const fields = useMemo(
    () =>
      Children.map(children, (child) => (isValidElement<FeeAgreementType>(child) ? child : undefined))?.filter(
        (child: unknown) => !!child
      ) as ReactElement<FeeAgreementType>[],
    [children]
  );
  const fieldsProps = useMemo(() => fields.map((childField) => childField.props), [fields]);
  const options = useMemo(() => fieldsProps.filter((childField) => !filters.includes(childField.value)).map(mapFilterToOption), [fieldsProps, filters]);

  return (
    <>
      {filters?.map((filterName, index) => {
        const filter = fields.find((field) => field.props.value === filterName);

        return (
          filter && (
            <Grid key={filter.key} item container xs={12} md={6} alignContent="start">
              <FeeAgreementField
                label={index > 0 ? "Add fee type" : "Choose fee type"}
                field={
                  <>
                    <BaseAutocomplete
                      options={fieldsProps.filter((field) => filterName === field.value || !filters.includes(field.value)).map(mapFilterToOption)}
                      value={filterName}
                      onChange={(value) => {
                        const filtersUpdate = filters.slice();
                        onChange?.("remove", filters[index], formik);

                        if (!value) {
                          helpers.setValue(filtersUpdate.splice(index, 1));
                        } else {
                          onChange?.("add", value as string, formik);
                          filtersUpdate[index] = value as string;
                        }

                        helpers.setValue(filtersUpdate);
                      }}
                    />
                    {cloneElement(filter as ReactElement<FeeAgreementType>, {})}
                  </>
                }
              />
            </Grid>
          )
        );
      })}
      {options.length > 0 && (
        <Grid item container xs={12} md={6} alignContent="start">
          <FeeAgreementField
            label={(filters ?? [].length) ? "Add fee type" : "Choose fee type"}
            field={
              <>
                <BaseAutocomplete
                  options={options}
                  onChange={(value) => {
                    if (!value) return;

                    onChange?.("add", value as string, formik);
                    helpers.setValue(filters?.slice().concat(value as string) ?? [value]);
                  }}
                />
                <ErrorMessage name={fieldProp} />
              </>
            }
          />
        </Grid>
      )}
    </>
  );
}

export { FeeAgreementSelector };
