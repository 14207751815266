import { Collapse, Grid } from "@mui/material";
import { ReactNode, useEffect, useState } from "react";
import { Button } from "./";
import { Typography } from "./Typography";

interface AccordionProps {
  alwaysShow?: ReactNode;
  children?: ReactNode;
  heading: ReactNode;
  hideExpandToggle?: boolean;
  defaultExpanded?: boolean;
  triggerExpand?: boolean;
}

function Accordion({ heading, hideExpandToggle, children, defaultExpanded = false, alwaysShow, triggerExpand = false }: AccordionProps) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [expandTriggered, setExpandTriggered] = useState(triggerExpand);

  useEffect(() => {
    if (triggerExpand && !expanded && !expandTriggered) {
      setExpandTriggered(true);
      setExpanded(true);
    }
  }, [expanded, expandTriggered, triggerExpand]);

  return (
    <Grid container>
      <Grid
        item
        container
        sx={{ alignItems: "flex-end", borderBottomColor: "divider", borderBottomStyle: "solid", borderBottomWidth: 1, justifyContent: "space-between" }}
      >
        <Grid item xs sx={{ pb: 1 }}>
          <Typography variant="section-heading">{heading}</Typography>
        </Grid>
        <Grid item xs="auto" sx={{ pb: 0.5 }}>
          {children && !hideExpandToggle && (
            <Button onClick={() => setExpanded(!expanded)} variant="outlined">
              {expanded ? "Hide" : "Show"}
            </Button>
          )}
        </Grid>
      </Grid>
      {!!alwaysShow && (
        <Grid item xs={12}>
          {alwaysShow}
        </Grid>
      )}
      {!!children && (
        <Collapse in={expanded} sx={{ width: "100%", pt: 2 }}>
          {children}
        </Collapse>
      )}
    </Grid>
  );
}

export { Accordion };
