import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";
import { GUTTER_SIZE } from "../../constants";
import { Breadcrumb, Breadcrumbs, SkeletonWrapper, ThemeIcon, Typography } from "./";

interface PageHeadingProps {
  breadcrumb?: {
    default: Breadcrumb[];
    current: Breadcrumb;
  };
  heading?: ReactNode;
  subheading?: ReactNode;
  subsubheading?: ReactNode;
  tabs?: ReactNode;
}

function List({ children, variant }: { children: ReactNode; variant: "page-subheading" | "page-subsubheading" }) {
  if (Array.isArray(children)) {
    return (
      <>
        {children.map((child, i) => (
          <Grid item xs={12} key={i}>
            <Typography variant={variant}>{child}</Typography>
          </Grid>
        ))}
      </>
    );
  }

  return (
    <Grid item xs={12}>
      <Typography variant={variant}>{children}</Typography>
    </Grid>
  );
}

function PageHeading({ breadcrumb, heading, subheading, subsubheading, tabs }: PageHeadingProps) {
  return (
    <Grid container sx={{ backgroundColor: "common.brandHigh", px: GUTTER_SIZE, pt: breadcrumb ? 1 : 5 }}>
      {!!breadcrumb && (
        <Grid item xs={12}>
          <Breadcrumbs defaultBreadcrumb={breadcrumb.default} current={breadcrumb.current} />
        </Grid>
      )}
      <Grid item container xs={6} paddingBottom={2.5}>
        <Typography variant="page-heading">
          {/* min-height hack to get things loading and not jumping, likely to do with font sizes and line height rounding */}
          <Box display="flex" alignItems="center" gap={1} minHeight="1.16em">
            <ThemeIcon size="large" sx={{ ml: -0.5 }} />
            <Box display="inline-block">
              <SkeletonWrapper>{heading}</SkeletonWrapper>
            </Box>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={6} gap={1} textAlign="right">
        <List variant="page-subheading">{subheading}</List>
        <List variant="page-subsubheading">{subsubheading}</List>
      </Grid>
      <Grid item xs={12}>
        {tabs}
      </Grid>
    </Grid>
  );
}

export { PageHeading };
