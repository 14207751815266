import { Grid } from "@mui/material";
import { FormikContextType, useFormikContext } from "formik";
import { ReactNode, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { AdviserDto, CreateRiskRatingCommand, NatureAndPurposeAnswer, NatureAndPurposeGroup, RiskFactorGroup } from "../../api";
import { GUTTER_SIZE } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../store";
import { associatedAdvisersAsync } from "../../store/advisers";
import { calculateRiskRatingAsync, createRiskRatingAsync, riskRatingQuestionsAsync } from "../../store/compliance";
import { Accordion, Panel } from "../common";
import { Autocomplete, Form, FormButton, NumberField, TextField } from "../form";

interface NatureAndPurposeAnswers {
  type?: string;
  question: string;
  answers?: NatureAndPurposeAnswer[];
  answer: string;
  override: string;
}

const createRiskRatingStructureValidation: (newAccount: boolean) => yup.Schema<CreateRiskRatingCommand> = () =>
  yup.object({
    accountName: yup.string().required("Account name is required"),
    adviserNumber: yup.string().required("Adviser is required"),
    authorisedIndividual: yup.string().required("Authorised individual is required"),
    adviserComment: yup
      .string()
      .when("overrideRiskRatingLevel", ([overrideRiskRatingLevel], schema) => (overrideRiskRatingLevel ? schema.required("Comment is required") : schema)),
    natureAndPurposeAnswers: yup
      .array(
        yup.object({
          question: yup.string().required("Question is required"),
          answer: yup.string().required("Answer is required"),
        })
      )
      .required(),
    overrideRiskRatingLevel: yup.string(),
    riskRatingAnswers: yup
      .array(
        yup.object({
          question: yup.string().required("Question is required"),
          answer: yup.string().required("Answer is required"),
        })
      )
      .required(),
  });

export function NewRiskRating(props: { accountKey?: string; accountName?: string; adviser?: AdviserDto }) {
  const dispatch = useAppDispatch();
  const { accountKey, accountName, adviser } = props;
  const { isLoading, isExporting, riskFactorGroups, natureAndPurposeGroups, riskRatingLevel, adviserRiskLevels } = useAppSelector((as) => as.compliance);
  const { advisers } = useAppSelector((as) => as.advisers);

  useEffect(() => {
    if (!natureAndPurposeGroups || !riskFactorGroups) {
      dispatch(riskRatingQuestionsAsync());
    }
  }, [dispatch, natureAndPurposeGroups, riskFactorGroups]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  return !riskFactorGroups || !natureAndPurposeGroups ? null : (
    <Panel>
      <Form
        initialValues={{
          accountName: accountName,
          accountNumber: accountKey,
          adviserComment: "",
          adviserName: adviser?.adviserName || "",
          adviserNumber: "",
          authorisedIndividual: "",
          overrideRiskRatingLevel: "",
          natureAndPurposeAnswers: natureAndPurposeGroups.map(({ answers, question, type }) => ({ type, question, answers, answer: "", override: "" })),
          riskRatingAnswers: riskFactorGroups.map(({ question }) => ({ question, answer: "" })),
        }}
        onSubmit={({ riskRatingAnswers }) => dispatch(calculateRiskRatingAsync({ riskRatingAnswers }))}
        validationSchema={createRiskRatingStructureValidation(accountKey == null || accountKey == "")}
      >
        <Accordion heading="Account details" defaultExpanded>
          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={12} md={6} alignContent="start">
              <RiskRatingField labelSize={4} fieldSize={8} label="Account name" field={<TextField name="accountName" />} />
              <RiskRatingField labelSize={4} fieldSize={8} label="Account number" field={<TextField disabled={!accountKey} name="accountNumber" />} />
            </Grid>
            <Grid item container xs={12} md={6} alignContent="start">
              <RiskRatingField
                labelSize={4}
                fieldSize={8}
                label="Adviser"
                field={(_, formik) => (
                  <Autocomplete
                    name="adviserNumber"
                    onChange={(e) => formik.setFieldValue("authorisedIndividual", advisers?.find((a) => a.number === e)?.name || "")}
                    options={advisers?.map((a) => ({ value: a.number, label: `${a.name} (${a.number})` })) || []}
                  />
                )}
              />
              <RiskRatingField labelSize={4} fieldSize={8} label="Authorised individual" field={<TextField name="authorisedIndividual" />} />
            </Grid>
          </Grid>
        </Accordion>
        <Accordion heading="AML risk assessment" defaultExpanded>
          {riskFactorGroups?.map((group: RiskFactorGroup, index) => (
            <RiskRatingField
              key={group.question}
              label={group.question}
              field={
                <Autocomplete
                  name={`riskRatingAnswers.${index}.answer`}
                  autoSelectIfOneOption
                  options={group.answers?.map((answer) => ({ value: answer.text || "", label: `${answer.text}` })) || []}
                />
              }
            />
          ))}
        </Accordion>
        <Accordion heading="Nature and purpose of business relationship" defaultExpanded>
          {natureAndPurposeGroups?.map((group, index) => (
            <NatureAndPurposeField key={group.question} group={group} fieldName={`natureAndPurposeAnswers.${index}`} />
          ))}
        </Accordion>
        <Accordion heading="AML risk assessment result" defaultExpanded>
          <Grid container item xs={12} spacing={2}>
            <Grid item container xs={12} md={6} alignContent="start">
              <RiskRatingField
                labelSize={4}
                fieldSize={8}
                label="Rating"
                field={
                  <Grid item xs={12} sx={{ pl: 0.5, pt: 1.5 }}>
                    {riskRatingLevel}
                  </Grid>
                }
              />
              <RiskRatingField labelSize={4} fieldSize={8} label="Comment" field={<TextField name="adviserComment" multiline minRows={1} />} />
              <RiskRatingField
                labelSize={4}
                fieldSize={8}
                field={(_, formik) => (
                  <>
                    <FormButton type="submit" loading={isLoading} disabled={isExporting} onClick={() => formik.setFieldValue("overrideRiskRatingLevel", "")}>
                      Calculate
                    </FormButton>
                    <FormButton
                      variant="outlined"
                      loading={isExporting}
                      disabled={isLoading || !riskRatingLevel}
                      onClick={(values: CreateRiskRatingCommand & { natureAndPurposeAnswers?: NatureAndPurposeAnswers[] }) => {
                        dispatch(
                          createRiskRatingAsync({
                            ...values,
                            natureAndPurposeAnswers: values.natureAndPurposeAnswers?.map((x: NatureAndPurposeAnswers) => ({
                              question: x.question,
                              answer: x.override === "" ? String(x.answer) : x.override,
                            })),
                          })
                        );
                      }}
                    >
                      Confirm & Export
                    </FormButton>
                  </>
                )}
              />
            </Grid>
            <Grid item container xs={12} md={6} alignContent="start">
              <RiskRatingField
                labelSize={4}
                fieldSize={8}
                label="Override rating"
                field={
                  <Autocomplete
                    name="overrideRiskRatingLevel"
                    options={
                      adviserRiskLevels
                        ?.filter((_, i) => {
                          const selectedIndex = adviserRiskLevels.findIndex((a) => a.riskRatingLevel === riskRatingLevel);
                          return selectedIndex === adviserRiskLevels.length - 1
                            ? i < adviserRiskLevels.length - 1
                            : i > selectedIndex && i < adviserRiskLevels.length - 1;
                        })
                        ?.map((riskRating) => ({ value: riskRating.riskRatingLevel ?? "", label: riskRating.riskRatingLevel ?? "" })) || []
                    }
                  />
                }
              />
            </Grid>
          </Grid>
        </Accordion>
      </Form>
    </Panel>
  );
}

function RiskRatingField<Values>({
  label,
  field,
  paddingTop,
  labelSize,
  fieldSize,
}: {
  label?: ReactNode;
  field: ReactNode | ((values: Values, formik: FormikContextType<Values>) => ReactNode);
  labelSize?: number;
  fieldSize?: number;
  paddingTop?: string | number;
}) {
  const formik = useFormikContext<Values>();

  return (
    <Grid item container xs={12} paddingBottom={2}>
      <Grid
        item
        xs={12}
        md={labelSize === undefined ? 8 : labelSize}
        sx={{ pr: GUTTER_SIZE }}
        alignSelf="top"
        paddingTop={typeof field !== "string" && typeof label === "string" && paddingTop === undefined ? "8px" : paddingTop}
      >
        {label}
      </Grid>
      <Grid item container xs={12} md={fieldSize === undefined ? 4 : fieldSize} alignItems="center" gap={1} alignSelf="top">
        {typeof field === "function" ? field(formik.values, formik) : field}
      </Grid>
    </Grid>
  );
}

function NatureAndPurposeField({ group, fieldName }: { group: NatureAndPurposeGroup; fieldName: string }) {
  const [override, setOverride] = useState(false);

  const handleChange = useCallback(
    (value: string | number | null | undefined) => {
      setOverride(!!group.answers?.some((a) => a.override && a.text === (value as string)));
    },
    [group.answers]
  );

  return (
    <Grid item container xs={12} paddingBottom={2}>
      <Grid item xs={12} md={8} sx={{ alignSelf: "top", pr: GUTTER_SIZE, pt: 1.5 }}>
        {group.question}
      </Grid>
      <Grid item container xs={12} md={4} alignItems="center" gap={1} alignSelf="top">
        {group.type == "array" && (
          <Autocomplete
            autoSelectIfOneOption
            name={`${fieldName}.answer`}
            onChange={handleChange}
            options={group.answers?.map((answer) => ({ label: `${answer.text}`, value: `${answer.text}` })) || []}
          />
        )}
        {override && <TextField name={`${fieldName}.override`} />}
        {group.type == "number" && <NumberField decimalScale={2} InputProps={{ startAdornment: "$" }} name={`${fieldName}.answer`} />}
        {group.type == "string" && <TextField name={`${fieldName}.answer`} />}
      </Grid>
    </Grid>
  );
}

export { RiskRatingField };
