import { Route, Switch } from "react-router-dom";
import { NewFeeStructure } from "../../fee-structure/NewFeeStructure";
import { Agreements } from "./Agreements";

export default function Agreement() {
  return (
    <Switch>
      <Route exact path="/account/:key/fee-structure/new">
        {(params) => <NewFeeStructure accountKey={params.match?.params.key} />}
      </Route>
      <Route>
        <Agreements />
      </Route>
    </Switch>
  );
}
