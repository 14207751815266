import * as yup from "yup";
import { MmgFeeStructure } from "../../../api";

const mmgFeeStructureValidation: yup.Schema<MmgFeeStructure> = yup.object({
  feeType: yup.string().required("Fee type required"),
  flatAmount: yup.number().when("feeType", ([feeType], schema) => (feeType === "flat" ? schema.required("Amount required") : schema)),
  tier1: yup
    .number()
    .when(["feeType", "tier2", "tier3", "tier4"], ([feeType, tier2, tier3, tier4], schema) =>
      feeType === "tier"
        ? schema.test((tier1, context) =>
            tier1 === undefined || tier2 === undefined || tier3 === undefined || tier4 === undefined
              ? context.createError({ message: "All tier rates required" })
              : true
          )
        : schema
    ),
});

export { mmgFeeStructureValidation };
