import { History, LocationDescriptor } from "history";
import { Breadcrumb } from "../components/common/Breadcrumbs";
import { differenceInYears, getThemeByRoute } from "./";

function generateLink(
  currentPageName: string,
  to: LocationDescriptor<undefined | { breadcrumbs?: Breadcrumb[] }>,
  history: History<undefined | { breadcrumbs?: Breadcrumb[] }>
) {
  const descriptor: LocationDescriptor<undefined | { breadcrumbs?: Breadcrumb[] }> = typeof to === "string" ? { pathname: to } : to;
  descriptor.state = {
    ...history.location.state,
    breadcrumbs: [
      ...(history.location.state?.breadcrumbs ? history.location.state.breadcrumbs : []),
      {
        label: currentPageName,
        pathname: history.location.pathname,
        state: history.location.state,
        theme: getThemeByRoute(history.location.pathname),
      },
    ],
  };

  return descriptor;
}

function getRebalanceUrl(accountKey: string, dateOfBirth?: Date) {
  const age = differenceInYears(dateOfBirth, new Date());
  return `${import.meta.env.VITE_REBALANCE_URL}/Rebalance?AccountKey=${accountKey}&Age=${age ? Math.floor(age) : 0}`;
}

export { generateLink, getRebalanceUrl };
