import { Grid } from "@mui/material";
import { useEffect, useMemo } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store";
import { accountAgreementsAsync } from "../../../store/accounts";
import { formatValue, generateLink, userHasEitherRole } from "../../../utils";
import { AddIcon, Breadcrumb, Button, Link, Panel, Typography } from "../../common";
import { Form } from "../../form";
import Table from "../../table";

const getAmountPercent = ({ amount, percentage }: { amount?: number; percentage?: number }) => {
  if (amount !== undefined && percentage !== undefined) {
    return formatValue(amount, { type: "money", typeOption: { decimalPlaces: 0 } }) + ", " + formatValue(percentage, { type: "percent" });
  }

  if (amount !== undefined) {
    return formatValue(amount, { type: "money", typeOption: { decimalPlaces: 0 } });
  }

  if (percentage !== undefined) {
    return formatValue(percentage, { type: "percent" });
  }

  return "";
};

function Agreements() {
  const { key } = useParams<{ key: string }>();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((as) => as.oidc);
  const { account: accountDetails, agreements, isLoadingAgreements } = useAppSelector((as) => as.accounts);
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();
  const canCreateAgreement = useMemo(() => userHasEitherRole(user, ["ASP", "ASP Support", "MSS", "MSS Support"]), [user]);

  useEffect(() => {
    dispatch(accountAgreementsAsync({ accountKey: key }));
  }, [dispatch, key]);

  return (
    <>
      <Panel>
        <Grid item xs={12} sx={{ "& h3": { color: "common.black" } }}>
          <Typography variant="page-subheading">Fee & agreement</Typography>
        </Grid>
        {canCreateAgreement && !["WB", "IG"].includes(accountDetails?.product || "") && (
          <Grid item xs={12} paddingTop={1}>
            <Link
              to={generateLink(`${accountDetails?.name} (${accountDetails?.product})`, generatePath("/account/:key/fee-structure/new", { key }), history)}
              target="_blank"
            >
              <Button icon={<AddIcon />}>Create new</Button>
            </Link>
          </Grid>
        )}
      </Panel>
      <Form initialValues={{}}>
        <Table
          loading={isLoadingAgreements}
          rows={agreements}
          columns={[
            {
              heading: "Creation date",
              field: "createdDate",
              type: "date",
              align: "left",
              width: 10,
            },
            {
              heading: "Active",
              field: "active",
              display: "check",
              width: 10.5,
            },
            {
              heading: "Status",
              field: "status",
              width: 12.5,
            },
            {
              heading: "Version",
              field: "version",
              align: "center",
              width: 10,
            },
            {
              heading: "Agreement Number",
              field: "clientAgreementNumber",
              align: "center",
              width: 15,
            },
            {
              heading: "Notes",
              field: "notes",
              width: 42,
            },
          ]}
          rowExpand={(row, backgroundColor) => (
            <Table
              child
              rowBackgroundColor={backgroundColor}
              rows={row.fees}
              columns={[
                {
                  heading: "Group",
                  field: "group",
                  display: "emphasize",
                  width: 10,
                },
                {
                  heading: "Name",
                  field: (row) => row.name || <span style={{ visibility: "hidden" }}>$500,001-$1,000,000</span>, // hidden text helps with alignment
                  width: 9.75,
                },
                {
                  heading: "Amount/Percent",
                  field: (row) => getAmountPercent(row),
                  width: 13.25, //5.75, (if right align)
                },
                {
                  heading: "Gap",
                  field: () => "",
                  width: 67, //74.25, (if right align)
                },
              ]}
            />
          )}
        />
      </Form>
    </>
  );
}

export { Agreements };
