import { useEffect, useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { FileType } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { getUiContentData } from "../../store/adviceTools";
import { userHasEitherRole, userHasRole } from "../../utils";
import { PageHeading, Tab, Tabs } from "../common";
import { DocumentsDisplay } from "./DocumentsDisplay";
import { UnitPrices } from "./unit-prices";

type FormValues = Parameters<typeof getUiContentData>[0];

const DEFAULT_VALUES: FormValues = {
  filterInUse: true,
  includeCategoryNames: true,
  includeKeywords: false,
  includeProductNames: true,
  includeRoles: false,
  keywordsPrefixFilter: "",
};

export default function AdviceTools() {
  const { user } = useAppSelector((as) => as.oidc);
  const isAdmin = userHasRole(user, "Content Admin");
  const dispatch = useAppDispatch();
  const communicationsAndUnitPricesAccess = useMemo(
    () =>
      userHasEitherRole(user, [
        "KiwiSaver",
        "KiwiSaver Support",
        "WealthBuilder",
        "WealthBuilder Support",
        "IncomeGenerator",
        "IncomeGenerator Support",
        "ASP",
        "ASP Support",
        "MSS",
        "MSS Support",
      ]),
    [user]
  );

  useEffect(() => {
    dispatch(getUiContentData({ ...DEFAULT_VALUES, filterInUse: !isAdmin, includeRoles: isAdmin }));
  }, [dispatch, isAdmin]);

  return (
    <>
      <PageHeading
        heading="Documents &amp; Communications"
        tabs={
          <Tabs>
            <Tab>Documents</Tab>
            {communicationsAndUnitPricesAccess && <Tab path="communications">Communications</Tab>}
            {communicationsAndUnitPricesAccess && <Tab path="unit-prices">Unit prices</Tab>}
          </Tabs>
        }
      />
      <Switch>
        <Route exact path="/advice-tools/unit-prices">
          <UnitPrices />
        </Route>
        <Route exact path="/advice-tools/communications" key={FileType.Communication}>
          <DocumentsDisplay fileType={FileType.Communication} isAdmin={isAdmin} />
        </Route>
        <Route key={FileType.Document}>
          <DocumentsDisplay fileType={FileType.Document} isAdmin={isAdmin} />
        </Route>
      </Switch>
    </>
  );
}
