import { Box, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountRecurringTransactionsAsync } from "../../store/accounts";
import { Panel, Typography } from "../common";
import { Form } from "../form";
import Table from "../table";

type FormValues = Parameters<typeof accountRecurringTransactionsAsync>[0];

export default function RecurringTransactions() {
  const { key } = useParams<{ key: string }>();
  const dispatch = useAppDispatch();
  const { isLoadingRecurringTransactions, recurringTransactions } = useAppSelector((as) => as.accounts);
  const initialValues = useMemo<FormValues>(() => ({ accountKey: key }), [key]);

  const doSearch = useCallback((values: FormValues) => dispatch(accountRecurringTransactionsAsync({ ...values })), [dispatch]);

  useEffect(() => {
    const promise = doSearch(initialValues);
    return () => promise.abort();
  }, [doSearch, initialValues]);

  return (
    <Form initialValues={initialValues} onSubmit={doSearch} submitOnChange submitOnReset>
      <Panel>
        <Grid item xs={12}>
          <Typography variant="page-subheading">
            <Box component="span" sx={{ color: "common.black" }}>
              Recurring transactions
            </Box>
          </Typography>
        </Grid>
      </Panel>
      <Grid container sx={{ backgroundColor: "common.white" }}>
        <Table
          minHeight={343}
          loading={isLoadingRecurringTransactions}
          rows={recurringTransactions?.results}
          pageSizeHidden
          columns={[
            {
              heading: (
                <>
                  Transaction type <span style={{ fontSize: 10, lineHeight: 1, verticalAlign: "top" }}>1</span>
                </>
              ),
              field: "transactionTypeDisplay",
              width: 25,
            },
            {
              heading: "Amount",
              field: (row) => row.holdingAllocations?.reduce((total, trans) => total + (trans.amount || 0), 0),
              type: "money",
              display: "emphasize",
              width: 20,
            },
            {
              heading: "Frequency",
              field: "transactionFrequency",
              align: "right",
              width: 10,
            },
            {
              heading: "Next due date",
              field: "nextDueDate",
              type: "date",
              align: "right",
              display: "emphasize",
              width: 12,
            },
            {
              heading: "Payments left",
              field: "paymentsLeft",
              align: "right",
              width: 11,
            },
            {
              heading: "Payments run out",
              field: "paymentsRunOut",
              type: "date",
              align: "right",
              width: 12,
            },
          ]}
          rowExpand={(row, backgroundColor) => (
            <Table
              child
              rowBackgroundColor={backgroundColor}
              rows={row.holdingAllocations}
              columns={[
                { heading: "Fund", field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.fundName}</>, width: 25, display: "emphasize" },
                { heading: "Value", field: "amount", type: "money", width: 20, display: "emphasize" },
                { heading: "Date", field: () => <span style={{ visibility: "hidden" }}>00/00/0000</span>, width: 45 },
              ]}
            />
          )}
        />
        <span style={{ fontSize: 13 }}>
          1. Note some clients may have a Regular Savings configuration of $100 which is used as a percentage to calculate allocations. This may not be the
          amount received.
        </span>
      </Grid>
    </Form>
  );
}
