import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { Report, ReportField, ReportList } from "../../../components/reports";
import { useAppDispatch, useAppSelector } from "../../../store";
import { exportFundHistoryAsync, fundGroupsAsync, fundHistoryAsync } from "../../../store/funds";
import { getProducts, portfolioSort } from "../../../utils";
import { Autocomplete, DateField } from "../../form";
import { UnitPricesTable } from "./UnitPricesTable";

function UnitPrices() {
  const { fundGroups } = useAppSelector((as) => as.funds);
  const { user } = useAppSelector((as) => as.oidc);
  const dispatch = useAppDispatch();
  const products = useMemo(() => getProducts(user), [user]);
  const maxDate = useMemo(() => new Date(), []);

  useEffect(() => {
    if (!fundGroups?.fundGroupTypes?.length) dispatch(fundGroupsAsync());
  }, [dispatch, fundGroups?.fundGroupTypes?.length]);

  const filterFundGroups = useCallback(
    (values: { service?: string }) => {
      if (!fundGroups?.fundGroupTypes || fundGroups.fundGroupTypes.length === 0) return [];

      const filtered = !values.service
        ? fundGroups.fundGroupTypes.slice()
        : fundGroups.fundGroupTypes.filter((fundGroup) => !values.service || fundGroup.service === values.service);
      const flatten = filtered.flatMap((fundGroup) => fundGroup.funds || []);

      return (
        portfolioSort(
          flatten?.map((fund) => ({ value: fund.fundKey || "", label: fund.name || "" })).sort((a, b) => a.label.localeCompare(b.label)),
          "label"
        ) || []
      );
    },
    [fundGroups?.fundGroupTypes]
  );

  return (
    <ReportList>
      <Report
        name="Unit Prices"
        description="View historical unit pricing for a particular portfolio/strategy."
        initialValues={
          {
            fundKey: undefined,
            service: undefined,
          } as Parameters<typeof fundHistoryAsync>[0]
        }
        filters={
          <>
            <ReportField label="Choose product">
              <Autocomplete allOption="All" name="service" onChangeClear={["fundKey"]} options={products} />
            </ReportField>
            <ReportField label="Choose fund">
              <Autocomplete allOption="All" autoSelectIfOneOption name="fundKey" options={filterFundGroups} />
            </ReportField>
            <ReportField label="Choose dates" fieldFullWidth>
              <Grid item container xs alignItems="center" gap={3}>
                <Grid item xs="auto">
                  <DateField label="From" maxDate={maxDate} name="from" shrinkLabel />
                </Grid>
                <Grid item xs="auto">
                  <DateField label="To" maxDate={maxDate} name="to" shrinkLabel />
                </Grid>
              </Grid>
            </ReportField>
          </>
        }
        export={(values) => dispatch(exportFundHistoryAsync(values))}
        view={(values) => dispatch(fundHistoryAsync(values))}
        validate={(values) => {
          if (!values.from || !values.to) return "Both from and to dates are required.";
          if (values.from > values.to) return "From date must be before to date.";
        }}
      >
        <UnitPricesTable />
      </Report>
    </ReportList>
  );
}

export { UnitPrices };
