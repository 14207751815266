import { Grid } from "@mui/material";
import { ReactNode } from "react";

export default function ReportField({
  children,
  dualColumn,
  fieldFullWidth,
  label,
}: {
  children?: ReactNode;
  dualColumn?: boolean;
  fieldFullWidth?: boolean;
  label?: ReactNode;
}) {
  return (
    <Grid item container xs={12} paddingBottom={2}>
      <Grid item xs={12} md={dualColumn ? 4 : 2} sx={{ alignSelf: "top", pt: typeof children !== "string" && typeof label === "string" ? 1 : undefined }}>
        {label}
      </Grid>
      <Grid item container xs={12} md={dualColumn ? 8 : fieldFullWidth ? 10 : 4} alignItems="center" gap={1} alignSelf="top">
        {children}
      </Grid>
    </Grid>
  );
}
