import { FieldType, FormatType } from "./format";

type Alignment = "left" | "center" | "right";

const defaultAlignment: Record<FieldType, Alignment | undefined> = {
  date: "center",
  money: "right",
  integer: "right",
  percent: "right",
  decimal: "right",
  special: "left",
  phone: "right",
  quarter: "center",
};

const getAlignment = (options: { align?: Alignment } & FormatType): Alignment | undefined => {
  if (options.align) {
    return options.align;
  }

  return options.type ? defaultAlignment[options.type] : undefined;
};

export type { Alignment };
export { getAlignment };
