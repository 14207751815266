import type {} from "@mui/x-date-pickers/themeAugmentation";
import { createTheme, ThemeOptions } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { SPACING_MULTIPLIER } from "../../constants";
import { BLACK, BLUE, GREEN, RED } from "../colors";
import SonsTabMedium from "../fonts/SonsTab-Medium.ttf";
import SonsTabRegular from "../fonts/SonsTab-Regular.ttf";

/** Set a few base theme defaults - reused further below to fully declare our theme. */
const initialThemeOptions: ThemeOptions = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 400,
      md: 800,
      lg: 1100,
      xl: 1536,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'SonsTab';
          font-weight: 400;
          src: url(${SonsTabRegular}) format('truetype');
        }
        @font-face {
          font-family: 'SonsTab';
          font-weight: 500;
          src: url(${SonsTabMedium}) format('truetype');
        }
        body {
          background-color: ${BLACK[30]};
          margin: 0;
          padding: 0;
        }
        html, body, #root {
          height: 100%;
          width: 100%;
        }
        html {
          overscroll-behavior: none;
          scrollbar-color: ${BLACK[100]} ${BLACK[30]};
          scrollbar-width: thin;
        }
        b, strong {
          font-weight: 500 !important;
        }
        a, a:visited, a:active, a:hover {
          color: inherit;
          outline: 0;
          text-decoration: none;
        }
        ::selection {
          background-color: ${BLUE[100]};
          color: ${BLACK[0]};
        }`,
    },
  },
  palette: {
    mode: "light",
    background: {
      default: BLACK[20],
      paper: BLACK[0],
    },
    common: {
      black: BLACK[100],
      white: BLACK[0],
      brandLow: BLACK[70],
      brandMid: BLACK[40],
      brandHigh: BLACK[20],
    },
    divider: BLACK[30],
    error: {
      light: RED[10],
      main: RED[100],
      dark: RED[120],
      contrastText: BLACK[0],
    },
    primary: {
      light: BLUE[10],
      lighter: BLUE[50],
      main: BLUE[100],
      dark: BLUE[110],
      contrastText: BLACK[0],
    },
    secondary: {
      light: BLACK[0],
      main: BLACK[0],
      dark: BLACK[20],
      contrastText: BLACK[100],
    },
    success: {
      light: BLACK[0],
      main: GREEN[100],
      dark: GREEN[120],
      contrastText: BLACK[0],
    },
    text: {
      secondary: BLACK[60],
      disabled: BLACK[40],
      primary: BLACK[100],
    },
  },
  shape: {
    borderRadius: SPACING_MULTIPLIER,
  },
  spacing: (factor: number | string) => (typeof factor === "string" ? factor : SPACING_MULTIPLIER * factor),
  transitions: {
    duration: {
      enteringScreen: 276,
      leavingScreen: 200,
      shortest: 200,
      shorter: 225,
      short: 249,
      standard: 276,
      complex: 300,
    },
  },
  typography: {
    fontFamily: ["SonsTab", "Helvetica", "Arial", "sans-serif"].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 500,
  },
};

/** Create the theme for reusing below - primarily for colors. */
const { palette, shape, spacing, typography } = createTheme(initialThemeOptions);

const otherThemeOptions: ThemeOptions = {
  components: {
    MuiAccordion: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
          width: "100%",
          ":before": {
            opacity: 0,
          },
          "&.Mui-expanded": {
            ":before": {
              opacity: 0,
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.white,
          ":after": {
            content: '""',
            position: "absolute",
            width: "100%",
            height: 1,
            background: "rgba(0, 0, 0, 0.12)",
            top: "100%",
            left: 0,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        fullWidth: true,
      },
      styleOverrides: {
        inputRoot: {
          padding: 0,
          "& .MuiInputBase-input": {
            color: palette.text.primary,
            fontSize: typography.pxToRem(16),
            lineHeight: 18 / 16,
            paddingBottom: 2,
            paddingLeft: 9,
            paddingRight: 9,
            paddingTop: 12,
          },
          "&.no-label": {
            "& .MuiInputBase-input": {
              paddingTop: 7,
              paddingBottom: 7,
            },
          },
          "& .MuiFilledInput-root .MuiFilledInput-input": {
            padding: `${spacing(1)} ${spacing(2)}`,
          },
        },
        listbox: {
          padding: 0,
          scrollbarColor: `${palette.primary.main} ${palette.background.default}`,
          scrollbarWidth: "thin",
          "& .MuiAutocomplete-option": {
            color: palette.text.primary,
            fontSize: typography.pxToRem(16),
            padding: "6px 9px",
            "&.Mui-focused": {
              backgroundColor: palette.background.paper,
              color: palette.primary.main,
            },
            "&:hover": {
              backgroundColor: palette.background.paper,
              color: palette.primary.main,
            },
            "&[aria-selected='true']:hover": {
              color: palette.primary.main,
            },
          },
        },
        loading: {
          fontSize: typography.pxToRem(16),
        },
        noOptions: {
          fontSize: typography.pxToRem(16),
        },
        paper: {
          borderRadius: shape.borderRadius,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          lineHeight: 1.14,
          minHeight: 36,
          padding: `0 ${spacing(2)}`,
          textTransform: "none",
          "&:disabled": {
            backgroundColor: palette.primary.light,
            borderWidth: 0,
            color: palette.primary.contrastText,
          },
          "& .spinner": {
            height: typography.pxToRem(18),
            left: "50%",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            "& .spinner-indicator": {
              left: "50%",
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
            },
          },
        },
        outlined: {
          backgroundColor: palette.background.paper,
          borderWidth: 0,
          color: palette.primary.main,
          "&.invert": {
            backgroundColor: palette.background.default,
          },
          "&:hover": {
            backgroundColor: palette.primary.dark,
            borderWidth: 0,
            color: palette.primary.contrastText,
          },
          "&:disabled": {
            backgroundColor: palette.primary.light,
            borderWidth: 0,
            color: palette.primary.contrastText,
          },
        },
        text: {
          color: palette.primary.main,
          minWidth: 0,
          paddingBottom: 0,
          paddingTop: 0,
          "&:hover": {
            backgroundColor: palette.primary.light,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: palette.background.paper,
          borderColor: palette.common.brandMid,
          borderStyle: "solid",
          borderWidth: 1,
          borderRadius: shape.borderRadius,
          height: 20,
          margin: 0,
          paddingBottom: 0,
          paddingLeft: 8,
          svg: {
            height: 18,
            width: 18,
          },
        },
        label: {
          lineHeight: 1.07,
          paddingLeft: 0,
          paddingRight: 8,
        },
        deleteIcon: {
          marginTop: 2,
          marginRight: 1,
          "&:hover": {
            color: palette.primary.dark,
          },
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.background.default,
          borderColor: palette.background.default,
          borderRadius: shape.borderRadius,
          borderStyle: "solid",
          borderWidth: 2,
          "&.invert": {
            backgroundColor: palette.background.paper,
            borderColor: palette.background.paper,
          },
          "&.Mui-disabled": {
            backgroundColor: palette.background.default,
            "&.invert": {
              backgroundColor: palette.background.paper,
            },
          },
          "&.Mui-error": {
            borderColor: palette.error.main,
          },
          "&.Mui-focused": {
            borderColor: palette.primary.main,
          },
          "&:not(&.Mui-focused):hover": {
            backgroundColor: palette.background.default,
            borderColor: palette.common.brandLow,
            "&.invert": {
              backgroundColor: palette.background.paper,
            },
          },
          "&.MuiInputBase-multiline": {
            height: "auto",
            minHeight: 39,
            padding: 0,
            textarea: {
              fontSize: typography.pxToRem(16),
              lineHeight: 18 / 16,
              padding: "12px 11px 5px",
            },
          },
          "&.MuiInputBase-adornedEnd": {
            "& .MuiIconButton-root": {
              padding: 4,
              "&:last-child": {
                marginLeft: 3,
              },
              "& > svg": {
                width: 24,
                height: 24,
              },
            },
            "& > .MuiSvgIcon-root": {
              marginRight: 9,
            },
          },
          "& .MuiIconButton-root": {
            color: BLUE[100],
            marginRight: 0,
            "&:hover": {
              backgroundColor: palette.primary.light,
            },
          },
          "& .MuiInputAdornment-positionEnd": {
            marginLeft: 6,
            marginRight: 6,
          },
          "&.MuiInputBase-root": {
            paddingRight: 0,
          },
          "& .MuiChip-root": {
            marginLeft: 6,
            marginBottom: 2,
            "&:first-of-type": {
              marginTop: 15,
            },
          },
          "&.no-label": {
            "& .MuiChip-root": {
              marginTop: 3,
            },
            "& .MuiInputBase-input": {
              paddingTop: 7,
              paddingBottom: 7,
            },
          },
          "& .MuiInputBase-input": {
            color: palette.text.primary,
            fontSize: typography.pxToRem(16),
            lineHeight: 18 / 16,
            paddingBottom: 2,
            paddingLeft: 9,
            paddingRight: 9,
            paddingTop: 12,
          },
          "& .MuiCircularProgress-root": {
            color: palette.primary.main,
            fontSize: typography.pxToRem(24),
            height: typography.pxToRem(24),
            marginRight: 7,
            padding: 3,
            width: typography.pxToRem(24),
          },
          "&.stacked-tags": {
            alignItems: "flex-start",
            flexDirection: "column",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& > .MuiFormControlLabel-labelPlacementEnd": {
            marginLeft: -9,
            marginRight: 0,
          },
          "& > .MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 0,
            marginRight: -9,
          },
        },
      },
    },
    MuiFormGroup: {
      styleOverrides: {
        root: {
          "& > .MuiFormControlLabel-labelPlacementEnd": {
            marginLeft: -9,
            marginRight: 0,
          },
          "& > .MuiFormControlLabel-labelPlacementStart": {
            marginLeft: 0,
            marginRight: -9,
          },
          "&.MuiFormGroup-row": {
            "& > .MuiFormControlLabel-labelPlacementEnd": {
              marginRight: 15,
            },
            "& > .MuiFormControlLabel-labelPlacementStart": {
              marginLeft: 15,
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(12),
          fontWeight: 400,
          lineHeight: 1.3,
          margin: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: typography.pxToRem(16),
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: palette.primary.light,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.text.secondary,
          fontSize: typography.pxToRem(16),
          lineHeight: 18 / 16,
          paddingRight: 30,
          userSelect: "none",
          transform: "translate(11px, 17px) scale(1)",
          "&.MuiInputLabel-shrink": {
            transform: "translate(11px, 3px) scale(0.7)",
          },
          "&.Mui-disabled": {
            color: palette.text.disabled,
          },
          "&.Mui-error": {
            color: palette.error.main,
            "&.MuiInputLabel-shrink": {
              color: palette.error.main,
            },
          },
        },
      },
    },
    MuiPagination: {
      defaultProps: {
        boundaryCount: 1,
        shape: "rounded",
        siblingCount: 4,
      },
      styleOverrides: {
        root: {
          alignItems: "end",
          display: "flex",
          flexDirection: "column",
          width: "100%",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          lineHeight: 1.17,
          height: 36,
          minWidth: 36,
          "&.Mui-selected": {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
            "&:hover": {
              backgroundColor: palette.primary.dark,
            },
          },
        },
        previousNext: {
          color: palette.primary.main,
          backgroundColor: palette.common.brandHigh,
          "&:hover": {
            backgroundColor: palette.divider,
          },
        },
        ellipsis: {
          color: palette.common.brandMid,
          lineHeight: 1.8,
        },
        page: {
          color: palette.common.brandLow,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .Mui-checked+.MuiSwitch-track": {
            backgroundColor: palette.primary.light,
            opacity: 1,
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: palette.common.brandLow,
          borderTopLeftRadius: shape.borderRadius,
          borderTopRightRadius: shape.borderRadius,
          textTransform: "none",
          minWidth: 120,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginBottom: -1,
        },
        flexContainer: {
          position: "relative",
          zIndex: 2,
        },
        indicator: {
          backgroundColor: palette.common.white,
          borderLeftColor: palette.divider,
          borderLeftStyle: "solid",
          borderLeftWidth: 1,
          borderRightColor: palette.divider,
          borderRightStyle: "solid",
          borderRightWidth: 1,
          borderTopColor: palette.divider,
          borderTopStyle: "solid",
          borderTopWidth: 1,
          height: "100%",
          zIndex: 1,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          paddingBottom: 12,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: "none",
          paddingBottom: 6,
          paddingLeft: 9,
          paddingRight: 9,
          paddingTop: 6,
        },
        body: {
          fontWeight: 400,
        },
        head: {
          backgroundColor: palette.primary.main,
          color: palette.common.white,
          lineHeight: 1.1,
          "& span": {
            color: `${palette.common.white} !important`,
            "& > svg": {
              color: `${palette.common.white} !important`,
              width: 24,
              height: 24,
            },
          },
        },
        sizeSmall: {
          fontSize: typography.pxToRem(13),
          paddingTop: 6,
          paddingRight: 9,
          paddingBottom: 9,
          paddingLeft: 6,
        },
        footer: {
          color: palette.common.black,
          fontSize: typography.pxToRem(14),
          fontWeight: 500,
          borderTopColor: palette.divider,
          borderTopStyle: "solid",
          borderTopWidth: 1,
          "&:first-of-type": {
            borderTopColor: "transparent",
          },
          "&:last-of-type": {
            borderTopColor: "transparent",
          },
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: palette.common.brandHigh,
          borderColor: palette.divider,
          borderStyle: "solid",
          borderBottomWidth: 1,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 1,
          scrollbarColor: `${palette.primary.main} ${palette.background.default}`,
          scrollPadding: 0,
          scrollbarWidth: "thin",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.MuiTableRow-hover:hover": {
            backgroundColor: palette.primary.light,
          },
        },
        head: {
          backgroundColor: palette.primary.main,
          height: 42,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
        fullWidth: true,
        variant: "filled",
      },
      styleOverrides: {
        root: {
          "& input::selection": {
            backgroundColor: palette.primary.main,
            color: palette.primary.contrastText,
          },
        },
      },
    },
  },
  typography: {
    body1: {
      color: palette.common.brandLow,
      fontSize: typography.pxToRem(16),
      fontWeight: 400,
    },
    caption: {
      color: palette.text.secondary,
      fontSize: typography.pxToRem(13),
      fontWeight: 500,
    },
    h1: {
      color: palette.common.black,
      fontSize: typography.pxToRem(30),
      fontWeight: 500,
    },
    h2: {
      color: palette.common.brandLow,
      fontSize: typography.pxToRem(16),
      fontWeight: 500,
    },
    h3: {
      color: palette.common.brandLow,
      fontSize: typography.pxToRem(20),
      fontWeight: 400,
    },
    h6: {
      fontSize: typography.pxToRem(30),
      fontWeight: 500,
    },
    subtitle2: {
      color: palette.common.black,
      fontSize: typography.pxToRem(18),
      fontWeight: 500,
      lineHeight: 1.25,
    },
  },
};

/** Module augmentation to adds custom colors and variants to Mui themes. */
declare module "@mui/material/styles" {
  interface CommonColors {
    brandLow: CommonColors["black"];
    brandMid: CommonColors["black"];
    brandHigh: CommonColors["black"];
  }

  interface PaletteColor {
    lighter?: PaletteColor["light"];
  }

  interface SimplePaletteColorOptions {
    lighter?: SimplePaletteColorOptions["light"];
  }
}

/** Module augmentation to adds custom colors and variants to Mui themes. */
declare module "@mui/material/TextField" {
  interface FilledTextFieldProps {
    /** Inversed theme colors. Typically used when the TextField's container has the same background color as the TextField's.  */
    invert?: boolean;
  }
}

const primaryThemeOptions = deepmerge(initialThemeOptions, otherThemeOptions);
const primaryTheme = createTheme(primaryThemeOptions);

export { primaryTheme, primaryThemeOptions };
