import { Route, Switch } from "react-router-dom";
import { useAppSelector } from "../../../store";
import { Panel } from "../../common";
import { NewRiskRating } from "../../risk-rating/NewRiskRating";
import RiskRatings from "./RiskRatings";

export default function RiskRating() {
  const { isLoadingDetails, account: accountDetails } = useAppSelector((as) => as.accounts);

  return (
    <Switch>
      <Route exact path="/account/:key/risk-rating/new">
        {isLoadingDetails ? (
          <Panel>Loading...</Panel>
        ) : (
          <NewRiskRating accountKey={accountDetails?.accountKey} accountName={accountDetails?.name} adviser={accountDetails?.advisers?.at(0)} />
        )}
      </Route>
      <Route>
        <RiskRatings />
      </Route>
    </Switch>
  );
}
