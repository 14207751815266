import { Autocomplete as MuiAutocomplete, FormHelperText, TextField as MuiTextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useEffect, useLayoutEffect, useState } from "react";
import { GetGisFeePresetDetailDto, GetGisFeePresetsResponse } from "../../../api";
import { useAppDispatch, useAppSelector } from "../../../store";
import { accountSearchClear, searchAccountAsync } from "../../../store/accounts";
import { className } from "../../../utils";
import { HelpIcon } from "../../common";
import { Checkbox } from "../../form";
import { IconPopover } from "../../form/IconPopover";
import { FeeAgreementList } from "../FeeAgreementList";
import { FeeAgreementSelector } from "../FeeAgreementSelector";

interface GisFeeStructureProps {
  presets: GetGisFeePresetsResponse;
  feeEntered: () => void;
}

const flatDetailsToObject = (details: GetGisFeePresetDetailDto[] | undefined) =>
  details?.reduce(
    (flats, detail) => {
      if (detail.gisFeeFundGroupID)
        flats[detail.gisFeeFundGroupID] = detail.tierOption || detail.flatRate === undefined || detail.flatRate === null ? null : Number(detail.flatRate);
      return flats;
    },
    {} as Record<string, number | null>
  );

function getDefaultFormValues(presets: GetGisFeePresetsResponse) {
  return {
    deductFromFundId: undefined,
    deductPerFundOption: true,
    details: flatDetailsToObject(presets.default.details),
    fees: [],
    gstRate: presets.default.gstRate,
    otherFeeAmount: undefined,
    otherFeeDescription: "",
    regularSavingFeeRate: undefined,
    tierRates: undefined,
  };
}

function GisFeeStructure({ feeEntered, presets }: GisFeeStructureProps) {
  const { accounts, isLoadingAccounts } = useAppSelector((as) => as.accounts);
  const { errors } = useAppSelector((as) => as.accounts);
  const dispatch = useAppDispatch();
  const [combineTiered, setCombineTiered] = useState(false);
  const [, , accountKeyHelper] = useField<string | undefined>("gis.linkedAccountKey");
  const [, , tierRatesHelper] = useField<number[] | undefined>("gis.tierRates");
  const [{ value }, , { setValue }] = useField<string[]>("gis.fees");
  const { setFieldValue } = useFormikContext();

  useLayoutEffect(() => {
    // clear the other fields if we change the adviser's agreement type.
    setFieldValue("pw", undefined);
    setFieldValue("mmg", undefined);
    setFieldValue("gis", getDefaultFormValues(presets));
  }, [presets, setFieldValue]);

  useEffect(() => {
    // clear the other fields if we change the adviser's agreement type.
    setFieldValue("gis.tierRates", undefined);
    setFieldValue("gis.linkedAccountKey", "");

    if (combineTiered) {
      // clear additional fees when "Combine accounts for tiered rate for ongoing fees" is chosen.
      setFieldValue("gis.details", {});
      setFieldValue("gis.regularSavingFeeRate", undefined);
      setFieldValue("gis.otherFeeAmount", undefined);
      setFieldValue("gis.otherFeeDescription", "");
    }
  }, [combineTiered, setFieldValue]);

  return (
    <FeeAgreementSelector
      field="gis.fees"
      onChange={(changeType, value, { setFieldValue }) => {
        feeEntered();

        if (changeType === "remove") {
          switch (value) {
            case "flat":
              setFieldValue("gis.details", {});
              break;
            case "tier":
              setFieldValue("gis.tierRates", undefined);
              setFieldValue("gis.linkedAccountKey", "");
              break;
            case "upfront":
              setFieldValue("gis.regularSavingFeeRate", undefined);
              break;
            case "other":
              setFieldValue("gis.otherFeeAmount", undefined);
              setFieldValue("gis.otherFeeDescription", "");
              break;
          }
        }
      }}
    >
      {!combineTiered && (
        <FeeAgreementList
          value="flat"
          name="Flat rate"
          field="gis.details"
          allOption="All portfolios"
          heading={{ label: "Category", field: "Fee" }}
          fees={
            presets.default?.details?.map((detail) => ({ field: `gis.details[${detail.gisFeeFundGroupID}]`, name: detail.fundName ?? "", type: "rate" })) ?? []
          }
        />
      )}
      {!combineTiered && (
        <FeeAgreementList
          value="other"
          name="Other service"
          fees={[
            { field: "gis.otherFeeAmount", name: "Amount pa", type: "amount", moreSpacing: true },
            { field: "gis.otherFeeDescription", name: "Description", type: "note" },
          ]}
        />
      )}
      <FeeAgreementList
        value="tier"
        name="Tiered rate"
        field="gis.tierRates"
        description="Applied to fund groups without a selected flat rate fee"
        heading={{ label: "Tier", field: "Fee" }}
        tiered
        fees={(values: { gisFeeAdviserPresetId?: number }) =>
          (
            (
              (values.gisFeeAdviserPresetId && presets.records?.find((preset) => preset.gisFeeAdviserPresetId === values.gisFeeAdviserPresetId)) ||
              presets.default
            )?.tierGates + ";"
          )
            .split(";")
            .map((threshold, index) => ({
              field: `gis.tierRates[${index}]`,
              name: `Tier ${index}`,
              disabled: combineTiered,
              type: "rate",
              thresholdTo: threshold ? Number(threshold) : undefined,
            }))
        }
        additionalFields={
          <>
            <Checkbox
              name="combineFees"
              checkboxPlacement="end"
              onChange={(c) => {
                setCombineTiered(c);
                !!c && setValue(value.filter((x) => ["flat", "other", "upfront"].indexOf(x) === -1));
                accountKeyHelper.setValue(undefined);
                tierRatesHelper.setValue(undefined);
              }}
            >
              Combine accounts for tiered rate ongoing fees?
            </Checkbox>
            <IconPopover
              message={
                <div style={{ textAlign: "left", paddingLeft: 20, paddingRight: 20, paddingTop: 10, paddingBottom: 10 }}>
                  You can only select an account for which a fee has been set up.
                  <br />
                  You cannot select an account which you have previously linked.
                </div>
              }
              icon={<HelpIcon color="inherit" fontSize="small" />}
            />
            {combineTiered && (
              <MuiAutocomplete
                fullWidth
                onChange={(_, selected) => accountKeyHelper.setValue(selected?.accountKey)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    autoComplete="off"
                    InputProps={{ ...params.InputProps, className: className(params.InputProps.className, "no-label") }}
                    placeholder="Enter parent account key or name"
                    onBlur={() => dispatch(accountSearchClear())}
                    onChange={(e) => {
                      e.target.value.length >= 3 ? dispatch(searchAccountAsync(e.target.value)) : dispatch(accountSearchClear());
                    }}
                  />
                )}
                loading={isLoadingAccounts}
                options={accounts ?? []}
                getOptionLabel={(option) => `${option.accountKey} (${option.name})`}
              />
            )}
            {errors?.status === 404 && <FormHelperText error>Selected account does not have fee setup</FormHelperText>}
          </>
        }
      />
      {!combineTiered && (
        <FeeAgreementList
          value="upfront"
          name="Upfront regular savings"
          field="gis.regularSavingFeeRate"
          fees={[{ field: "gis.regularSavingFeeRate", name: "Regular contribution fee", type: "rate" }]}
        />
      )}
    </FeeAgreementSelector>
  );
}

export { GisFeeStructure };
