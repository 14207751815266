import { useAppSelector } from "../../../store";
import Table from "../../table";

function RiskAssessmentByCountry() {
  const { isLoading, riskAssessmentByCountry } = useAppSelector((as) => as.compliance);

  return !isLoading && !riskAssessmentByCountry ? null : (
    <Table
      loading={isLoading}
      loadingBlankRows={15}
      minHeight={341}
      rows={riskAssessmentByCountry}
      columns={[
        {
          heading: "Adviser Branch",
          field: "adviserBranchName",
          width: 30,
        },
        {
          heading: "Country",
          field: () => "",
          width: 30,
        },
        {
          heading: "Accounts",
          field: "investorCount",
          type: "integer",
          width: 20,
        },
        {
          heading: "Value",
          field: "value",
          type: "money",
          width: 20,
        },
      ]}
      rowExpand={(data, backgroundColor) => (
        <Table
          child
          rowBackgroundColor={backgroundColor}
          rows={data.rows}
          columns={[
            {
              heading: "Adviser Branch",
              field: () => "",
              width: 30,
            },
            {
              heading: "Country",
              field: "countryName",
              width: 30,
            },
            {
              heading: "Accounts",
              field: "investorCount",
              type: "integer",
              width: 20,
            },
            {
              heading: "Value",
              field: "value",
              type: "money",
              width: 20,
            },
          ]}
        />
      )}
    />
  );
}

export { RiskAssessmentByCountry };
