import { Grid } from "@mui/material";
import { GetAccountDetailsResponse, LegalEntityDto } from "../../api";
import Theme from "../../theme";
import { Chip, ThemeIcon, Typography } from "../common";

function legalEntitiesGetSortByRoleType(legalEntity: LegalEntityDto) {
  return (legalEntity.roles.some((r) => r === "Primary") ? "AAA" : legalEntity.roles || "ZZZ") + "-" + legalEntity.name;
}

function legalEntitiesSortByRoleType(legalEntities?: LegalEntityDto[]) {
  return legalEntities?.slice().sort((a, b) => legalEntitiesGetSortByRoleType(a).localeCompare(legalEntitiesGetSortByRoleType(b)));
}

export default function LegalEntitiesDetail(values: GetAccountDetailsResponse) {
  return (
    <Theme variant="client">
      <Typography variant="label">
        <Grid container spacing={1} paddingTop={0.5}>
          {legalEntitiesSortByRoleType(values.legalEntities)?.map(({ roles, name }) => (
            <Grid key={name} item>
              <Chip startAdornment={<ThemeIcon />}>
                {name} <span style={{ fontWeight: 400 }}>{values.clients?.length === 1 && roles.some((r) => r === "Primary") ? undefined : roles}</span>
              </Chip>
            </Grid>
          ))}
        </Grid>
      </Typography>
    </Theme>
  );
}
