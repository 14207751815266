import { Box, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useCallback, useMemo } from "react";
import { GUTTER_SIZE } from "../../constants";
import { getAlignment } from "../../utils";
import { ColumnDefinition } from "./ColumnDefinition";
import { SortLabel } from "./SortLabel";

interface HeaderProps<Row, Sort> {
  columns: ColumnDefinition<Row, Sort>[];
  child: boolean;
}

function Heading<Row, Sort extends string>({ col, loading, sort }: { col: ColumnDefinition<Row, Sort>; loading?: boolean; sort?: string }) {
  let heading = (
    <>
      {col.heading}
      {col.headingSuffix}
    </>
  );

  if (col.headingHover)
    heading = (
      <Tooltip title={col.headingHover}>
        <span>{heading}</span>
      </Tooltip>
    );

  if (col.sort)
    heading = (
      <SortLabel
        active={sort === col.sort || sort === col.sort + "_Desc"}
        direction={!sort ? undefined : sort === col.sort + "_Desc" ? "desc" : "asc"}
        disabled={loading}
      >
        {heading}
      </SortLabel>
    );

  return heading;
}

export default function Header<Row, Sort extends string>({ columns, child }: HeaderProps<Row, Sort>) {
  const { submitForm } = useFormikContext();
  const [{ value }, , { setValue }] = useField<string>("sortOrder");
  const cellStyle = useMemo(() => ({ backgroundColor: child ? "common.brandHigh" : "primary.main", py: 0 }), [child]);
  const childStyle = useMemo(() => (child ? { backgroundColor: "common.brandLow" } : { ...cellStyle, py: 1.5 }), [cellStyle, child]);

  const handleSortChange = useCallback(
    async (sort: Sort | undefined) => {
      if (!sort) return;

      await setValue(value === sort ? sort + "_Desc" : sort);
      submitForm();
    },
    [setValue, submitForm, value]
  );

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ ...cellStyle, p: 0, width: 0 }}>
          <Box sx={({ typography }) => ({ width: typography.pxToRem(GUTTER_SIZE) })} />
        </TableCell>
        {columns.map((col, colIndex) => (
          <TableCell align={getAlignment(col)} key={colIndex} onClick={() => handleSortChange(col.sort)} sx={{ ...childStyle, width: `${col.width}%` }}>
            <Heading col={col} sort={value} />
          </TableCell>
        ))}
        <TableCell sx={{ ...cellStyle, p: 0, width: 0 }}>
          <Box sx={({ typography }) => ({ width: typography.pxToRem(GUTTER_SIZE) })} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
