import { Tooltip } from "@mui/material";
import { ReactNode } from "react";
import { HelpIcon } from "./";

interface HelpTextProps {
  text: NonNullable<ReactNode>;
}
const HelpText = ({ text }: HelpTextProps) => (
  <Tooltip title={text} style={{ position: "absolute", marginLeft: "0.2em", marginTop: "3px" }}>
    <HelpIcon fontSize="small" />
  </Tooltip>
);

export { HelpText };
