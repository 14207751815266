import SwitchLeftIcon from "@mui/icons-material/SwitchLeftRounded";
import { Box, SvgIcon, SvgIconProps } from "@mui/material";

interface SortIconProps {
  direction?: "asc" | "desc";
}

function CustomSortIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M 8.629 8.491 L 15.389 8.491 L 12.009 5.111 L 8.629 8.491 Z M 11.299 3.701 C 11.689 3.311 12.319 3.311 12.709 3.701 L 17.299 8.291 C 17.929 8.911 17.489 9.991 16.599 9.991 L 7.419 9.991 C 6.529 9.991 6.079 8.911 6.709 8.281 L 11.299 3.701 Z M 8.629 15.501 L 15.389 15.501 L 12.009 18.881 L 8.629 15.501 Z M 11.299 20.291 C 11.689 20.681 12.319 20.681 12.709 20.291 L 17.299 15.701 C 17.929 15.081 17.489 14.001 16.599 14.001 L 7.419 14.001 C 6.529 14.001 6.079 15.081 6.709 15.711 L 11.299 20.291 Z"></path>
      </svg>
    </SvgIcon>
  );
}

export function SortIcon({ direction }: SortIconProps) {
  return (
    <Box
      sx={{
        height: 24,
        my: 0,
        p: 0,
        position: "relative",
        width: 24,
      }}
    >
      {!direction && <CustomSortIcon />}
      {!!direction && <SwitchLeftIcon sx={{ transform: `rotate(${direction === "asc" ? 90 : -90}deg)` }} />}
    </Box>
  );
}
