import {
  Box,
  Collapse,
  Grid,
  Pagination,
  PaginationItem,
  PaginationRenderItemParams,
  Skeleton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
} from "@mui/material";
import { useField, useFormikContext } from "formik";
import { LocationDescriptor } from "history";
import { memo, ReactNode, useCallback, useMemo } from "react";
import { GUTTER_SIZE, PAGE_SIZE_OPTIONS } from "../../constants";
import { getAlignment } from "../../utils";
import { CircularProgress, Typography } from "../common";
import { Autocomplete } from "../form/Autocomplete";
import { CollapseWrapper } from "./CollapseWrapper";
import { Column } from "./Column";
import { ColumnDefinition } from "./ColumnDefinition";
import Header from "./Header";
import { getFooter } from "./value";

interface Paging {
  currentPage?: number;
  pageCount?: number;
  pageSize?: number;
}

interface TableProps<Row, Sort> {
  /** Defaults to `false`. */
  child?: boolean;
  columns: ColumnDefinition<Row, Sort>[];
  disableNoRowsMessage?: boolean;
  handlePageChange?: (page: number) => void;
  loading?: boolean;
  loadingBlankRows?: number;
  minHeight?: number;
  onRowClick?: (data: Row) => void;
  onToggleCollapse?: (data: Row, isOpen?: boolean) => void;
  pageSizeHidden?: boolean;
  paging?: Paging;
  rowBackgroundColor?: string;
  rowExpand?: (data: Row, backgroundColor?: string) => ReactNode;
  /** Defaults to `hidden`. */
  rowExpanded?: "onload" | "always" | "hidden";
  rows?: Row[];
  to?: (data: Row, additionalData: { values: unknown }) => LocationDescriptor<unknown>;
}

const gutterTableCell = (
  <TableCell sx={{ backgroundColor: "common.brandHigh", p: 0 }} width="0">
    <Box sx={({ spacing }) => ({ width: spacing(GUTTER_SIZE) })} />
  </TableCell>
);

const loadingSpinner = (
  <Box sx={{ borderRadius: "50%", position: "absolute", pr: 3, pt: 1.5, right: 0, top: 0, zIndex: 3 }}>
    <CircularProgress small />
  </Box>
);

const paddingTopRow = (
  <TableRow>
    <TableCell sx={{ height: 12, padding: 0 }} />
  </TableRow>
);

function renderItem(params: PaginationRenderItemParams) {
  return <PaginationItem {...params} />;
}

const skeleton = <Skeleton height="1.1rem" sx={{ my: 0.5 }} width="100%" variant="rounded" />;

function InternalTable<Row, Sort extends string>({
  child = false,
  columns,
  disableNoRowsMessage,
  handlePageChange,
  loading,
  loadingBlankRows,
  pageSizeHidden,
  minHeight = 0,
  onRowClick,
  onToggleCollapse,
  paging,
  rowBackgroundColor,
  rowExpand,
  rowExpanded = "hidden",
  rows,
  to,
}: TableProps<Row, Sort>) {
  const [, , { setValue }] = useField<number | undefined>("currentPage");
  const [{ value }, ,] = useField<number | undefined>("pageSize");
  const { submitForm, values } = useFormikContext();
  const currentPage = useMemo(() => paging?.currentPage || 0, [paging?.currentPage]);
  const pageCount = useMemo(() => paging?.pageCount || 0, [paging?.pageCount]);
  const pageSize = useMemo(() => paging?.pageSize || 35, [paging?.pageSize]);
  const hasMultiplePages = useMemo(() => pageCount > 1, [pageCount]);
  const showFooter = useMemo(() => !!rows && columns.some((col) => col.footer), [columns, rows]);
  const footerBackgroundColor = useMemo(() => ((rows?.length || 0) % 2 === 1 ? "background.default" : "background.paper"), [rows?.length]);
  const _pageSizeHidden = useMemo(() => !!pageSizeHidden || !!child || !value, [child, pageSizeHidden, value]);

  const onPaginationChange = useCallback(
    async (_, page: number) => {
      const toPage = page - 1;

      if (!handlePageChange) {
        await setValue(toPage);
        submitForm();
      } else {
        handlePageChange(toPage);
      }
    },
    [handlePageChange, setValue, submitForm]
  );

  return (
    <Grid
      container
      item
      xs={12}
      sx={{ flexGrow: 1, height: child ? undefined : minHeight, maxHeight: `calc(100vh - calc(100% - ${minHeight}px))`, position: "relative" }}
    >
      {!!loading && loadingSpinner}
      <TableContainer
        sx={({ typography }) =>
          !child
            ? { maxHeight: `calc(100vh - calc(100% + ${typography.pxToRem(63)}))`, minHeight, position: "relative" }
            : { minHeight, position: "relative", borderTopWidth: 0, borderBottomWidth: 0 }
        }
      >
        <MuiTable size={child ? "small" : undefined} stickyHeader={!child} sx={{ flexGrow: 1 }}>
          {!child && <Header child={child} columns={columns} />}
          <TableBody>
            {!child && paddingTopRow}
            {!!loading &&
              !rows &&
              [...Array(loadingBlankRows !== undefined ? loadingBlankRows : pageSize)].map((_, rowIndex) => (
                <TableRow sx={{ backgroundColor: rowBackgroundColor || (rowIndex % 2 === 0 ? "background.paper" : "background.default") }} key={rowIndex}>
                  {gutterTableCell}
                  {columns.map((_, colIndex) => (
                    <TableCell key={colIndex}>{skeleton}</TableCell>
                  ))}
                  {gutterTableCell}
                </TableRow>
              ))}
            {!child && !rows?.length && (
              <TableRow sx={rows?.length === 0 ? { backgroundColor: "background.paper" } : undefined}>
                {gutterTableCell}
                <TableCell colSpan={columns.length}>
                  <Typography variant="emphasize">{!disableNoRowsMessage && rows?.length === 0 && "No information to display..."}</Typography>
                </TableCell>
                {gutterTableCell}
              </TableRow>
            )}
            {rows?.map((row, rowIndex) => {
              const backgroundColor = rowBackgroundColor || (rowIndex % 2 === 0 ? "background.paper" : "background.default");
              const rowExpandNode = rowExpand?.(row, backgroundColor);

              return !rowExpandNode ? (
                <TableRow key={rowIndex} hover={!!to || !!onRowClick} sx={{ backgroundColor }}>
                  {gutterTableCell}
                  {columns.map((col, colIndex) => (
                    <Column col={col} key={colIndex} onRowClick={onRowClick} row={row} setWidths={child && rowIndex === 0} to={to?.(row, { values })} />
                  ))}
                  {gutterTableCell}
                </TableRow>
              ) : (
                <CollapseWrapper
                  initialOpen={rowExpanded !== "hidden"}
                  key={currentPage * pageSize + rowIndex}
                  onToggleCollapse={(isOpen) => onToggleCollapse?.(row, isOpen)}
                >
                  {(expandNode, open) => (
                    <>
                      <TableRow hover={!!to || !!onRowClick} sx={{ backgroundColor }}>
                        {gutterTableCell}
                        {columns.map((col, colIndex) => (
                          <Column key={colIndex} col={col} row={row} to={to?.(row, { values })} setWidths={child && rowIndex === 0} />
                        ))}
                        <TableCell sx={{ backgroundColor: "common.brandHigh", p: 0 }}>
                          <Box sx={({ spacing }) => ({ width: spacing(GUTTER_SIZE) })}>{rowExpanded !== "always" && expandNode}</Box>
                        </TableCell>
                      </TableRow>
                      <TableRow sx={{ backgroundColor }}>
                        <TableCell colSpan={columns.length + 2} sx={{ padding: 0 }}>
                          <Collapse in={open}>{rowExpandNode}</Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </CollapseWrapper>
              );
            })}
          </TableBody>
          {showFooter && (
            <TableFooter sx={{ borderTop: "none" }}>
              <TableRow sx={{ backgroundColor: footerBackgroundColor }}>
                {gutterTableCell}
                {columns.map((col, colIndex) => (
                  <TableCell align={getAlignment(col)} key={colIndex}>
                    {getFooter({ col, rows: rows! })}
                  </TableCell>
                ))}
                {gutterTableCell}
              </TableRow>
            </TableFooter>
          )}
        </MuiTable>
      </TableContainer>
      {(!_pageSizeHidden || hasMultiplePages) && (
        <Grid item container xs={12} sx={{ backgroundColor: child ? "background.default" : "background.paper", px: GUTTER_SIZE }}>
          <Grid
            container
            item
            xs={12}
            sx={{
              alignItems: "center",
              backgroundColor: child ? rowBackgroundColor : "background.paper",
              pb: child ? 1.5 : 3,
              pt: child ? 0.5 : 3,
              px: 1,
            }}
          >
            <Grid item xs>
              {hasMultiplePages && <Pagination count={pageCount} onChange={onPaginationChange} page={currentPage + 1} renderItem={renderItem} />}
            </Grid>
            {!_pageSizeHidden && (
              <Grid item sx={{ pl: 6, width: 114 }}>
                <Autocomplete disableClearable label="Rows" name="pageSize" onChange={submitForm} options={PAGE_SIZE_OPTIONS} shrinkLabel />
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

const genericMemo: <T>(component: T) => T = memo;
export const Table = genericMemo(InternalTable);

export type { Paging };
