import { Box, Typography as MuiTypography, useTheme } from "@mui/material";
import { Variant as MuiVariant } from "@mui/material/styles/createTypography";
import { CSSProperties, ReactNode, useMemo } from "react";
import { Alignment } from "../../types";

type Variant =
  | "page-heading"
  | "section-heading"
  | "page-subheading"
  | "page-subsubheading"
  | "heading"
  | "label"
  | "value"
  | "footnote"
  | "superscript"
  | "emphasize";

function variantSetup(color: string) {
  return {
    "page-heading": { variant: "h1" },
    "section-heading": { variant: "h2" },
    "page-subheading": { variant: "h3" },
    "page-subsubheading": { variant: "caption", style: { color, fontWeight: 400 } },
    heading: { variant: "h6" },
    label: { variant: "caption" },
    value: { variant: "subtitle2" },
    footnote: { variant: "caption", style: { color, fontWeight: 400 } },
    superscript: { variant: "decoration", style: { fontSize: "0.8em", paddingLeft: 1, verticalAlign: "super" } },
    emphasize: { variant: "decoration", style: { fontWeight: 500 } },
  } as Record<Variant, { variant: MuiVariant | "decoration"; style?: CSSProperties }>;
}

interface TypographyProps {
  alignment?: Alignment;
  children: ReactNode;
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  variant: Variant;
}

function Typography({ variant: variantKey, children, startAdornment, endAdornment, alignment }: TypographyProps) {
  const { palette } = useTheme();
  const variant = useMemo(() => variantSetup(palette.common.brandLow)[variantKey], [palette.common.brandLow, variantKey]);

  return variant.variant === "decoration" ? (
    startAdornment || endAdornment ? (
      <Box style={{ display: "flex", alignItems: "center", gap: 6, justifyContent: alignment }}>
        {startAdornment} <span style={variant.style}>{children}</span> {endAdornment}
      </Box>
    ) : (
      <span style={variant.style}>{children}</span>
    )
  ) : (
    <MuiTypography variant={variant.variant} style={variant.style}>
      {children}
    </MuiTypography>
  );
}

export { Typography };
