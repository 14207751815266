import { ActionReducerMapBuilder, AsyncThunk, AsyncThunkPayloadCreator, createAsyncThunk, Dispatch, Draft } from "@reduxjs/toolkit";
import { FileResponse, ProblemDetails } from "../api";
import { RootState } from "./";

interface AsyncThunkConfig {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
}

const addCase = <TResponse, TArgs, TApiConfig extends AsyncThunkConfig, TState extends { errors?: ProblemDetails }>(
  builder: ActionReducerMapBuilder<TState>,
  asyncThunk: AsyncThunk<TResponse, TArgs, TApiConfig>,
  processLoading: (state: Draft<TState>, isLoading: boolean) => void,
  processPayload?: (state: Draft<TState>, payload: TResponse) => void
) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      processLoading(state, true);
      state.errors = undefined;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      processLoading(state, false);
      processPayload && processPayload(state, action.payload);
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      processLoading(state, false);
      state.errors = action.payload as ProblemDetails;
    });
};

const addLoading = <TResponse, TArgs, TApiConfig extends AsyncThunkConfig, TState extends { isLoading: boolean; errors?: ProblemDetails }>(
  builder: ActionReducerMapBuilder<TState>,
  asyncThunk: AsyncThunk<TResponse, TArgs, TApiConfig>,
  processPayload: (state: Draft<TState>, payload: TResponse) => void
) => {
  addCase(builder, asyncThunk, (state, isLoading) => (state.isLoading = isLoading), processPayload);
};

const addExporting = <TResponse, TArgs, TApiConfig extends AsyncThunkConfig, TState extends { isExporting: boolean; errors?: ProblemDetails }>(
  builder: ActionReducerMapBuilder<TState>,
  asyncThunk: AsyncThunk<TResponse, TArgs, TApiConfig>
) => {
  addCase(builder, asyncThunk, (state, isExporting) => (state.isExporting = isExporting));
};

const addDownloading = <TResponse, TArgs, TApiConfig extends AsyncThunkConfig, TState extends { isDownloading: boolean; errors?: ProblemDetails }>(
  builder: ActionReducerMapBuilder<TState>,
  asyncThunk: AsyncThunk<TResponse, TArgs, TApiConfig>
) => {
  addCase(builder, asyncThunk, (state, isDownloading) => (state.isDownloading = isDownloading));
};

type ArgumentTypes<T> = T extends (...args: infer U) => unknown ? U : never;
type ReplaceReturnType<T, TNewReturn> = (...a: ArgumentTypes<T>) => TNewReturn;

const fileThunk = <TPayload>(
  key: string,
  data: ReplaceReturnType<AsyncThunkPayloadCreator<FileResponse, TPayload, { state: RootState; rejectValue: ProblemDetails }>, Promise<FileResponse>>,
  defaultFileName: ((payload: TPayload) => string) | string
) =>
  createAsyncThunk<void, TPayload, { state: RootState; rejectValue: ProblemDetails }>(key, (payload, options) =>
    Promise.resolve(data(payload, options))
      .then((f) => {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(f.data);
        link.download = f.fileName || (typeof defaultFileName === "function" ? defaultFileName(payload) : defaultFileName);
        link.click();
        link.remove();
      })
      .catch(options.rejectWithValue)
  );

export { fileThunk, addCase, addLoading, addExporting, addDownloading };
