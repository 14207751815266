import CalendarIcon from "@mui/icons-material/CalendarMonth";
import ClearIcon from "@mui/icons-material/Clear";
import { IconButton, InputAdornment } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { startOfDay } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { formatDateJSON, parseDate } from "../../utils";
import { BaseTextField, TextFieldProps } from "./BaseTextField";

interface BaseDateFieldProps extends Omit<TextFieldProps, "value"> {
  defaultValue?: string | Date;
  fullWidth?: boolean;
  maxDate?: string | Date;
  onChange?: (value: string | undefined) => void;
  value?: string | Date;
}

function BaseDateField({ className, defaultValue = "", fullWidth, invert, label, maxDate, onChange, shrinkLabel, value }: Omit<BaseDateFieldProps, "onError">) {
  const [keyboardInput, setKeyboardInput] = useState<string>();
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);
  const [datePickerKey, setDatePickerKey] = useState(Math.random().toString());
  const _maxDate = useMemo(() => parseDate(maxDate || new Date()), [maxDate]);
  const minDate = useMemo(() => new Date(1990, 0, 1), []);
  const _value = useMemo(() => value || defaultValue || null, [defaultValue, value]);

  const handleChange = useCallback(
    (value: Date | null | undefined) => {
      onChange?.(value ? formatDateJSON(startOfDay(value)) : "");
    },
    [onChange]
  );

  const handleAccept = useCallback(
    (newDate: Date | null) => {
      setKeyboardInput(undefined);
      handleChange(newDate);
    },
    [handleChange]
  );

  const handleBlur = useCallback(() => {
    if (keyboardInput) {
      let value = parseDate(keyboardInput);

      if (_maxDate && value && value > _maxDate) value = _maxDate;

      setKeyboardInput(undefined);
      handleChange(value);
      setOpen(false);
    }
  }, [_maxDate, handleChange, keyboardInput]);

  const handleClear = useCallback(() => {
    handleChange(undefined);
  }, [handleChange]);

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleMouseEnter = useCallback(() => {
    setHover(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setHover(false);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    // This is a workaround where the MUI DatePicker is not clearing the HTML input's value
    if (!value) setDatePickerKey(Math.random().toString());
  }, [value]);

  return (
    <DesktopDatePicker
      defaultCalendarMonth={_maxDate}
      disableFuture
      showDaysOutsideCurrentMonth
      inputFormat="dd/MM/yyyy"
      key={datePickerKey}
      maxDate={_maxDate}
      minDate={minDate}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open}
      value={_value}
      views={["year", "month", "day"]}
      onAccept={handleAccept}
      onChange={(_, keyboardInputValue) => keyboardInputValue && setKeyboardInput(keyboardInputValue)}
      renderInput={({ InputProps, inputProps, inputRef }) => (
        <BaseTextField
          className={className}
          error={false}
          fullWidth={fullWidth}
          inputRef={inputRef}
          invert={invert}
          label={label}
          shrinkLabel={shrinkLabel}
          InputProps={{
            ...InputProps,
            onBlur: handleBlur,
            sx: fullWidth ? undefined : { width: 195 },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton disabled={!_value} onClick={handleClear} sx={{ opacity: !!_value && (focus || hover) ? 1 : 0 }}>
                  <ClearIcon />
                </IconButton>
                <IconButton onClick={handleOpen}>
                  <CalendarIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onBlurCapture={() => setFocus(false)}
          onFocusCapture={handleFocus}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            ...inputProps,
            onKeyDown: (event) => {
              inputProps?.onKeyDown?.(event);
              event.key === "Enter" && event.currentTarget.blur();
            },
          }}
        />
      )}
    />
  );
}

export { BaseDateField };
export type { BaseDateFieldProps };
