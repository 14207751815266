import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { GetAccountPositionsResponse, TimeSpan } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { securitiesAsync } from "../../store/securities";
import { PositionGroupFilter } from "../../types";
import { formatWeight, toPositionGroupType } from "../../utils";
import Table from "../table";

interface GroupedSecurities {
  fundKey: string;
  positions?: GetAccountPositionsResponse;
}

export default function Strategies() {
  const dispatch = useAppDispatch();
  const { key } = useParams<{ key: string }>();
  const { holdingsAt, isLoadingHoldingsAt } = useAppSelector((as) => as.accounts);
  const { isLoadingSecurities } = useAppSelector((as) => as.securities);
  const [groupedSecurities, setGroupSecurities] = useState<GroupedSecurities[]>([]);
  const groupedHoldings = useMemo(
    () => holdingsAt?.map((performance) => performance.performance.map((holding) => ({ ...performance, from: holding.dateRange?.from }))).flat(),
    [holdingsAt]
  );

  return (
    <Table
      key={PositionGroupFilter.Strategies}
      loading={isLoadingHoldingsAt || isLoadingSecurities}
      loadingBlankRows={3}
      minHeight={276}
      pageSizeHidden
      rows={holdingsAt?.map((holding) => ({ ...holding, weight: holding.weight || 0 }))?.sort((a, b) => (a.weight > b.weight ? -1 : 1))}
      onToggleCollapse={async (row, isOpen) => {
        if (isOpen) {
          const securitiesByGroupType = await dispatch(
            securitiesAsync({
              accountNumber: key,
              pageSize: 30,
              currentPage: 0,
              fundKey: row.fundKey,
              positionGroupName: row.fundName,
              positionGroupType: toPositionGroupType(PositionGroupFilter.Strategies),
            })
          ).unwrap();

          if (!groupedSecurities.find((s) => s.fundKey === row.fundKey)) {
            setGroupSecurities((previous) => [...previous, { fundKey: row.fundKey!, positions: securitiesByGroupType }]);
          }
        }
      }}
      columns={[
        {
          heading: "Strategy",
          field: "fundName",
          display: "emphasize",
          width: 40,
        },
        {
          heading: "6 months",
          field: (row) => groupedHoldings?.find((holding) => holding.fundKey === row.fundKey && holding.from === TimeSpan.SixMonthsAgo)?.performance,
          display: "emphasize",
          width: 7,
          align: "right",
          type: "percent",
          typeOption: { decimalPlaces: 1 },
        },
        {
          heading: "1 year",
          field: (row) => groupedHoldings?.find((holding) => holding.fundKey === row.fundKey && holding.from === TimeSpan.OneYearAgo)?.performance,
          display: "emphasize",
          width: 5,
          align: "right",
          type: "percent",
          typeOption: { decimalPlaces: 1 },
        },
        {
          heading: "YTD",
          field: (row) => groupedHoldings?.find((holding) => holding.fundKey === row.fundKey && holding.from === TimeSpan.YearToDate)?.performance,
          display: "emphasize",
          width: 5,
          align: "right",
          type: "percent",
          typeOption: { decimalPlaces: 1 },
        },
        {
          heading: "",
          field: () => "",
          width: 23,
          align: "center",
        },
        {
          heading: "% of total",
          field: (row) => formatWeight(row.weight),
          display: "emphasize",
          width: 10,
          align: "right",
        },
        {
          heading: "Value",
          field: "balance",
          display: "emphasize",
          width: 15,
          type: "money",
        },
      ]}
      rowExpand={(row, backgroundColor) => (
        <Table
          child
          loadingBlankRows={3}
          paging={{ ...groupedSecurities.find((s) => s.fundKey === row.fundKey)?.positions }}
          rowBackgroundColor={backgroundColor}
          rows={groupedSecurities.find((s) => s.fundKey === row.fundKey)?.positions?.results || []}
          handlePageChange={async (page) => {
            const securitiesByGroupType = await dispatch(
              securitiesAsync({
                accountNumber: key,
                pageSize: 30,
                currentPage: page,
                fundKey: row.fundKey,
                positionGroupName: row.fundName,
                positionGroupType: toPositionGroupType(PositionGroupFilter.Strategies),
              })
            ).unwrap();

            if (groupedSecurities.find((s) => s.fundKey === row.fundKey)) {
              setGroupSecurities(
                groupedSecurities.map((security) => {
                  if (security.fundKey === row.fundKey)
                    return {
                      fundKey: row.fundKey!,
                      positions: securitiesByGroupType,
                    };
                  else {
                    return { ...security };
                  }
                })
              );
            }
          }}
          columns={[
            {
              heading: "Name",
              field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.name}</>,
              display: "emphasize",
              width: 40,
            },
            {
              heading: "6 months",
              field: (row) => row.sixMonthPerformance || 0,
              display: "emphasize",
              width: 7,
              align: "right",
              type: "percent",
              typeOption: { decimalPlaces: 1 },
            },
            {
              heading: "1 year",
              field: () => "",
              width: 5,
              align: "right",
            },
            {
              heading: "YTD",
              field: "ytdPerformance",
              display: "emphasize",
              width: 5,
              align: "right",
              type: "percent",
              typeOption: { decimalPlaces: 1 },
            },
            {
              heading: "",
              field: () => "",
              width: 23,
              align: "center",
            },
            {
              heading: "% of total",
              field: "weight",
              display: "emphasize",
              width: 10,
              align: "right",
              type: "percent",
              typeOption: { decimalPlaces: 1 },
            },
            {
              heading: "Value",
              field: "amount",
              display: "emphasize",
              width: 15,
              type: "money",
            },
          ]}
        />
      )}
    />
  );
}
