import { Box, Grid } from "@mui/material";
import { format } from "date-fns";
import { enNZ } from "date-fns/locale";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { TimeSpan } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountHoldingsAtAsync } from "../../store/accounts";
import { formatUnitsPrice, groupBy, parseDate } from "../../utils";
import { Panel, Typography } from "../common";
import { DateField, Form } from "../form";
import Table from "../table";

type FormValues = Parameters<typeof accountHoldingsAtAsync>[0];
const TIME_SPANS = [TimeSpan.SixMonthsAgo, TimeSpan.OneYearAgo, TimeSpan.YearToDate];

const holdingsAsAtLabel = (
  <>
    Holdings as at<Typography variant="superscript">1</Typography>
  </>
);

export default function Holdings({ accountNumber, group, headerLeft }: { accountNumber: string; group?: boolean; headerLeft?: ReactNode }) {
  const dispatch = useAppDispatch();
  const { holdingsAt, isLoadingHoldingsAt, account } = useAppSelector((as) => as.accounts);
  const maxDate = useMemo(() => format(parseDate(account?.balanceDate) || new Date(), "yyyy-MM-dd", { locale: enNZ }), [account?.balanceDate]);
  const dateRanges = useMemo(() => TIME_SPANS.map((timeSpan) => ({ timeSpan, to: maxDate })), [maxDate]);
  const initialValues = useMemo<FormValues>(
    () => ({ accountNumber, balanceAt: account?.balanceDate || "", dateRanges: [] }),
    [account?.balanceDate, accountNumber]
  );
  const holdingTotal = useMemo(() => holdingsAt?.reduce((prev, current) => prev + (current.balance || 0), 0), [holdingsAt]);
  const holdings = useMemo(
    () =>
      holdingsAt
        ? groupBy(holdingsAt, "fundGroupName", (items) => ({
            value: items.reduce((total, item) => total + (item.balance || 0), 0),
          }))
        : holdingsAt,
    [holdingsAt]
  );

  const getFundPerformance = useCallback(
    (timeSpan: TimeSpan, fundKey: string) =>
      holdingsAt?.find((p) => p.fundKey === fundKey)?.performance.find((p) => p.dateRange?.timeSpan === timeSpan)?.performance ?? 0,
    [holdingsAt]
  );

  const handleSubmit = useCallback(
    (values: FormValues) => {
      dispatch(accountHoldingsAtAsync({ ...values, dateRanges }));
    },
    [dateRanges, dispatch]
  );

  useEffect(() => {
    const promises = dispatch(accountHoldingsAtAsync({ accountNumber, dateRanges }));
    return () => promises.abort();
  }, [accountNumber, dateRanges, dispatch]);

  return (
    <Form initialValues={initialValues} onSubmit={handleSubmit}>
      {({ submitForm }) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Panel alternate>
            <Grid item container xs={6} alignItems="center">
              {headerLeft}
            </Grid>
            <Grid item container xs={6} sx={{ alignItems: "center", gap: 3, justifyContent: "flex-end" }}>
              <Grid item sx={{ width: 200 }}>
                <DateField fullWidth invert label={holdingsAsAtLabel} maxDate={maxDate} name="balanceAt" onChange={submitForm} shrinkLabel />
              </Grid>
            </Grid>
          </Panel>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {group ? (
              <Table
                loading={isLoadingHoldingsAt}
                loadingBlankRows={3}
                minHeight={100}
                rowExpanded="always"
                rows={holdings}
                columns={[
                  {
                    display: "emphasize",
                    field: (row) => row.fundGroupName,
                    footer: () => "Total holdings",
                    heading: "Fund",
                    width: 35,
                  },
                  {
                    align: "right",
                    field: () => "",
                    heading: "6 months",
                    width: 7,
                  },
                  {
                    align: "right",
                    field: () => "",
                    heading: "1 year",
                    width: 5,
                  },
                  {
                    align: "right",
                    field: () => "",
                    heading: "YTD",
                    width: 5,
                  },
                  {
                    align: "center",
                    field: () => "",
                    heading: "",
                    width: 23,
                  },
                  {
                    field: (row) => (holdingTotal && row?.value !== undefined && row.value !== null ? (row.value as number) / holdingTotal : undefined),
                    heading: "% of total",
                    type: "percent",
                    typeOption: { inputStyle: "decimal" },
                    width: 15,
                  },
                  {
                    display: "emphasize",
                    field: "value",
                    footer: "sum",
                    heading: "Value",
                    type: "money",
                    width: 15,
                  },
                ]}
                rowExpand={(row, backgroundColor) => (
                  <Table
                    child
                    rowBackgroundColor={backgroundColor}
                    rows={row.items}
                    columns={[
                      {
                        display: "emphasize",
                        field: (row) => <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{row.fundName}</>,
                        heading: "Fund",
                        width: 35,
                      },
                      {
                        align: "right",
                        field: (row) => getFundPerformance(TimeSpan.SixMonthsAgo, row.fundKey),
                        heading: "6 months",
                        type: "percent",
                        width: 7,
                      },
                      {
                        align: "right",
                        field: (row) => getFundPerformance(TimeSpan.OneYearAgo, row.fundKey),
                        heading: "1 year",
                        type: "percent",
                        width: 5,
                      },
                      {
                        align: "right",
                        field: (row) => getFundPerformance(TimeSpan.YearToDate, row.fundKey),
                        heading: "YTD",
                        type: "percent",
                        width: 5,
                      },
                      {
                        align: "right",
                        field: (row) => formatUnitsPrice(row.units, row.price),
                        heading: "Units & Price",
                        width: 23,
                      },
                      {
                        field: (row) => (holdingTotal && row?.balance !== undefined ? row.balance / holdingTotal : undefined),
                        heading: "% of total",
                        type: "percent",
                        typeOption: { inputStyle: "decimal" },
                        width: 15,
                      },
                      {
                        display: "emphasize",
                        field: "balance",
                        heading: "Value",
                        type: "money",
                        width: 15,
                      },
                    ]}
                  />
                )}
              />
            ) : (
              <Table
                loading={isLoadingHoldingsAt}
                loadingBlankRows={3}
                minHeight={100}
                rows={holdingsAt}
                columns={[
                  {
                    display: "emphasize",
                    field: "fundName",
                    footer: () => "Total holdings",
                    heading: "Fund",
                    width: 40,
                  },
                  {
                    align: "right",
                    field: (row) => getFundPerformance(TimeSpan.SixMonthsAgo, row.fundKey),
                    heading: "6 months",
                    type: "percent",
                    width: 7,
                  },
                  {
                    align: "right",
                    field: (row) => getFundPerformance(TimeSpan.OneYearAgo, row.fundKey),
                    heading: "1 year",
                    width: 5,
                    type: "percent",
                  },
                  {
                    align: "right",
                    field: (row) => getFundPerformance(TimeSpan.YearToDate, row.fundKey),
                    heading: "YTD",
                    width: 5,
                    type: "percent",
                  },
                  {
                    align: "right",
                    field: (row) => formatUnitsPrice(row.units, row.price),
                    width: 23,
                  },
                  {
                    align: "right",
                    field: (row) => (holdingTotal && row.balance !== undefined ? row.balance / holdingTotal : undefined),
                    heading: "% of total",
                    type: "percent",
                    typeOption: { inputStyle: "decimal" },
                    width: 10,
                  },
                  {
                    display: "emphasize",
                    field: "balance",
                    footer: "sum",
                    heading: "Value",
                    type: "money",
                    width: 15,
                  },
                ]}
              />
            )}
          </Box>
        </Box>
      )}
    </Form>
  );
}
