import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EditFileResults, FileParameter, FileType, ProblemDetails, SearchDocumentSort, SearchFileResults, StaticDataResults } from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";
import { fileThunk } from "./helpers";

interface adviseToolsState {
  isLoading: boolean;
  isSubmitting: boolean;
  errors?: ProblemDetails;
  isError: boolean;
  isKeyFileLoading: boolean;
  isKeyFileError: boolean;
  isStaticDataLoading: boolean;
  isStaticDataError: boolean;
  searchResult?: SearchFileResults;
  keyFileResult?: SearchFileResults;
  staticDataResults?: StaticDataResults;
}

export interface DeleteFileDetails {
  fileId?: string;
  fileName?: string;
}

const initialState: adviseToolsState = {
  isLoading: false,
  isSubmitting: false,
  isError: false,
  isKeyFileLoading: false,
  isKeyFileError: false,
  isStaticDataLoading: false,
  isStaticDataError: false,
};

export const searchAsync = createAsyncThunk<
  SearchFileResults,
  {
    priority: boolean;
    fileType: FileType;
    productTagIds: string[];
    categoryTagIds: string[];
    productSelected?: string;
    categorySelected?: string;
    sortOrder: SearchDocumentSort;
    term: string;
    currentPage: number;
    pageSize: number;
  },
  { state: RootState }
>("advisetools/search", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .adviseToolsClient.search(
      payload.priority,
      payload.fileType,
      payload.productTagIds,
      payload.categoryTagIds,
      payload.productSelected,
      payload.categorySelected,
      payload.term,
      payload.sortOrder,
      payload.currentPage,
      payload.pageSize,
      signal
    )
    .catch(rejectWithValue)
);

export const getKeyFilesAsync = createAsyncThunk<
  SearchFileResults,
  {
    fileType: FileType;
  },
  { state: RootState }
>("advisetools/keyfiles", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).adviseToolsClient.keyFiles(payload.fileType, signal).catch(rejectWithValue)
);

export const getUiContentData = createAsyncThunk<
  StaticDataResults,
  {
    includeRoles: boolean;
    includeProductNames: boolean;
    includeCategoryNames: boolean;
    keywordsPrefixFilter: string;
    includeKeywords: boolean;
    filterInUse: boolean;
  },
  { state: RootState }
>("advisetools/getUiContentData", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState())
    .adviseToolsClient.getUiContentData(
      payload.includeRoles,
      payload.includeProductNames,
      payload.includeCategoryNames,
      payload.keywordsPrefixFilter,
      payload.includeKeywords,
      payload.filterInUse,
      signal
    )
    .catch(rejectWithValue)
);

export interface UploadFileData {
  fileType?: FileType;
  fileId?: string;
  fileName?: string;
  url?: string;
  keywords?: string;
  priority?: boolean;
  hasContent?: boolean;
  lastUpdated?: string;
  products?: string[];
  roles?: string[];
  categoryNames?: string[];
  categorySelected?: string;
  file?: FileParameter;
}

export const uploadFile = createAsyncThunk<EditFileResults, UploadFileData, { state: RootState }>(
  "advisetools/uploadfile",
  (payload, { getState, rejectWithValue, signal }) =>
    getApis(getState())
      .adviseToolsClient.uploadFile(
        payload.fileType,
        payload.fileId,
        payload.fileName,
        payload.url,
        payload.keywords,
        payload.priority,
        payload.lastUpdated,
        payload.products,
        payload.roles,
        payload.categorySelected,
        payload.file && payload.file.fileName && payload.file.data ? payload.file : undefined,
        !payload.file || !payload.file.fileName,
        signal
      )
      .catch(rejectWithValue)
);

export const getFile = fileThunk<{
  fileId?: string;
}>("advisetools/getFile", (payload, { getState, signal }) => getApis(getState()).adviseToolsClient.getFile(payload.fileId, signal), "File.pdf");

export const deleteFile = createAsyncThunk<
  boolean,
  {
    fileId?: string;
  },
  { state: RootState }
>("advisetools/deleteFile", (payload, { getState, rejectWithValue, signal }) =>
  getApis(getState()).adviseToolsClient.deleteFile(payload.fileId, signal).catch(rejectWithValue)
);

export const adviseToolsSlice = createSlice({
  name: "adviseTools",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(searchAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(searchAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = action.payload as SearchFileResults;
      })
      .addCase(searchAsync.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })

      .addCase(getKeyFilesAsync.pending, (state) => {
        state.isKeyFileLoading = true;
        state.isKeyFileError = false;
      })
      .addCase(getKeyFilesAsync.fulfilled, (state, action) => {
        state.isKeyFileLoading = false;
        state.keyFileResult = action.payload as SearchFileResults;
      })
      .addCase(getKeyFilesAsync.rejected, (state) => {
        state.isKeyFileLoading = false;
        state.isKeyFileError = true;
      })
      .addCase(getUiContentData.pending, (state) => {
        state.isStaticDataLoading = true;
        state.isStaticDataError = false;
      })
      .addCase(getUiContentData.fulfilled, (state, action) => {
        state.isStaticDataLoading = false;
        state.staticDataResults = action.payload as StaticDataResults;
      })
      .addCase(getUiContentData.rejected, (state) => {
        state.isStaticDataLoading = false;
        state.isStaticDataError = true;
      })
      .addCase(deleteFile.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.searchResult = action.payload as unknown as SearchFileResults;
      })
      .addCase(deleteFile.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(uploadFile.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(uploadFile.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload as ProblemDetails;
      });
  },
});

export default adviseToolsSlice.reducer;
