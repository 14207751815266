import { Box, Collapse, FormControlLabel, Grid, Switch } from "@mui/material";
import { useFormikContext } from "formik";
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { Panel } from "../common";
import { FormButton } from "../form";
import { FormButtonMulti, FormButtonMultiOption } from "../form/FormButtonMulti";
import { ReportButtonOnClick } from "./ReportButton";
import ReportField from "./ReportField";

interface ReportMultiOption<Values> extends FormButtonMultiOption<Values> {
  action?: string;
}

interface ReportProps<Values> {
  name: string;
  description?: ReactNode;
  initialValues: Values;
  filters?: ReactNode;
  advancedFilters?: ReactNode;
  validate?: (values: Values, options: { action: string }) => ReactNode;
  generate?: (values: Values) => void | Promise<unknown>;
  view?: (values: Values) => void | Promise<unknown>;
  export?: ReportMultiOption<Values>[] | ((values: Values) => void | Promise<unknown>);
  onChange?: () => void;
  buttons?: (onClick: ReportButtonOnClick<Values>) => ReactNode;
  children?: ReactNode;
}

export default function Report<Values>({
  description,
  filters,
  advancedFilters,
  validate,
  generate: generateFunc,
  view: viewFunc,
  export: exportButton,
  buttons,
  onChange,
}: ReportProps<Values>) {
  const [generating, setGenerating] = useState(false);
  const [viewing, setViewing] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [error, setError] = useState<ReactNode>();
  const context = useFormikContext<Values>();

  const onClick = (action: string, values: Values, success: (values: Values) => void | Promise<unknown>, setLoading?: Dispatch<SetStateAction<boolean>>) => {
    if (validate) {
      const result = validate(values, { action });

      if (result) {
        setError(result);
        return;
      }
    }

    setError(undefined);
    setLoading?.(true);
    Promise.resolve(success(values)).then(() => setLoading?.(false));
  };

  // clear result if values have changed or if we are exiting the report
  useEffect(() => {
    onChange?.();
    return () => onChange?.();
  }, [context.values, onChange]);

  const filterAndButtons = (
    <>
      {filters}
      {!!error && (
        <Grid item xs={12} sx={{ justifyContent: "flex-end", pb: 2 }}>
          <Box component="span" sx={{ color: "error.main" }}>
            {error}
          </Box>
        </Grid>
      )}
      <ReportField dualColumn={!!advancedFilters}>
        <Grid container gap={1}>
          {!!generateFunc && (
            <FormButton loading={generating} onClick={(values: Values) => onClick("generate", values, generateFunc, setGenerating)}>
              Generate
            </FormButton>
          )}
          {!!viewFunc && (
            <FormButton loading={viewing} onClick={(values: Values) => onClick("view", values, viewFunc, setViewing)}>
              View
            </FormButton>
          )}
          {!!exportButton && typeof exportButton === "function" && (
            <FormButton variant="outlined" loading={exporting} onClick={(values: Values) => onClick("export", values, exportButton, setExporting)}>
              Export
            </FormButton>
          )}
          {!!exportButton && typeof exportButton !== "function" && (
            <FormButtonMulti
              variant="outlined"
              loading={exporting}
              options={exportButton.map((option) => ({
                ...option,
                onClick: (values: Values) => option.onClick && onClick(option.action ?? option.label, values, option.onClick, setExporting),
              }))}
            >
              Export
            </FormButtonMulti>
          )}
          {!!buttons && buttons(onClick)}
        </Grid>
      </ReportField>
    </>
  );

  return (
    <Panel joinPanelAbove>
      {advancedFilters ? (
        <>
          <Grid item container xs={6} alignContent="start">
            {!!description && (
              <ReportField dualColumn label="Description">
                {description}
              </ReportField>
            )}
          </Grid>
          <Grid item container xs={6} alignContent="end" paddingLeft={4}>
            <Grid item xs={12} paddingBottom="2px">
              <FormControlLabel
                label="Show advanced filters"
                control={<Switch color="primary" checked={showAdvancedFilters} onChange={(e) => setShowAdvancedFilters(e.currentTarget.checked)} />}
              />
            </Grid>
          </Grid>
          <Grid item container xs={6} alignContent="start">
            {filterAndButtons}
          </Grid>
          <Grid item container xs={6} alignContent="start" paddingLeft={4}>
            <Collapse in={showAdvancedFilters} style={{ width: "100%" }}>
              {advancedFilters}
            </Collapse>
          </Grid>
        </>
      ) : (
        <>
          {!!description && <ReportField label="Description">{description}</ReportField>}
          {filterAndButtons}
        </>
      )}
    </Panel>
  );
}

export type { ReportProps };
