import { Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AdviserModel, AgreementType, CreateFeeStructureCommand, GetAccountDetailsResponse, GetGisFeePresetsResponse } from "../../api";
import { useAppDispatch, useAppSelector } from "../../store";
import { accountAgreementPresetsAsync, accountCreateFeeStructureAsync } from "../../store/accounts";
import { associatedAdvisersAsync } from "../../store/advisers";
import { fundGroupsAsync } from "../../store/funds";
import { Accordion } from "../common";
import { Autocomplete, AutocompleteMultiple, Form, FormButton, TextField } from "../form";
import { GisFeeStructure, MmgFeeStructure, PrivateWealthFeeStructure } from "./fee-details";
import { FeeAgreementField } from "./FeeAgreementField";
import { GisFeeSelectPreset } from "./GisFeeSelectPreset";
import { GisFeeOther, PrivateWealthFeeOther } from "./other";
import { NoteToAdviser } from "./other/NoteToAdviser";
import { accountFeeStructureValidation } from "./validation/accountFeeStructureValidation";

interface AccountFeeStructureProps {
  adviser?: AdviserModel;
  account?: GetAccountDetailsResponse;
  agreementType: AgreementType.GIS | AgreementType.MMG | AgreementType.PrivateWealth;
  presets?: GetGisFeePresetsResponse;
}

function getAccountAddress(account: GetAccountDetailsResponse): string {
  return (
    [account.street1, account.street2, account.suburb, account.city]
      .map((address) => address?.trim())
      .filter(Boolean)
      .join("\n") + (account.postcode ? ` ${account.postcode}` : "")
  );
}

function AccountFeeStructure({ agreementType, account, adviser, presets }: AccountFeeStructureProps) {
  const dispatch = useAppDispatch();
  const { isDownloading } = useAppSelector((as) => as.accounts);
  const { advisers } = useAppSelector((as) => as.advisers);
  const { fundGroups } = useAppSelector((as) => as.funds);
  const [expandOther, setExpandOther] = useState(false);
  const clients = useMemo(
    () => account?.clients?.map((client) => ({ value: `CLIENT-${client.clientNumber}`, label: client.name ?? "" })) || [],
    [account?.clients]
  );
  const legalEntities = useMemo(
    () => account?.legalEntities?.map((legalEntity) => ({ value: `LEGAL-${legalEntity.legalEntityKey}`, label: legalEntity.name ?? "" })) || [],
    [account?.legalEntities]
  );
  const partiesToAgreement = useMemo(() => clients.concat(legalEntities), [clients, legalEntities]);
  const funds = useMemo(
    () => fundGroups?.fundGroupTypes?.find((fg) => fg.service === (account?.product ?? "ASP"))?.funds,
    [account?.product, fundGroups?.fundGroupTypes]
  );
  const initialValues = useMemo<CreateFeeStructureCommand>(
    () => ({
      accountKey: account?.accountKey || "",
      accountName: "",
      additionalClients: [],
      address: account ? getAccountAddress(account) : "",
      adviserNumber: adviser?.adviserNumber || "",
      clientEmail: "",
      clientMobile: "",
      clientPhone: "",
      partiesToAgreement: partiesToAgreement.map((party) => party.value),
      presetName: "",
      preview: false,
    }),
    [account, adviser?.adviserNumber, partiesToAgreement]
  );

  const feeEntered = useCallback(() => !expandOther && setExpandOther(true), [expandOther]);

  useEffect(() => {
    !fundGroups && dispatch(fundGroupsAsync());
  }, [dispatch, fundGroups]);

  useEffect(() => {
    !advisers && dispatch(associatedAdvisersAsync());
  }, [advisers, dispatch]);

  return (
    <Form
      initialValues={initialValues}
      validationSchema={accountFeeStructureValidation(!account)}
      onSubmit={(values) => dispatch(accountCreateFeeStructureAsync(values))}
    >
      {({ values }) => (
        <>
          <Accordion heading="Account details" defaultExpanded>
            <Grid container item xs={12} spacing={2}>
              <Grid container item xs={12} md={6} alignContent="start">
                {account ? (
                  <FeeAgreementField
                    field={
                      <AutocompleteMultiple disableTagsLimit name="partiesToAgreement" placeholder="Press enter between names" options={partiesToAgreement} />
                    }
                    label="Parties to agreement"
                  />
                ) : (
                  <FeeAgreementField label="Account name" field={<TextField name="accountName" />} />
                )}
                <FeeAgreementField
                  field={
                    <AutocompleteMultiple
                      disableTagsLimit
                      freeSolo
                      name="additionalClients"
                      options={values.additionalClients}
                      placeholder="Press enter between names"
                    />
                  }
                  label={account ? "Additional parties" : "Parties to agreement"}
                />
                {agreementType === AgreementType.GIS && presets && <GisFeeSelectPreset presets={presets} feeEntered={feeEntered} funds={funds} />}
              </Grid>
              <Grid item container xs={12} md={6} alignContent="start">
                <FeeAgreementField
                  label="Adviser"
                  field={
                    <Autocomplete
                      autoSelectIfOneOption
                      name="adviserNumber"
                      onChange={(value) => dispatch(accountAgreementPresetsAsync({ adviserNumber: value as string }))}
                      options={advisers?.map((a) => ({ value: a.number, label: `${a.name} (${a.number})` }))}
                    />
                  }
                />
                <FeeAgreementField
                  label="Client Address"
                  field={<TextField name="address" minRows={3} multiline placeholder={"Address1\nAddress2\nCity Postcode"} />}
                />
              </Grid>
            </Grid>
          </Accordion>
          <Accordion heading="Fee details" defaultExpanded>
            <Grid container item xs={12} spacing={2}>
              {agreementType === AgreementType.MMG && <MmgFeeStructure feeEntered={feeEntered} />}
              {agreementType === AgreementType.GIS && presets && <GisFeeStructure presets={presets} feeEntered={feeEntered} />}
              {agreementType === AgreementType.PrivateWealth && <PrivateWealthFeeStructure account={account} feeEntered={feeEntered} />}
            </Grid>
          </Accordion>
          <Accordion heading="Other" triggerExpand={expandOther}>
            <Grid container item xs={12} spacing={2}>
              <Grid item container xs={12} md={6}>
                {agreementType === AgreementType.MMG && <FeeAgreementField label="Notes" field={<TextField name="mmg.notes" multiline />} />}
                {agreementType === AgreementType.GIS && <GisFeeOther funds={funds} />}
                {agreementType === AgreementType.PrivateWealth && <PrivateWealthFeeOther account={account} />}
                <FeeAgreementField
                  field={
                    <>
                      <FormButton type="submit" loading={isDownloading}>
                        Finalise
                      </FormButton>
                      <FormButton
                        loading={isDownloading}
                        onClick={(values: CreateFeeStructureCommand) => dispatch(accountCreateFeeStructureAsync({ ...values, preview: true }))}
                        variant="outlined"
                      >
                        Preview
                      </FormButton>
                    </>
                  }
                />
              </Grid>
              <NoteToAdviser />
            </Grid>
          </Accordion>
        </>
      )}
    </Form>
  );
}

export { AccountFeeStructure };
