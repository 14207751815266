import { Box } from "@mui/material";
import { LocationDescriptorObject } from "history";
import { Fragment, ReactNode, useLayoutEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import Theme, { ThemeVariant } from "../../theme";
import { KeyboardArrowRightIcon, SkeletonWrapper } from "./";
import { Link } from "./Link";

type Breadcrumb = { label: ReactNode; showLoading?: boolean; theme?: ThemeVariant } & LocationDescriptorObject<unknown>;

interface BreadcrumbsProps {
  defaultBreadcrumb: Breadcrumb[];
  current?: Breadcrumb;
}

function Breadcrumbs({ defaultBreadcrumb, current }: BreadcrumbsProps) {
  const history = useHistory<{ breadcrumbs?: Breadcrumb[] } | undefined>();
  const breadcrumbs = useMemo(
    () => [...(history.location.state?.breadcrumbs || []), ...(current ? [{ ...current, showLoading: true }] : [])],
    [current, history.location.state?.breadcrumbs]
  );

  useLayoutEffect(() => {
    if (!history.location.state?.breadcrumbs || (history.location.state?.breadcrumbs.length === 0 && defaultBreadcrumb.length > 0))
      history.replace(history.location.pathname, { ...history.location.state, breadcrumbs: defaultBreadcrumb });
  }, [defaultBreadcrumb, history]);

  return (
    <Box sx={({ typography }) => ({ alignItems: "center", color: "common.black", display: "flex", fontSize: typography.pxToRem(12), pb: 1.5, pt: 3.5 })}>
      {breadcrumbs.map(({ label, showLoading, theme, ...to }, index) => {
        let breadcrumb = to.pathname ? (
          <Link to={to} variant="breadcrumb">
            {label}
          </Link>
        ) : (
          <Box
            sx={({ typography }) => ({
              backgroundColor: "common.white",
              borderRadius: 1,
              color: "text.primary",
              fontSize: typography.pxToRem(12),
              px: 2,
              py: 1,
              userSelect: "none",
            })}
          >
            {label}
          </Box>
        );

        if (showLoading)
          breadcrumb = (
            <SkeletonWrapper approxChars={24} height={30} loading={label === undefined} variant="rounded">
              {breadcrumb}
            </SkeletonWrapper>
          );

        if (theme) breadcrumb = <Theme variant={theme}>{breadcrumb}</Theme>;

        return (
          <Fragment key={index}>
            {breadcrumb}
            {index < breadcrumbs.length - 1 && <KeyboardArrowRightIcon sx={{ color: "text.secondary" }} />}
          </Fragment>
        );
      })}
    </Box>
  );
}

export type { Breadcrumb };
export { Breadcrumbs };
