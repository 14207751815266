import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetNotificationResponse, ProblemDetails, UpsertNotificationCommand } from "../api";
import { RootState } from "./";
import { getApis } from "./apiSelector";

export interface NotificationsState {
  isLoading: boolean;
  isSubmitting: boolean;
  notificationserrors?: ProblemDetails;
  errors?: ProblemDetails;
}

const initialState: NotificationsState = {
  isLoading: false,
  isSubmitting: false,
};

export const getNotification = createAsyncThunk<GetNotificationResponse, void, { state: RootState; rejectValue: ProblemDetails }>(
  "notifications/get",
  (_, { getState, rejectWithValue, signal }) => getApis(getState()).notificationsClient.get(signal).catch(rejectWithValue)
);

export const upsertNotification = createAsyncThunk<void, UpsertNotificationCommand, { state: RootState; rejectValue: ProblemDetails }>(
  "notifications/upsert",
  (payload, { getState, rejectWithValue, signal }) => getApis(getState()).notificationsClient.upsert(payload, signal).catch(rejectWithValue)
);

export const deleteNotification = createAsyncThunk<void, void, { state: RootState; rejectValue: ProblemDetails }>(
  "notifications/delete",
  (_, { getState, rejectWithValue, signal }) => getApis(getState()).notificationsClient.delete(signal).catch(rejectWithValue)
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state) => {
        state.isLoading = true;
        state.errors = undefined;
      })
      .addCase(getNotification.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.errors = action.payload;
      })
      .addCase(upsertNotification.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(upsertNotification.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(upsertNotification.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload;
      })
      .addCase(deleteNotification.pending, (state) => {
        state.isSubmitting = true;
        state.errors = undefined;
      })
      .addCase(deleteNotification.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(deleteNotification.rejected, (state, action) => {
        state.isSubmitting = false;
        state.errors = action.payload;
      });
  },
});

export default notificationsSlice.reducer;
